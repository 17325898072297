import React from 'react'
import {ReactComponent as DownloadIcon} from "../../../../../../../assets/images/icons/download.svg";
import './ActionDownload.scss';

const ActionDownload = ({link}) => {
    return (
        <a className='ActionDownload d-flex justify-content-start align-items-center' 
            href={link} target="_blank"
            rel="noreferrer"
        >
            Download <DownloadIcon />
        </a>
    );
}

export default ActionDownload;
