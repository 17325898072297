import React, { useState, useEffect, useContext } from 'react'
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Card from '../../../components/Dashboard/Card/Card';
import { SectionTitle } from '../../../components/Dashboard/SectionTitle/SectionTitle';
import { SideMenu } from '../../../components/Dashboard/SideMenu/SideMenu';
import TopBar from '../../../components/Dashboard/TopBar/TopBar';
import AvatarUpload from '../../../components/forms/AvatarUpload/AvatarUpload';
import SelectWithLabel from '../../../components/forms/SelectWithLabel/SelectWithLabel';
import TextInput from '../../../components/forms/TextInput/TextInput';
import Button from '../../../components/molecules/Button/Button';
import useFormState from '../../../hooks/useFormState';
import { ApiContext } from '../../../services/api/api-config';
import { municipalityForSelectSGroups } from '../../../services/models/municipalityUtils';
import { stateDetailSGroups } from '../../../services/models/stateUtils';
import { getEmptyWarehouse, prepareWarehouseFilesForServer, prepareWarehouseForServer, warehouseDetailSGroups, warehouseToFormData } from '../../../services/models/warehousesUtils';
import { getNotifier } from '../../../services/notifier';
import { paths } from '../../../services/routes/appRoutes';
import './WarehousesForm.scss';

const WarehousesForm = () => {
    const {t} = useTranslation();
    const { id } = useParams();
    const api = useContext(ApiContext);
    const navigate = useNavigate();
    const { form, setForm, bindInput, bindSimple } = useFormState(() => getEmptyWarehouse());
    const [states, setStates] = useState([]);
    const [municipalities, setMunicipalities] = useState([]);
    const [neighborhoods, setNeighborhoods] = useState([]);
    const loadingId = `Grower.form.${id}`;

    // -- load data
    useEffect(() => {
        // load warehouse data
        if (id) {
            api.warehouses.get({id, params: {sGroups: warehouseDetailSGroups}})
                .then(async(warehouse) => {
                    const data = warehouseToFormData(warehouse);
                    setForm( data );

                    if(data?.postalCode?.length === 5){
                        // get neighborhoods for the CP
                        const neighborhoodsParams = { zipCode: data?.postalCode };
                        const neighborhoods = await api.neighbourhoods.get({ params: neighborhoodsParams });
                        setNeighborhoods(objectToOptions(neighborhoods));
            
                        // get municipalities for the CP
                        const municipalitiesParams = { 'neighbourhoods.zipCode': data?.postalCode, sGroups: municipalityForSelectSGroups };
                        const municipalities = await api.municipalities.get({ params: municipalitiesParams});
                        setMunicipalities(objectToOptions(municipalities))
                    }
                })
                .catch(error => getNotifier().error(error.detail||error.message));
        }

        // load states
        api.states.get({ params: { sGroups: stateDetailSGroups } }).then(states => setStates(objectToOptions(states)));
    }, [api, id, setForm, t]);

    const objectToOptions = (object) => {
        return object.map(item => {
            return {
                value: item.id,
                label: item.name
            }
        }
    )};

    const handleStateChange = (option) => {
        const newForm = {...form};
        newForm.state = option;
        // update the state of the form
        setForm({...newForm});

        // get municipalities for the selected state
        api.municipalities.get({ params: { state: option.value } }).then(municipalities => setMunicipalities(objectToOptions(municipalities)));
    }

    const handleMunicipalityChange = (option) => {
        const newForm = {...form};
        newForm.municipality = option;
        // update the state of the form
        setForm({...newForm});

        // get municipalities for the selected state
        api.neighbourhoods.get({ params: { municipality: option.value } }).then(neighborhoods => setNeighborhoods(objectToOptions(neighborhoods)));
    }

    const handleChangePostalCode = async (e) => {
        const postalCode = e.target.value;
        let state = '';
        let municipality = '';
        let neighborhood = '';

        setForm({...form, postalCode});

        if(postalCode.length === 5){
            // get neighborhoods for the CP
            const neighborhoodsParams = { zipCode: postalCode };
            const neighborhoods = await api.neighbourhoods.get({ params: neighborhoodsParams });
            setNeighborhoods(objectToOptions(neighborhoods));

            // get municipalities for the CP
            const municipalitiesParams = { 'neighbourhoods.zipCode': postalCode, sGroups: municipalityForSelectSGroups };
            const municipalities = await api.municipalities.get({ params: municipalitiesParams});
            setMunicipalities(objectToOptions(municipalities))

            // if there is only one municipality, select it
            if(municipalities.length === 1){
                municipality = {
                    label: municipalities[0].name,
                    value: municipalities[0].id
                };
            }

            // if there is only one neighborhood, select it
            if(neighborhoods.length === 1){
                neighborhood = {
                    label: neighborhoods[0].name,
                    value: neighborhoods[0].id
                };
            }

            // select state
            state = {
                label: municipalities[0].state.name,
                value: municipalities[0].state.id
            };
        }

        setForm({...form, postalCode, state, municipality, neighborhood});
    }

    const handleCreateOrUpdateWarehouse = useCallback(() => {
        const {id} = form;
        const method = id ? 'update' : 'create';
        let warehouse = null;
        let warehouseFiles = null;

        try {
            warehouse = prepareWarehouseForServer(form, t);
            warehouseFiles = prepareWarehouseFilesForServer(form, t);
        } catch (error) {
            return getNotifier().error(error.message||error.detail);            
        }
        
        api.warehouses[method]({ id, params: warehouse, files: warehouseFiles })
            .then(warehouse => {
                getNotifier().success( id ? t('Warehouse-updated-successfully') : t('Warehouse-created-successfully'));
                navigate(paths.mm.warehouses);
            })
            .catch(error => getNotifier().error(error.message||error.detail));
    }, [api, form, navigate, t]);

    const loading = useSelector(s=>!!s.loadingIds[loadingId]);

    return (
        <div className="WarehousesForm dashboard-container">
            <SideMenu />
            <div className='right-content'>
                <TopBar />
                <div className='main-content'>
                    <SectionTitle>{ !id ? t('Add-new-warehouse') : t('Edit-warehouse')}</SectionTitle>

                    <div className='row'>
                        <Card className='OrderStep1'>
                            <div className='row mt-3'>
                                <div className='col-sm-6 margin-top-mobile'>
                                    <AvatarUpload
                                        label={t('Upload-warehouse-logo')}
                                        btnLabel={t('Facade-picture')}
                                        fileTypes={['jpg', 'png', 'jpeg']}
                                        maxFileSizeInMB={5}
                                        {...bindSimple('facadePicture')}
                                    />
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className='col-sm-6 margin-top-mobile'>
                                    <TextInput
                                        label={t('Name')}
                                        placeholder={t('Name')}
                                        {...bindInput('name')}
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-sm-6 col-lg-3 margin-top-mobile'>
                                    <TextInput 
                                        label={t('Postal-code')}
                                        placeholder={t('Postal-code')}
                                        value={form.postalCode}
                                        onChange={handleChangePostalCode}
                                    />
                                </div>    
                                <div className='col-sm-6 col-lg-3 margin-top-mobile'>
                                    <SelectWithLabel
                                        label={t("State")}
                                        inputProps={{
                                            options: states,
                                            onChange: handleStateChange,
                                            value: form.state
                                        }}
                                    />
                                </div> 
                                <div className='col-sm-6 col-lg-3 margin-top-mobile'>
                                    <SelectWithLabel
                                        label={t("Municipality")}
                                        inputProps={{
                                            options: municipalities,
                                            onChange: handleMunicipalityChange,
                                            value: form.municipality
                                        }}
                                    />
                                </div>
                                <div className='col-sm-6 col-lg-3 margin-top-mobile'>
                                    <SelectWithLabel
                                        label={t("Neighborhood")}
                                        inputProps={{
                                            options: neighborhoods,
                                            ...bindSimple('neighbourhood')
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-sm-6 col-lg-3 margin-top-mobile'>
                                    <TextInput 
                                        label={t('Street')}
                                        placeholder={t('Street')}
                                        {...bindInput('street')}
                                    />
                                </div>  
                                <div className='col-sm-6 col-lg-3 margin-top-mobile'>
                                    <TextInput 
                                        label={t('Ext-number')}
                                        placeholder={t('Ext-number')}
                                        {...bindInput('externalNumber')}
                                    />
                                </div>  
                                <div className='col-sm-6 col-lg-3 margin-top-mobile'>
                                    <TextInput 
                                        label={t('Int-number')}
                                        placeholder={t('Int-number')}
                                        {...bindInput('internalNumber')}
                                    />
                                </div>
                            </div>

                            <div className='row d-flex justify-content-center align-items-center buttons-container'>
                                <Button
                                    className={'btn-primary btn-outline'}
                                    onClick={() => navigate(paths.mm.warehouses) }
                                    design={'clear'}
                                >
                                    {t('Cancel')}
                                </Button>

                                <Button 
                                    className={`btn-primary btnNextStep`}
                                    onClick={handleCreateOrUpdateWarehouse}
                                    disabled={loading}
                                >
                                    { id ? t('Update') : t('Create') }
                                </Button>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WarehousesForm;