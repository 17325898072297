import React, {useCallback, useContext} from 'react'
import GeneralContainer from '../../../../../../components/layout/GeneralContainer/GeneralContainer';
import Button from '../../../../../../components/molecules/Button/Button';
import {useTranslation} from "react-i18next";
import {ApiContext} from "../../../../../../services/api/api-config";
import {getNotifier} from "../../../../../../services/notifier";
import {useSelector} from 'react-redux';
import './ValidationMessage.scss';

export const ValidationMessage = ({email}) => {
    const {t} = useTranslation();
    const api = useContext(ApiContext);
    const loadingId = 'ConfirmEmail';
    const loading = useSelector(s=>!!s.loadingIds[loadingId]);

    const reSendEmail = useCallback(() => {
        api.users.sendConfirmationMail({params: {email}, method: "POST", useCommonPath: false })
            .then(()=>{
                getNotifier().success(t('confirmation-mail-sent-to-email'));
            })
            .catch(e=>{
                console.log(e);
                return getNotifier().error(e.detail);
            });
    }, [api, email, t]);

    return (
        <div className='ConfirmEmail'>
            <GeneralContainer>
                <h1 className='Title'>{t('confirm-your-email')}</h1>
                <p className='subTitle'>{t('confirmation-mail-sent')} {email}</p>
                <p className='contentText'>{t('confirmation-mail-text')}</p>
                <p className='contentText contentText--confirm'>{t('did-not-receive-email')}</p>

                <Button className={'submit'} onClick={reSendEmail} disabled={loading}>
                    {t('re-send-confirmation-email')}
                </Button>
            </GeneralContainer>
        </div>
    )
}
