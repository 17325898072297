import React from 'react'
import './UserInfo.scss';

const UserInfo = () => {
    return (
        <div className='UserInfo'>
            <div>
                <div className='UserInfo__name'>Armando Fuentes</div>
                <div className='UserInfo__role'>Grower</div>
            </div>
            <div className='UserInfo__picture'>
                <img src='https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50?s=200' alt='Armando Fuentes' />
            </div>
        </div>
    );
}


export default UserInfo;