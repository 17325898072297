import React, {useState, useContext, useEffect, useMemo} from 'react'
import Modal from '../../../../../../../components/Modal/Modal';
import useFormState from '../../../../../../../hooks/useFormState';
import { getEmptyCompanyProduct, companyProductDetailSGroups, companyProductToFormData, prepareNewCompanyProductForServer } from '../../../../../../../services/models/companyProductUtils';
import { ApiContext } from '../../../../../../../services/api/api-config';
import { useTranslation } from 'react-i18next';
import TextInput from '../../../../../../../components/forms/TextInput/TextInput';
import SearchBarDateFilter from '../../../../../../../components/SearchBar/filters/SearchBarDateFilter/SearchBarDateFilter';
import SelectWithLabel from '../../../../../../../components/forms/SelectWithLabel/SelectWithLabel';
import { sectorForSelectSGroups } from '../../../../../../../services/models/sectorUtils';
import { getNotifier } from '../../../../../../../services/notifier';
import Textarea from '../../../../../../../components/forms/Textarea/Textarea';
import { productSelectSGroups } from '../../../../../../../services/models/productUtils';
import { handlingTypesOptions } from '../../../../../../../services/models/companyProductUtils';

const SectorProductForm = ({productId, onClose, onCreateProduct, secondaryButtonAction, orchard, grower}) => {
    const {t} = useTranslation();
    const { form, bindSimple, bindInput, setForm } = useFormState(getEmptyCompanyProduct());
    const api = useContext(ApiContext);
    const [products, setProducts] = useState([]);
    const [sectorOptions, setSectorOptions] = useState();

    const sectorParams = useMemo(() => ({
        orchard: orchard.id,
        sGroups: sectorForSelectSGroups,
    }), [orchard]);

    // load initial 
    useEffect(() => {
        if(productId && typeof productId !== "undefined")
            api.companyProducts.get({ params:{id:productId, sGroups: companyProductDetailSGroups}})
                .then(products => {
                    setForm(companyProductToFormData(products[0]));
                });
    }, [api, productId, setForm]);


    useEffect(() => {
        // get sector from api
        api.sectors.get({params: sectorParams})
            .then(sectors => setSectorOptions(sectors.map(sector => ({ value: sector.id, label: sector.name }))))
            .catch(e => getNotifier().error(e.message));
            
        // get products from api
        api.products.get({params: {sGroups: productSelectSGroups}})
            .then(setProducts)
            .catch(e => getNotifier().error(e.message||e.detail));
    }, [orchard, api, sectorParams]);

    const productOptions = useMemo(() => products.map(product => ({ value: product.id, label: product.baseProduct?.name||product.productCode })), [products]);
    const variations = products?.find(product => product.id === form.product?.value)?.variationAttributes||[];

    const handleSubmit = () => {
        let newProduct = null;
        const {id} = form;
        const method = id ? 'update' : 'create';

        try {
            newProduct = prepareNewCompanyProductForServer(form, variations, t);
            newProduct.company = grower.id;
        } catch (error) {
            return getNotifier().error(error.message||error.detail);
        }

        api.companyProducts[method]({id, params: newProduct}).then(createdProduct => {
            onCreateProduct();
            getNotifier().success(t('Product-saved'));
        }).catch(error => getNotifier().error(error.message||error.detail));
    }

    return (
        <Modal
            title={t('Add-new-product')}
            secondaryButtonAction={secondaryButtonAction}
            mainButtonAction={handleSubmit}
            onClose={onClose}
            className={'NewProductModal'}
        >
            <div className='SectorProductForm'>
                <div className='row'>
                    <div className='col-md-6'>
                        <TextInput
                            label={'Productor ID'}
                            placeholder={'Productor ID'}
                            className={'mt-modal-input TextInput--disabled'}    
                            inputProps={{disabled: true, readOnly: true}}
                            value={grower?.id}
                        />
                    </div>
                    <div className='col-md-6'>
                        <TextInput
                            label={'Product ID'}
                            placeholder={'Product ID'}
                            className={'mt-modal-input TextInput--disabled'}
                            inputProps={{disabled: true, readOnly: true}}
                            value={productId}
                        />
                    </div>
                </div> 

                <div className='row'>
                    <div className='col-md-6'>
                        <SelectWithLabel
                            className='mt-modal-input'
                            label={t('Sector')}
                            inputProps={{
                                options: sectorOptions,                            
                                ...bindSimple('sector')
                            }}
                        />
                    </div>
                    <div className='col-md-6'>
                        <SelectWithLabel
                            className='mt-modal-input'
                            label={t('Product')}
                            inputProps={{
                                options: productOptions,
                                ...bindSimple('product')
                            }}
                        />
                    </div>
                </div>

                <div className='row'>
                    <div className='col-md-6'>
                        <TextInput
                            label={`${t('Estimated-quantity')} (Tons)`}
                            placeholder={`${t('Quantity')} Tons`}
                            className={'mt-modal-input'}
                            {...bindInput('quantity')}
                            type="number"
                        />
                    </div>
                    <div className='col-md-6'>
                        <SelectWithLabel
                            className='mt-modal-input'  
                            label={t('Handling')}
                            inputProps={{
                                options: handlingTypesOptions,
                                ...bindSimple('handling')
                            }}
                        />
                    </div>
                </div>

                <div className='row'>
                    <div className='col-md-6'>
                        <SearchBarDateFilter
                            placeholder={t("Estimated-start-date")}
                            label={t('Estimated-start-date')}
                            withIcon={true}
                            className={'mt-modal-input'}
                            {...bindSimple('estimatedStartDate')}
                            disabled={true}
                        />
                    </div>
                    <div className='col-md-6'>
                        <SearchBarDateFilter
                            placeholder={t("Estimated-end-date")}
                            label={t('Estimated-end-date')}
                            withIcon={true}
                            className={'mt-modal-input'}
                            {...bindSimple('estimatedEndDate')}
                            disabled={true}
                        />
                    </div>
                </div>

                <div className='row'>
                    <div className='col-md-12'>
                        <Textarea
                            label={t('Description')}
                            placeholder={t('Description')}
                            className={'mt-modal-input'}
                            {...bindInput('description')}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default SectorProductForm;