import React, { useMemo, useCallback } from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import useFormState from '../../hooks/useFormState';
import { ApiContext } from '../../services/api/api-config';
import { activeIngredientsTableSGruops } from '../../services/models/activeIngredientsUtils';
import { companyWithOrchardsSGroups } from '../../services/models/companyUtils';
import { getEmptyFoliarApplicationDetail } from '../../services/models/foliarApplicationDetailUtils';
import { applicationUnitOptions, 
    getEmptyFoliarApplication, 
    prepareFoliarApplicationToServer, 
    foliarApplicationsDetailSGroups,
    foliarApplicationToFormData
} from '../../services/models/foliarApplicationUtils';
import { getNotifier } from '../../services/notifier';
import MiniTitle from '../Dashboard/MiniTitle/MiniTitle';
import SelectWithLabel from '../forms/SelectWithLabel/SelectWithLabel';
import Switch from '../forms/Switch/Switch';
import Textarea from '../forms/Textarea/Textarea';
import Modal from '../Modal/Modal';
import Button from '../molecules/Button/Button';
import SearchBarDateFilter from '../SearchBar/filters/SearchBarDateFilter/SearchBarDateFilter';
import FoliarApplicationItem from './components/FoliarApplicationItem/FoliarApplicationItem';
import './FoliarApplicationsForm.scss';

const FoliarApplicationsForm = ({id, selectedProductor, selectedOrchard, orchards, application, onClose, reloadTable}) => {
    const {t} = useTranslation();
    const api = useContext(ApiContext);
    const [productors, setProductors] = useState();
    const [activeIngredients, setActiveIngredients] = useState([]);
    const {form, setForm, bindSimple, bindInput} = useFormState(getEmptyFoliarApplication({productor: selectedProductor, orchard: selectedOrchard}));

    // --- load initial data
    useEffect(() => {
        api.companies.get({params: {sGroups: companyWithOrchardsSGroups}}).then(setProductors);
        api.activeIngredients.get({params: {sGroups: activeIngredientsTableSGruops}}).then(setActiveIngredients);

        if (application) {
            api.foliarApplications.get({id: application.id?.toString(), params: {sGroups: foliarApplicationsDetailSGroups}})
                .then(application => setForm( foliarApplicationToFormData(application) ));
        }
    }, [api, application, setForm]);

    const productorOptions = useMemo(() => productors?.map(productor => 
        ({value: productor.id, label: `${productor.businessName} - ${productor.contactEmail}`})), 
    [productors]);

    const productor = productors?.find(productor => productor.id === form.productor?.value);
    const orchardOptions = useMemo(() => productor?.orchards?.map(orchard => ({ value: orchard.id, label: orchard.name })), [productor]);
    const sectors = useMemo(() => orchards?.find(orchard => orchard.id === form.orchard?.value)?.sectors, [orchards, form.orchard])||[];
    const activeIngredientsOptions = useMemo(() => activeIngredients?.map(activeIngredient => ({ value: activeIngredient.id, label: activeIngredient.name })), [activeIngredients]);

    const handleSaveApplication = () => {
        const method = !form.id ? 'create' : 'update';
        const id = form.id||null;
        let foliarApplication = null;

        try {
            foliarApplication = prepareFoliarApplicationToServer(form, sectors, t);
        } catch (error) {
            return getNotifier().error(error.message||error.detail);
        }

        api.foliarApplications[method]({id, params: foliarApplication})
            .then( fa => {
                getNotifier().success(id ? t('foliarApplicationSaved') : t('foliarApplicationCreated'));
                onClose();
                reloadTable();
            })
            .catch(error => getNotifier().error(error.message||error.detail));
    }

    const addFoliarApplicationDetail = useCallback(()=>{
        setForm({
          ...form,
          foliarApplicationDetails: [...form.foliarApplicationDetails, getEmptyFoliarApplicationDetail()]
        });
    },[setForm, form]);

    const removeFoliarApplicationDetail = useCallback((index)=>{
        setForm({
            ...form,
            foliarApplicationDetails: form.foliarApplicationDetails.filter((_, i) => i !== index)
        });
    }, [setForm, form]);

    const handleFoliarApplicationDetailChange = useCallback((index, foliarApplicationDetail)=>{
        const newFoliarApplicationDetails = [...form.foliarApplicationDetails];
        newFoliarApplicationDetails[index] = foliarApplicationDetail;
        setForm({ ...form, foliarApplicationDetails: newFoliarApplicationDetails });
    }, [setForm, form]);

    return (
        <Modal
            title={t('Add-foliar-application-to-sectors')}
            className="FoliarApplicationsForm"
            onClose={onClose}
            mainButtonAction={handleSaveApplication}
            secondaryButtonAction={onClose}
        >
            <div className='row mt-3'>
                <div className='col-md-4'>
                    <SelectWithLabel
                        label={t('Productor')}
                        inputProps={{
                            options: productorOptions,
                            ...bindSimple('productor'),
                            placeholder: t('Select productor')
                        }}
                    />
                </div>
                <div className='col-md-4 margin-top-mobile'>
                    <SelectWithLabel
                        label={t('Orchard')}
                        inputProps={{
                            options: orchardOptions,
                            ...bindSimple('orchard'),
                            placeholder: t('Select orchard')
                        }}
                    />
                </div>
                <div className='col-md-4 margin-top-mobile'>
                    <SearchBarDateFilter
                        label={t('Date')}
                        name='date'
                        {...bindSimple('date')}
                        className="mt-0"
                    />
                </div>
            </div>
            
            <div className='mt-3'>
                {t('Select the sectors to which you want to add the foliar application')}
            </div>

            <div className='row'>
                <div className='col'>
                    {sectors.length===0 && <div className='mt-3'><i>{t('Please-select-the-orchard')}</i></div>}

                    {sectors?.map(sector => (
                        <div key={sector.id} className="mt-3">
                            <Switch
                                {...bindSimple(`sectors.${sector.id}`)}
                            />
                            {sector.name}
                        </div>
                    ))}
                </div>
            </div>

            <div className="row mt-3">
                <div className='col'>
                    <div className='d-flex align-items-center justify-content-start gap-3'>
                        <MiniTitle className="mt-0 mb-0">{t('Fill-the-products-applied-in-the-sector')}:</MiniTitle>
                        <Button design="link" onClick={addFoliarApplicationDetail}>
                            {t('Add-product')}
                        </Button>
                    </div>
                </div>
            </div>

            <div className="row mt-3">
                <div className='col'>
                    {form.foliarApplicationDetails?.map((foliarApplicationDetail, index) => 
                        <FoliarApplicationItem 
                            foliarApplicationDetail={foliarApplicationDetail} 
                            key={index}
                            index={index}
                            applicationUnitOptions={applicationUnitOptions}
                            activeIngredientsOptions={activeIngredientsOptions}
                            onDelete={() => removeFoliarApplicationDetail(index)}
                            onChange={(_foliarApplicationDetail) => handleFoliarApplicationDetailChange(index, _foliarApplicationDetail)}
                        />)}
                </div>    
            </div>

            <div className='row mt-3'>
                <div className='col'>
                    <Textarea                        
                        label={t('Other-comments')}
                        placeholder={t('Add-other-comments-here')}
                        {...bindInput('otherComments')}
                    />
                </div>
            </div>
        </Modal>
    );
}

export default FoliarApplicationsForm;