import moment from 'moment';
import enLocale from "moment/locale/en-gb";
import esLocale from "moment/locale/es";

const MAX_HOURS = 24;

/**
 * It returns a string that represents the distance in time from the given date to now
 * @param date - The date to be formatted.
 * @param [lang=en] - The language to use for the date.
 * @returns A string with the date in the format 'LL, h:mma'
 */
export const getDistanceDateFromNow = (date, lang='en') => {
    moment.locale(lang, [enLocale, esLocale]);
    const now = moment();
    const dateMoment = moment(date);
    const diff = now.diff(dateMoment, 'hours');
    
    if (diff < MAX_HOURS) {
        return dateMoment.fromNow();    
    }

    return dateMoment.format('LL, h:mma');
};