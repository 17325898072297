import Login from "../../scenes/public/Login/Login";
import RegisterForm from "../../scenes/public/Register/RegisterForm/RegisterForm";
import RoleSelection from "../../scenes/public/Register/RoleSelection/RoleSelection";
import ValidateAccount from "../../scenes/public/Register/ValidateAccount/ValidateAccount";

export const paths={
    login:'/login',
    registerRole:'/register/role',
    registerForm:'/register/:role',
    validateAccount: '/validate-email',
};

const notLoggedRoutes=[
    {path:paths.login, Component: Login},
    {path:paths.registerRole, Component: RoleSelection},
    {path:paths.registerForm, Component: RegisterForm},
    {path:paths.validateAccount, Component: ValidateAccount},
];

export default notLoggedRoutes;
