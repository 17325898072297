import React from 'react'
import { ClientDetails } from './components/ClientDetails/ClientDetails';
import ClientLogo from './components/ClientLogo/ClientLogo';
import './ClientCard.scss';

const ClientCard = ({client}) => {
    return (
        <div className='ClientCard'>
            <ClientLogo logo={client.logo} />
            <ClientDetails client={client} />
        </div>
    );
}

export default ClientCard;
