import React from 'react'

import './CardHeader.scss';

const CardHeader = ({ children }) => {
    return (
        <div className='CardHeader'>
            {children}
        </div>
    );
}

export default CardHeader;