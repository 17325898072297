import React, { useContext } from 'react'
import {useTranslation} from "react-i18next";
import Typography from '../../../../../../../components/Dashboard/Typography/Typography';
import Switch from '../../../../../../../components/forms/Switch/Switch';
import TextInput from '../../../../../../../components/forms/TextInput/TextInput';
import UploadFile from '../../../../../../../components/forms/UploadFile/UploadFile';
import SearchBarDateFilter from '../../../../../../../components/SearchBar/filters/SearchBarDateFilter/SearchBarDateFilter';
import useCallbackCreator from 'use-callback-creator';
import { ApiContext } from '../../../../../../../services/api/api-config';
import moment from 'moment';

const Certification = ({certification, title, onChange, isGeneric=false}) => {
    const {t} = useTranslation();
    const api = useContext(ApiContext);

    const handleChange = useCallbackCreator((name, value) => {
        onChange({ ...certification, [name]: (value.target?.value ? value.target.value : value) });
    }, [onChange, certification]);

    const isValidCertification = ((!certification?.validity || !certification?.active) || 
                                        (certification?.active && moment(certification?.validity).isAfter(moment())));
    
    return (
        <>
            <Typography 
                tag="h3" 
                className={"Analysis__subtitle"}
            >
                .{title}
            </Typography>
            {isGeneric && 
                <div className="row mb-3">
                    <div className="col-md-6">
                        <TextInput
                            
                            label={t('Name')}
                            placeholder={t('Name')}
                            onChange={handleChange('name')}
                            value={certification?.name}
                        />
                    </div>
                </div>}

            <div className='row row--border-bottom'>
                <div className='col-6 col-lg-3 margin-top-mobile center-all'>
                    <UploadFile
                        onChange={file => onChange({...certification, file})}
                    >
                        <div className='Link-analysis'>
                            {certification?.file?.id ? `+ ${t("Replace-file")}` : `+ ${t("Add-file")}`}
                        </div>

                        {certification?.file?.id &&
                        <div className='Link-analysis'>
                            <a href={api.appFiles.getUrl(certification?.file)} target="_blank" rel='noreferrer'>{t('Download')}</a>
                        </div>}
                    </UploadFile>
                </div>
                <div className='col-6 col-lg-3 margin-top-mobile'>
                    <SearchBarDateFilter
                        placeholder={"Date"}
                        label={t('Date')}
                        withIcon={true}
                        onChange={handleChange('validity')}
                        value={certification?.validity}
                        className='mt-0'
                        showError={!isValidCertification}
                        errorMessage={t('expired-certification-message')}
                    />
                </div>
                <div className='col-6 col-lg-3 margin-top-mobile'>
                    <TextInput
                        label={'Links'}
                        placeholder={t('Add-Links')}
                        onChange={handleChange('links')}
                        value={certification?.links||''}
                    />
                </div>
                <div className='col-6 col-lg-3 margin-top-mobile d-flex justify-content-center align-items-center gap-3'>
                    {t('Active-certification')}
                    <Switch 
                        checked={true}
                        onChange={handleChange('active')}
                        value={certification?.active||false}
                    />
                </div>
            </div> 
            
        </>
    );
}

export default Certification;