import React, {useState, useContext, useEffect} from 'react'
import TideEntitySelect from '../../../../../components/utility/TideEntitySelect/TideEntitySelect';
import useCallbackCreator from 'use-callback-creator';
import {useTranslation} from "react-i18next";
import ProductMainInfo from '../ProductMainInfo/ProductMainInfo';
import SelectedProductDetail from '../SelectedProductDetail/SelectedProductDetail';
import { ApiContext } from '../../../../../services/api/api-config';
import { companyProductsWithProductGroups } from '../../../../../services/models/companyProductUtils';
import ProductDetailsForm from '../ProductDetailsForm/ProductDetailsForm';
import SimpleAcordeon from '../../../../../components/Dashboard/SimpleAcordeon/SimpleAcordeon';
import { getNotifier } from '../../../../../services/notifier';
import Button from '../../../../../components/molecules/Button/Button';
import { useCallback } from 'react';
import { productEditSGroups } from '../../../../../services/models/productUtils';
import Modal from '../../../../../components/Modal/Modal';
import useBoolean from '../../../../../hooks/useBoolean';

const additionalProductFilters = {
    sGroups: productEditSGroups
};

const OrderProductForm = ({ orderProduct, onChange, reloadOrder, onRemove, brands }) => {
    const {t} = useTranslation();
    const api = useContext(ApiContext);
    const [productors, setProductors] = useState([]);
    const [showDeleteConfirmation, openDeleteConfirmation, closeDeleteConfirmation] = useBoolean(false);
    const [productorToDelete, setProductorToDelete] = useState();

    const loadProductorsWithProduct = useCallback((product) => {
        const productId = product.id;
        api.companyProducts.get({params: {
            'product.id': productId,
            sGroups: companyProductsWithProductGroups,
        }})
        .then(setProductors)
        .catch(error => getNotifier().error(error.message||error.detail));
    }, [api]);

    useEffect(() => {
        if(orderProduct.product)
            loadProductorsWithProduct(orderProduct.product);
    }, [api, orderProduct, loadProductorsWithProduct]);

    const handleChange = useCallbackCreator((name, value) => {
        let newOrderProduct = {...orderProduct};
        newOrderProduct[name] = value;

        onChange(newOrderProduct);

        if (name === 'product') loadProductorsWithProduct(value);
    }, [onChange, orderProduct]);

    const handleInputChange = useCallbackCreator((name, e) => {
        onChange({ ...orderProduct, [name]: e.target?.value });
    }, [onChange, orderProduct]);

    const productorOptions = [
        ...new Map(productors.map(productor => ({ value: productor.sector?.orchard.company.id, label: productor.sector?.orchard.company.companyName }))
            .map((productor) => [productor.value, productor])).values(),
    ];

    const handleAddNewProductor = () => {
        onChange({ ...orderProduct, productors: [
            ...orderProduct.productors,
            { id:null },
        ] });
    };

    const onProductorChange = (productor, index) => {
        orderProduct['productors'][index] = productor;
        onChange({ ...orderProduct});
    }
    
    const onDeleteProductor = (productor, index) => {
        if(!productor.id) {
            const newProductors = [...orderProduct.productors];
            newProductors.splice(index, 1);
            onChange({ ...orderProduct, productors: newProductors });
        } else {
            setProductorToDelete(productor.id);
            // Show modal to confirm delete
            openDeleteConfirmation();
        }
    }

    const handleConfirmDeleteProductor = useCallback(() => {
        if(!productorToDelete) return;

        api.productOffers.delete({id: productorToDelete})
        .then(() => {
            getNotifier().success(t('Productor-deleted-successfully'));
            closeDeleteConfirmation();
            reloadOrder();
        })
        .catch(error => getNotifier().error(error.message||error.detail));
    }, [api, productorToDelete, t, reloadOrder, closeDeleteConfirmation]);

    const productorTotalBoxes = orderProduct?.productors?.reduce(function(acc, p) { return acc + p.boxes; }, 0)
    const boxesLeft = orderProduct.noBoxes - (isNaN(productorTotalBoxes) ? 0 : productorTotalBoxes);

    return (
        <div>
            <div className='row mt-3'>
                <div className='col-md-12'>
                    <TideEntitySelect
                        entity='products'
                        className='products-select'
                        placeholder={t('Search-by-name-or-id')}
                        onChange={handleChange('product')}
                        preload
                        additionalFilters={additionalProductFilters}
                        value={orderProduct?.product}
                        labelCreator={product => `${product.id} - ${product.baseProduct?.name||product.productCode}`}
                    />

                    {orderProduct?.product && <SelectedProductDetail product={orderProduct?.product}/>}
                </div>
            </div>

            <ProductMainInfo
                handleInputChange={handleInputChange}
                handleChange={handleChange}
                orderProduct={orderProduct}
                brands={brands}
            />

            <SimpleAcordeon className='ProductDetail mt-3' title={t('Details')} alwaysOpen={true}>

            {orderProduct?.productors?.map((productor, index) => 
                <ProductDetailsForm
                    key={index}
                    index={index}
                    productor={productor}
                    onChange={(data) => onProductorChange(data, index)}
                    productorOptions={productorOptions}
                    boxesLeft={boxesLeft}
                    handleRemoveProductor={() => onDeleteProductor(productor, index)}
                />)}
            </SimpleAcordeon>


            <Button
                design='link'
                onClick={handleAddNewProductor} >+ {t('Add-New-Productor')}
            </Button>

            {showDeleteConfirmation && 
                <Modal
                    title={t('Confirm')}
                    onClose={closeDeleteConfirmation}
                    mainButtonAction={handleConfirmDeleteProductor}
                    confirmText={t('Upload')}
                    confirmDisabled={false}
                    show={false}
                    mainButtonColor='danger'
                    secondaryButtonAction={closeDeleteConfirmation}
                >
                    <p>{t('Are-you-sure-you-want-to-delete-this-productor')}</p>
                    <p>{t('This-data-will-be-lost')}</p>
                </Modal>}
        </div>
    );
}

export default OrderProductForm;