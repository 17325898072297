import React from 'react'
import MiniTitle from '../../../../../components/Dashboard/MiniTitle/MiniTitle';
import {useTranslation} from "react-i18next";
import TextInput from '../../../../../components/forms/TextInput/TextInput';
import './Quality.scss';
import MmSelect from '../../../../../components/forms/MmSelect/MmSelect';
import { qualityOptions } from '../../../../../services/qualityUtils';

const Quality = ({bindInput, bindSimple}) => {
    const {t} = useTranslation();

    return (
        <div className='Quality'>
            <MiniTitle className="product-title mt-4">{t('Quality')}</MiniTitle>

            <div className='row mt-3'>
                <div className='col-md-4'>
                    <MmSelect
                        label={t('Caliber')}
                        placeholder={t('Caliber')}
                        options={qualityOptions(t)}
                        {...bindSimple('caliber')}
                    />
                </div>
                <div className='col-md-8'>
                    <TextInput
                        label={t('Comments')}
                        placeholder={t('Comments')}
                        {...bindInput('caliberComments')}
                    />
                </div>  
            </div>

            <div className='row'>
                <div className='col-md-4'>
                    <MmSelect
                        label={t('Consistency')}
                        placeholder={t('Consistency')}
                        options={qualityOptions(t)}
                        {...bindSimple('consistency')}
                    />
                </div>
                <div className='col-md-8'>
                    <TextInput
                        label={t('Comments')}
                        placeholder={t('Comments')}
                        {...bindInput('consistencyComments')}
                    />
                </div>  
            </div>
        </div>
    );
}

export default Quality;