import classNames from 'classnames';
import React from 'react'
import { Link } from 'react-router-dom';
import Card from '../../../../../components/Dashboard/Card/Card';
import './OrdersMenu.scss';

const OrdersMenu = ({steps, currentStep, orderId}) => {
    return (
        <Card className='OrdersMenu'>
            {steps.filter(step => !step.hidden).map((step, index) => 
                <Link key={index} to={step.link.replace(':id', orderId)} className={classNames('OrdersMenu__item', index===currentStep ? 'OrdersMenu__item--active' : '') }>
                    {step.name}
                </Link>)}
        </Card>
    );
}

export default OrdersMenu;