import React from 'react'
import { SectionTitle } from '../../../components/Dashboard/SectionTitle/SectionTitle';
import { SideMenu } from '../../../components/Dashboard/SideMenu/SideMenu';
import TopBar from '../../../components/Dashboard/TopBar/TopBar';
import CardWithImage from '../../../components/Dashboard/CardWithImage/CardWithImage';
import avocadoImage from '../../../assets/images/dummy/avocado.jpg';
import { SearchTextbox } from '../../../components/forms/SearchTextbox/SearchTextbox';
import { t } from 'i18next';
import './Products.scss';
import SearchBar from '../../../components/SearchBar/SearchBar';
import ImageWithLabel from '../../../components/Dashboard/ImageWithLabel/ImageWithLabel';
import {filterTypes} from "../../../services/searchUtils";

const filtersConfig = {
    filters: [
        {
            type: filterTypes.SELECT,
            field: 'value',
            label:'Productos',
            options:[{'label': 'Avocado', 'value': 'Avocado'}, {'label': 'Onion', 'value': 'Onion'}],
            placeholder: "All Products"
        },
        {
            type: filterTypes.SELECT,
            field: 'value',
            label:'Status',
            options:[{'label': 'PENDING', 'value': 'PENDING'}, {'label': 'DELIVERED', 'value': 'DELIVERED'}],
            placeholder: "All Status"
        },
        {
            type: filterTypes.SELECT,
            field: 'value',
            label:'Status',
            options:[{'label': 'date1', 'value': 'date1'}, {'label': 'date2', 'value': 'date2'}],
            placeholder: "All Dates"
        }
    ]
};
const Products = () => {
    return (
        <div className="Products">
            <SideMenu />
            <div className='right-content'>
                <TopBar />
                <div className='main-content'>
                    <SectionTitle>My products</SectionTitle>

                    <div className='row'>
                        <SearchTextbox
                            placeholder={t('Search by name, product...')}
                        />
                    </div>

                    <div className="row">
                        <div className="">
                            <SearchBar
                                filtersConfig={filtersConfig}
                            />
                        </div>
                    </div>  

                    <div className="row">
                        <div className="col-xs-6 col-sm-6 col-lg-3">
                            <CardWithImage 
                                image={avocadoImage} 
                                title={"Hass Avocado"}
                                subTitle={"$13.44 US"} 
                                unit={"kg"}
                                showLikeButton={true}
                                productId={'11111'}
                                onLike={() => {}}
                            >
                                <ImageWithLabel
                                    image={<img src='https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50?s=200' alt='Juan Alvarez' />}
                                    label={'Juan Alvarez'}
                                    align={'left'}
                                />
                            </CardWithImage>
                        </div>
                        <div className="col-xs-6 col-sm-6 col-lg-3">
                            <CardWithImage 
                                image={avocadoImage} 
                                title={"Hass Avocado"}
                                subTitle={"$13.44 US"} 
                                unit={"kg"}
                                content={"Wholesale 4"}
                                showLikeButton={true}
                                productId={'22211'}
                                onLike={() => {}}
                            >
                                <ImageWithLabel
                                    image={<img src='https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50?s=200' alt='Juan Alvarez' />}
                                    label={'Juan Alvarez'}
                                    align={'left'}
                                />
                            </CardWithImage>
                        </div>
                        <div className="col-xs-6 col-sm-6 col-lg-3">
                            <CardWithImage 
                                image={avocadoImage} 
                                title={"Hass Avocado"}
                                subTitle={"$13.44 US"} 
                                unit={"kg"}
                                content={"Wholesale 4"}
                                showLikeButton={true}
                                productId={'32211'}
                                onLike={() => {}}
                            >
                                <ImageWithLabel
                                    image={<img src='https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50?s=200' alt='Juan Alvarez' />}
                                    label={'Juan Alvarez'}
                                    align={'left'}
                                />
                            </CardWithImage>
                        </div>
                        <div className="col-xs-6 col-sm-6 col-lg-3">
                            <CardWithImage 
                                image={avocadoImage} 
                                title={"Hass Avocado"}
                                subTitle={"$13.44 US"} 
                                unit={"kg"}
                                content={"Wholesale 4"}
                                showLikeButton={true}
                                productId={'44455'}
                                onLike={() => {}}
                            >
                                <ImageWithLabel
                                    image={<img src='https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50?s=200' alt='Juan Alvarez' />}
                                    label={'Juan Alvarez'}
                                    align={'left'}
                                />
                            </CardWithImage>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Products;