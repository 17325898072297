import React, {useEffect, useState, useContext} from 'react'
import { SectionTitle } from '../../../../../components/Dashboard/SectionTitle/SectionTitle';
import TextInput from '../../../../../components/forms/TextInput/TextInput';
import UploadFile from '../../../../../components/forms/UploadFile/UploadFile';
import {useTranslation} from "react-i18next";
import { companyDetailSGroups, companyToFormData, financingProfilesOptions, getExportProgramOptions, personTypeOptions, prepareFilesForServer, prepareGrowerForServerStep, REGISTRY_STATUS_PENDING } from '../../../../../services/models/companyUtils';
import { ApiContext } from '../../../../../services/api/api-config';
import { stateDetailSGroups } from '../../../../../services/models/stateUtils';
import FileLink from '../../../../../components/Dashboard/FileLink/FileLink';
import GrowerChangeStatusButtons from '../GrowerChangeStatusButtons/GrowerChangeStatusButtons';
import useFormState from '../../../../../hooks/useFormState';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../../../../../components/molecules/Button/Button';
import { getNotifier } from '../../../../../services/notifier';
import {ReactComponent as NextIcon} from "../../../../../assets/images/icons/arrow-details.svg";
import { paths } from '../../../../../services/routes/appRoutes';
import { municipalityForSelectSGroups } from '../../../../../services/models/municipalityUtils';
import SelectWithLabel from '../../../../../components/forms/SelectWithLabel/SelectWithLabel';
import GrowerDocumentsList from '../GrowerDocumentsList/GrowerDocumentsList';
import MMPhoneInput from '../../../../../components/forms/MMPhoneInput/MMPhoneInput';
import MiniTitle from '../../../../../components/Dashboard/MiniTitle/MiniTitle';
import './NewGrowerFormStep1.scss';
import { useSelector } from 'react-redux';

const docsFileTypes = ["DOCX", "ZIP", "DOC"];
const maxFileSizeInMB = 5;

const NewGrowerFormStep1 = ({gotoStep2, updateGrower, ...props}) => {
    const {t} = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();
    const api = useContext(ApiContext);
    const [states, setStates] = useState([]);
    const [municipalities, setMunicipalities] = useState([]);
    const [neighborhoods, setNeighborhoods] = useState([]);
    const [taxSystems, setTaxSystems] = useState([]);
    const { form, bindSimple, bindInput, setForm, handleSimpleChange } = useFormState();
    const [grower, setGrower] = useState();
    const loadingId = `Grower.form.${id}`;

    const isEditingMode = id !== undefined && id !== null;

    // -- load data
    useEffect(() => {        
        // if we are in editing mode, we need to get the grower from the API
        if(isEditingMode)
            api.companies.get({ id, params:{sGroups: companyDetailSGroups}})
                .then(grower => {
                    setGrower(grower);
                    setForm(companyToFormData(grower, t));
                })
                .catch(err => getNotifier().error(err.message));
        
        api.states.get({ params: { sGroups: stateDetailSGroups } }).then(states => setStates(objectToOptions(states))); 
        api.taxSystems.get({ params: { sGroups: ['tax_system_read'] } }).then(taxs => setTaxSystems(objectToOptions(taxs))); 
    }, [api, isEditingMode, id, setForm, t]);

    const reloadGrower = () => {
        api.companies.get({ id, params:{sGroups: companyDetailSGroups}})
                .then(grower => {
                    setGrower(grower);
                    setForm(companyToFormData(grower, t));
                })
                .catch(err => getNotifier().error(err.message));
    }

    const handleStateChange = (option) => {
        const newForm = {...form};
        newForm.state = option;
        // update the state of the form
        setForm({...newForm});

        // get municipalities for the selected state
        api.municipalities.get({ params: { state: option.value } }).then(municipalities => setMunicipalities(objectToOptions(municipalities)));
    }

    const handleMunicipalityChange = (option) => {
        const newForm = {...form};
        newForm.municipality = option;
        // update the state of the form
        setForm({...newForm});

        // get municipalities for the selected state
        api.neighbourhoods.get({ params: { municipality: option.value } }).then(neighborhoods => setNeighborhoods(objectToOptions(neighborhoods)));
    }
    
    const objectToOptions = (object) => {
        return object.map(item => {
            return {
                value: item.id,
                label: item.name
            }
        }
    )}; 
    
    const handleCreateOrUpdateGrower = () => {
        const {id} = form;
        const apiMethod = id ? 'update' : 'create';
        let _grower = null;

        try{
            _grower = prepareGrowerForServerStep({...form});

            if(!isEditingMode)
                _grower.registryStatus = REGISTRY_STATUS_PENDING;

            if(id){
                _grower.email = null;
                _grower.username = null;
            }

        } catch (e){
            return getNotifier().error(e.message);
        }

        const files = prepareFilesForServer({...form});
        //const companyFiles = prepareCompanyDocumentsForServer({...form});

        // save grower
        api.companies[apiMethod]({id: id, params: _grower, files, loadingId})
            .then(newGrower => {
                if(isEditingMode) {
                    getNotifier().success(t('Saved-changes')); // show confirmation message
                    updateGrower(newGrower); // send grower for next step
                } else {
                    getNotifier().success(t('Grower-created-successfully')); // show confirmation message
                    gotoStep2(newGrower.id); // go to step 2
                }
            })
            .catch((e)=>{ getNotifier().error(e.message) });
    }

    const handleChangePostalCode = async (e) => {
        const postalCode = e.target.value;
        let state = '';
        let municipality = '';
        let neighborhood = '';

        setForm({...form, postalCode});

        if(postalCode.length === 5){
            // get neighborhoods for the CP
            const neighborhoodsParams = { zipCode: postalCode };
            const neighborhoods = await api.neighbourhoods.get({ params: neighborhoodsParams });
            setNeighborhoods(objectToOptions(neighborhoods));

            // get municipalities for the CP
            const municipalitiesParams = { 'neighbourhoods.zipCode': postalCode, sGroups: municipalityForSelectSGroups };
            const municipalities = await api.municipalities.get({ params: municipalitiesParams});
            setMunicipalities(objectToOptions(municipalities))

            // if there is only one municipality, select it
            if(municipalities.length === 1){
                municipality = {
                    label: municipalities[0].name,
                    value: municipalities[0].id
                };
            }

            // if there is only one neighborhood, select it
            if(neighborhoods.length === 1){
                neighborhood = {
                    label: neighborhoods[0].name,
                    value: neighborhoods[0].id
                };
            }

            // select state
            state = {
                label: municipalities[0].state.name,
                value: municipalities[0].state.id
            };
        }

        const newForm = {...form};
        newForm.postalCode = postalCode;
        newForm.state = state;
        newForm.municipality = municipality;
        newForm.neighbourhood = neighborhood;

        setForm({...newForm});
    }

    const loading = useSelector(s=>!!s.loadingIds[loadingId]);

    return (
        <div className={"NewGrowerFormStep1"} {...props}>

            <div className='d-flex align-items-center justify-content-between'>
                <SectionTitle className='mt-0 mb-0'>{t('Create-account')}</SectionTitle>
                
                {isEditingMode &&
                    <GrowerChangeStatusButtons
                        grower={grower}
                        onUpdateStatus={reloadGrower}
                    />}
            </div>

            <div className='row mt-4'>
                <div className='col-sm-6 col-lg-3 margin-top-mobile'>
                    <TextInput 
                        label={t('Company-name')}
                        placeholder={t('Company-name-here')}
                        {...bindInput('companyName')}
                    />
                </div>
                <div className='col-sm-6 col-lg-3 margin-top-mobile'>
                    <TextInput 
                        label={t('Email')}
                        placeholder={'@'}
                        {...bindInput('contactEmail')}
                        type="email"
                    />
                </div>
                <div className='col-sm-6 col-lg-3 margin-top-mobile'>
                    <MMPhoneInput
                        label={t('Telephone')}
                        inputProps={{
                            ...bindSimple('contactCellphoneNumber')
                        }}
                    />                    
                </div>
            </div>
            
            <div className='row mt-4'>
                <div className='col-sm-6 col-lg-3 margin-top-mobile'>
                    <SelectWithLabel
                        label={t('Financing-profile')}                        
                        inputProps={{
                            options: financingProfilesOptions,
                            ...bindSimple('financingProfile')
                        }}                        
                    />
                </div>
                <div className='col-sm-6 col-lg-3 margin-top-mobile'>
                    <SelectWithLabel
                        label={t('Export-program')}
                        inputProps={{
                            options: getExportProgramOptions(t),
                            ...bindSimple('exportProgram')
                        }}
                    />
                </div>
            </div>

            <SectionTitle className="mt-4">{t('Fiscal-Information')}</SectionTitle>
            
            <div className='row'>
                <div className='col-sm-6 col-lg-3 margin-top-mobile'>
                    <TextInput 
                        label={t("Business-name")}
                        placeholder={t("Business-name")}
                        {...bindInput('businessName')}
                    />
                </div>
                <div className='col-sm-6 col-lg-3 margin-top-mobile'>
                    <TextInput 
                        label={t('RFC')}
                        placeholder={t('RFC')}
                        {...bindInput('rfc')}
                    />
                </div>
                <div className='col-sm-6 col-lg-3 margin-top-mobile'>
                    <SelectWithLabel
                        label={t('Person-type')}
                        inputProps={{
                            placeholder: t('Person-type'),
                            options: personTypeOptions,
                            ...bindSimple('personType')
                        }}
                    />
                </div> 
                <div className='col-sm-6 col-lg-3 margin-top-mobile'>
                    <SelectWithLabel
                        label={t("Tax-system")}
                        inputProps={{
                            options: taxSystems,
                            ...bindSimple('taxSystem')
                        }}
                    />
                </div> 
            </div>
            <div className='row mt-4'>
                <div className='col-sm-6 col-lg-3 margin-top-mobile'>
                    <TextInput 
                        label={t('Postal-code')}
                        placeholder={t('Postal-code')}
                        value={form.postalCode}
                        onChange={handleChangePostalCode}
                    />
                </div>    
                <div className='col-sm-6 col-lg-3 margin-top-mobile'>
                    <SelectWithLabel
                        label={t("State")}
                        inputProps={{
                            options: states,
                            onChange: handleStateChange,
                            value: form.state
                        }}
                    />
                </div> 
                <div className='col-sm-6 col-lg-3 margin-top-mobile'>
                    <SelectWithLabel
                        label={t("Municipality")}
                        inputProps={{
                            options: municipalities,
                            onChange: handleMunicipalityChange,
                            value: form.municipality
                        }}
                    />
                </div>
                <div className='col-sm-6 col-lg-3 margin-top-mobile'>
                    <SelectWithLabel
                        label={t("Neighborhood")}
                        inputProps={{
                            options: neighborhoods,
                            ...bindSimple('neighbourhood')
                        }}
                    />
                </div>
            </div>
            <div className='row mt-4'>
                <div className='col-sm-6 col-lg-3 margin-top-mobile'>
                    <TextInput 
                        label={t('Street')}
                        placeholder={t('Street')}
                        {...bindInput('street')}
                    />
                </div>  
                <div className='col-sm-6 col-lg-3 margin-top-mobile'>
                    <TextInput 
                        label={t('Ext-number')}
                        placeholder={t('Ext-number')}
                        {...bindInput('externalNumber')}
                    />
                </div>  
                <div className='col-sm-6 col-lg-3 margin-top-mobile'>
                    <TextInput 
                        label={t('Int-number')}
                        placeholder={t('Int-number')}
                        {...bindInput('internalNumber')}
                    />
                </div> 
            </div>
            
            <SectionTitle className="mb-0 mt-4">{t('Documents')}</SectionTitle>

            <MiniTitle className={"files-validations-label"}>
                {t('Files-should-be')}:<br/>
                {t('Types')}: {docsFileTypes.map(f => `.${f.toLowerCase()}`).join(', ')}<br/>
                {t('Max-files-size')}: {maxFileSizeInMB}MB
            </MiniTitle>
                
            <div className='row mt-3'>
                <div className='col-sm-12 col-lg-12 margin-top-mobile'>
                    
                    <GrowerDocumentsList 
                        grower={grower}
                        form={form}
                        handleSimpleChange={handleSimpleChange}
                        bindInput={bindInput}
                        setForm={setForm}
                        docsFileTypes={docsFileTypes}
                        maxFileSizeInMB={maxFileSizeInMB}
                    />

                </div>
            </div>  

            <SectionTitle className='mt-4'>{t("Billing-data")}</SectionTitle>
                
            <div className='row mt-3'>
                <div className='col-sm-6 col-lg-3 margin-top-mobile'>
                    <UploadFile 
                        label={t('Digital-signature')}
                        btnLabel={t('Digital-signature-here')}
                        showImage={false}
                        onChange={file => setForm({...form, digitalSignatureFile: file})}
                        fileTypes={['pdf', 'zip', 'key', 'cer']}
                        maxFileSizeInMB={5}
                    />
                    {form.digitalSignatureFile && id &&
                        <FileLink file={form.digitalSignatureFile} label={t('See-file')} />}
                </div>

                <div className='col-sm-6 col-lg-3 margin-top-mobile'>
                    <UploadFile 
                        label={'.key'}
                        btnLabel={t('key-here')}
                        onChange={file => setForm({...form, digitalSignatureKey: file})}
                        showImage={false}
                        fileTypes={['key']}
                        maxFileSizeInMB={5}
                    />
                    {form.digitalSignatureKey && id && 
                        <FileLink file={form.digitalSignatureKey} label={t('See-file')} />}
                </div>

                <div className='col-sm-6 col-lg-3 margin-top-mobile'>
                    <UploadFile 
                        label={'.CER'}
                        btnLabel={t('cer-here')}
                        onChange={file => setForm({...form, digitalSignatureCer: file})}
                        showImage={false}
                        fileTypes={['cer']}
                        maxFileSizeInMB={5}
                    />
                    {form.digitalSignatureCer && id && 
                        <FileLink file={form.digitalSignatureCer} label={t('See-file')} />}
                </div>
            </div>
                        
            <SectionTitle className='mt-4'>{t('Certificate-and-digital-seal')}</SectionTitle>

            <div className='row mt-3'>
                <div className='col-sm-6 col-lg-3 margin-top-mobile'>
                    <TextInput 
                        label={t('Password')}
                        placeholder={t('Password')}
                        {...bindInput('digitalSealPassword')}
                        type='password'
                    />
                </div>

                <div className='col-sm-6 col-lg-3 margin-top-mobile'>
                    <UploadFile 
                        label={'.key'}
                        btnLabel={t('key-here')}
                        onChange={file => setForm({...form, digitalSealKey: file})}
                        showImage={false}
                        fileTypes={['key']}
                        maxFileSizeInMB={5}
                    />
                    {form.digitalSealKey && id && 
                        <FileLink file={form.digitalSealKey} label={t('See-file')} />}
                </div>

                <div className='col-sm-6 col-lg-3 margin-top-mobile'>
                    <UploadFile 
                        label={'.CER'}
                        btnLabel={t('cer-here')}
                        onChange={file => setForm({...form, digitalSealCer: file})}
                        showImage={false}
                        fileTypes={['cer']}
                        maxFileSizeInMB={5}
                    />
                    {form.digitalSealCer && id &&
                        <FileLink file={form.digitalSealCer} label={t('See-file')} />}
                </div>

                <div className='row NextStepButtons'>
                    { isEditingMode ?
                        <Button
                            className={'btn-primary btn-outline NextStepButtons__button'}
                            onClick={() => navigate(paths.mm.growers) }
                            design={'clear'}
                        >
                            {t('Cancel-changes')}
                        </Button>
                        :
                        <Button
                            className={'btn-primary btn-outline NextStepButtons__button'}
                            onClick={() => navigate(paths.mm.growers) }
                            design={'clear'}
                        >
                            {t('Cancel')}
                        </Button>}

                    <Button 
                        className={`btn-primary btnNextStep NextStepButtons__button NextStepButtons__button--0`}
                        onClick={handleCreateOrUpdateGrower}
                        disabled={loading}
                    >
                        { isEditingMode ? t('Save') : t('Save-and-continue') }
                        { !isEditingMode && <NextIcon /> }
                    </Button>
                </div>
            </div>            
        </div>
    );
}

export default NewGrowerFormStep1;
