import Dashboard from "../../scenes/Dashboard/Dashboard";
import GrowerDashboard from "../../scenes/Grower/Dashboard/Dashboard";
import GrowerOrders from "../../scenes/Grower/Orders/Orders";
import GrowerRequests from "../../scenes/Grower/Requests/Requests";
import GrowerProducts from "../../scenes/Grower/Products/Products";

import WholesaleDashboard from "../../scenes/Wholesale/Dashboard/Dashboard";
import WholesaleMarketplace from "../../scenes/Wholesale/Marketplace/Marketplace";

import MMDashboard from "../../scenes/MM/Dashboard/Dashboard";
import MMGrowersList from "../../scenes/MM/GrowersList/GrowersList";
import MMGrowersForm from "../../scenes/MM/GrowersForm/GrowersForm";
import MMOrdersForm from "../../scenes/MM/OrdersForm/OrdersForm";
import MMOrdersList from "../../scenes/MM/OrdersList/OrdersList";
import MMOrdersFormStep2 from "../../scenes/MM/OrdersFormStep2/OrdersFormStep2";
import MMOrdersFormStep3 from "../../scenes/MM/OrdersFormStep3/OrdersFormStep3";
import MMProductsList from "../../scenes/MM/ProductsList/ProductsList";
import MMProductsForm from "../../scenes/MM/ProductsForm/ProductsForm";
import MMWarehousesList from "../../scenes/MM/WarehousesList/WarehousesList";
import MMWarehousesForm from "../../scenes/MM/WarehousesForm/WarehousesForm";
import MMFollowUpsList from "../../scenes/MM/FollowUpsList/FollowUpsList";
import MMFollowUpsForm from "../../scenes/MM/FollowUpsForm/FollowUpsForm";
import MMBrandsList from "../../scenes/MM/BrandsList/BrandsList";
import MMBrandsForm from "../../scenes/MM/BrandsForm/BrandsForm";
import MMUsersList from "../../scenes/MM/UsersList/UsersList";
import MMUsersForm from "../../scenes/MM/UsersForm/UsersForm";
import MMFoliarApplicationsList from "../../scenes/MM/FoliarApplicationsList/FoliarApplicationsList";
import MMActiveIngredientsList from "../../scenes/MM/ActiveIngredientsList/ActiveIngredientsList";
import MMActiveIngredientsForm from "../../scenes/MM/ActiveIngredientsForm/ActiveIngredientsForm";

import {ReactComponent as HomeIcon} from "../../assets/images/icons/home.svg";
import {ReactComponent as HomeActiveIcon} from "../../assets/images/icons/home-active.svg";
import {ReactComponent as OrdersIcon} from "../../assets/images/icons/orders.svg";
import {ReactComponent as OrdersActiveIcon} from "../../assets/images/icons/orders-active.svg";
import {ReactComponent as RequestsIcon} from "../../assets/images/icons/shopping-cart.svg";
import {ReactComponent as RequestsActiveIcon} from "../../assets/images/icons/shopping-cart-active.svg";
import {ReactComponent as ProductsIcon} from "../../assets/images/icons/avocado-one.svg";
import {ReactComponent as ProductsActiveIcon} from "../../assets/images/icons/avocado-one-active.svg";
import {ReactComponent as SettingsIcon} from "../../assets/images/icons/settings.svg";
import {ReactComponent as SettingsActiveIcon} from "../../assets/images/icons/settings-active.svg";
import {ReactComponent as HelpIcon} from "../../assets/images/icons/help.svg";
import {ReactComponent as ShopIcon} from "../../assets/images/icons/shop.svg";
import {ReactComponent as ShopActiveIcon} from "../../assets/images/icons/shop-active.svg";
import {ReactComponent as ShoppingCartActiveIcon} from "../../assets/images/icons/shopping-cart-active.svg";
import {ReactComponent as ShoppingCartIcon} from "../../assets/images/icons/shopping-cart.svg";
import {ReactComponent as UsersIcon} from "../../assets/images/icons/users.svg";
import {ReactComponent as UsersActiveIcon} from "../../assets/images/icons/users-active.svg";
import {ReactComponent as PackageIcon} from "../../assets/images/icons/package.svg";
import {ReactComponent as PackageActiveIcon} from "../../assets/images/icons/package-active.svg";
import {ReactComponent as VisitsIcon} from "../../assets/images/icons/visits.svg";
import {ReactComponent as VisitsActiveIcon} from "../../assets/images/icons/visits-active.svg";
import {ReactComponent as ApplicationsIcon} from "../../assets/images/icons/applications.svg";
import {ReactComponent as ApplicationsActiveIcon} from "../../assets/images/icons/applications-active.svg";
import {ReactComponent as BrandsIcon} from "../../assets/images/icons/brands.svg";
import {ReactComponent as BrandsActiveIcon} from "../../assets/images/icons/brands-active.svg";
import {ReactComponent as IngredientsIcon} from "../../assets/images/icons/ingredients.svg";
import {ReactComponent as IngredientsActiveIcon} from "../../assets/images/icons/ingredients-active.svg";

import i18n from "i18next";
import { ROLE_GROWER, ROLE_WHOLESALE, ROLE_MIAMERKATO_ADMIN, SUPER_ADMIN } from "../models/userUtils";

export const paths={
    dashboard:'/dashboard',
    myOrders:'/my-orders',
    myRequests:'/my-requests',
    products:'/products',
    settings:'/settings',
    help:'/help',

    grower: {
        dashboard: '/dashboard',
        orders:'/orders',
        requests:'/requests',
        products:'/products',
        settings:'/settings'
    },

    wholesale: {
        dashboard: '/dashboard',
        marketplace:'/marketplace',
        orders:'/orders',
        users:'/users',
        settings:'/settings'
    },

    mm: {
        dashboard: '/dashboard',
        growers: '/growers',
        growersForm: '/growers/new',
        growersFormStep: '/growers/new/:id/step/:step',
        growersEdit: '/growers/:id/edit',
        growersEditOrchard: '/growers/:id/edit/:step/orchards/:orchardId',
        orders: '/orders',
        ordersEdit:         '/orders/:id/step1',
        ordersFormStep2:    '/orders/:id/step2',
        ordersFormStep3:    '/orders/:id/step3',
        ordersForm: '/orders/new',
        products: '/products',
        productsForm: '/products/new',
        productsEdit: '/products/:id/edit',
        warehouses: '/warehouses',
        warehousesForm: '/warehouses/new',
        warehousesEdit: '/warehouses/:id/edit',
        followups: '/followups',
        followupsForm: '/followups/new',
        followupsEdit: '/followups/:id/edit',
        brands: '/brands',
        brandsForm: '/brands/new',
        brandsEdit: '/brands/:id/edit',
        users: '/users',
        usersForm: '/users/new',
        activeIngredients: '/active-ingredients',
        activeIngredientsForm: '/active-ingredients/new',
        activeIngredientsEdit: '/active-ingredients/:id/edit',
        foliarapplications: '/foliar-applications'
    }
};

const getAppRoutes =(securityManager)=>{
    let routes = [];
    
    if(securityManager.canSeeEntity()){
        // routes for grower
        if(securityManager.me?.role?.name ===  ROLE_GROWER ){
            routes.push({
                path:paths.grower?.dashboard,
                name: 'Home', 
                text: i18n.t('Home'),
                Component: GrowerDashboard, 
                icon: <HomeIcon />, 
                activeIcon: <HomeActiveIcon />, 
                alerts: 0 
            });
            
            routes.push({
                path:paths.grower?.orders, 
                name:'Orders', 
                text: i18n.t('Orders'),
                Component: GrowerOrders, 
                icon: <OrdersIcon />, 
                activeIcon: <OrdersActiveIcon />, 
                alerts: 0
            });

            routes.push({
                path:paths.grower?.requests, 
                name:'Requests', 
                text: i18n.t('Requests'),
                Component: GrowerRequests, 
                icon: <RequestsIcon />, 
                activeIcon: <RequestsActiveIcon />, 
                alerts: 5
            });

            routes.push({
                path:paths.grower?.products, 
                name:'Products', 
                text: i18n.t('Products'),
                Component: GrowerProducts, 
                icon: <ProductsIcon />, 
                activeIcon: <ProductsActiveIcon />, 
                alerts: 0
            });

            routes.push({
                path:paths.grower?.settings, 
                name:'Settings', 
                text: i18n.t('Settings'),
                Component: Dashboard, 
                icon: <SettingsIcon />, 
                activeIcon: <SettingsActiveIcon />, 
                alerts: 0
            });

            routes.push({
                path:paths.help, 
                name:'Help', 
                text: i18n.t('Get-Help'),
                Component: Dashboard, 
                icon: <HelpIcon />, 
                activeIcon: <HelpIcon />, 
                alerts: 0, 
                hidden: true
            });

        } else if(securityManager.me?.role?.name === ROLE_WHOLESALE ) {
            routes.push({
                path: paths.wholesale?.dashboard,
                name: 'Home',
                text: i18n.t('Home'),
                Component: WholesaleDashboard,
                icon: <HomeIcon/>,
                activeIcon: <HomeActiveIcon/>,
                alerts: 0
            });

            routes.push({
                path: paths.wholesale?.marketplace,
                name: 'Marketplace',
                text: i18n.t('Marketplace'),
                Component: WholesaleMarketplace,
                icon: <ShopIcon/>,
                activeIcon: <ShopActiveIcon/>,
                alerts: 0
            });
        } else if(securityManager.me?.role?.name === ROLE_MIAMERKATO_ADMIN || securityManager.me?.role?.name === SUPER_ADMIN ) {
            routes.push({
                path:paths.mm.dashboard, 
                name: 'Home', 
                text: i18n.t('Home'),
                Component: MMDashboard, 
                icon: <HomeIcon />, 
                activeIcon: <HomeActiveIcon />, 
                alerts: 0 
            });
            
            routes.push({
                path: paths.mm.growers, 
                name: 'Productors', 
                text: i18n.t('Productors'),
                Component: MMGrowersList, 
                icon: <UsersIcon />, 
                activeIcon: <UsersActiveIcon />,
                alerts: 0 
            }); 

            routes.push({
                path: paths.mm.growersForm, 
                name: 'NewProductor', 
                text: i18n.t('New-Productor'),
                Component: MMGrowersForm, 
                icon: <UsersIcon />, 
                activeIcon: <UsersActiveIcon />, 
                alerts: 0,
                hidden: true
            });

            routes.push({
                path: paths.mm.growersFormStep, 
                name: 'NewProductorStep', 
                text: i18n.t('Edit-Productor'),
                Component: MMGrowersForm,
                icon: <UsersIcon />, 
                activeIcon: <UsersActiveIcon />, 
                alerts: 0,
                hidden: true
            }); 
            
            routes.push({
                path: paths.mm.growersEditOrchard, 
                name: 'EditOrchardProductor',
                text: i18n.t('Edit-Orchard-Productor'),
                Component: MMGrowersForm,
                icon: <UsersIcon />, 
                activeIcon: <UsersActiveIcon />, 
                alerts: 0,
                hidden: true
            }); 


            if ( securityManager.canAdminOrders() ) {
                routes.push({
                    path: paths.mm.orders, 
                    name: 'MyOrders', 
                    text: i18n.t('My-Orders'),
                    Component: MMOrdersList, 
                    icon: <ShoppingCartIcon />, 
                    activeIcon: <ShoppingCartActiveIcon />, 
                    alerts: 0,
                    hidden: false
                });

                routes.push({
                    path: paths.mm.ordersForm, 
                    name: 'NewOrder', 
                    text: i18n.t('New-Order'),
                    Component: MMOrdersForm, 
                    icon: <ShoppingCartIcon />, 
                    activeIcon: <ShoppingCartActiveIcon />, 
                    alerts: 0,
                    hidden: true
                });
    
                routes.push({
                    path: paths.mm.ordersEdit,
                    name: 'EditOrder', 
                    text: i18n.t('Edit-Order'),
                    Component: MMOrdersForm, 
                    icon: <ShoppingCartIcon />, 
                    activeIcon: <ShoppingCartActiveIcon />, 
                    alerts: 0,
                    hidden: true
                });
    
                routes.push({
                    path: paths.mm.ordersFormStep2, 
                    name: 'OrderStep2', 
                    text: 'OrderStep2',
                    Component: MMOrdersFormStep2, 
                    icon: <ShoppingCartIcon />, 
                    activeIcon: <ShoppingCartActiveIcon />, 
                    alerts: 0,
                    hidden: true
                });
    
                routes.push({
                    path: paths.mm.ordersFormStep3, 
                    name: 'OrderStep3', 
                    text: 'OrderStep3',
                    Component: MMOrdersFormStep3, 
                    icon: <ShoppingCartIcon />, 
                    activeIcon: <ShoppingCartActiveIcon />, 
                    alerts: 0,
                    hidden: true
                });
            }

            routes.push({
                path: paths.mm.products,
                name: 'Products',
                text: i18n.t('Products'),
                Component: MMProductsList,
                icon: <ProductsIcon />,
                activeIcon: <ProductsActiveIcon />,
                alerts: 0,
                hidden: false
            });
            
            routes.push({
                path: paths.mm.productsForm,
                name: 'NewProduct',
                text: i18n.t('New-Product'),
                Component: MMProductsForm,
                icon: <ProductsIcon />,
                activeIcon: <ProductsActiveIcon />,
                alerts: 0,
                hidden: true
            });

            routes.push({
                path: paths.mm.productsEdit,
                name: 'EditProduct',
                text: i18n.t('Edit-Product'),
                Component: MMProductsForm,
                icon: <ProductsIcon />,
                activeIcon: <ProductsActiveIcon />,
                alerts: 0,
                hidden: true
            });

            routes.push({
                path: paths.mm.growersEdit, 
                name: 'EditProductor', 
                text: i18n.t('Edit-Productor'),
                Component: MMGrowersForm, 
                icon: <ShopIcon />, 
                activeIcon: <ShopActiveIcon />, 
                alerts: 0,
                hidden: true
            });

            routes.push({
                path: paths.mm.warehouses,
                name: 'Warehouses',
                text: i18n.t('Warehouses'),
                Component: MMWarehousesList,
                icon: <PackageIcon />,
                activeIcon: <PackageActiveIcon />,
                alerts: 0,
                hidden: false
            });

            routes.push({
                path: paths.mm.warehousesForm, 
                name: 'NewWarehouse', 
                text: i18n.t('New-warehouse'),
                Component: MMWarehousesForm, 
                icon: <PackageIcon />,
                activeIcon: <PackageActiveIcon />,
                alerts: 0,
                hidden: true
            });

            routes.push({
                path: paths.mm.warehousesEdit, 
                name: 'EditWarehouse', 
                text: i18n.t('Edit-warehouse'),
                Component: MMWarehousesForm, 
                icon: <PackageIcon />,
                activeIcon: <PackageActiveIcon />,
                alerts: 0,
                hidden: true
            });


            if(securityManager.canEditOrdersAndAdminVisits()) {
                routes.push({
                    path: paths.mm.followups,
                    name: 'Followups',
                    text: i18n.t('Visits'),
                    Component: MMFollowUpsList,
                    icon: <VisitsIcon />,
                    activeIcon: <VisitsActiveIcon />,
                    alerts: 0,
                    hidden: false
                });

                routes.push({
                    path: paths.mm.followupsForm, 
                    name: 'NewFollowUp', 
                    text: i18n.t('New-visit'),
                    Component: MMFollowUpsForm, 
                    icon: <VisitsIcon />,
                    activeIcon: <VisitsActiveIcon />,
                    alerts: 0,
                    hidden: true
                });

                routes.push({
                    path: paths.mm.followupsEdit,
                    name: 'EditFollowUp',
                    text: i18n.t('Edit-visit'),
                    Component: MMFollowUpsForm, 
                    icon: <VisitsIcon />,
                    activeIcon: <VisitsActiveIcon />,
                    alerts: 0,
                    hidden: true
                });

                routes.push({
                    path: paths.mm.foliarapplications,
                    name: 'FoliarApplications',
                    text: i18n.t('Applications'),
                    Component: MMFoliarApplicationsList,
                    icon: <ApplicationsIcon />,
                    activeIcon: <ApplicationsActiveIcon />,
                    alerts: 0,
                    hidden: false
                });
            }

            routes.push({
                path: paths.mm.brands,
                name: 'Brands',
                text: i18n.t('Brands'),
                Component: MMBrandsList,
                icon: <BrandsIcon />,
                activeIcon: <BrandsActiveIcon />,
                alerts: 0,
                hidden: false
            });

            routes.push({
                path: paths.mm.brandsForm,
                name: 'NewBrand',
                text: i18n.t('New-Brand'),
                Component: MMBrandsForm,
                icon: <BrandsIcon />,
                activeIcon: <BrandsActiveIcon />,
                alerts: 0,
                hidden: true
            });

            routes.push({
                path: paths.mm.brandsEdit,
                name: 'EditBrand',
                text: i18n.t('Edit-Brand'),
                Component: MMBrandsForm,
                icon: <BrandsIcon />,
                activeIcon: <BrandsActiveIcon />,
                alerts: 0,
                hidden: true
            });

            routes.push({
                path: paths.mm.activeIngredients,
                name: 'ActiveIngredients',
                text: i18n.t('Ingredients'),
                Component: MMActiveIngredientsList,
                icon: <IngredientsIcon />,
                activeIcon: <IngredientsActiveIcon />,
                alerts: 0,
                hidden: false
            });

            routes.push({
                path: paths.mm.activeIngredientsForm,
                name: 'ActiveIngredientsForm',
                text: i18n.t('Add-Ingredient'),
                Component: MMActiveIngredientsForm,
                icon: <IngredientsIcon />,
                activeIcon: <IngredientsActiveIcon />,
                alerts: 0,
                hidden: true
            });

            routes.push({
                path: paths.mm.activeIngredientsEdit,
                name: 'ActiveIngredientsEdit',
                text: i18n.t('Edit-Ingredient'),
                Component: MMActiveIngredientsForm,
                icon: <IngredientsIcon />,
                activeIcon: <IngredientsActiveIcon />,
                alerts: 0,
                hidden: true
            });

            routes.push({
                path: paths.mm.users,
                name: 'Users',
                text: i18n.t('Users'),
                Component: MMUsersList,
                icon: <UsersIcon />,
                activeIcon: <UsersActiveIcon />,
                alerts: 0,
                hidden: false
            });

            routes.push({
                path: paths.mm.usersForm,
                name: 'NewUser',
                text: i18n.t('New-User'),
                Component: MMUsersForm,
                icon: <UsersIcon />,
                activeIcon: <UsersActiveIcon />,
                alerts: 0,
                hidden: true
            });
        }
    }

    return routes;
};


export default getAppRoutes;
