import React, {useState} from "react";

import classNames from "classnames";
import './ProductCard.scss';

const ProductCard = ({product, showPrice=false, onClick}) => {
    const [selected, setSelected] = useState();
    const selectCard = () => {
        setSelected(!selected);

        if (onClick) {
            onClick();
        }
    }

    return (
        <div className={classNames("ProductCard", !!selected&&'ProductCard--selected')}
             onClick={selectCard}>
            <div className="ProductCard__image-container">
                <img src={product.picture} alt="Avocado" className={"ProductCard__image"}/>
            </div>
            <div className="ProductCard__content">
                <div className="ProductCard__title">
                    {product.name}
                </div>
                {showPrice &&
                    <div className="ProductCard__price">
                        {product.price}
                    </div>}
            </div>
        </div>
    );
}

export default ProductCard;