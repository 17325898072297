import Slider from 'rc-slider';
import React from 'react'
import Card from '../../../../../components/Dashboard/Card/Card';
import {ReactComponent as InfoIcon} from "../../../../../assets/images/icons/info.svg";
import { SectionTitle } from '../../../../../components/Dashboard/SectionTitle/SectionTitle';
import {useTranslation} from "react-i18next";
import './OrderStatus.scss';

const OrderStatus = ({ productOffer }) => {
    const {t} = useTranslation();

    const getFilledBoxes = productOffer => {
        let boxes = 0;

        for (let i = 0; i < productOffer.productors?.length; i++)
            boxes += parseInt(productOffer.productors[i]?.boxes)||0;
            
        return boxes;
    };

    const noBoxes = productOffer.noBoxes||0;
    const filledBoxes = getFilledBoxes(productOffer);
    const percentage = (noBoxes>0 ? parseFloat((filledBoxes / noBoxes) * 100).toFixed(2) : 0);
    
    return (
            <Card className='OrderStatus'>
                <SectionTitle>Order status</SectionTitle>
                <hr/>
                <div>
                    {t("Order-percentage-completed", {percentage: percentage})}
                </div>
                <Slider
                    key={percentage}
                    defaultValue={percentage >100 ? 100 : percentage}
                    min={0}
                    max={100}
                    step={1}
                    disabled
                    className='mt-3'
                />
                
                <div className='OrderStatus__info'>                    
                    <InfoIcon />
                    <div>
                        {t('Order-will-be-compleated-once-all-products-fulfill-their-quantity')}
                    </div>
                </div>
            </Card>
    );
}

export default OrderStatus;