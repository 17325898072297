import { copyPropertyOrThrow } from "../formUtils";
import { prepareNewAddressForServer } from "./addressUtils";

export const warehouseTableSGroups = [
    'warehouse_read',
    'warehouse_read_id',
    'warehouse_read_name',
    'warehouse_read_address',
        'address_read',
        'address_read_id',
        'address_read_street',
        'address_read_external_number',
        'address_read_internal_number',
        'address_read_latitude',
        'address_read_longitude',
        'address_read_neighbourhood',
        'neighbourhood_read_municipality',
            'municipality_read_state',
                'state_read',
        'address_read_postal_code',
    'read_created_by',
    'read_updated_by',
    'read_created_date',
    'read_updated_date',
]

export const warehouseDetailSGroups = [
    'warehouse_read',
    'warehouse_read_id',
    'warehouse_read_name',
    'warehouse_read_address',
        'address_read',
        'address_read_id',
        'address_read_street',
        'address_read_external_number',
        'address_read_internal_number',
        'address_read_latitude',
        'address_read_longitude',
        'address_read_neighbourhood',
        'neighbourhood_read_municipality',
        'neighbourhood_read_id',
        'neighbourhood_read_name',
        'neighbourhood_read_postal_code',
            'municipality_read_id',
            'municipality_read_name',
            'municipality_read_state',
                'state_read',
        'address_read_postal_code',
    'read_file',
]

export const getEmptyWarehouse = () => {
    return {
        id: null,
        name: '',
        address: {
            street: '',
            city: '',
            state: '',
            zip: '',
            country: '',
        },
    }
}

export const warehouseToFormData = (warehouse) => {
    const data = {
        ...warehouse,
        postalCode: warehouse.address?.postalCode,
        neighbourhood: warehouse.address?.neighbourhood?.id ? { value: warehouse.address?.neighbourhood?.id, label: warehouse.address?.neighbourhood?.name} : null,
        municipality: warehouse.address?.neighbourhood?.municiaplity?.id ? { value: warehouse.address?.neighbourhood?.municiaplity?.id, label: warehouse.address?.neighbourhood?.municiaplity?.name} : null,
        state: warehouse.address?.neighbourhood?.municiaplity?.state?.id ? { value: warehouse.address?.neighbourhood?.municiaplity?.state?.id, label: warehouse.address?.neighbourhood?.municiaplity?.state?.name} : null,
        street: warehouse.address?.street,
        externalNumber: warehouse.address?.externalNumber,
        internalNumber: warehouse.address?.internalNumber
    };

    return data;   
}

export const prepareWarehouseForServer = (form, t) => {
    let warehouse = {};

    copyPropertyOrThrow(form, warehouse, 'name', t('missing-name-field'));
    warehouse.address = prepareNewAddressForServer(form);

    return warehouse;
}

export const prepareWarehouseFilesForServer = (form, t) => {
    let files = {};
    
    if(form.facadePicture && !form.facadePicture?.id)
        files['facadePicture'] = form.facadePicture;

    return files;
}