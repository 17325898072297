import React from 'react'
import uploadButton from "../../../../assets/images/buttons/upload-btn-clean.png";

import './UploadAvatarButton.scss';

const UploadAvatarButton = ({legend, btnLabel}) => {
    return (
        <div className='UploadAvatarButton'>
            <img src={uploadButton} alt="upload button" width="120" height="120" />
            {legend}
            <div className='UploadAvatarButton__text'>
                {btnLabel}
            </div>
        </div>
    );
}

export default UploadAvatarButton;