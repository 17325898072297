export const qualityValues = [
    'very-bad',
    'bad',
    'regular',
    'good',
    'very-good',

]

export const qualityOptions = (t) => qualityValues.map(option => ({value: option, label: t(option)||option }));
