import React from 'react'
import { useTranslation } from 'react-i18next';
import {ReactComponent as UploadIcon} from "../../../../../../../assets/images/icons/upload.svg";
import UploadFile from '../../../../../../../components/forms/UploadFile/UploadFile';
import ActionSee from '../ActionSee/ActionSee';

import './ActionUpload.scss';

const ActionUpload = ({fieldName, docsFileTypes, maxFileSizeInMB, form, setForm}) => {
    const {t} = useTranslation();

    const handleImageChange = (files) => {
        setForm({...form, [fieldName]: files});
    };

    return (
        <div className='ActionUpload'>
            <div className='w-50'>
                <ActionSee />
            </div>
            <div className='w-50'>
                <div className='d-flex justify-content-start align-items-center gap10'>
                    <UploadFile 
                        multiple={false}
                        onChange={handleImageChange}
                        showImage={false}
                        maxFileSizeInMB={maxFileSizeInMB}
                        fileTypes={docsFileTypes}
                    >
                        {t('Upload')} <span style={{width: '15px'}}><UploadIcon /></span>
                    </UploadFile>
                </div>
            </div>
        </div>
    );
}

export default ActionUpload;