import fieldTypes from "../../components/FormModal/fields/fieldTypes";

import {
    copyPropertyOrThrow,
    copyPropertyOrNull,
    copyPropertyIdOrNull,
    copyPropertyIdOrThrow,
    copyPropertyValueOrThrow,
    copyAndFormatDateOrThrow
} from "../formUtils";
import { defaultBoxesPerPallet } from "../generalConfig";
import { paths } from "../routes/appRoutes";

export const productOfferTableSGroups = [
    'product_offer_read_id',
    'product_offer_read_buyer_company',
        'company_read_company_name',
        'company_read_contact_name',
        'company_read_contact_last_name',
        'company_read_logo',
    'product_offer_read_status',
    'read_created_date',
    'read_updated_date',
    'app_file_read'
];

export const productOfferVisitsSectionSGroups = [
    'product_offer_read_id',
    'product_offer_read_status',
    'product_offer_read_filling_order',
    'product_offer_read_buyer_company',
    'product_offer_read_company_product',
        'company_product_read_name',
        'company_product_read_id',
        'company_product_read_sector',
            'sector_read_name',
            'sector_read_id',
            'sector_read_orchard',
                'orchard_read_name',
                'orchard_read_company',
        'company_product_read_company',
            'company_read_company_name',
            'company_read_id',
        'company_product_read_visits',
    'product_offer_read_user_in_charge',
    'company_product_read_orchard_visits',
        'orchard_visit_read_id',
        'orchard_visit_read_phenological_stage',
        'orchard_visit_read_reason',
        'orchard_visit_read_status',
        'orchard_visit_read_user_in_charge',
];

export const  productOfferDetailStep1SGroups  = [
    'product_offer_read',
    'product_offer_read_id',
    'product_offer_read_status',
    'product_offer_read_offer_code',
    'product_offer_read_description',
    'product_offer_read_buyer_company',
    'product_offer_read_company_product',
        'company_product_read_name',
        'company_product_read_id',
        'company_read_company_name',
        'company_read_id',
    'product_offer_read_buying_order',
    'warehouse_read',
    'read_file',
    'extra_file_read_id',
    'extra_file_read_name',
    'extra_file_read_file'
];

export const  productOfferDetailStep2SGroups  = [
    'product_offer_read',
    'product_offer_read_id',
    'product_offer_read_status',
    'product_offer_read_offer_code',
    'product_offer_read_description',
    'product_offer_read_buyer_company',
    'product_offer_read_address',
        'address_read',
    'product_offer_read_company_product',
        'company_product_read_name',
        'company_product_read_id',
        'company_product_read_sector',
        'company_product_read_quantity',
            'sector_read_name',
            'sector_read_id',
            'sector_read_orchard',
                'orchard_read_name',
                'orchard_read_company',
        'company_product_read_company',
            'company_read_company_name',
            'company_read_id',
    'company_product_read_product',
        'company_read_id',
    'product_offer_read_buying_order',
    'read_file',
    'product_read',
        'variation_attribute_read_id',
        'variation_attribute_read_attribute',
        'variation_value_read_variation_attribute',
            'variation_value_read_id',
            'variation_value_read_value',
    'product_offer_read_unit',
        'unit_read_id',
        'unit_read_name',
    'company_product_read_boxes_per_pallet',
        'brand_read'
];


export const productOfferDetailSGroups = [
    'product_offer_read',
    'product_offer_read_id',
    'product_offer_read_status',
    'product_offer_read_offer_code',
    'product_offer_read_description',
    'product_offer_read_company_product',
    'company_product_read',
    'product_offer_read_filling_order',
    'product_offer_read_filled_by_product_orders',
    'product_offer_read_orchard',
    'orchard_read',
    'product_offer_read_buyer_company',
    'company_read',
    'product_offer_read_seller_company',
    'product_offer_read_buyer_signature',
    'app_file_read',
    'product_offer_read_seller_signature',
    'product_offer_read_contract_file',
    'product_offer_read_product_offer_updates',
    'product_offer_update_read',
    'product_offer_read_interested_companies',
    'product_offer_read_brand_name',
    'product_offer_read_quality',
    'product_offer_read_units_per_box',
    'product_offer_read_boxes_per_pallet',
    'product_offer_read_price_per_box',
    'product_offer_read_driving',
    'product_offer_read_variety',
    'read_created_by',
    'user_read',
    'read_updated_by',
    'read_created_date',
    'read_updated_date',
];

export const productOfferTableColumns = [
    {
        Header: 'id',
        accessor: 'id'
    },
     {
        Header: 'status',
        accessor: 'status'
    },
     {
        Header: 'offerCode',
        accessor: 'offerCode'
    },
     {
        Header: 'description',
        accessor: 'description'
    },
     {
        Header: 'companyProduct',
        accessor: 'companyProduct?.id'
    },
     {
        Header: 'fillingOrder',
        accessor: 'fillingOrder?.id'
    },
     {
        Header: 'filledByProductOrders',
        accessor: 'filledByProductOrders?.id'
    },
     {
        Header: 'orchard',
        accessor: 'orchard?.id'
    },
     {
        Header: 'buyerCompany',
        accessor: 'buyerCompany?.id'
    },
     {
        Header: 'sellerCompany',
        accessor: 'sellerCompany?.id'
    },
     {
        Header: 'buyerSignature',
        accessor: 'buyerSignature?.id'
    },
     {
        Header: 'sellerSignature',
        accessor: 'sellerSignature?.id'
    },
     {
        Header: 'contractFile',
        accessor: 'contractFile?.id'
    },
     {
        Header: 'productOfferUpdates',
        accessor: 'productOfferUpdates?.id'
    },
     {
        Header: 'interestedCompanies',
        accessor: 'interestedCompanies?.id'
    },
     {
        Header: 'brandName',
        accessor: 'brandName'
    },
     {
        Header: 'quality',
        accessor: 'quality'
    },
     {
        Header: 'unitsPerBox',
        accessor: 'unitsPerBox'
    },
     {
        Header: 'boxesPerPallet',
        accessor: 'boxesPerPallet'
    },
     {
        Header: 'pricePerBox',
        accessor: 'pricePerBox'
    },
     {
        Header: 'driving',
        accessor: 'driving'
    },
     {
        Header: 'variety',
        accessor: 'variety'
    },
     {
        Header: 'createdBy',
        accessor: 'createdBy?.id'
    },
     {
        Header: 'updatedBy',
        accessor: 'updatedBy?.id'
    },
     {
        Header: 'createdDate',
        accessor: 'createdDate'
    },
     {
        Header: 'updatedDate',
        accessor: 'updatedDate'
    },
 ];

export const productOfferDataDetailsArray = (productOffer) => [
    {data: productOffer?.id, dataLabel: 'id'},
    {data: productOffer?.status, dataLabel: 'status'},
    {data: productOffer?.offerCode, dataLabel: 'offerCode'},
    {data: productOffer?.description, dataLabel: 'description'},
    {data: productOffer?.companyProduct?.id, dataLabel: 'companyProduct'},
    {data: productOffer?.fillingOrder?.id, dataLabel: 'fillingOrder'},
    {data: productOffer?.filledByProductOrders?.id, dataLabel: 'filledByProductOrders'},
    {data: productOffer?.orchard?.id, dataLabel: 'orchard'},
    {data: productOffer?.buyerCompany?.id, dataLabel: 'buyerCompany'},
    {data: productOffer?.sellerCompany?.id, dataLabel: 'sellerCompany'},
    {data: productOffer?.buyerSignature?.id, dataLabel: 'buyerSignature'},
    {data: productOffer?.sellerSignature?.id, dataLabel: 'sellerSignature'},
    {data: productOffer?.contractFile?.id, dataLabel: 'contractFile'},
    {data: productOffer?.productOfferUpdates?.id, dataLabel: 'productOfferUpdates'},
    {data: productOffer?.interestedCompanies?.id, dataLabel: 'interestedCompanies'},
    {data: productOffer?.brandName, dataLabel: 'brandName'},
    {data: productOffer?.quality, dataLabel: 'quality'},
    {data: productOffer?.unitsPerBox, dataLabel: 'unitsPerBox'},
    {data: productOffer?.boxesPerPallet, dataLabel: 'boxesPerPallet'},
    {data: productOffer?.pricePerBox, dataLabel: 'pricePerBox'},
    {data: productOffer?.driving, dataLabel: 'driving'},
    {data: productOffer?.variety, dataLabel: 'variety'},
    {data: productOffer?.createdBy?.id, dataLabel: 'createdBy'},
    {data: productOffer?.updatedBy?.id, dataLabel: 'updatedBy'},
    {data: productOffer?.createdDate, dataLabel: 'createdDate'},
    {data: productOffer?.updatedDate, dataLabel: 'updatedDate'},
];


export const newProductOfferFormDefinition = [
    {
        name: 'status',
        label: 'status',
        required: false,
        type: fieldTypes.text
    },

    {
        name: 'description',
        label: 'description',
        required: true,
        type: fieldTypes.text
    },

    {
        name: 'companyProduct',
        label: 'companyProduct',
        required: true,
        type: fieldTypes.entity,
        entity: 'companyProducts'
    },

    {
        name: 'fillingOrder',
        label: 'fillingOrder',
        required: false,
        type: fieldTypes.entity,
        entity: 'productOffers'
    },

    {
        name: 'orchard',
        label: 'orchard',
        required: false,
        type: fieldTypes.entity,
        entity: 'orchards'
    },

    {
        name: 'buyerSignature',
        label: 'buyerSignature',
        required: false,
        type: fieldTypes.entity,
        entity: 'appFiles'
    },

    {
        name: 'sellerSignature',
        label: 'sellerSignature',
        required: false,
        type: fieldTypes.entity,
        entity: 'appFiles'
    },

    {
        name: 'contractFile',
        label: 'contractFile',
        required: false,
        type: fieldTypes.entity,
        entity: 'appFiles'
    },

    {
        name: 'brandName',
        label: 'brandName',
        required: false,
        type: fieldTypes.text
    },

    {
        name: 'quality',
        label: 'quality',
        required: false,
        type: fieldTypes.text
    },

    {
        name: 'unitsPerBox',
        label: 'unitsPerBox',
        required: false,
        type: fieldTypes.number
    },

    {
        name: 'boxesPerPallet',
        label: 'boxesPerPallet',
        required: false,
        type: fieldTypes.number
    },

    {
        name: 'pricePerBox',
        label: 'pricePerBox',
        required: false,
        type: fieldTypes.number
    },

    {
        name: 'driving',
        label: 'driving',
        required: false,
        type: fieldTypes.text
    },

    {
        name: 'variety',
        label: 'variety',
        required: false,
        type: fieldTypes.text
    },

];

export const editProductOfferFormDefinition = [
    ...newProductOfferFormDefinition
];

export const prepareNewProductOfferForServer = (form) => {
    const productOffer= {};

    copyPropertyOrNull(form, productOffer, 'status');
    copyPropertyOrThrow(form, productOffer, 'description', 'Debes agregar description de productOffer');
    copyPropertyIdOrThrow(form, productOffer, 'companyProduct', 'Debes agregar companyProduct de productOffer');
    copyPropertyIdOrNull(form, productOffer, 'fillingOrder', 'Debes agregar fillingOrder de productOffer');
    copyPropertyIdOrNull(form, productOffer, 'orchard', 'Debes agregar orchard de productOffer');
    copyPropertyIdOrNull(form, productOffer, 'buyerSignature', 'Debes agregar buyerSignature de productOffer');
    copyPropertyIdOrNull(form, productOffer, 'sellerSignature', 'Debes agregar sellerSignature de productOffer');
    copyPropertyIdOrNull(form, productOffer, 'contractFile', 'Debes agregar contractFile de productOffer');
    copyPropertyOrNull(form, productOffer, 'brandName');
    copyPropertyOrNull(form, productOffer, 'quality');
    copyPropertyOrNull(form, productOffer, 'unitsPerBox');
    copyPropertyOrNull(form, productOffer, 'boxesPerPallet');
    copyPropertyOrNull(form, productOffer, 'pricePerBox');
    copyPropertyOrNull(form, productOffer, 'driving');
    copyPropertyValueOrThrow(form, productOffer, 'variety');

    return productOffer;
};

export const prepareEditedProductOfferForServer = (form) => {
    return prepareNewProductOfferForServer(form);
};

export const productOfferToFormData = (productOffer) => {
    let varieties = {};

    productOffer.variationValues?.forEach(variationValue => 
        varieties[`variantAttribute_${variationValue.variationAttribute?.id}`] = {value: variationValue.id, label: variationValue.value}
    );
    
    let deliveryDate = null;

    if(productOffer.deliveryDate) {
        deliveryDate = new Date(productOffer?.deliveryDate);
        deliveryDate.setMinutes(deliveryDate.getMinutes() + deliveryDate.getTimezoneOffset())
    }

    return {
        ...productOffer,
        ...varieties,
        product: {...productOffer.product},
        noBoxes: productOffer.boxes,
        boxesPerPallet: productOffer.boxesPerPallet,
        buyerCompany: {label: productOffer.buyerCompany?.companyName, value: productOffer.buyerCompany?.id},
        warehouse: {label: productOffer.warehouse?.name, value: productOffer.warehouse?.id},
        brand: {label: productOffer.brand?.name, value: productOffer.brand?.id},
        handling: {label: productOffer.handling, value: productOffer.handling},
        deliveryDate
    }
}

export const productorToproductorOffer = (product) => {
    return {
        ...product,
        product: {...product.product},
        noBoxes: product.boxes,
        boxesPerPallet: product.boxesPerPallet,
    }
}

export const productOfferStatus = {
    STATUS_PENDING: 'STATUS_PENDING',
    STATUS_ACCEPTED: 'STATUS_ACCEPTED',
    STATUS_REJECTED: 'STATUS_REJECTED',
    STATUS_CANCELED: 'STATUS_CANCELED',
}

export const prepareProductOfferForServerStep1 = (form, t) => {
    const productOffer= {};

    copyPropertyIdOrNull(form, productOffer, 'sellerCompany', t('must-select-seller-company'));
    copyPropertyValueOrThrow(form, productOffer, 'buyerCompany', t('must-select-buyer-company'));
    copyPropertyValueOrThrow(form, productOffer, 'warehouse', t('must-select-warehouse'));
    copyPropertyOrThrow(form, productOffer, 'deliveryDate', t('must-select-delivery-date'));


    if(!productOffer.status)
        productOffer.status = productOfferStatus.STATUS_ACCEPTED; // by default status is accepted 
    
    productOffer.extraFiles = getExtraFilesToSync(form);

    return productOffer;
};

export const getExtraFilesToSync = (form) => {
    const extraFiles = [];
    
    for (let j = 0; j < form.extraFiles?.length; j++) {
        if(form.extraFiles[j].id) {
            extraFiles.push({
                id: form.id,
                name: form.name,
            });
        }
    }
    

    return extraFiles;
}

export const getProductOfferFilesStep1 = (form) => {
    const files = {};
    
    files['extraFiles'] = [];
    files['buyingOrder'] = !form.buyingOrder?.id ? form.buyingOrder : null;
    files['paymentXmlInvoice'] = !form.paymentXmlInvoice?.id ? form.paymentXmlInvoice : null;
    files['paymentPdfInvoice'] = !form.paymentPdfInvoice?.id ? form.paymentPdfInvoice : null;
    files['exportationPdfInvoice'] = !form.exportationPdfInvoice?.id ? form.exportationPdfInvoice : null;
    files['exportationXmlInvoice'] = !form.exportationXmlInvoice?.id ? form.exportationXmlInvoice : null;
    files['packingList'] = !form.packingList?.id ? form.packingList : null;

    // extrafiles 
    for (let j = 0; j < form.extraFiles?.length; j++) {
        files['extraFiles'].push({
            'name': form.extraFiles[j].name,
            'file': form.extraFiles[j].file
        });
    }

    Object.keys(files).forEach(key => {
        if (files[key] === undefined || files[key] === null) {
          delete files[key];
        }
    });

    return files;
}

export const getEmptyArrayProductOffers = () => {
    return [getEmptyProductOffer()];
}

export const getEmptyProductOffer = () => {
    return {
        id: null,
        unit: null,
        boxes: null,
        boxesPerPallet: defaultBoxesPerPallet,
        productors: [],
        product: null,
        address: {},
        buyingOrder: null,
    };
}

export const prepareProductOfferForServerStep2 = (form, productOfferId, t) => {
    const productOffers= [];
    const productOfferFiles= [];

    for (let i = 0; i < form.length; i++) {
        const productForm = form[i];
        
        let productOffer = {};
        productOffer.fillingOrder = productOfferId
        productOffer.id = productForm.id;

        // validations
        copyPropertyIdOrThrow(productForm, productOffer, 'product', t('Must-select-product'), 'product');
        copyPropertyOrThrow(productForm, productOffer, 'noBoxes', t('Must-select-no-boxes'));
        copyPropertyOrThrow(productForm, productOffer, 'boxesPerPallet', t('Must-select-boxes-per-pallet'));
        copyPropertyOrNull(productForm, productOffer, 'packingComments');
        copyPropertyValueOrThrow(productForm, productOffer, 'brand', t('Must-select-brand'));
        copyPropertyValueOrThrow(productForm, productOffer, 'handling', t('Must-select-handling'));

        productOffer.noBoxes = parseInt(productOffer.noBoxes);
        productOffer.boxes = parseInt(productOffer.noBoxes);
        productOffer.boxesPerPallet = parseInt(productOffer.boxesPerPallet);

        productOffer.variationValues = [];

        const variationAttributes = getVariationAttributes(productForm.product);

        // check for variationAttributes
        if (variationAttributes) {
            // check if each variation exists in the form
            variationAttributes.forEach(variationAttribute => {
                const variationValueId = productForm[`variantAttribute_${variationAttribute.id}`]?.value;

                if (!variationValueId) {
                    throw new Error(t('Must-select-all-varieties'));
                }

                productOffer.variationValues.push(variationValueId);
            });
        }

        // productors
        if(productForm.productors.length===0)
            throw new Error(t('Must-select-at-least-one-productor'));

        productOffer.productors = [];

        for (let j = 0; j < productForm.productors.length; j++) {
            let productorForm = {};            

            // validations for productor fields
            copyPropertyValueOrThrow(productForm.productors[j], productorForm, 'sellerCompany', t('Must-select-productor'));
            copyPropertyOrThrow(productForm.productors[j], productorForm, 'boxes', t('Must-select-no-boxes'));
            copyPropertyOrThrow(productForm.productors[j], productorForm, 'pricePerBox', t('Must-select-price-per-box'));
            copyAndFormatDateOrThrow(productForm.productors[j], productorForm, 'date', t('Must-select-delivery-date'));
            copyPropertyOrNull(productForm.productors[j], productorForm, 'comments');
            copyPropertyIdOrThrow(productForm, productOffer, 'product', t('Must-select-product'), 'product');

            productorForm.boxes = parseInt(productorForm.boxes);
            productorForm.pricePerBox = parseFloat(productorForm.pricePerBox);
            productorForm.status = productForm.productors[j].status || productOfferStatus.STATUS_ACCEPTED;
            productorForm.id = productForm.productors[j].id;

            productOffer.productors.push(productorForm);
        }

        productOffer.status = productOfferStatus.STATUS_ACCEPTED;

        productOffers.push(productOffer);

        // files for productOffer
        const {files, extraFiles} = prepareProductOfferFilesForServerStep2(productForm);
        productOfferFiles.push(files);
        productOffer.extraFiles = extraFiles;
    }    

    return {productOffers, productOfferFiles};
}

export const prepareProductOfferFilesForServerStep2 = (form) => {
    const files = {};
    const extraFiles = [];
    
    files['paymentXmlInvoice'] = form.paymentXmlInvoice;
    files['paymentPdfInvoice'] = form.paymentPdfInvoice;
    files['exportationXmlInvoice'] = form.exportationXmlInvoice;
    files['exportationPdfInvoice'] = form.exportationPdfInvoice;
    files['extraFiles'] = [];

    // extrafiles 
    for (let j = 0; j < form.extraFiles?.length; j++) {
        extraFiles.push({
            'name': form.extraFiles[j].name,
            'file': form.extraFiles[j].file
        });
    }

    Object.keys(files).forEach(key => {
        if (files[key] === undefined || files[key] === null) {
          delete files[key];
        }
    });

    return {files, extraFiles};
}

// steps for creating ordeer
export const getOrderSteps = (t) => [
    {
        name: t('Client-order'),
        link: paths.mm.ordersEdit,
    },
    {
        name: t('Products-to-be-ordered'),
        link: paths.mm.ordersFormStep2,
    }
];

const getVariationAttributes = (product) => {
    const variationAttributes = [];

    product?.variationValues.forEach(variationValue => {
        const variationAttribute = variationValue.variationAttribute;
        
        if (!variationAttributes.find(va => va.id === variationAttribute.id)) {
            variationAttributes.push(variationAttribute);
        }
    });

    return variationAttributes;
}