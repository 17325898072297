import React, { useContext } from 'react'
import { ApiContext } from '../../../../../services/api/api-config';
import './SelectedProductDetail.scss';

const SelectedProductDetail = ({product}) => {
    const api = useContext(ApiContext);
    const image = product.image ? api.appFiles.getUrl({id: product.image.id}) : 'https://via.placeholder.com/150';

    return (
        <div className='SelectedProductDetail mt-3'>
            <img src={image} alt=""/>
            <div>
                <div className='SelectedProductDetail__title'>{product.baseProduct?.name||product.codeProduct}</div>
                <div className='SelectedProductDetail__id'>{product.id}</div>
            </div>
        </div>
    );
}

export default SelectedProductDetail;
