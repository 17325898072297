import classNames from 'classnames';
import React, { useState } from 'react'
import {ReactComponent as HeartIcon} from "../../../assets/images/icons/heart.svg";

import './CardWithImage.scss';

const CardWithImage = ({image, title, subTitle, unit, content, liked=null, productId, onLike, promotionValue, children}) => {
    const [like, setLike] = useState(liked);
    
    return (
        <div className='CardWithImage'>
            {onLike &&
                <div className={classNames('CardWithImage__like', !!like&&'CardWithImage__like--active')} onClick={() => setLike(!like)}>
                    <HeartIcon />
                </div>}

            {promotionValue && 
                <div className='CardWithImage__promotion'>
                    -{promotionValue}% off
                </div>}

            <img src={image} alt="Avocado" className='CardWithImage__header-image'/>
            <div className='CardWithImage__title'>
                {title}
            </div>
            <div className='CardWithImage__productId'>
                ID{productId}
            </div>            
            <div className='CardWithImage__price'>
                <div className='CardWithImage__subTitle'>
                    {subTitle}
                </div>
                <div className='CardWithImage__unit'>
                    /
                    {unit}
                </div>
            </div>
            <div className='CardWithImage__content'>
                {content || children}
            </div>
        </div>
    );
}

export default CardWithImage;