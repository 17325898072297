import React, { useContext, useState, useMemo, useCallback } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Card from '../../../components/Dashboard/Card/Card';
import { SectionTitle } from '../../../components/Dashboard/SectionTitle/SectionTitle';
import { SideMenu } from '../../../components/Dashboard/SideMenu/SideMenu';
import TopBar from '../../../components/Dashboard/TopBar/TopBar';
import SelectWithLabel from '../../../components/forms/SelectWithLabel/SelectWithLabel';
import TextInput from '../../../components/forms/TextInput/TextInput';
import FileUploader from '../../../components/forms/FileUploader/FileUploader';
import Button from '../../../components/molecules/Button/Button';
import useFormState from '../../../hooks/useFormState';
import { ApiContext } from '../../../services/api/api-config';
import { getEmptyProduct, getProductFiles, prepareNewProductForServer, productDetailSGroups, productToFormData } from '../../../services/models/productUtils';
import { getNotifier } from '../../../services/notifier';
import { paths } from '../../../services/routes/appRoutes';
import VariationsForm from './components/VariationsForm/VariationsForm';
import './ProductsForm.scss';
import { variationAttributesDetailSGroups, variationAttributesToFormData } from '../../../services/models/variationAttributeUtils';
import { baseProductSGropus } from '../../../services/models/baseProductUtils';
import { SecurityContext } from '../../../services/SecurityManager';

const ProductsForm = () => {
    const {t} = useTranslation();
    const api = useContext(ApiContext);
    const {me} = useContext(SecurityContext);
    const {id} = useParams();
    const { form, setForm, bindInput, bindSimple } = useFormState({...getEmptyProduct()});
    const navigate = useNavigate();
    const customPropId = 'product.detail';
    const [baseProducts, setBaseProducts] = useState([]);
    const [variationAttributes, setVariationAttributes] = useState([]);

    const reloadVariants = useCallback(() => {
        api.variationAttributes.get({ params: {sGroups: variationAttributesDetailSGroups} })
        .then(variationAttributes => setVariationAttributes( variationAttributesToFormData(variationAttributes) ))
        .catch(e => getNotifier().error(e.message));
    }, [api.variationAttributes]);

    // Load data
    useEffect(() => {
        if(id)
            api.products.get({id, params: {sGroups: productDetailSGroups}, customProp: customPropId})
                .then(product => setForm( {...productToFormData(product)} ))
                //.catch(error => getNotifier().error(error.message||error.detail));

        // get base products
        api.baseProducts.get({ params: {sGroups: baseProductSGropus}})
            .then(setBaseProducts)
            .catch(e => getNotifier().error(e.message||e.detail));
        
        // get variation attributes from api
        api.variationAttributes.get({ params: {sGroups: variationAttributesDetailSGroups, lang: me.locale } })
            .then(variationAttributes => setVariationAttributes( variationAttributesToFormData(variationAttributes) ))
            .catch(e => getNotifier().error(e.message||e.detail));
    }, [api, id, setForm, customPropId, me]);

    const gotoProductsList = () => {
        navigate(paths.mm.products);
    }

    // Method for create new product or update if id is set
    const saveProduct = () => {
        let product = null;
        let files = null;
        const {id} = form;
        const method = id ? 'update' : 'create';

        try {
            product = prepareNewProductForServer(form, t);
            files = getProductFiles(form);
        } catch (error) {
            return getNotifier().error(error.message||error.detail);
        }

        // save product
        api.products[method]({id, params: product, files})
            .then(product => showConfirmationAndRedirect(product))
            .catch(error => getNotifier().error(error.message||error.detail));
    }

    const showConfirmationAndRedirect = (product) => {
        getNotifier().success(t('Product-created-successfully'));
        gotoProductsList();
    }

    const baseProductOptions = useMemo(() => baseProducts.map(baseProduct => ({ value: baseProduct.id, label: baseProduct.name })), [baseProducts]);

    return (
        <div className="ProductsForm dashboard-container">
            <SideMenu />
            <div className='right-content'>
                <TopBar />
                <div className='main-content'>
                    <SectionTitle>{t('Add-new-product')}</SectionTitle>

                    <div className='row'>
                        <Card className='OrderStep1'>
                            <div className='row mt-3'>
                                <div className='col-sm-6 col-lg-4 margin-top-mobile'>                                   
                                    <SelectWithLabel
                                        label={t('baseProduct')}
                                        inputProps={{
                                            ...bindSimple('baseProduct'),
                                            options: baseProductOptions
                                        }}
                                    />
                                </div>                            
                                <div className='col-sm-6 col-lg-4 margin-top-mobile'>
                                    <TextInput 
                                        label={t('Product-code')}
                                        {...bindInput('productCode')}
                                    />
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className='col-sm-6 col-lg-4 margin-top-mobile'>
                                    <FileUploader
                                        label={t('Upload-image')}
                                        btnLabel={t('Product-image')}
                                        fileTypes={['jpg', 'png', 'jpeg']}
                                        maxFileSizeInMB={5}
                                        {...bindSimple('image')}
                                    />
                                </div>
                            </div>
                            
                            <VariationsForm 
                                form={form}
                                setForm={setForm}
                                bindInput={bindInput}
                                bindSimple={bindSimple}
                                variationAttributes={variationAttributes}
                                reloadVariants={reloadVariants}
                            />

                            <div className='row mt-3 d-flex justify-content-center align-items-center gap-3'>
                                <Button
                                    className={'btn-primary btn-outline btnNextStep NextStepButtons__button'}
                                    onClick={gotoProductsList}
                                    design={'clear'}
                                >
                                    {t('Cancel')}
                                </Button>
                                <Button
                                    className={'btn-primary btnNextStep NextStepButtons__button'}
                                    onClick={saveProduct}
                                >
                                    {t('Save-and-continue')}
                                </Button>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProductsForm;