import moment from 'moment';
import React, { useMemo, useEffect, useState } from 'react'
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Card from '../../../components/Dashboard/Card/Card';
import MiniTitle from '../../../components/Dashboard/MiniTitle/MiniTitle';
import { SectionTitle } from '../../../components/Dashboard/SectionTitle/SectionTitle';
import { SideMenu } from '../../../components/Dashboard/SideMenu/SideMenu';
import SimpleList from '../../../components/Dashboard/SimpleList/SimpleList';
import TopBar from '../../../components/Dashboard/TopBar/TopBar';
import FoliarApplicationsForm from '../../../components/FoliarApplicationsForm/FoliarApplicationsForm';
import MmSelect from '../../../components/forms/MmSelect/MmSelect';
import Switch from '../../../components/forms/Switch/Switch';
import Button from '../../../components/molecules/Button/Button';
import SearchBarDateFilter from '../../../components/SearchBar/filters/SearchBarDateFilter/SearchBarDateFilter';
import TideEntitySelect from '../../../components/utility/TideEntitySelect/TideEntitySelect';
import TideReactTable from '../../../components/utility/TideReactTable/TideReactTable';
import useBoolean from '../../../hooks/useBoolean';
import useFormState from '../../../hooks/useFormState';
import { ApiContext } from '../../../services/api/api-config';
import { companyDetailSGroups, companyTypes } from '../../../services/models/companyUtils';
import { orchardDetailSGroups } from '../../../services/models/orchardUtils';
import { getEmptyOrchardVisit, getOrchardSectorsColumns, prepareOrchardVisitForServer, orchardVisitToFormData, orchardVisitDetailSGroups } from '../../../services/models/orchardVisitUtils';
import { ADMIN_ROLE_ID, userListSGroups } from '../../../services/models/userUtils';
import { getNotifier } from '../../../services/notifier';
import { paths } from '../../../services/routes/appRoutes';
import SectorDetailsModal from './components/SectorDetailsModal/SectorDetailsModal';
import { generalDatesFormat } from '../../../services/generalConfig';
import { foliarApplicationsTableSGroups } from '../../../services/models/foliarApplicationUtils';
import './FollowUpsForm.scss';
import useTideTable from '../../../components/utility/TideReactTable/useTideTable';
import { useCallback } from 'react';
import { getEmptyVisitSectorDetails } from '../../../services/models/visitSectorDetailUtils';

const companyFilters = {
    sGroups: companyDetailSGroups,
    type: companyTypes.TYPE_GROWER
}

const FollowUpsForm = () => {
    const {t} = useTranslation();
    const {id} = useParams();
    const navigate = useNavigate();
    const api = useContext(ApiContext);
    const {form, setForm, bindSimple} = useFormState(getEmptyOrchardVisit());
    const [isOpenFoliarApplicationModal, openFoliarApplicationModal, closeFoliarApplicationModal] = useBoolean(false);
    const [isOpenSectorModal, openSectorModal, closeSectorModal] = useBoolean(false);
    const [users, setUsers] = useState([]);

    useEffect(() => {
        api.users.get({params: {role: ADMIN_ROLE_ID, sGroups: userListSGroups }})
            .then(setUsers)
            .catch(error => {getNotifier().error(error.message||error.detail);});

        if(id)
            api.orchardVisits.get({id, params: { sGroups: orchardVisitDetailSGroups }})
                .then(orchardVisit => setForm( orchardVisitToFormData(orchardVisit, t) ))
                .catch(error => {getNotifier().error(error.message||error.detail);});
        
    } , [api, id, t, setForm]);

    const showNotificationAndRedirect = (newVisit) => {
        getNotifier().success(`${t('visit.created')} ${t('visit.cantrack')}`);
        navigate(paths.mm.followups);
    }

    const handleSaveFollowUp = (e) => {
        e.preventDefault();
        const id = form.id;
        const method = id ? 'update' : 'create';

        let visit = {};

        try {
            visit = prepareOrchardVisitForServer(form, t);
        } catch (error) {
            return getNotifier().error(error.message||error.detail);
        }

        api.orchardVisits[method]({id, params: visit})
            .then(showNotificationAndRedirect)
            .catch(error => getNotifier().error(error.message||error.detail));
    };

    const orchardFilters = {
        sGroups: orchardDetailSGroups,
        company: form?.company?.id,
    };

    const handleChangeProductor = (company) => {
        setForm({...form, orchard: null, companyProduct: null, company});
    }

    const userInChargeOptions = users.map(user => { return { value: user.id, label: user.email } });

    const orchardSectorsData = useMemo(() => {
        if(!form?.orchard?.sectors) return [];
        
        return form.orchard?.sectors?.map(sector => {
            let selectedSector = form?.sectors?.find(s => s.id === sector.id);
            if(!selectedSector) {
                selectedSector = getEmptyVisitSectorDetails();
                selectedSector.sector = sector;
            }

            return {
                id: sector.id,
                name: sector.name,
                command: 
                    <div className='d-flex justify-content-start align-items-center gap-3'>
                        <div>
                            <Switch
                                id={`sector-${sector.id}-visited`}
                                name={`sector-${sector.id}-visited`}
                                value={ sector.visited||false }
                                disabled={true}
                            />
                            { form.visitSectorDetails?.find( v => v.sector?.id === sector.id ) ? t('Visited') : t('Pending') }
                        </div>
                        
                        <Button type="button" onClick={() => {
                            setForm({...form, selectedSector});
                            openSectorModal();
                        }}>
                            {t('Sector-details')}
                        </Button>
                    </div>
            }
        });
    }, [setForm, form, openSectorModal, t]);

    const ordersTableColumns = useMemo(() => [
        {
            Header: 'ID',
            accessor: 'id'
        },
        {
            Header: t('Orchard'),
            accessor: 'orchard.name'
        },
        {
            Header: t('Sectors'),
            accessor: application => <div>{application.sectors.map(s => s.name).join(', ')}</div>
        },
        {
            Header: t('Created-by'),
            accessor: 'createdBy.email'
        },
        {
            Header: t('Application-date'),
            accessor: application => moment(application.createdDate).format(generalDatesFormat)
        }
    ], [t]);

    //const orchardFilter = [];

    const requestFilters = useMemo(() => {
        const orchardFilter = {
            orchard: form.orchard?.id
        };

        return {
            sGroups: foliarApplicationsTableSGroups,
            ...orchardFilter
        }
    }, [form.orchard]);

    const table = useTideTable({
        entity: 'foliarApplications',
        columns: ordersTableColumns,
        requestFilters
    });

    const handleVisitSectorChange = useCallback((visitSector) => {
        const newVisitSectorDetails = [...form.visitSectorDetails||[]];
        const index = newVisitSectorDetails.length>0 ? newVisitSectorDetails?.findIndex(v => v.sector?.id === visitSector.sector?.id) : null;

        if(index !== null) 
            newVisitSectorDetails[index] = visitSector;
        else
            newVisitSectorDetails.push(visitSector);
        
        setForm({...form, visitSectorDetails: newVisitSectorDetails});
    }, [setForm, form]);

    const currentSectorDetail = form.visitSectorDetails?.find(sd => sd.sector?.id===form.selectedSector?.sector?.id) || getEmptyVisitSectorDetails(form.selectedSector?.sector);

    return (
        <div className='FollowUpsForm'>
            <SideMenu />
            <div className='right-content'>
                <TopBar />
                <div className='main-content'>
                    <SectionTitle>{ !id ? t('Add-new-visit') : t('Edit-visit')}</SectionTitle>
                    
                    <Card>
                        <form onSubmit={handleSaveFollowUp}>
                            <MiniTitle className="product-title">{t('General-Information')}</MiniTitle>

                            <div className="row mt-3">
                                <div className="col-md-6">
                                    <MiniTitle>{t('Productor')}</MiniTitle>
                                    <TideEntitySelect
                                        entity='companies'
                                        className='products-select'
                                        placeholder={t('Search-by-name-or-id')}
                                        preload
                                        additionalFilters={companyFilters}
                                        onChange={handleChangeProductor}
                                        value={form.company}
                                        labelCreator={(company) => `${company.companyName}`}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <MiniTitle>{t('Orchard')}</MiniTitle>
                                    <TideEntitySelect
                                        entity='orchards'
                                        className='orchards-select'
                                        placeholder={t('Search-by-name-or-id')}
                                        preload
                                        additionalFilters={orchardFilters}
                                        {...bindSimple('orchard')}
                                        labelCreator={orchard => `${orchard.name}`}
                                        key={form?.company?.id||0}
                                    />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-6">
                                    <SearchBarDateFilter
                                        className="mt-0"
                                        label={t('Date')}
                                        name='date'
                                        {...bindSimple('date')}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <MmSelect
                                        placeholder={t('User')}
                                        options={userInChargeOptions}
                                        {...bindSimple('userInCharge')}                                        
                                    />
                                </div>
                            </div>

                            <div className="row mt-3">
                                <MiniTitle>{t('Orchard-sectors')}</MiniTitle>
                            </div>

                            <SimpleList
                                className='sectors-list'
                                columns={getOrchardSectorsColumns(t)}
                                data={orchardSectorsData}
                            />

                            <div className='mt-4 col d-flex justify-content-between align-items-center gap-3'>
                                <MiniTitle>{t('Orchard-applications')}</MiniTitle>

                                <Button 
                                    className={'submit btnNewProducer'} 
                                    type='button' 
                                    onClick={() => {
                                        openFoliarApplicationModal();
                                    }}
                                    id="add-new-foliar-application"
                                >
                                    + {t("Add-new-foliar-application")}
                                </Button>
                            </div>

                            <div className="row mt-4">
                                <div className="">
                                    <TideReactTable 
                                        {...table.tableProps} 
                                        className="productos-table"
                                    />
                                </div>
                            </div>  

                            <div className='row mt-4'>
                                <div className='col-md-12 d-flex justify-content-center align-items-center gap-3 mt-3'>
                                    <Button
                                        design='clear'
                                        onClick={()=> {}}
                                        className="footer-button"
                                    >
                                        {t('Back')}
                                    </Button>

                                    <Button
                                        type='submit'
                                        className="footer-button"
                                    >
                                        {t('Save')}
                                    </Button>
                                </div>
                            </div>
                        </form>               
                    </Card>
                </div>
            </div>

            {isOpenFoliarApplicationModal && 
                <FoliarApplicationsForm 
                    onClose={closeFoliarApplicationModal}
                    selectedProductor={form.company}
                    selectedOrchard={form.orchard}
                    reloadTable={table.reload}
                />}
            {isOpenSectorModal && 
                <SectorDetailsModal 
                    onClose={closeSectorModal}
                    sectorDetails={currentSectorDetail}
                    orchard={form.orchard}
                    onChange={handleVisitSectorChange}
                />}
        </div>
    );
}

export default FollowUpsForm;