import React from 'react'

import './SectorName.scss';

const SectorName = ({children}) => {
    return (
        <div className="SectorName">{children}</div>
    );
}

export default SectorName;