import classNames from 'classnames';
import React from 'react'

import './Textarea.scss';

const Textarea = ({ value, onChange, className, label, placeholder, type, inputProps, ...props }) => {
    return (
        <div className={classNames("Textarea", className)} {...props}>
            {label &&
            <label className='text-input-label'>{label}</label>}
            <textarea 
                className='input-el' 
                placeholder={placeholder} onChange={onChange} type={type} {...inputProps}
                value={typeof value === 'undefined' || !value ? '':value}
            />
        </div>
    );
};

export default Textarea;
