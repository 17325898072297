import React from 'react'
import Card from '../Card/Card';
import dummyImage from '../../../assets/images/dummy/card-image.png';
import Typography from '../Typography/Typography';
import './ResultsCard.scss';
import classNames from 'classnames';

const ResultsCard = ({title, title2, title3, value, value2, value3, color, color2, color3, backgroundColor='', justify}) => {
    const backgroundColorClass = backgroundColor ? `bg-${backgroundColor}` : 'bg-white';
    const justifyClass = justify ? `justify-${justify}` : 'justify-center';

    return (
        <div className={ classNames("ResultsCard") }>
            <Card sx={{height: '100%', minHeight: '161px'}} className={`${backgroundColorClass}`}>
                <div className='ResultsCard__container'>
                    <div className='ResultsCard__left-content'>
                        <img src={dummyImage} alt="" className='img-fluid' />
                    </div>
                    <div className={`ResultsCard__right-content ${justifyClass}`}>
                        <Typography tag={"div"} className={'ResultsCard__main-title'}>
                            {title}
                        </Typography>
                        <Typography tag={"div"} className={'ResultsCard__sub-title'}>
                            {value}
                        </Typography>
                    </div>
                </div>
                <div className='ResultsCard__container ResultsCard__container--mt12'>
                    <div className={`ResultsCard__left-content ${justifyClass}`}>
                        <Typography tag={"div"} className={'ResultsCard__main-title'}>
                            {title2}
                        </Typography>
                        <Typography tag={"div"} className={'ResultsCard__sub-title-mini'}>
                            {value2}
                        </Typography>
                    </div>
                    <div className={`ResultsCard__right-content ${justifyClass}`}>
                        <Typography tag={"div"} className={'ResultsCard__main-title'}>
                            {title3}
                        </Typography>
                        <Typography tag={"div"} className={'ResultsCard__sub-title'} color={color3}>
                            {value3}
                        </Typography>
                    </div>
                </div>
            </Card>
        </div>
    );
}

export default ResultsCard;