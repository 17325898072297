import React, {useCallback, useContext, useEffect, useState} from 'react';
import './RegisterForm.scss';
import HalfScreenLayout from "../../../../components/layout/HalfScreenLayout/HalfScreenLayout";
import background from "../../../../assets/images/backgrounds/Intersectlogin.jpg";
import TextInput from "../../../../components/forms/TextInput/TextInput";
import PasswordInput from "../../../../components/forms/PasswordInput/PasswordInput";
import {Link, useNavigate, useParams} from "react-router-dom";
import Button from "../../../../components/molecules/Button/Button";
import {paths} from "../../../../services/routes/notLoggedRoutes";
import {Trans, useTranslation} from "react-i18next";
import useFormState from "../../../../hooks/useFormState";
import Checkbox from "../../../../components/forms/Checkbox/Checkbox";
import useBoolean from "../../../../hooks/useBoolean";
import {prepareUserFromRegister} from "../../../../services/models/userUtils";
import {ApiContext} from "../../../../services/api/api-config";
import {getNotifier} from "../../../../services/notifier";
import { ValidationMessage } from './components/ValidationMessage/ValidationMessage';

const RegisterForm=()=>{
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {role} = useParams();
    const api = useContext(ApiContext);
    const [validationMessage, setValidationMessage] = useState();

    //If the role doesn't exists, redirect to role selection
    useEffect(()=>{
        if(!role || (role !== 'wholesale' && role !=='grower' )){
            navigate(paths.registerRole, {replace:true});
        }
    },[role, navigate])

    const {form, handleInputChange} = useFormState({});
    const [terms,,,toggleTerms] = useBoolean();

    const signUp = useCallback((e)=>{
        e.preventDefault();
        let user = {};
        
        // validate terms and conditions
        if(!terms) return getNotifier().error(t('must-accept-terms-privacy'));
        
        try {
            user = prepareUserFromRegister(form, role);
        } catch (e){
            return getNotifier().error(e.message);
        }

        api.users.create({params: user, useCommonPath: false })
            .then((user)=>{
                getNotifier().success(t('register-success'));
                setValidationMessage(true);
            })
            .catch(e=>{
                console.log(e);
                return getNotifier().error(e.detail);
            });

    },[api, form, role, t, terms]);

    const loading = false;

    return (
        <>
            {validationMessage && <ValidationMessage email={form.email} />}

            {!validationMessage && 
                <HalfScreenLayout className='Register' rightBackground={background}>
                {[
                    <div className='form-container' key={'form'}>
                        <h1 className='title'>{t("Sign-up")}</h1>
                        <p className='subtitle'>{ role==='grower'? t("As-a-grower") : t("As-a-wholesale")}</p>

                        <form onSubmit={signUp} className='form'>
                            <TextInput
                                label={t("First-name")}
                                placeholder={t("Enter-first-name-here")}
                                onChange={handleInputChange('name')}
                                value={form.name || ''} 
                                className={'TextInput--small'}
                            />
                            <TextInput
                                label={t("Last-name")}
                                placeholder={t("Enter-last-name-here")}
                                onChange={handleInputChange('lastName')}
                                value={form.lastName || ''} 
                                className={'TextInput--small'}
                            />
                            <TextInput
                                label={t("Email")}
                                placeholder={t("Enter-company-email-here")}
                                onChange={handleInputChange('email')}
                                value={form.email || ''} 
                                className={'TextInput--small'}
                            />

                            <PasswordInput
                                label={t("Password")}
                                placeholder={t("Password")}
                                onChange={handleInputChange('password')}
                                value={form.password || ''}
                            />
                            <PasswordInput
                                label={t("Confirm-password")}
                                placeholder={t("Password")}
                                onChange={handleInputChange('confirmPass')}
                                value={form.confirmPass || ''}
                            />

                            <div className='terms-text'>
                                <Checkbox value={terms} onChange={toggleTerms} />{' '}
                                <Trans i18nKey={'I-agree-with-terms-privacy'}>
                                    <Link target={'_blank'} to={"/"}>terms</Link><Link target={'_blank'} to={"/"}>Privacy</Link>
                                </Trans>
                            </div>

                            <div className='but-container'>
                                <Button className={'submit'} type='submit' disabled={loading}>{t("Sign-up")}</Button>
                            </div>
                            <p className='sign-up-text'>
                                {t("already-have-account")} <Link to={paths.login} className={'sign-up-link'}>{t("login-here")}</Link>
                            </p>

                        </form>
                    </div>,
                    <p key={'text'} className={'text-block'}>
                        {t("We-believe-we-can-login-text")}
                    </p>
                ]}
            </HalfScreenLayout>
            }
        </>
    );
};

export default RegisterForm;
