import React from 'react'
import FilterButton from '../forms/FilterButton/FilterButton';
import {filterTypes} from "../../services/searchUtils";
import SearchBarTextFilter from "./filters/SearchBarTextFilter/SearchBarTextFilter";
import SearchBarSelectFilter from "./filters/SearchBarSelectFilter/SearchBarSelectFilter";
import SearchBarDateFilter from "./filters/SearchBarDateFilter/SearchBarDateFilter";
import useCallbackCreator from "use-callback-creator";
import _ from "lodash";
import './SearchBar.scss';

const filterDefinition = {
    [filterTypes.TEXT]: SearchBarTextFilter,
    [filterTypes.SELECT]: SearchBarSelectFilter,
    [filterTypes.DATE]: SearchBarDateFilter,
}

const FilterComponent = ({type, ...props})=>{
    const Component = filterDefinition[type]||filterDefinition[filterTypes.TEXT];
    return <Component {...props} />
};

const SearchBar = ({ onFiltersChange, filters, filtersConfig }) => {
    const filterList = _.filter( filtersConfig?.filters, filter=>!filter.main );

    const handleFilterChange = useCallbackCreator((field, value)=>{
        const newFilters = {...filters};
        if (value === '' || typeof value === 'undefined')//Erase keys of empty filters
            delete newFilters[field];
        else // Or set the new value to filter
            newFilters[field] = value;
        onFiltersChange(newFilters);
    },[filters, onFiltersChange]);

    return (
        <div className='SearchBar'>
            <div className='SearchBar__filter-container'>
                {_.chunk(filterList, 4).map((row, rowIndex) =>
                    <div className='SearchBar__filters' key={rowIndex}>
                        {row.map((filter, index) =>
                            <FilterComponent
                                placeholder={filter.placeholder}
                                key={`${rowIndex}-${index}-${filter.field}`}
                                type={filter.type}
                                value={filters && filter ? filters[filter?.field] : null}
                                onChange={handleFilterChange(filter.field)}
                                config={filter}
                                label={filter.label}
                            />)}
                    </div>
                )}
            </div>

            <FilterButton />
        </div>
    );
}

export default SearchBar;
