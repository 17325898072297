import React from 'react'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import './MMPhoneInput.scss';

const MMPhoneInput = ({label, inputProps}) => {
    return (
        <div className='MMPhoneInput'>
            <label>{label}</label>
            <PhoneInput
                label={label}
                {...inputProps}
                international
                limitMaxLength={true}
            />
        </div>
    );
}

export default MMPhoneInput;