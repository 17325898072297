import React, { useState } from 'react'
import MiniTitle from '../../../../../components/Dashboard/MiniTitle/MiniTitle';
import {useTranslation} from "react-i18next";
import FirstApplicationMessage from '../FirstApplicationMessage/FirstApplicationMessage';
import Button from '../../../../../components/molecules/Button/Button';
import Modal from '../../../../../components/Modal/Modal';
import TextInput from '../../../../../components/forms/TextInput/TextInput';
import MmSelect from '../../../../../components/forms/MmSelect/MmSelect';
import { applicationUnitOptions, prepareFoliarApplicationToServer } from '../../../../../services/models/orchardVisitUtils';
import SearchBarDateFilter from '../../../../../components/SearchBar/filters/SearchBarDateFilter/SearchBarDateFilter';
import { getNotifier } from '../../../../../services/notifier';
import FoliarApplicationRow from './components/FoliarApplicationRow/FoliarApplicationRow';
import './FoliarApplications.scss';

const FoliarApplications = ({form, setForm}) => {
    const {t} = useTranslation();
    const [isShowingModal, setIsShowingModal] = useState();
    
    const handleAddNewFoliarApplication = () => {
        setIsShowingModal(true);
    }

    const handleInsertFoliarApplication = () => {
        let foliarApplication = null;

        try{
            foliarApplication = prepareFoliarApplicationToServer(form);
        } catch(e) {
            getNotifier().error(e.message);
        }

        setForm(prevState => ({...form, foliarApplications: [...prevState.foliarApplications, foliarApplication]}));
        setIsShowingModal(false);
    }

    return (
        <div className='FoliarApplications mt-4'>
            <div className='row'>
                <div className='col d-flex justify-content-between align-items-center gap-3'>
                    <MiniTitle className="product-title">{t('Foliar-applications')}</MiniTitle>

                    <Button
                        className={'submit btnNewProducer'} 
                        type='button' 
                        onClick={handleAddNewFoliarApplication}
                        design='link'
                    >
                        + {t("add-new-application")}
                    </Button>
                </div>
            </div>

            {form.foliarApplications?.map((foliarApplication, index) => 
                <FoliarApplicationRow
                    key={index} 
                    foliarApplication={foliarApplication}
                    mainData={form}
                />)}

            {form.foliarApplications?.length===0 &&
                <div className='row mt-3'>
                    <div className='col-md-12'>
                        <FirstApplicationMessage 
                            key={isShowingModal}
                            onClick={() => setIsShowingModal(true)}
                            isShowingModal={isShowingModal}
                        />
                    </div>  
                </div>}
            
            {isShowingModal && 
                <Modal
                    title={t('add-new-application')}
                    className='FoliarApplications__modal'
                    mainButtonText={'Confirm'}
                    mainButtonColor='success'
                    mainButtonAction={handleInsertFoliarApplication}
                    secondaryButtonText={'Cancel'}
                    secondaryButtonAction={() => setIsShowingModal(false)}
                    onClose={() => setIsShowingModal(false)}
                >
                    <div className='GrowerChangeStatusButtons__modal-content'>
                        <div className='GrowerChangeStatusButtons__modal-content-title'>
                            <div className='row'>
                                <div className='col-md-4'>
                                    <TextInput
                                        label={t('Application no') + ' 1'}
                                        value={''}
                                        inputProps={{readOnly: true, disabled: true}}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='GrowerChangeStatusButtons__modal-content-title'>
                            <div className='row'>
                                <div className='col-md-4'>
                                    <TextInput
                                        label={t('Product')}
                                        value={'Fresa'}
                                        inputProps={{readOnly: true, disabled: true}}
                                    />
                                </div>
                                <div className='col-md-4'>
                                    <TextInput
                                        label={t('Dosis')}
                                        placeholder="ej 100"
                                        value={form.dose||''}
                                        onChange={(e) => setForm({...form, dose: e.target.value})}
                                    />
                                </div>
                                <div className='col-md-4'>
                                    <MmSelect
                                        placeholder={t('Units')}
                                        options={applicationUnitOptions}
                                        value={form.unit||''}
                                        onChange={(val) => setForm({...form, unit: val})}
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-8'>
                                    <TextInput
                                        label={t('Reason')}         
                                        placeholder={t('Explain-reason-of-application')}
                                        value={form.reason||''}
                                        onChange={(e) => setForm({...form, reason: e.target.value})}
                                    />
                                </div>
                                <div className='col-md-4'>
                                    <SearchBarDateFilter
                                        className="mt-0"
                                        label={t('Application-date')}
                                        placeholder={t('Date')}
                                        value={form.date||''}
                                        onChange={(val) => setForm({...form, date: val})}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>}
        </div>
    );
}

export default FoliarApplications;
