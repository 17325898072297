import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Switch from '../../../../../components/forms/Switch/Switch';
import Modal from '../../../../../components/Modal/Modal';
import useFormState from '../../../../../hooks/useFormState';
import { ApiContext } from '../../../../../services/api/api-config';
import { userPermissionGroupsToFormData } from '../../../../../services/models/permissionUtils';
import { usersWithPermissionsGSGroups } from '../../../../../services/models/userUtils';
import { getNotifier } from '../../../../../services/notifier';
import './ManageUserModal.scss';

const permissionGroupsToAssign = [
    'ORDER_MANAGEMENT',
    'COMPANY_MANAGEMENT',
    'APPROVE_COMPANIES',
    'VISIT_MANAGEMENT'
]

const ManageUserModal = ({ user, onClose }) => {
    const {t} = useTranslation();
    const api = useContext(ApiContext);
    const {form, setForm, handleSimpleChange} = useFormState(userPermissionGroupsToFormData(user));
    const [permissionGroups, setPermissionGroups] = useState([]);

    useEffect(() => {
        const params = {sGroups: usersWithPermissionsGSGroups, paginate: false};

        // get permissions groups
        api.permissionGroups.get({params}).then(groups => {
            setPermissionGroups(groups);
        });

        // set form data
        setForm(userPermissionGroupsToFormData(user));
    }, [api, user, setForm]);

    const updatePermissions = () => {
        let permissionGroupsToSend = [];
        
        permissionGroupsToAssign.forEach(groupName => {
            const group = permissionGroups.find(g => g.code === groupName);

            if(form[groupName] && group?.id) {
                permissionGroupsToSend.push(group?.id);
            }
        });

        // update permission groups for user
        const userId = user.id?.toString();
        api.users.update({id: userId, params: {permissionGroups: permissionGroupsToSend}})
            .then(() => {
                getNotifier().success(t('User-permissions-updated-successfully'))
                onClose();
            })
            .catch(error => getNotifier().error(error.message||error.detail));
    };

    return (
        <Modal className='ManageUserModal'
            title={t('Manage-permissions')}
            mainButtonText={t('Save-changes')}
            mainButtonColor='success'
            mainButtonAction={updatePermissions}
            secondaryButtonText={'Cancel'}
            secondaryButtonAction={onClose}
            onClose={onClose}
        >
            <hr />

            <div className='row mt-3'>
                <div className='col'>
                    {t('Select-permissions-to-grant')}
                </div>
            </div>
            
            <div className='row mt-3'>
                <div className='col-md-6'>
                    <div className="d-flex justify-content-between align-items-center">
                        <div className='ManageUserModal__permission-name'>
                            {t('Create-orders')}
                        </div>
                        <Switch 
                            onChange={handleSimpleChange('ORDER_MANAGEMENT')}
                            value={form.ORDER_MANAGEMENT||false}
                        />
                    </div>
                    <div className='col'>
                        <div className='ManageUserModal__permission-description'>
                            {t('Create-orders-description')}
                        </div>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className="d-flex justify-content-between align-items-center">
                        <div className='ManageUserModal__permission-name'>
                            {t('Create-productors')}
                        </div>
                        <Switch 
                            onChange={handleSimpleChange('COMPANY_MANAGEMENT')}
                            value={form.COMPANY_MANAGEMENT||false}
                        />
                    </div>
                    <div className='col'>
                        <div className='ManageUserModal__permission-description'>
                            {t('Create-productors-description')}
                        </div>
                    </div>
                </div>
            </div>
            <div className='row mt-3'>
                <div className='col-md-6'>
                    <div className="d-flex justify-content-between align-items-center">
                        <div className='ManageUserModal__permission-name'>
                            {t('Approve-productors')}
                        </div>
                        <Switch 
                            onChange={handleSimpleChange('APPROVE_COMPANIES')}
                            value={form.APPROVE_COMPANIES||false}
                        />
                    </div>
                    <div className='col'>
                        <div className='ManageUserModal__permission-description'>
                            {t('Approve-productors-description')}
                        </div>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className="d-flex justify-content-between align-items-center">
                        <div className='ManageUserModal__permission-name'>
                            {t('Followup-track-orders')}
                        </div>
                        <Switch 
                            onChange={handleSimpleChange('VISIT_MANAGEMENT')}
                            value={form.VISIT_MANAGEMENT||false}
                        />
                    </div>
                    <div className='col'>
                        <div className='ManageUserModal__permission-description'>
                            {t('Followup-track-orders-description')}
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default ManageUserModal