import fieldTypes from "../../components/FormModal/fields/fieldTypes";
import {
    copyPropertyOrThrow,
    copyPropertyIdOrNull,
} from "../formUtils";

export const permissionTableSGroups = [
    'permission_read',
    'permission_read_id',
    'permission_read_name',
    'permission_read_roles',
    'role_read',
    'read_created_by',
    'user_read',
    'read_updated_by',
    'read_created_date',
    'read_updated_date',
];

export const permissionDetailSGroups = [
    'permission_read',
    'permission_read_id',
    'permission_read_name',
    'permission_read_roles',
    'role_read',
    'read_created_by',
    'user_read',
    'read_updated_by',
    'read_created_date',
    'read_updated_date',
];

export const permissionTableColumns = [
    {
        Header: 'id',
        accessor: 'id'
    },
     {
        Header: 'name',
        accessor: 'name'
    },
     {
        Header: 'roles',
        accessor: 'roles?.id'
    },
     {
        Header: 'createdBy',
        accessor: 'createdBy?.id'
    },
     {
        Header: 'updatedBy',
        accessor: 'updatedBy?.id'
    },
     {
        Header: 'createdDate',
        accessor: 'createdDate'
    },
     {
        Header: 'updatedDate',
        accessor: 'updatedDate'
    },
 ];

export const permissionDataDetailsArray = (permission) => [
    {data: permission?.id, dataLabel: 'id'},
    {data: permission?.name, dataLabel: 'name'},
    {data: permission?.roles?.id, dataLabel: 'roles'},
    {data: permission?.createdBy?.id, dataLabel: 'createdBy'},
    {data: permission?.updatedBy?.id, dataLabel: 'updatedBy'},
    {data: permission?.createdDate, dataLabel: 'createdDate'},
    {data: permission?.updatedDate, dataLabel: 'updatedDate'},
];


export const newPermissionFormDefinition = [
    {
        name: 'name',
        label: 'name',
        required: true,
        type: fieldTypes.text
    },


];

export const editPermissionFormDefinition = [
    ...newPermissionFormDefinition
];

export const prepareNewPermissionForServer = (form) => {
    const permission= {};

    copyPropertyOrThrow(form, permission, 'name', 'Debes agregar name de permission');
    copyPropertyIdOrNull(form, permission, 'roles', 'Debes agregar roles de permission');

    return permission;
};

export const prepareEditedPermissionForServer = (form) => {
    return prepareNewPermissionForServer(form);
};

export const permissionToFormData = (permission) => {
    return {
        ...permission
    }
}

export const userPermissionsToFormData = (user) => {
    return user?.permissions ? user?.permissions?.map(permissionToFormData) : [] ;
}

export const permissionGroupToFormData = (permissionGroup) => {
    return {[permissionGroup.code]: true};
}

export const userPermissionGroupsToFormData = (user) => {
    let permissionGroups = {};

    user?.permissionGroups?.forEach(permissionGroup => {
        permissionGroups = {...permissionGroups, ...permissionGroupToFormData(permissionGroup)};
    });

    return permissionGroups;
}

