import React, {useMemo, useState, useCallback, useContext } from 'react'
import { SectionTitle } from '../../../components/Dashboard/SectionTitle/SectionTitle';
import { SideMenu } from '../../../components/Dashboard/SideMenu/SideMenu';
import TopBar from '../../../components/Dashboard/TopBar/TopBar';
import {useTranslation} from "react-i18next";
import { SearchTextbox } from '../../../components/forms/SearchTextbox/SearchTextbox';
import Button from '../../../components/molecules/Button/Button';
import useTideTable from "../../../components/utility/TideReactTable/useTideTable";
import TideReactTable from "../../../components/utility/TideReactTable/TideReactTable";
import { ApiContext } from '../../../services/api/api-config';
import debounce from 'lodash.debounce';
import {paths} from "../../../services/routes/appRoutes";
import { useNavigate } from 'react-router-dom';
import './OrdersList.scss';
import { productOfferTableSGroups } from '../../../services/models/productOfferUtils';
import ImageWithLabel from '../../../components/Dashboard/ImageWithLabel/ImageWithLabel';
import moment from 'moment';
import { generalDatesFormat } from '../../../services/generalConfig';
import LabelStatus from '../../../components/Dashboard/LabelStatus/LabelStatus';

const OrdersList = () => {
    const api = useContext(ApiContext);
    const [textFilter, setTextFilter] = useState('');
    const {t} = useTranslation();
    const navigate = useNavigate();

    const handleEditOrder = useCallback((row)=>navigate( paths.mm.ordersEdit.replace(':id', row.id)), [navigate]);

    const ordersTableColumns = useMemo(() => [
        {
            Header: 'ID',
            accessor: 'id'
        },
        {
            Header: t('Buyer'),
                accessor: order => order.buyerCompany ? '-' : 
                        (order.buyerCompany?.logo ? 
                            <ImageWithLabel
                                image={<img src={api?.appFiles.getUrl(order.buyerCompany?.logo)} 
                                alt={order.buyerCompany?.contactName} />} 
                                label={`${order.buyerCompany?.contactName}`} 
                                align={'left'} 
                            /> : 
                            order.buyerCompany?.contactName)
        },
        {
            Header: t('Order-created'),
            accessor: order => moment(order.createdDate).format(generalDatesFormat)
        },
        {
            Header: t("Status"),
            id: 'registryStatus',
            accessor: order => <LabelStatus status={order.status} statusText={ t(order.status) } />
        },
        {
            Header: t("Actions"),
            accessor: order => 
                    <div className="actions">
                        <Button 
                            onClick={() => handleEditOrder(order)} 
                            className="btn-primary"
                        >
                            {t('Edit')}
                        </Button>
                    </div>
        }
    ], [t, api, handleEditOrder]);

    const searchFilter = useMemo(() => {
        return {
            search: textFilter
        }
    }, 
    [textFilter]);

    const requestFilters = useMemo(() => ({
        sGroups: productOfferTableSGroups,
        'exists[fillingOrder]': false,
        ...searchFilter
    }), [searchFilter]);

    const handleSearchTextChange = useCallback((e) => {
        setTextFilter(e.target.value);
    }, []);

    const debouncedChangeHandler = debounce(handleSearchTextChange, 400); 

    const table = useTideTable({
        entity: 'productOffers',
        columns: ordersTableColumns,
        requestFilters
    });

    const handleNewOrder = useCallback(()=>navigate(paths.mm.ordersForm), [navigate]);

    return (
        <div className="OrdersList dashboard-container">
            <SideMenu />
            <div className='right-content'>
                <TopBar />
                <div className='main-content'>
                    <SectionTitle>{t('My-Orders')}</SectionTitle>

                    <div className='row'>
                        <div className='col d-flex justify-content-between align-items-center gap-3'>
                            <SearchTextbox
                                placeholder={t('Search-by-name, email, id')}
                                onChange={debouncedChangeHandler}
                            />

                            <Button 
                                className={'submit btnNewProducer'} 
                                type='button' 
                                onClick={handleNewOrder}
                                id="add-new-order"
                            >
                                + {t("add-new-order")}
                            </Button>
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="">
                            <TideReactTable 
                                {...table.tableProps} 
                                className="productos-table"
                            />
                        </div>
                    </div>   
                </div>
            </div>
        </div>
    );
}

export default OrdersList;