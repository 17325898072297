import React from 'react'
import { SectionTitle } from '../../../components/Dashboard/SectionTitle/SectionTitle';
import { SideMenu } from '../../../components/Dashboard/SideMenu/SideMenu';
import TopBar from '../../../components/Dashboard/TopBar/TopBar';
import ResultsCard from '../../../components/Dashboard/ResultsCard/ResultsCard';
import CardWithImage from '../../../components/Dashboard/CardWithImage/CardWithImage';
import avocadoImage from '../../../assets/images/dummy/avocado.jpg';
import { TransactionCard } from '../../../components/Dashboard/TransactionCard/TransactionCard';
import SimpleList from '../../../components/Dashboard/SimpleList/SimpleList';
import LabelStatus from '../../../components/Dashboard/LabelStatus/LabelStatus';
import './Dashboard.scss';
import ImageWithLabel from "../../../components/Dashboard/ImageWithLabel/ImageWithLabel";

const columns = [
    {
        Header: "Quantity",
        accessor: "quantity"
    },
    {
        Header: "Product",
        accessor: "product"
    },
    {
        Header: "Producer",
        id: "producer",
        accessor: (row) => <ImageWithLabel
                                image={<img src='https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50?s=200' alt='Juan Alvarez' />}
                               label={'Armando Fuentes'}
                                />
    },
    {
        Header: "Status",
        id: 'status',
        accessor: (row) => <LabelStatus status={row.status} statusText={row.status} />
    },
    {
        Header: "Order Created",
        accessor: "orderCreated"
    },
];

const data = [
    {
        quantity: "30 kg",
        product: "Avocado",
        producer: "John Doe",
        status: "pending",
        orderCreated: "12/06/2019"
    },
    {
        quantity: "100 kg",
        product: "Strawberry",
        producer: "John Doe",
        status: "delivered",
        orderCreated: "12/06/2019"
    },
    {
        quantity: "200 kg",
        product: "Blackberry",
        producer: "John Doe",
        status: "pending",
        orderCreated: "12/06/2019"
    },
    {
        quantity: "30kg",
        product: "Avocado",
        producer: "John Doe",
        status: "pending",
        orderCreated: "12/06/2019"
    }
];

const Dashboard = () => {
    return (
        <div className="Dashboard">
            <SideMenu />
            <div className='right-content'>
                <TopBar />
                <div className='main-content'>
                    <SectionTitle>Dashboard</SectionTitle>

                    <div className="row">
                        <div className="col-xs-6 col-sm-6 col-lg-3">
                            <ResultsCard 
                                title="Today’s sales"
                                value="$887.40 US"
                                title2="Previuos"
                                value2="$367.22"
                                title3="% Change"
                                value3="-4.84%"
                                color3="red"
                            />
                        </div>
                        <div className="col-xs-6 col-sm-6 col-lg-3">
                            <ResultsCard 
                                title="My Orders"
                                value="473"
                                title2="Previuos"
                                value2="129"
                                title3="% Change"
                                value3="+4.84%"
                                color3="green"
                            />
                        </div>
                        <div className="col-xs-6 col-sm-6 col-lg-3">
                            <ResultsCard 
                                title="My Orders"
                                value="473"
                                title2="Previuos"
                                value2="129"
                                title3="% Change"
                                value3="+4.84%"
                                color3="green"
                            />
                        </div>
                        <div className="col-xs-6 col-sm-6 col-lg-3">
                            <TransactionCard 
                                credits={439}
                            />
                        </div>
                    </div>

                    <SectionTitle>Recent orders</SectionTitle>
                    
                    <div className="row">
                        <div className="col-xs-6 col-lg-9">
                            <SimpleList columns={columns} data={data} />
                        </div>
                    </div>  
                    
                    <SectionTitle>Featured Products</SectionTitle>

                    <div className="row">
                        <div className="col-xs-6 col-sm-6 col-lg-3">
                            <CardWithImage
                                image={avocadoImage}
                                title={"Hass Avocado"}
                                subTitle={"$13.44 US"}
                                unit={"kg"}
                                showLikeButton={true}
                                productId={'11111'}
                                onLike={() => {}}
                            >
                                <ImageWithLabel
                                    image={
                                        <img src='https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50?s=200' alt='Juan Alvarez' />
                                    }
                                    label={'Juan Alvarez'}
                                    align={'left'}
                                    link={"/"}
                                />
                            </CardWithImage>
                        </div>
                        <div className="col-xs-6 col-sm-6 col-lg-3">
                            <CardWithImage
                                image={avocadoImage}
                                title={"Hass Avocado"}
                                subTitle={"$13.44 US"}
                                unit={"kg"}
                                showLikeButton={true}
                                productId={'22211'}
                                onLike={() => {}}
                            >
                                <ImageWithLabel
                                    image={
                                        <img src='https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50?s=200' alt='Juan Alvarez' />
                                    }
                                    label={'Juan Alvarez'}
                                    align={'left'}
                                    link={"/"}
                                />
                            </CardWithImage>
                        </div>
                        <div className="col-xs-6 col-sm-6 col-lg-3">
                            <CardWithImage
                                image={avocadoImage}
                                title={"Hass Avocado"}
                                subTitle={"$13.44 US"}
                                unit={"kg"}
                                showLikeButton={true}
                                productId={'32211'}
                                onLike={() => {}}
                            >
                                <ImageWithLabel
                                    image={
                                        <img src='https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50?s=200' alt='Juan Alvarez' />
                                    }
                                    label={'Juan Alvarez'}
                                    align={'left'}
                                    link={"/"}
                                />
                            </CardWithImage>
                        </div>
                        <div className="col-xs-6 col-sm-6 col-lg-3">
                            <CardWithImage
                                image={avocadoImage}
                                title={"Hass Avocado"}
                                subTitle={"$13.44 US"}
                                unit={"kg"}
                                showLikeButton={true}
                                productId={'44455'}
                                onLike={() => {}}
                            >
                                <ImageWithLabel
                                    image={
                                        <img src='https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50?s=200' alt='Juan Alvarez' />
                                    }
                                    label={'Juan Alvarez'}
                                    align={'left'}
                                    link={"/"}
                                />
                            </CardWithImage>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Dashboard;