import { copyPropertyOrNull, copyPropertyValueOrThrow } from "../formUtils";
import { getFenologicalStageOptions } from "./orchardVisitUtils";

export const visitSectorDetailSGroups = [
    'visit_sector_detail_read',
];

export const prepareVisitSectorDetailForServer = (form, t) => {
    let visitSectorDetail = {};

    visitSectorDetail["sector"] = form.sector.id;

    copyPropertyOrNull(form, visitSectorDetail, 'id');
    copyPropertyValueOrThrow(form, visitSectorDetail, 'phenologicalStage', t('Must-select-phenological-stage'));
    copyPropertyValueOrThrow(form, visitSectorDetail, 'caliber', t('Must-select-caliber'));
    copyPropertyValueOrThrow(form, visitSectorDetail, 'caliberComments', t('Must-write-caliber-comments'));
    copyPropertyValueOrThrow(form, visitSectorDetail, 'consistency', t('Must-select-consistency'));
    copyPropertyValueOrThrow(form, visitSectorDetail, 'consistencyComments', t('Must-write-consistency-comments'));
    copyPropertyOrNull(form, visitSectorDetail, 'maturity');
    copyPropertyOrNull(form, visitSectorDetail, 'plagueDamage');
    copyPropertyOrNull(form, visitSectorDetail, 'climateDamage');
    copyPropertyOrNull(form, visitSectorDetail, 'foliarApplicationDamage');
    copyPropertyOrNull(form, visitSectorDetail, 'comments');
    
    return visitSectorDetail;
}

export const visitSectorDetailsToFormData = (visitedSectorDetail, t) => {
    const phenologicalStages = getFenologicalStageOptions(t);

    return {
        ...visitedSectorDetail,
        phenologicalStage: {label: phenologicalStages.find(phenologicalStage => phenologicalStage.value === visitedSectorDetail.phenologicalStage).label, value: visitedSectorDetail.phenologicalStage},
        caliber: {label: visitedSectorDetail.caliber, value: visitedSectorDetail.caliber},
        consistency: {label: visitedSectorDetail.consistency, value: visitedSectorDetail.consistency},
    };
}

export const getEmptyVisitSectorDetails = (sector) => {
    return {
        id: null,
        sector,
        phenologicalStage: null,
        caliber: null,
        caliberComments: null,
        consistency: null,
        consistencyComments: null,
        maturity: null,
        plagueDamage: null,
        climateDamage: null,
        foliarApplicationDamage: null,
        comments: null,
    };   
}