import { copyPropertyOrThrow } from "../formUtils";

export const brandsTableSGroups = [
    'brand_read',
    'read_created_by',
    'read_updated_by',
    'read_created_date',
    'read_updated_date',
]

export const brandDetailSGroups  = [
    'brand_read'
]

export const prepareBrandForServer = (form, t) => {
    const brand= {};

    copyPropertyOrThrow(form, brand, 'name', t('brand.name'));

    return brand;
}
