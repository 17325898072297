import classNames from 'classnames';
import React from 'react'
import Card from '../../../../../components/Dashboard/Card/Card'

import './OrchardsMenu.scss';

const OrchardsMenu = ({ orchards, orchardActive, onSelectOrchard }) => {

    return (
        <div className='OrchardsMenu mt-3'>
            <Card>
                {orchards.map((orchard, index) =>
                    <div key={orchard.id} 
                        className={classNames('OrchardsMenu__item', ((orchardActive?.id===orchard?.id) && 'OrchardsMenu__item--active'))}
                        onClick={() => onSelectOrchard(orchard)}
                    >  
                        {orchard.name}
                    </div>)}
            </Card>
        </div>
    );
}

export default OrchardsMenu;