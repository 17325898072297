import React from 'react'

import './Loader.scss';

const Loader = () => {
    return (
        <div className='Loader'>
            <div className="container">
                <div className="inner one"></div>
                <div className="inner two"></div>
                <div className="inner three"></div>
            </div>
        </div>
    );
}

export default Loader