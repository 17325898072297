import React from 'react'
import {ReactComponent as EyeIcon} from "../../../../../../../assets/images/icons/eye-green.svg";
import {ReactComponent as EyeDisabledIcon} from "../../../../../../../assets/images/icons/eye-disabled.svg";
import './ActionSee.scss';
import { useTranslation } from 'react-i18next';

const ActionSee = ({link}) => {
    const {t} = useTranslation();

    return (
        <>
            {link ?
                <a className='ActionSee d-flex justify-content-start align-items-center' 
                    href={link} target="_blank"
                    rel="noreferrer"
                >
                    {t('See')} <EyeIcon />
                </a>
                :
                <div className='ActionSee d-flex justify-content-start align-items-center cursor-not-allowed'>
                    {t('See')} <EyeDisabledIcon />
                </div>
            }
        </>
    )
}

export default ActionSee;
