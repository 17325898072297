import React from 'react'
import MiniTitle from '../../../../../components/Dashboard/MiniTitle/MiniTitle';
import TextInput from '../../../../../components/forms/TextInput/TextInput';
import useCallbackCreator from 'use-callback-creator';
import {useTranslation} from "react-i18next";
import SearchBarDateFilter from '../../../../../components/SearchBar/filters/SearchBarDateFilter/SearchBarDateFilter';
import Message from '../../../../../components/Dashboard/Message/Message';

import './ProductDetailsForm.scss';
import SelectWithLabel from '../../../../../components/forms/SelectWithLabel/SelectWithLabel';
import Button from '../../../../../components/molecules/Button/Button';

const ProductDetailsForm = ({productor, handleRemoveProductor, onChange, index, productorOptions}) => {
    const {t} = useTranslation();

    const handleProductorChange = useCallbackCreator((name, value) => {
        onChange({ ...productor, sellerCompany: value });
    }, [onChange, productor]);

    const handleChange = useCallbackCreator((name, value) => {
        onChange({ ...productor, [name]: value });
    }, [onChange, productor]);

    const handleInputChange = useCallbackCreator((name, el) => {
        onChange({ ...productor, [name]: el.target.value });
    }, [onChange, productor]);

    const boxesLeft = productor.productor?.boxesLeft;


    return (
        <div className="ProductDetailsForm mt-3">
            <div className='d-flex align-items-center justify-content-between'>
                <MiniTitle className="mt-3">Productor {index+1}</MiniTitle>

                <Button 
                    design="link"
                    onClick={handleRemoveProductor}
                >
                    Eliminar
                </Button>
            </div>

            <div className='row mt-3'>
                <div className='col-md-6'>
                    <SelectWithLabel
                        label={t('Productor')}
                        inputProps={{
                            options: productorOptions,
                            value: productor.sellerCompany,
                            onChange: handleProductorChange(index)
                        }}
                    />
                </div>
                <div className='col-md-6'>
                    <Message
                        type='success'
                    >
                        {boxesLeft} {t('boxes-left-with-this-productor')}
                    </Message>
                </div>
            </div>
            
            <div className='row mt-4'>
                <div className='col-md-6'>
                    <TextInput
                        label={t('Select-No-boxes')}
                        placeholder={t('Desired-bozed-to-buy')}
                        type="number"
                        onChange={handleInputChange('boxes')}
                        value={productor.boxes||''}
                    />
                    
                </div>                                
                <div className='col-md-6'>
                    <TextInput
                        label={t('Price-per-box')}
                        placeholder={'$'}
                        type="number"
                        onChange={handleInputChange('pricePerBox')}
                        value={productor.pricePerBox||''}
                    />
                </div>
            </div>

            <div className='row mt-4'>
                <div className='col-md-6'>
                    <SearchBarDateFilter
                        label={t('Productor-devlivery-date')}
                        onChange={handleChange('date')}
                        value={productor.date||''}
                    />
                </div>
                <div className='col-md-6'>
                    <TextInput 
                        label={t('Comments')}
                        onChange={handleInputChange('comments')}
                        value={productor.comments||''}
                    />
                </div>                                
            </div>
        </div>
    );
}

export default ProductDetailsForm;
