import React, {useCallback, useContext, useEffect, useState} from 'react'
import { SideMenu } from '../../../components/Dashboard/SideMenu/SideMenu';
import Steps from '../../../components/Dashboard/Steps/Steps';
import TopBar from '../../../components/Dashboard/TopBar/TopBar';
import {useTranslation} from "react-i18next";
import Card from '../../../components/Dashboard/Card/Card';
import { SectionTitle } from '../../../components/Dashboard/SectionTitle/SectionTitle';
import CardHeader from '../../../components/Dashboard/CardHeader/CardHeader';
import MiniTitle from '../../../components/Dashboard/MiniTitle/MiniTitle';
import useFormState from '../../../hooks/useFormState';
import {getEmptyArrayProductOffers, getEmptyProductOffer, getOrderSteps, prepareProductOfferForServerStep2, productOfferDetailStep2SGroups, productOfferToFormData, productorToproductorOffer} from '../../../services/models/productOfferUtils';
import useCallbackCreator from 'use-callback-creator';
import OrderProductForm from './components/OrderProductForm/OrderProductForm';
import Button from '../../../components/molecules/Button/Button';
import ShipmentDetail from './components/ShipmentDetail/ShipmentDetail';
import SummaryByProductor from './components/SummaryByProductor/SummaryByProductor';
import OrderStatus from './components/OrderStatus/OrderStatus';
import { getNotifier } from '../../../services/notifier';
import { useNavigate, useParams } from 'react-router-dom';
import { ApiContext } from '../../../services/api/api-config';
import './OrdersFormStep2.scss';
import { paths } from '../../../services/routes/appRoutes';
import OrdersMenu from '../OrdersForm/components/OrdersMenu/OrdersMenu';

const OrdersFormStep2 = () => {
    const { id } = useParams();
    const api = useContext(ApiContext);
    const {t} = useTranslation();
    const navigate = useNavigate();
    const { form, setForm } = useFormState([...getEmptyArrayProductOffers()]);
    const [brands, setBrands] = useState([]);

    const loadOrder = useCallback(() => {
        const setInitialForm = (async (productOffers) => {
            let ordersForm = productOffers;
            
            ordersForm = await Promise.all(ordersForm.map(async order => {
                order = productOfferToFormData(order);
                
                let productors = await api.productOffers.get({params: {fillingOrder: order.id, sGroups: productOfferDetailStep2SGroups }});
                order.productors = productors.map(productorToproductorOffer);
                order.noBoxes = order.boxes;
                
                // set company product to orders
                productors = productors.map(productor => {
                    productor.sellerCompany = {label: productor.sellerCompany?.companyName, value: productor.sellerCompany?.id};
                    return productor;
                });
    
                order.productors = productors;

                return {...order};
            }));
    
            if(ordersForm.length > 0)
                setForm(ordersForm);
            else 
                setForm([...getEmptyArrayProductOffers()]);
        });

        if(id)
            api.productOffers.get({params: {fillingOrder: id, sGroups: productOfferDetailStep2SGroups }})
                .then(setInitialForm)
                .catch(error => getNotifier().error(error.message||error.detail));

        api.brands.get({ params: { sGroups: 'brand_read' } })
            .then(brands => setBrands(brands.map(brand => ({label: brand.name, value: brand.id}))))
            .catch(error => getNotifier().error(error.message||error.detail));
    }, [api, id, setForm]);

    // ------ load data
    useEffect(() => {
        loadOrder();
    }, [loadOrder]);

    // ------ Form state handlers ------
    const addOrderProduct = useCallback(() => {
        setForm([
            ...form, 
            getEmptyProductOffer()
        ]);
    }, [setForm, form]);

    const removeOrderProduct = useCallbackCreator((index) => {
        const newOrderProducts = [...form];
        newOrderProducts.splice(index, 1);

        setForm([...form, newOrderProducts]);
    }, [setForm, form]);

    const handleOrderProductChange = useCallbackCreator((index, orderProduct) => {
        let newForm = [...form];
        newForm[index] = orderProduct;
        setForm(newForm);
    }, [setForm, form]);
    // ------ End of form state handlers ------

    const handleGotoStep1 = useCallback(() => {
        // navigate to step 1
        navigate(paths.mm.ordersEdit.replace(':id', id));
    }, [id, navigate]);


    const handleSaveAndGotoOrdersList = useCallback(() => {
        // save order and navigate to orders list
        navigate(paths.mm.orders);
    }, [navigate]);
    
        
    const hasErrorForm = (form)  => {
        let hasError = false;

        form.forEach(orderProduct => {
            if( parseFloat(orderProduct?.noBoxes) > parseFloat(orderProduct?.productor?.boxes) ) {
                hasError = true;
            }
        });

        return hasError;
    }

    const handleSubmit = useCallback(async() => {
        let orders = null;
        let files = null;

        try {
            const data = prepareProductOfferForServerStep2(form, id, t);
            orders = data.productOffers;
            files = (data.productOfferFiles[0] ? data.productOfferFiles[0] : null);
        } catch (error) {
            console.error(error);
            return getNotifier().error(error.message||error.detail);            
        }

        // send data to server for each order
        if (orders) {
            orders.forEach(async order => {
                const productOrderId = order.id;
                const method = productOrderId ? 'update' : 'create';
                const extraFiles = order.extraFiles;
                delete order.extraFiles;

                try {
                    const productors = order.productors;
    
                    // send order
                    const productOffer = await api.productOffers[method]({id: productOrderId, params: order, files});

                    // send productors
                    productors.forEach(async productor => {
                        const productorId = productor.id;
                        const method = productorId ? 'update' : 'create';

                        productor.fillingOrder = productOffer.id;
                        await api.productOffers[method]({id: productorId, params: productor});
                    });

                    // send extra files
                    extraFiles.forEach(async extraFile => {
                        await api.extraFiles.create({params: {
                            name: extraFile.name,
                            productOffer: productOffer.id
                        }, files: {file: extraFile.file}});
                    });

                } catch (error) {
                    console.error(error);
                    return getNotifier().error(error.message||error.detail);
                }
            })
        };

        getNotifier().success(t('Saved-changes'));
        navigate(paths.mm.orders);
    }, [form, id, t, navigate, api.extraFiles, api.productOffers]);

    return (
        <div className='OrdersFormStep2 dashboard-container'>
            <SideMenu />
            <div className='right-content'>
                <TopBar />
                <div className='main-content'>
                    {id ? <OrdersMenu orderId={id} steps={getOrderSteps(t)} currentStep={1}  /> : <Steps steps={getOrderSteps(t)} currentStep={1} />}
                    
                    {form?.map((orderProduct, index) => 
                    <div className='row' key={index}>
                        <div className='col-md-8'>
                            <Card >
                                {index===0 &&
                                    <CardHeader>
                                        <SectionTitle>{t('Create-product-order')}</SectionTitle>

                                        <Button
                                            design='link'
                                            onClick={addOrderProduct} >
                                                +{t('Add-new-product')}
                                        </Button>
                                        
                                        {index>1 &&
                                            <div onClick={removeOrderProduct}>+Delete product</div>}
                                    </CardHeader>}

                                <MiniTitle>{t('Product')} {index+1}</MiniTitle>
                                
                                <OrderProductForm 
                                    onChange={handleOrderProductChange(index)}
                                    orderProduct={orderProduct}
                                    reloadOrder={loadOrder}
                                    brands={brands}
                                />
                            </Card>
                        </div>
                        <div className='col-md-4'>
                            <Card>
                                <SectionTitle>
                                    {t('Shipment-details')}
                                </SectionTitle>

                                <MiniTitle>{ orderProduct?.product?.name }</MiniTitle>
                                            
                                <hr />

                                <MiniTitle>{t('Resume')}</MiniTitle>

                                <ShipmentDetail productOffer={orderProduct} />

                                <hr />
                                
                                <SummaryByProductor
                                    productOffer={orderProduct}
                                />
                            </Card>
                            <OrderStatus 
                                productOffer={orderProduct}
                            />
                        </div>  
                    </div>)}

                    <div className='row'>
                        <div className='col-md-8'>
                            <Card>
                                <div className='row'>
                                    <div className='col-md-12 d-flex justify-content-center align-items-center gap-3 mt-3'>
                                        <Button
                                            design='clear'
                                            onClick={handleGotoStep1}
                                            className="footer-button"
                                        >
                                            {t('Back')}
                                        </Button>

                                        <Button
                                            onClick={handleSubmit}
                                            className="footer-button"
                                            key={hasErrorForm}
                                        >
                                            {t('Save')}
                                        </Button>
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <div className='col-md-12 d-flex justify-content-center align-items-center gap-3'>
                                        <Button className='link' onClick={handleSaveAndGotoOrdersList}>
                                            {t('Continue-later')}
                                        </Button>
                                    </div>
                                </div>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OrdersFormStep2;