import React from 'react'
import Card from '../../../../../../../components/Dashboard/Card/Card';
import {ReactComponent as LeavesIcon} from "../../../../../../../assets/images/icons/leaves.svg";
import {generalDatesFormat} from "../../../../../../../services/generalConfig";

import './FoliarApplicationRow.scss';
import moment from 'moment';

const FoliarApplicationRow = ({mainData, foliarApplication}) => {

    return (
        <Card className='FoliarApplicationRow'>
            <div className='FoliarApplicationRow__icon'>
                <LeavesIcon />
            </div>
            <div className='FoliarApplicationRow__content'>
                <div className='FoliarApplicationRow__content-two-cols'>
                    <div className='FoliarApplicationRow__row1'>
                        <span>{mainData.companyProduct?.product?.companyProduct?.name}</span>
                        <span className='FoliarApplicationRow__dose'>{foliarApplication?.dose} {foliarApplication?.unit?.value}</span>
                    </div>
                </div>
                <div className='FoliarApplicationRow__content-two-cols space-between'>
                    <div className='FoliarApplicationRow__reason'>
                        {foliarApplication?.reason}
                    </div>
                    <div>
                        <span className='FoliarApplicationRow__app-date-label'>
                            Application date:
                        </span>
                        <span className='FoliarApplicationRow__app-date-value'>
                            {foliarApplication?.date && moment(foliarApplication?.date).format(generalDatesFormat)}
                        </span>
                    </div>
                </div>
            </div>
        </Card>
    );
}

export default FoliarApplicationRow;