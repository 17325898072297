import React from 'react'
import ActionDownload from '../ActionDownload/ActionDownload';
import ActionSee from '../ActionSee/ActionSee';



const ActionsSeeAndDownload = ({linkSee, linkDownload}) => {
    return (
        <div className='ActionsSeeAndDownload'>
            <div className='w-50'>
                <ActionSee link={linkSee} />
            </div>
            <div className='w-50'>
                <ActionDownload link={linkDownload} />
            </div>
        </div>
    );
}

export default ActionsSeeAndDownload;
