import React, {useMemo} from 'react'

import './GeneralContainer.scss';

const GeneralContainer = ({children, styles}) => {
    const stylesGeneralContainer = useMemo(()=>{
        return {
            ...styles
        }
    }, [styles]);

    return (
        <div className='GeneralContainer' 
            style={stylesGeneralContainer}
        >
            {children}
        </div>
    )
}

export default GeneralContainer;