import React, { useContext, useState } from 'react'
import Button from '../../../../../components/molecules/Button/Button';
import {ReactComponent as DisenrollIcon} from "../../../../../assets/images/icons/disenroll.svg";
import {ReactComponent as ConfirmIcon} from "../../../../../assets/images/icons/confirm.svg";
import { companyStatus } from '../../../../../services/models/companyUtils';
import Modal from '../../../../../components/Modal/Modal';
import { ApiContext } from '../../../../../services/api/api-config';
import { t } from 'i18next';
import { getNotifier } from '../../../../../services/notifier';
import './GrowerChangeStatusButtons.scss';

const GrowerChangeStatusButtons = ({grower, onUpdateStatus}) => {
    const api = useContext(ApiContext);
    const [isShowingModal, setIsShowingModal] = useState();
    const showConfirmButton = grower?.registryStatus !== companyStatus.REGISTRY_STATUS_PRODUCER;
    const modalTitle = grower?.registryStatus === companyStatus.REGISTRY_STATUS_PRODUCER ? t('Disenroll-grower') : t('Confirm-grower');
    const modalContent = grower?.registryStatus === companyStatus.REGISTRY_STATUS_PRODUCER ? 
                            t('Grower-disenroll-message') :
                            t('Grower-confirm-message');
    
    const handleChangeGrowerStatus = ({status}) => {
        api.companies.update({id: grower.id, params: {registryStatus: status} })
            .then(newGrower => showConfirmNotification(newGrower))
            .catch(e => showErrorNotification(e));
    }

    const showConfirmNotification = (newGrower) => {
        const message = <div>
                            <b>
                                {showConfirmButton ? t('Productor-confirmed-successfully') : t('Productor-disenrolled-successfully') }
                            </b>
                        </div>;
        // show message
        getNotifier().success(message);

        // close modal
        setIsShowingModal(false);
        
        onUpdateStatus&&onUpdateStatus();
    }

    const showErrorNotification = (error) => {
        getNotifier().success(error.message);
    }

    return (
        <div className='GrowerChangeStatusButtons'>
            {!showConfirmButton && 
                <Button
                    className="GrowerChangeStatusButtons__button"
                    design="gray"
                    onClick={() => setIsShowingModal(true)}
                >
                    Disenroll <DisenrollIcon />
                </Button>}

            {showConfirmButton && 
                <Button
                    className="GrowerChangeStatusButtons__button"
                    design="basic"
                    onClick={() => setIsShowingModal(true)}
                >
                    Confirm <ConfirmIcon />
                </Button>}

            {isShowingModal &&
                <Modal
                    title={modalTitle}
                    className='GrowerChangeStatusButtons__modal'
                    mainButtonText={t('Confirm')}
                    mainButtonColor='success'
                    mainButtonAction={() => handleChangeGrowerStatus({status: !showConfirmButton ? companyStatus.REGISTRY_STATUS_DISENROLL : companyStatus.REGISTRY_STATUS_PRODUCER})}
                    secondaryButtonText={'Cancel'}
                    secondaryButtonAction={() => setIsShowingModal(false)}
                >
                    <div className='GrowerChangeStatusButtons__modal-content'>
                        <div className='GrowerChangeStatusButtons__modal-content-title'>
                            {modalContent}
                        </div>
                    </div>
                </Modal>}
        </div>
    );
}

export default GrowerChangeStatusButtons;