import React from 'react';
import './RoleSelection.scss';
import HalfScreenLayout from "../../../../components/layout/HalfScreenLayout/HalfScreenLayout";
import rolesBackground from '../../../../assets/images/backgrounds/roles-selection.jpg'
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {paths} from "../../../../services/routes/notLoggedRoutes";

const RoleSelection = () => {

    const {t} = useTranslation();

    return (
        <HalfScreenLayout className={"RoleSelection"} rightBackground={rolesBackground}>
            <div className='roles-container'>
                <h1 className='title'>{t("What-your-role")}</h1>

                <Link to={paths.registerForm.replace(":role", "grower")}>
                    <div className='role-block'>
                        <p className='role-name'><span>{t("Grower")}</span></p>
                        <p className='role-desc'><span>{t("grower-role-description")}</span></p>
                    </div>
                </Link>

                <Link to={paths.registerForm.replace(":role", "wholesale")}>
                    <div className='role-block'>
                        <p className='role-name'>{t("Wholesale")}</p>
                        <p className='role-desc'>{t("wholesale-role-description")}</p>
                    </div>
                </Link>
            </div>
        </HalfScreenLayout>
    );
};

export default RoleSelection;
