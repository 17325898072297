import React from 'react'

import './ClientDetails.scss';

export const ClientDetails = ({client}) => {
    return (
        <div className='ClientDetails'>
            <div className='ClientDetails__title'>{client.companyName}</div>
            <div className='ClientDetails__id'>Client ID {client.id}</div>
            <div className='ClientDetails__label ClientDetails__label--mt'>
                Location: <span className='ClientDetails__value'>{client.address?.street}</span>
            </div>
            <div className='ClientDetails__label'>
                Contact: <span className='ClientDetails__value'>{client.contactName}</span>
            </div>
        </div>
    );
}
