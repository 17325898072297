import React, {useState, useContext, useEffect, useMemo} from 'react'
import Card from '../../../../../components/Dashboard/Card/Card';
import MiniTitle from '../../../../../components/Dashboard/MiniTitle/MiniTitle';
import SectionSubTitle from '../../../../../components/Dashboard/SectionSubTitle/SectionSubTitle';
import { ApiContext } from '../../../../../services/api/api-config';
import ClientCard from '../../../../../components/ClientCard/ClientCard';
import { companyForDropdopwnSGroups, companyTypes } from '../../../../../services/models/companyUtils';
import {useTranslation} from "react-i18next";
import Message from '../../../../../components/Dashboard/Message/Message';
import useFormState from '../../../../../hooks/useFormState';
import Button from '../../../../../components/molecules/Button/Button';
import classNames from 'classnames';
import { getNotifier } from '../../../../../services/notifier';
import { getEmptyProductOffer, getProductOfferFilesStep1, prepareProductOfferForServerStep1, productOfferDetailStep1SGroups, productOfferToFormData } from '../../../../../services/models/productOfferUtils';
import { useNavigate, useParams } from 'react-router-dom';
import { paths } from '../../../../../services/routes/appRoutes';
import SelectWithLabel from '../../../../../components/forms/SelectWithLabel/SelectWithLabel';
import { warehouseTableSGroups } from '../../../../../services/models/warehousesUtils';
import SimpleAcordeon from '../../../../../components/Dashboard/SimpleAcordeon/SimpleAcordeon';
import MultipleFileUpload from '../../../../../components/forms/MultipleFileUpload/MultipleFileUpload';
import TextInput from '../../../../../components/forms/TextInput/TextInput';
import useCallbackCreator from 'use-callback-creator';
import FileUploader from '../../../../../components/forms/FileUploader/FileUploader';
import './OrderStep1.scss';
import SearchBarDateFilter from '../../../../../components/SearchBar/filters/SearchBarDateFilter/SearchBarDateFilter';

const validDocTypesForDocuments = ['pdf', 'zip', 'doc', 'docx'];
const validDocTypesForBuyingOrder = ['pdf', 'zip', 'doc', 'docx'];

const OrderStep1 = () => {
    const api = useContext(ApiContext);
    const {t} = useTranslation();
    const {id} = useParams();
    const navigate = useNavigate();
    const [clients, setClients] = useState([]);
    const { form, bindSimple, setForm } = useFormState(getEmptyProductOffer());
    const [warehouses, setWarehouses] = useState([]);

    const requestParams = useMemo(() => ({
        sGroups: companyForDropdopwnSGroups,
        type: companyTypes.TYPE_WHOLESALE
    }), []);

    // load data from api
    useEffect(() => {
        const setInitialForm = (productOffer) => {
            setForm(productOfferToFormData(productOffer));
        }

        api.companies.get({ params: requestParams, customProp:'_' }).then(setClients);
        api.warehouses.get({ params: {sGroups: warehouseTableSGroups} }).then(setWarehouses);

        if(id)
            api.productOffers.get({ id, params: { sGroups: productOfferDetailStep1SGroups }, customProp:'_' })
            .then((productOffer) => setInitialForm(productOffer))
            .catch(error => getNotifier().error(error.message||error.detail));
    }, [api, requestParams, id, setForm]);
    
    const clientOptions = clients.map(_client => ({ value: _client.id, label: _client.companyName }));
    const warehouseOptions = warehouses.map(_warehouse => ({ value: _warehouse.id, label: _warehouse.name }));

    const selectedClient = clients.find(c => c.id === form?.client?.id);
    const formFilled = !!form.buyerCompany && !!form.buyingOrder && !!form.warehouse;
    
    // Send data to api 
    const sendData = async () => {
        if(!formFilled) return;

        const method = form.id ? 'update' : 'create';
        let productOffer = null;
        let productOfferFiles = null;

        try {
            productOffer = prepareProductOfferForServerStep1(form, t);
            productOfferFiles = getProductOfferFilesStep1(form);
        } catch (error) {
            // show message
            console.error(error);
            return getNotifier().error(t(error));
        }

        const extraFiles = productOfferFiles?.extraFiles;
        delete productOfferFiles.extraFiles;

        let order = null;

        order = await api.productOffers[method]({id, params: productOffer, files: productOfferFiles});

        // send extra files
        extraFiles.forEach(async extraFile => {
            await api.extraFiles.create({params: {
                name: extraFile.name,
                productOffer: form.id
            }, files: {file: extraFile.file}});
        });

        return order;
    }

    // send data to api
    const handleSaveAndGotoStep2 = async () => {
        if(!formFilled) return;

        const order = await sendData();
        navigate(paths.mm.ordersFormStep2.replace(':id', order.id));
        getNotifier().success(t('order.created'));
    };

    // send data and navigate to ordersList
    const handleSaveAndContinue = async () => {
        if(!formFilled) return;

        try{
            await sendData();
            navigate(paths.mm.orders);
        } catch (error) {
            // show message
            console.error(error);
            getNotifier().error(t('Error-while-sending-data'));
        }
    }

    const handleAddExtraFile = () => {
        setForm({ ...form, extraFiles: [
            ...form.extraFiles||[],
            { id:null },
        ] });
    }

    const handleExtraFilesChange = useCallbackCreator((index, extraFile) => {
        const newExtraFiles = [...form.extraFiles];
        newExtraFiles[index] = { ...newExtraFiles[index], 'file': extraFile }
        setForm({ ...form, extraFiles: newExtraFiles });
    }, [setForm, form]);

    const handleExtraFilesInputChange = useCallbackCreator((index, e) => {
        const newExtraFiles = [...form.extraFiles];
        newExtraFiles[index] = { ...newExtraFiles[index], 'name': e.target.value };
        setForm({ ...form, extraFiles: newExtraFiles });
    }, [setForm, form]);

    const handleDeleteExtraFile = useCallbackCreator(index => {
        const newExtraFiles = [...form.extraFiles];
        newExtraFiles.splice(index, 1);
        setForm({ ...form, extraFiles: newExtraFiles });
    } , [setForm, form]);

    const selectedWarehouse = warehouses?.find(w => w.id === form.warehouse?.value);
    const warehouseAddress = selectedWarehouse?.address;

    return (
        <Card className='OrderStep1'>
            <SectionSubTitle>{t('Select-client')}</SectionSubTitle>

            <MiniTitle>
                {t('Client')}
            </MiniTitle>

            <div className='row mt-3'>
                <div className='col-sm-6 margin-top-mobile'>
                    <SelectWithLabel
                        label={t('Client')}
                        inputProps={{
                            options: clientOptions,
                            ...bindSimple('buyerCompany')
                        }}
                    />
                </div>
                <div className='col-sm-6 margin-top-mobile'>
                    <SearchBarDateFilter
                        placeholder={t("Delivery-date")}
                        label={t('Delivery-date')}
                        withIcon={true}
                        className={'mt-0'}
                        {...bindSimple('deliveryDate')}
                        disabled={true}
                    />
                </div>
            </div>

            <div className='row mt-3'>
                <div className='col-sm-6 margin-top-mobile'>
                    <SelectWithLabel
                        label={t('Warehouse')}
                        inputProps={{
                            options: warehouseOptions,
                            ...bindSimple('warehouse')
                        }}
                    />
                </div>
            </div>
            <div className='row mt-3'>
                <div className='col-sm-12 margin-top-mobile'>
                    {warehouseAddress && 
                        <Message className='mt-3' showIcon={false} showTitle={false}>
                            {t('Street')}: {warehouseAddress.street},&nbsp; #{warehouseAddress.externalNumber},&nbsp;{warehouseAddress.internalNumber}<br/>
                            {t('Postal-code')}: {warehouseAddress.postalCode}<br/>
                            {t('State')}: {warehouseAddress.neighbourhood?.municiaplity?.state?.name}
                        </Message>}
                </div>
            </div>

            {selectedClient && 
                <ClientCard
                    client={selectedClient}
                />}

            <SectionSubTitle>
                {t('Upload-buying-order')}
            </SectionSubTitle>

            <Message className='mt-3'>
                {t('Once-ODC-is-uploaded-status-will-change-to')}
                <span className='bold'>"{t('Accepted')}"</span>
            </Message>
            
            <div className='row mb-3 mt-3'>
                <div className='col-sm-6 col-lg-4 margin-top-mobile mt-3'>
                    <FileUploader
                        name='buying-order'
                        placeholder={t("Upload-buying-order")}
                        fileTypes={validDocTypesForBuyingOrder}
                        maxFileSizeInMB={5}
                        label={t("Upload-buying-order")}
                        btnLabel={t("Upload-buying-order")}
                        {...bindSimple('buyingOrder')}
                    />
                </div>
            </div>

            <SimpleAcordeon 
                className='DocumentsToUpload mt-3' 
                title={t('Documents-to-upload')} 
                alwaysOpen={true}
            >
                <div className='mt-3'>
                    <MiniTitle>{t('Payment')}</MiniTitle>
                </div>
                <div className='row mt-4'>
                    <div className='col-md-6'>
                        <MiniTitle>{t('XML-Invoice')}</MiniTitle>
                        
                        <MultipleFileUpload
                            label={t('Upload-file')}
                            fileTypes={validDocTypesForDocuments}
                            onChange={file => setForm({...form, paymentXmlInvoice: file})}
                            files={[form?.paymentXmlInvoice]}
                            multiple={false}
                            preview={true}
                        />
                    </div>
                    <div className='col-md-6'>
                        <MiniTitle>{t('PDF-Invoice')}</MiniTitle>
                        
                        <MultipleFileUpload
                            label={t('Upload-file')}
                            fileTypes={validDocTypesForDocuments}
                            onChange={file => setForm({...form, paymentPdfInvoice: file})}
                            files={[form?.paymentPdfInvoice]}
                            multiple={false}
                            preview={true}
                        />
                    </div>                                
                </div>

                <hr />

                <div className='mt-3'>
                    <MiniTitle>{t('Exportation')}</MiniTitle>
                </div>
                <div className='row mt-3'>
                    <div className='col-md-6'>
                        <MiniTitle>{t('XML-Invoice')}</MiniTitle>
                        
                        <MultipleFileUpload
                            label={t('Upload-file')}
                            fileTypes={validDocTypesForDocuments}
                            onChange={file => setForm({...form, exportationXmlInvoice: file})}
                            files={[form?.exportationXmlInvoice]}
                            multiple={false}
                            preview={true}
                        />
                    </div>
                    <div className='col-md-6'>
                        <MiniTitle>{t('PDF-Invoice')}</MiniTitle>
                        
                        <MultipleFileUpload
                            label={t('Upload-file')}
                            fileTypes={validDocTypesForDocuments}
                            onChange={file => setForm({...form, exportationPdfInvoice: file})}
                            files={[form?.exportationPdfInvoice]}
                            multiple={false}
                            preview={true}
                        />
                    </div>                                
                </div>

                <hr />

                <div className='mt-3'>
                    <MiniTitle>{t('Packing-list')}</MiniTitle>
                </div>
                <div className='row mt-3'>
                    <div className='col-md-6'>
                        <MiniTitle>{t('Document')}</MiniTitle>
                        
                        <MultipleFileUpload
                            label={t('Upload-file')}
                            fileTypes={validDocTypesForDocuments}
                            onChange={file => setForm({...form, packingList: file})}
                            files={[form?.packingList]}
                            multiple={false}
                            preview={true}
                        />
                    </div>                              
                </div>
                
                <hr />
                
                <div className='mt-3'>
                    <MiniTitle>{t('Exportation')}</MiniTitle>
                </div>
                <div className='row mt-3'>
                    <div className='col-md-6 mt-3'>                    
                        {form?.extraFiles?.map((file, index) =>
                            <div className='mt-3' key={index}>
                                <div className='d-flex align-items-center justify-conent-start'>
                                    <MiniTitle className="mt-0">Extra document</MiniTitle>

                                    <Button design="link" onClick={handleDeleteExtraFile(index)}>
                                        {t('Delete')}
                                    </Button>
                                </div>
                                
                                    <TextInput
                                        label={t('Document-name')}
                                        onChange={handleExtraFilesInputChange(index)}
                                        value={file?.name}
                                    />

                                    {file?.file && 
                                        <>{file?.file?.name}</> }

                                    <MultipleFileUpload
                                        label={t('Upload-file')}
                                        fileTypes={validDocTypesForDocuments}
                                        onChange={handleExtraFilesChange(index)}
                                        value={file?.file}
                                        multiple={false}
                                    />
                            </div>)}
                    </div>
                    <div className='col-md-6'>
                        
                        
                        <Button
                            design='link'
                            onClick={handleAddExtraFile}
                        >
                            + {t('Add-document')}
                        </Button>
                    </div>                                
                </div>

                <hr />
        </SimpleAcordeon>
            
            <div className='row mt-3 d-flex justify-content-center align-items-center gap-3'>
                <Button
                    className={'btn-primary btn-outline btnNextStep NextStepButtons__button'}
                    onClick={() => navigate(paths.mm.orders)}
                    design={'clear'}
                >
                    {t('Cancel')}
                </Button>
                <Button 
                    className={classNames('btn-primary btnNextStep NextStepButtons__button', !formFilled&&'btn-disabled')}
                    onClick={handleSaveAndGotoStep2}
                    disabled={!formFilled}
                >
                    {t('Save-and-continue')}
                </Button>
            </div>
            <div className='row mt-3'>
                <div className='col d-flex justify-content-center align-content-center'>
                    <Button className={classNames('link', !formFilled&&'link-disabled')} onClick={handleSaveAndContinue}>
                        {t('Save-and-continue-later')}
                    </Button>
                </div>                
            </div>
        </Card>
    );
}

export default OrderStep1;