import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next';
import useCallbackCreator from 'use-callback-creator';
import MiniTitle from '../../../../../components/Dashboard/MiniTitle/MiniTitle';
import MmSelect from '../../../../../components/forms/MmSelect/MmSelect';
import SelectWithLabel from '../../../../../components/forms/SelectWithLabel/SelectWithLabel';
import Textarea from '../../../../../components/forms/Textarea/Textarea';
import TextInput from '../../../../../components/forms/TextInput/TextInput';
import Modal from '../../../../../components/Modal/Modal';
import { getFenologicalStageOptions } from '../../../../../services/models/orchardVisitUtils';
import { prepareVisitSectorDetailForServer } from '../../../../../services/models/visitSectorDetailUtils';
import { getNotifier } from '../../../../../services/notifier';
import { qualityOptions } from '../../../../../services/qualityUtils';
import SliderWithLabel from '../../../OrdersFormStep3/components/SliderWithLabel/SliderWithLabel';

const SectorDetailsModal = ({ sectorDetails, onClose, onChange }) => {
    const {t} = useTranslation();

    const handleChange = useCallbackCreator((name, value) => {
        onChange({ ...sectorDetails, [name]: value });
    }, [sectorDetails, onChange]);

    const handleInputChange = useCallbackCreator((name, e) => {
        onChange({ ...sectorDetails, [name]: e.target.value });
    }, [sectorDetails, onChange]);

    const handleSaveSectorDetails = useCallback(() => {
        try {
            prepareVisitSectorDetailForServer(sectorDetails, t);
        } catch (e) {
            return getNotifier().error( e.message||e.detail );
        }

        onChange({ ...sectorDetails});
        onClose();
    }, [sectorDetails, onChange, onClose, t]);

    return (
        <Modal 
            onClose={onClose}
            title={`${t('Add-sector-details')}: ${sectorDetails?.sector?.name}`}
            className="SectorDetailsModal"
            mainButtonAction={handleSaveSectorDetails}
            secondaryButtonAction={onClose}
        >
            <MiniTitle
                title={t('Sector-details')}
            />
            <div className='row mt-3'>
                <div className='col-md-12'>
                    <SelectWithLabel
                        label={t('Phenological-stage')}
                        inputProps={{
                            options: getFenologicalStageOptions(t),
                            value: sectorDetails?.phenologicalStage,
                            onChange: handleChange('phenologicalStage'),
                        }}
                    />                            
                </div>
            </div>
                    
            <MiniTitle className="product-title mt-4">{t('Quality')}</MiniTitle>

            <div className='row mt-3'>
                <div className='col-md-4'>
                    <MmSelect
                        label={t('Caliber')}
                        placeholder={t('Caliber')}
                        options={qualityOptions(t)}
                        onChange={handleChange('caliber')}
                        value={sectorDetails?.caliber||''}
                    />
                </div>
                <div className='col-md-8'>
                    <TextInput
                        label={t('Comments')}
                        placeholder={t('Comments')}
                        onChange={handleInputChange('caliberComments')}
                        value={sectorDetails?.caliberComments||''}
                    />
                </div>  
            </div>

            <div className='row'>
                <div className='col-md-4'>
                    <MmSelect
                        label={t('Consistency')}
                        placeholder={t('Consistency')}
                        options={qualityOptions(t)}
                        onChange={handleChange('consistency')}
                        value={sectorDetails?.consistency||''}
                    />
                </div>
                <div className='col-md-8'>
                    <TextInput
                        label={t('Comments')}
                        placeholder={t('Comments')}
                        onChange={handleInputChange('consistencyComments')}
                        value={sectorDetails?.consistencyComments||''}
                    />
                </div>  
            </div>

            <MiniTitle className="product-title">{t('Properties')}</MiniTitle>

            <div className='row'>
                <div className='col-md-6'>
                    <SliderWithLabel
                        label={t('Maturity')}
                        className='mt-4'
                        onChange={handleChange('maturity')}
                        value={sectorDetails?.maturity||''}
                        showDetail={true}
                    />
                </div>
                <div className='col-md-6'>
                    <SliderWithLabel
                        label={t('Plague-demage')}
                        className='mt-4'
                        onChange={handleChange('plagueDamage')}
                        value={sectorDetails?.plagueDamage||''}
                        showDetail={true}
                    />
                </div>
            </div>

            <div className='row'>
                <div className='col-md-6'>
                    <SliderWithLabel
                        label={t('Climate-damage')}
                        className='mt-4'
                        onChange={handleChange('climateDamage')}
                        value={sectorDetails?.climateDamage||''}
                        showDetail={true}
                    />
                </div>
                <div className='col-md-6'>
                    <SliderWithLabel
                        label={t('Foliar-application-damage')}
                        className='mt-4'
                        onChange={handleChange('foliarApplicationDamage')}
                        value={sectorDetails?.foliarApplicationDamage||''}
                        showDetail={true}
                    />
                </div>
            </div>

            <div className='row mt-4 mb-3'>
                <div className='col-md-12 margin-top-mobile'>
                    <Textarea
                        label={t('Comments')}
                        onChange={handleInputChange('comments')}
                        value={sectorDetails?.comments||''}
                        name="comments"
                    />
                </div>
            </div>
        </Modal>
    );
}

export default SectorDetailsModal;