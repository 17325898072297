import { copyPropertyOrThrow, copyPropertyValueOrThrow } from "../formUtils";

export const getEmptyFoliarApplicationDetail = () => {
    return {
        id: null,
        foliarApplication: null,
        dose: null,
        unit: null,
        activeIngredient: null,
    };
};

export const prepareFoliarApplicationDetailToServer = (form, t) => {
    let detail = {};
    
    copyPropertyOrThrow(form, detail, 'dose', t('Please-enter-a-dose'));
    copyPropertyValueOrThrow(form, detail, 'unit', t('Please-select-a-unit'));
    copyPropertyValueOrThrow(form, detail, 'activeIngredient', t('Please-select-an-active-ingredient'));
    copyPropertyOrThrow(form, detail, 'reason', t('Please-enter-a-reason'));

    detail.dose = parseFloat(detail.dose);

    return detail;
}

export const foliarApplicationDetailToFormData = (foliarApplicationDetail) => {
    return {
        ...foliarApplicationDetail,
        activeIngredient: {label: foliarApplicationDetail.activeIngredient.name, value: foliarApplicationDetail.activeIngredient.id},
        unit: {label: foliarApplicationDetail.unit, value: foliarApplicationDetail.unit},
    }
}