import classNames from 'classnames';
import React from 'react'

import './MiniTitle.scss';

const MiniTitle = ({design='', className, children}) => {
    return (
        <div className={classNames('MiniTitle', design, className)}>{children}</div>
    );
}

export default MiniTitle;