export const stateTableSGroups = [
    'state_read',
    'state_read_id',
    'state_read_name',
    'state_read_code',
    'state_read_country',
    'country_read',
    'state_read_municipalities',
    'municipality_read',
    'read_created_by',
    'user_read',
    'read_updated_by',
    'read_created_date',
    'read_updated_date',
];

export const stateDetailSGroups = [
    'state_read_id',
    'state_read_name',
    'state_read_country',
    'country_read_id'
];

export const stateTableColumns = [
    {
        Header: 'id',
        accessor: 'id'
    },
     {
        Header: 'name',
        accessor: 'name'
    },
     {
        Header: 'code',
        accessor: 'code'
    },
     {
        Header: 'country',
        accessor: 'country?.id'
    },
     {
        Header: 'municipalities',
        accessor: 'municipalities?.id'
    },
     {
        Header: 'createdBy',
        accessor: 'createdBy?.id'
    },
     {
        Header: 'updatedBy',
        accessor: 'updatedBy?.id'
    },
     {
        Header: 'createdDate',
        accessor: 'createdDate'
    },
     {
        Header: 'updatedDate',
        accessor: 'updatedDate'
    },
 ];

export const stateDataDetailsArray = (state) => [
    {data: state?.id, dataLabel: 'id'},
    {data: state?.name, dataLabel: 'name'},
    {data: state?.code, dataLabel: 'code'},
    {data: state?.country?.id, dataLabel: 'country'},
    {data: state?.municipalities?.id, dataLabel: 'municipalities'},
    {data: state?.createdBy?.id, dataLabel: 'createdBy'},
    {data: state?.updatedBy?.id, dataLabel: 'updatedBy'},
    {data: state?.createdDate, dataLabel: 'createdDate'},
    {data: state?.updatedDate, dataLabel: 'updatedDate'},
];


export const newStateFormDefinition = [
];

export const editStateFormDefinition = [
    ...newStateFormDefinition
];

export const prepareNewStateForServer = (form) => {
    const state= {};


    return state;
};

export const prepareEditedStateForServer = (form) => {
    return prepareNewStateForServer(form);
};

export const stateToFormData = (state) => {
    return {
        ...state
    }
}