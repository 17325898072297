import React from 'react'
import { SectionTitle } from '../../../components/Dashboard/SectionTitle/SectionTitle';
import { SideMenu } from '../../../components/Dashboard/SideMenu/SideMenu';
import TopBar from '../../../components/Dashboard/TopBar/TopBar';
import ResultsCard from '../../../components/Dashboard/ResultsCard/ResultsCard';
import CardWithImage from '../../../components/Dashboard/CardWithImage/CardWithImage';
import avocadoImage from '../../../assets/images/dummy/avocado.jpg';
import widgetImage from '../../../assets/images/dummy/weather-widget.png';
import './Dashboard.scss';
import { TransactionCard } from '../../../components/Dashboard/TransactionCard/TransactionCard';
import SimpleList from '../../../components/Dashboard/SimpleList/SimpleList';
import LabelStatus from '../../../components/Dashboard/LabelStatus/LabelStatus';

const columns = [
    {
        Header: "Product",
        accessor: "product"
    },
    {
        Header: "Quantity",
        accessor: "quantity"
    },
    {
        Header: "Ordered By",
        accessor: "orderedBy"
    },
    {
        Header: "Status",
        id: 'status',
        accessor: (row) => <LabelStatus status={row.status} statusText={row.status} />
    },
    {
        Header: "Price",
        accessor: "price"
    },
];

const data = [
    {
        product: "Hass Avocado",
        quantity: "120kg",
        orderedBy: "John Doe",
        status: "pending",
        price: "$13.44 US"
    },
    {
        product: "Hass Avocado",
        quantity: "120kg",
        orderedBy: "John Doe",
        status: "delivered",
        price: "$13.44 US"
    },
    {
        product: "Hass Avocado",
        quantity: "120kg",
        orderedBy: "John Doe",
        status: "pending",
        price: "$13.44 US"
    },
    {
        product: "Hass Avocado",
        quantity: "120kg",
        orderedBy: "John Doe",
        status: "pending",
        price: "$13.44 US"
    },
    {
        product: "Hass Avocado",
        quantity: "120kg",
        orderedBy: "John Doe",
        status: "pending",
        price: "$13.44 US"
    },
    {
        product: "Hass Avocado",
        quantity: "120kg",
        orderedBy: "John Doe",
        status: "pending",
        price: "$13.44 US"
    }
];

const Dashboard = () => {
    return (
        <div className="Dashboard">
            <SideMenu />
            <div className='right-content'>
                <TopBar />
                <div className='main-content'>
                    <SectionTitle>Dashboard</SectionTitle>

                    <div className="row">
                        <div className="col-xs-6 col-sm-6 col-lg-3">
                            <ResultsCard 
                                title="Today’s sales"
                                value="$887.40 US"
                                title2="Previuos"
                                value2="$367.22"
                                title3="% Change"
                                value3="-4.84%"
                                color3="red"
                            />
                        </div>
                        <div className="col-xs-6 col-sm-6 col-lg-3">
                            <ResultsCard 
                                title="My Orders"
                                value="473"
                                title2="Previuos"
                                value2="129"
                                title3="% Change"
                                value3="+4.84%"
                                color3="green"
                            />
                        </div>
                        <div className="col-xs-6 col-sm-6 col-lg-3">
                            <ResultsCard 
                                title="My Orders"
                                value="473"
                                title2="Previuos"
                                value2="129"
                                title3="% Change"
                                value3="+4.84%"
                                color3="green"
                            />
                        </div>
                        <div className="col-xs-6 col-sm-6 col-lg-3">
                            <TransactionCard 
                                credits={439}
                            />
                        </div>
                    </div>

                    <SectionTitle>Delivery Orders</SectionTitle>
                    
                    <div className="row">
                        <div className="col-xs-6 col-lg-9">
                            <SimpleList columns={columns} data={data} />
                        </div>
                        <div className="col-xs-6 col-lg-3 margin-top-mobile">
                            <img src={widgetImage} alt="Widget" style={{width: '100%'}} />
                        </div>
                    </div>  
                    
                    <SectionTitle>My Products</SectionTitle>

                    <div className="row">
                        <div className="col-xs-6 col-sm-6 col-lg-3">
                            <CardWithImage 
                                image={avocadoImage} 
                                title={"Hass Avocado"}
                                subTitle={"$13.44 US"} 
                                unit={"kg"}
                                content={"Wholesale 4"}
                            />
                        </div>
                        <div className="col-xs-6 col-sm-6 col-lg-3">
                            <CardWithImage 
                                image={avocadoImage} 
                                title={"Hass Avocado"}
                                subTitle={"$13.44 US"} 
                                unit={"kg"}
                                content={"Wholesale 4"}
                            />
                        </div>
                        <div className="col-xs-6 col-sm-6 col-lg-3">
                            <CardWithImage 
                                image={avocadoImage} 
                                title={"Hass Avocado"}
                                subTitle={"$13.44 US"} 
                                unit={"kg"}
                                content={"Wholesale 4"}
                            />
                        </div>
                        <div className="col-xs-6 col-sm-6 col-lg-3">
                            <CardWithImage 
                                image={avocadoImage} 
                                title={"Hass Avocado"}
                                subTitle={"$13.44 US"} 
                                unit={"kg"}
                                content={"Wholesale 4"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard;