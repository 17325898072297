import React, {useMemo, useCallback } from 'react'
import { SectionTitle } from '../../../components/Dashboard/SectionTitle/SectionTitle';
import { SideMenu } from '../../../components/Dashboard/SideMenu/SideMenu';
import TopBar from '../../../components/Dashboard/TopBar/TopBar';
import {useTranslation} from "react-i18next";
import Button from '../../../components/molecules/Button/Button';
import useTideTable from "../../../components/utility/TideReactTable/useTideTable";
import TideReactTable from "../../../components/utility/TideReactTable/TideReactTable";
import { Link, useNavigate } from 'react-router-dom';
import { brandsTableSGroups } from '../../../services/models/brandsUtils';
import './BrandsList.scss';
import { paths } from '../../../services/routes/appRoutes';
import moment from 'moment';
import { generalDatesFormat } from '../../../services/generalConfig';

const customProp = "BrandsList";

const BrandsList = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const brandsTableColumns = useMemo(() => [
        {
            Header: 'ID',
            accessor: 'id',
        },
        {
            Header: t('Name'),
            accessor: 'name',
        },

        {
            Header: t('Created'),
            accessor: brand => brand.createdDate ? moment(brand.createdDate).format(generalDatesFormat) : ''
        },
        {
            Header: t('Updated'),
            accessor: brand => brand.updatedDate ? moment(brand.updatedDate).format(generalDatesFormat) : ''
        },
        {
            Header: t("Actions"),
            accessor: brand => 
                    <div className="actions">
                        <Link 
                            to={ paths.mm.brandsEdit.replace(":id", brand.id) }
                            className="btn-primary"
                        >
                            {t('Edit')}
                        </Link>
                    </div>
        }
    ], [t]);

    const requestFilters = useMemo(() => ({
        sGroups: brandsTableSGroups
    }), []);

    const requestOptions = useMemo(() => ({ customProp }), []);

    const table = useTideTable({
        entity: 'brands',
        columns: brandsTableColumns,
        requestFilters,
        requestOptions
    });

    const handleNewBrand = useCallback(()=>navigate(paths.mm.brandsForm), [navigate]);

    return (
        <div className="BrandsList dashboard-container">
            <SideMenu />
            <div className='right-content'>
                <TopBar />
                <div className='main-content'>
                    <SectionTitle>{t('My-brands')}</SectionTitle>

                    <div className='row'>
                        <div className='col d-flex justify-content-end align-items-center gap-3'>
                            <Button 
                                className={'submit btnNewProducer'} 
                                type='button' 
                                onClick={handleNewBrand}
                                id="add-new-brand"
                            >
                                + {t("Add-new-brand")}
                            </Button>
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="">
                            <TideReactTable 
                                {...table.tableProps} 
                                className="productos-table"
                            />
                        </div>
                    </div>   
                </div>
            </div>
        </div>
    );
}

export default BrandsList