import React, {useState} from 'react'
import { SectionTitle } from '../../../components/Dashboard/SectionTitle/SectionTitle';
import { SideMenu } from '../../../components/Dashboard/SideMenu/SideMenu';
import TopBar from '../../../components/Dashboard/TopBar/TopBar';
import ResultsCard from '../../../components/Dashboard/ResultsCard/ResultsCard';
import LabelStatus from '../../../components/Dashboard/LabelStatus/LabelStatus';
import useTideTable from "../../../components/utility/TideReactTable/useTideTable";
import TideReactTable from "../../../components/utility/TideReactTable/TideReactTable";
import CustomCheckbox from '../../../components/Dashboard/CustomCheckbox/CustomCheckbox';
import SearchBar from '../../../components/SearchBar/SearchBar';
import { SearchTextbox } from '../../../components/forms/SearchTextbox/SearchTextbox';
import { t } from 'i18next';
import './Orders.scss';
import {filterTypes} from "../../../services/searchUtils";

const tableColumns = [
    {
        Header: <>
                    <CustomCheckbox> 
                        Quantity
                    </CustomCheckbox>                        
                </>,
        accessor: "quantity"
    },
    {
        Header: "Package",
        accessor: "Package"
    },
    {
        Header: "Product",
        accessor: "product"
    },
    {
        Header: "Buyer",
        accessor: "buyer"
    },
    {
        Header: "Status",
        id: 'status',
        accessor: (row) => <LabelStatus status={row.status} statusText={row.status} />
    },
    {
        Header: "Order Created",
        accessor: "orderCreated"
    },
    {
        Header: "",
        accessor: "commands"
    }
]

const filtersConfig = {
    filters: [
        {
            type: filterTypes.SELECT,
            field: 'products',
            label:'Productos',
            options:[{'label': 'Avocado', 'value': 'Avocado'}, {'label': 'Onion', 'value': 'Onion'}],
            placeholder: "All Products"
        },
        {
            type: filterTypes.SELECT,
            field: 'status',
            label:'Status',
            options:[{'label': 'PENDING', 'value': 'PENDING'}, {'label': 'DELIVERED', 'value': 'DELIVERED'}],
            placeholder: "All Status"
        },
        {
            type: filterTypes.DATE,
            field: 'dateFrom',
            label:'dateFrom',
            placeholder: "All Dates"
        },
        {
            type: filterTypes.DATE,
            field: 'dateTo',
            label:'dateTo',
            placeholder: "All Dates"
        }
    ]
};

const Orders = () => {
    const [filters, setFilters] = useState();

    const table = useTideTable({
        entity: 'users',
        columns: tableColumns
    });

    return (
        <div className="Orders">
            <SideMenu />
            <div className='right-content'>
                <TopBar />
                <div className='main-content'>
                    <SectionTitle>My Orders</SectionTitle>

                    <div className='row'>
                        <SearchTextbox 
                            placeholder={t('Search-by-name-product...')}
                        />
                    </div>

                    <div className="row">
                        <div className="col-xs-6 col-sm-6 col-lg-3">
                            <ResultsCard 
                                title="Today's sales"
                                value="$887.40 US"
                                title2="Previuos"
                                value2="$362.22"
                                title3="% Change"
                                value3="+7.84%"
                                color3="green"
                            />
                        </div>
                        <div className="col-xs-6 col-sm-6 col-lg-3">
                            <ResultsCard 
                                title="My Orders"
                                value="473"
                                title2="Previuos"
                                value2="129"
                                title3="% Change"
                                value3="+4.84%"
                                color3="green"
                            />
                        </div>
                        <div className="col-xs-6 col-sm-6 col-lg-3">
                            <ResultsCard 
                                title="My Orders"
                                value="473"
                                title2="Previuos"
                                value2="129"
                                title3="% Change"
                                value3="+4.84%"
                                color3="green"
                            />
                        </div>
                        <div className="col-xs-6 col-sm-6 col-lg-3">
                            <ResultsCard
                                title="My Orders"
                                value="73"
                                title2="Previuos"
                                value2="129"
                                title3="% Change"
                                value3="-4.84%"
                                color3="red"
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="">
                            <SearchBar
                                filtersConfig={filtersConfig}
                                onFiltersChange={(filters) => setFilters(filters)}
                                filters={filters}
                            />
                        </div>
                    </div>   
                    <div className="row">
                        <div className="">
                            <TideReactTable {...table.tableProps} />
                        </div>
                    </div>   
                </div>
            </div>
        </div>
    )
}

export default Orders;