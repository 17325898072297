import React, { useCallback } from 'react'
import { getEmptySector } from '../../../../../services/models/sectorUtils';
import AddNewSectorButton from './components/AddNewSectorButton/AddNewSectorButton';
import Sector from './components/Sector/Sector';
import useCallbackCreator from 'use-callback-creator';
import './SectorsForm.scss';

const SectorsForm = ({form, setForm, orchard, loadOrchardData}) => {
    const addSector = useCallback(()=>{
        setForm({
            ...form,
            sectors: [...form.sectors||[], getEmptySector()]
        });
    },[setForm, form]);

    const handleSectorChange = useCallbackCreator((index, sector)=>{
        const newSectors = [...form.sectors];
        newSectors[index] = sector;
        setForm({ ...form, sectors: newSectors });
    }, [setForm, form]);

    return (
        <div className='SectorsForm'>
            {form.sectors && form.sectors?.map((sector, index) => 
                <Sector 
                    key={index}
                    index={index}
                    sector={sector}
                    orchard={orchard}
                    onChange={handleSectorChange(index)}
                    loadOrchardData={loadOrchardData}
                />
            )}
            
            <div className='row new-sector-button'>
                <div className='col d-flex justify-content-end'>
                    <AddNewSectorButton handleAddSector={addSector} />
                </div>
            </div>
        </div>
    );
}

export default SectorsForm;