import React, { useEffect, useContext } from 'react'
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Card from '../../../components/Dashboard/Card/Card';
import { SectionTitle } from '../../../components/Dashboard/SectionTitle/SectionTitle';
import { SideMenu } from '../../../components/Dashboard/SideMenu/SideMenu';
import TopBar from '../../../components/Dashboard/TopBar/TopBar';
import TextInput from '../../../components/forms/TextInput/TextInput';
import Button from '../../../components/molecules/Button/Button';
import useFormState from '../../../hooks/useFormState';
import { ApiContext } from '../../../services/api/api-config';
import { brandDetailSGroups, prepareBrandForServer } from '../../../services/models/brandsUtils';
import { getNotifier } from '../../../services/notifier';
import { paths } from '../../../services/routes/appRoutes';
import './BrandsForm.scss';

const BrandsForm = () => {
    const {t} = useTranslation();
    const { id } = useParams();
    const api = useContext(ApiContext);
    const navigate = useNavigate();
    const { form, setForm, bindInput } = useFormState({});
    const loadingId = `Grower.form.${id}`;

    // -- load data
    useEffect(() => {
        // load brand data
        if (id) {
            api.brands.get({id, params: {sGroups: brandDetailSGroups}})
                .then(async(brand) => {
                    setForm( brand );
                })
                .catch(error => getNotifier().error(error.detail||error.message));
        }
    }, [api, id, setForm, t]);

    const handleCreateOrUpdateBrand = useCallback(() => {
        const {id} = form;
        const method = id ? 'update' : 'create';
        let brand = null;

        try {
            brand = prepareBrandForServer(form, t);
        } catch (error) {
            return getNotifier().error(error.message||error.detail);            
        }
        
        api.brands[method]({ id, params: brand })
            .then(brand => {
                getNotifier().success( id ? t('Brand-updated-successfully') : t('Brand-created-successfully'));
                navigate(paths.mm.brands);
            })
            .catch(error => getNotifier().error(error.message||error.detail));
    }, [api, form, navigate, t]);

    const loading = useSelector(s=>!!s.loadingIds[loadingId]);

    return (
        <div className="BrandsForm dashboard-container">
            <SideMenu />
            <div className='right-content'>
                <TopBar />
                <div className='main-content'>
                    <SectionTitle>{ !id ? t('Add-new-brand') : t('Edit-brand')}</SectionTitle>

                    <div className='row'>
                        <Card className='OrderStep1'>
                            <div className='row mt-3'>
                                <div className='col-sm-6 margin-top-mobile'>
                                    <TextInput
                                        label={t('Name')}
                                        placeholder={t('Name')}
                                        {...bindInput('name')}
                                    />
                                </div>
                            </div>
                            
                            <div className='row d-flex justify-content-center align-items-center buttons-container'>
                                <Button
                                    className={'btn-primary btn-outline'}
                                    onClick={() => navigate(paths.mm.brands) }
                                    design={'clear'}
                                >
                                    {t('Cancel')}
                                </Button>

                                <Button 
                                    className={`btn-primary btnNextStep`}
                                    onClick={handleCreateOrUpdateBrand}
                                    disabled={loading}
                                >
                                    { id ? t('Update') : t('Create') }
                                </Button>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BrandsForm