import React, {useMemo, useContext, useEffect, useState} from 'react'
import confirmEmailBackground from '../../../../assets/images/backgrounds/confirmEmailBackground.jpg';
import GeneralContainer from '../../../../components/layout/GeneralContainer/GeneralContainer';
import {useTranslation} from "react-i18next";
import {ApiContext} from "../../../../services/api/api-config";
import useBoolean from '../../../../hooks/useBoolean';
import {Link, useLocation} from "react-router-dom";
import './ValidateAccount.scss';
import { paths } from '../../../../services/routes/notLoggedRoutes';

const ValidateAccount = () => {
    const {t} = useTranslation();
    const [isValid, validTrue, validFalse] = useBoolean();
    const [loaded, setLoaded] = useState();
    const api = useContext(ApiContext);
    const queryString = useLocation().search;

    useEffect(() => {
        setLoaded(true);
    }, []);    

    useEffect(() => {
        const token = new URLSearchParams(queryString).get("t");
        api.users.validateAccount({params: {token}, method: 'POST', useCommonPath: false}).then(() => {
            validTrue();
        }).catch(() => {
            validFalse();
        })
    }, [api, queryString, validFalse, validTrue]);

    const containerStyle = useMemo(()=>{
        return { backgroundImage: `url("${confirmEmailBackground}")`, backgroundSize: 'cover', backgroundPosition: 'center' }
    }, []);

    return (
        <div className='ValidateAccount' style={containerStyle}>
            {loaded &&
                <GeneralContainer>
                    { isValid ? 
                        <>
                            <h1 className='subTitle'>{t('account-confirmed')}</h1>
                            <Link to={paths.login} className={'sign-up-link'}>
                                {t("login-here")}
                            </Link>
                        </> : 
                        <h1 className='subTitle'>{t('account-confirmation-error')}</h1>
                    }
                </GeneralContainer>}
        </div>
    )
}

export default ValidateAccount;