export const municipalityTableSGroups = [
    'municipality_read',
    'municipality_read_id',
    'municipality_read_name',
    'municipality_read_state',
    'state_read',
    'municipality_read_neighbourhoods',
    'neighbourhood_read',
    'read_created_by',
    'user_read',
    'read_updated_by',
    'read_created_date',
    'read_updated_date',
];

export const municipalityDetailSGroups = [
    'municipality_read',
    'municipality_read_id',
    'municipality_read_name',
    'municipality_read_state',
    'state_read',
    'municipality_read_neighbourhoods',
    'neighbourhood_read',
    'read_created_by',
    'user_read',
    'read_updated_by',
    'read_created_date',
    'read_updated_date',
];

export const municipalityForSelectSGroups = [
    'municipality_read',
        'state_read_id',
        'state_read_name',
];

export const municipalityTableColumns = [
    {
        Header: 'id',
        accessor: 'id'
    },
     {
        Header: 'name',
        accessor: 'name'
    },
     {
        Header: 'state',
        accessor: 'state?.id'
    },
     {
        Header: 'neighbourhoods',
        accessor: 'neighbourhoods?.id'
    },
     {
        Header: 'createdBy',
        accessor: 'createdBy?.id'
    },
     {
        Header: 'updatedBy',
        accessor: 'updatedBy?.id'
    },
     {
        Header: 'createdDate',
        accessor: 'createdDate'
    },
     {
        Header: 'updatedDate',
        accessor: 'updatedDate'
    },
 ];

export const municipalityDataDetailsArray = (municipality) => [
    {data: municipality?.id, dataLabel: 'id'},
    {data: municipality?.name, dataLabel: 'name'},
    {data: municipality?.state?.id, dataLabel: 'state'},
    {data: municipality?.neighbourhoods?.id, dataLabel: 'neighbourhoods'},
    {data: municipality?.createdBy?.id, dataLabel: 'createdBy'},
    {data: municipality?.updatedBy?.id, dataLabel: 'updatedBy'},
    {data: municipality?.createdDate, dataLabel: 'createdDate'},
    {data: municipality?.updatedDate, dataLabel: 'updatedDate'},
];


export const newMunicipalityFormDefinition = [
];

export const editMunicipalityFormDefinition = [
    ...newMunicipalityFormDefinition
];

export const prepareNewMunicipalityForServer = (form) => {
    const municipality= {};


    return municipality;
};

export const prepareEditedMunicipalityForServer = (form) => {
    return prepareNewMunicipalityForServer(form);
};

export const municipalityToFormData = (municipality) => {
    return {
        ...municipality
    }
}