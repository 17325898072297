import React, {useMemo} from 'react'

import './SearchBarSelectFilter.scss';
import MmSelect from "../../../forms/MmSelect/MmSelect";

const SearchBarSelectFilter = ({placeholder, config, onChange}) => {
    const selectOptions = useMemo( ()=>config.options?.map( opt=>{
        if(typeof opt === 'string')
            return {value: opt, label: opt};
        else
            return opt;
    } ),[config.options] );

    return (
        <MmSelect
            options={selectOptions}
            placeholder={placeholder}
            onChange={onChange}
            value={config.value}
        />
    )
}

export default SearchBarSelectFilter;