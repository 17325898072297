import classNames from 'classnames';
import Slider from 'rc-slider';
import React from 'react'
import MiniTitle from '../../../../../components/Dashboard/MiniTitle/MiniTitle';

import './SliderWithLabel.scss';

const marks = {
    0: <strong>0</strong>,
    100: <strong>100%</strong>,
};

const SliderWithLabel = ({label, value, onChange, className='', showDetail=false, sliderProps={min: 0, max: 100, defaultValue: 0}}) => {
    return (
        <div className={classNames('SliderWithLabel', className)}>
            <MiniTitle>{label} {showDetail ? `${value}%` : ''}</MiniTitle>
            <Slider
                marks={marks}
                {...sliderProps}
                step={10}
                value={value||0}
                onChange={onChange}
            />
        </div>
    );
}

export default SliderWithLabel;