import React, {useCallback, useContext} from 'react';
import './Login.scss';
import {ApiContext} from "../../../services/api/api-config";
import useFormState from "../../../hooks/useFormState";
import {LOGIN_STATE, LOGIN_LOADING_ID} from "tide-api";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import HalfScreenLayout from "../../../components/layout/HalfScreenLayout/HalfScreenLayout";
import background from './../../../assets/images/backgrounds/Intersectlogin.jpg';
import TextInput from "../../../components/forms/TextInput/TextInput";
import {Link} from "react-router-dom";
import Button from "../../../components/molecules/Button/Button";
import {ReactComponent as PlusIcon} from "../../../assets/images/icons/union.svg";
import PasswordInput from "../../../components/forms/PasswordInput/PasswordInput";
import {paths} from "../../../services/routes/notLoggedRoutes";


const Login=()=>{

    const api=useContext(ApiContext);
    const {t} = useTranslation();

    const {form, handleInputChange} = useFormState({});

    const login=useCallback((e)=>{
        e.preventDefault();
        if(!form.user || !form.pass)
            return;
        api.login(form.user,form.pass);
    },[api, form]);


    // --- login state message ------
    const loginState=useSelector(({api})=>api.loggedIn);
    const loading=useSelector(({loadingIds})=>loadingIds[LOGIN_LOADING_ID] || loadingIds['Login.me']);

    let loginMsg;
    switch (loginState) {
        case LOGIN_STATE.LOGIN_ERROR:
            loginMsg= 'Lo sentimos, hubo un error al iniciar sesión.';
            break;
        case LOGIN_STATE.BAD_CREDENTIALS:
            loginMsg= 'El usuario y contraseña no coinciden';
            break;
        default:
            loginMsg= null;
            break;
    }

    return (
        <HalfScreenLayout className='Login' rightBackground={background}>
            {[
                <div className='form-container' key={'form'}>
                    <h1 className='title'>{t("Log in")}</h1>
                    <p className='subtitle'>{t("Great-see-again")}</p>
                    <form onSubmit={login} className='form'>
                        <TextInput
                            label={t("Email")}
                            placeholder={t("Enter-email-here")}
                            onChange={handleInputChange('user')}
                            value={form.user || ''} 
                            className={'TextInput--small'}
                        />
                        <PasswordInput
                            placeholder={t("Password")}
                            onChange={handleInputChange('pass')}
                            value={form.pass || ''}
                        />
                        <p className='forgot-section'>
                            <Link to={"/"}>{t("Forget-password")}</Link>
                        </p>

                        <div className='but-container'>
                            <Button className={'submit'} type='submit' disabled={loading}>{t("Log in")}</Button>
                            <Button className={'google'} design='clear' disabled={loading}>
                                <PlusIcon className={'plus-icon'}/>
                                {t("Log-in-google")}
                            </Button>
                        </div>
                        <p className='sign-up-text'>
                            {t("dont-have-account")} <Link to={paths.registerRole} className={'sign-up-link'}>{t("sign-up-free")}</Link>
                        </p>
                        {loginMsg &&
                        <p className='error'>{loginMsg}</p>}
                    </form>
                </div>,
                    <p key={'text'} className={'text-block'}>
                        {t("We-believe-we-can-login-text")}
                    </p>
            ]}
        </HalfScreenLayout>
    );
};

export default Login;
