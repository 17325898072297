
export const getEmptyVariationAttribute = () => ({
    id: null,
    attribute: null,
    variationValues: []
});

export const variationAttributesDetailSGroups = [
    'variation_attribute_read',
        'variation_value_read'
]

export const variationAttributesToFormData = (variationAttributes) => {
    let formData = [];

    variationAttributes.forEach( (variationAttribute) => {
        let variationValues = [];

        variationAttribute.variationValues.forEach( (variationValue) => {
            variationValues.push({
                id: variationValue.id,
                value: variationValue.value||'---',
                variationValueTranslations: variationValue.variationValueTranslations,
                active: true,
            });
        });

        formData.push({
            id: variationAttribute.id,
            attribute: variationAttribute.attribute||'---',
            variationAttributeTranslations: variationAttribute.variationAttributeTranslations,
            variationValues
        });
    });

    return formData;
}   