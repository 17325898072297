import { t } from 'i18next';
import React from 'react'

import './ShipmentDetail.scss';

const ShipmentDetail = ({productOffer}) => {

    console.log( "productOffer", productOffer.productors );

    return (
        <div className='ShipmentDetail'>
            {productOffer?.product &&
                productOffer?.productors?.map((productor, index) =>
                    <div className='ShipmentDetail__details' key={index}>
                        {/*productor.quantity}x{productOffer?.unit?.label*/} 
                        {/*({productor.quantity}x{productOffer?.unit?.label} {productOffer?.baseProduct?.name || productOffer?.product?.name})*/}
                        $ { productor?.pricePerBox } x { productor?.boxes } {t('boxes')}
                    </div>)}
        </div>
    );
}

export default ShipmentDetail;