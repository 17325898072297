import classNames from 'classnames';
import React from 'react'
import {useTranslation} from "react-i18next";
import {ReactComponent as PlusIcon} from "../../../../../../../assets/images/icons/plus.svg";
import './AddNewSectorButton.scss';

const AddNewSectorButton = ({handleAddSector, className}) => {
    const {t} = useTranslation();

    return (
        <span className={classNames('AddNewSectorButton', className)} onClick={handleAddSector}>
            <PlusIcon /> {t('Add-new-sector')}
        </span>
    );
}

export default AddNewSectorButton;
