import fieldTypes from "../../components/FormModal/fields/fieldTypes";
import {
    copyPropertyOrNull,
    copyPropertyIdOrNull,
    copyPropertyOrThrow,
} from "../formUtils";
import { getEmptyCertification } from "./certificationUtils";
import { getFileFromForm } from "./companyUtils";

export const sectorTableSGroups = [
    'sector_read',
    'sector_read_id',
    'sector_read_name',
    'sector_read_orchard',
    'orchard_read',
    'read_created_by',
    'user_read',
    'read_updated_by',
    'read_created_date',
    'read_updated_date',
];

export const sectorDetailSGroups = [
    'sector_read',
    'sector_read_id',
    'sector_read_name',
    'sector_read_orchard',
    'read_created_by',
    'read_updated_by',
    'read_created_date',
    'read_updated_date',
    'sector_read'
];

export const sectorForSelectSGroups = [
    'sector_read_id',
    'sector_read_name'
];

export const sectorTableColumns = [
    {
        Header: 'id',
        accessor: 'id'
    },
     {
        Header: 'name',
        accessor: 'name'
    },
     {
        Header: 'orchard',
        accessor: 'orchard?.id'
    },
     {
        Header: 'createdBy',
        accessor: 'createdBy?.id'
    },
     {
        Header: 'updatedBy',
        accessor: 'updatedBy?.id'
    },
     {
        Header: 'createdDate',
        accessor: 'createdDate'
    },
     {
        Header: 'updatedDate',
        accessor: 'updatedDate'
    },
 ];

export const sectorDataDetailsArray = (sector) => [
    {data: sector?.id, dataLabel: 'id'},
    {data: sector?.name, dataLabel: 'name'},
    {data: sector?.orchard?.id, dataLabel: 'orchard'},
    {data: sector?.createdBy?.id, dataLabel: 'createdBy'},
    {data: sector?.updatedBy?.id, dataLabel: 'updatedBy'},
    {data: sector?.createdDate, dataLabel: 'createdDate'},
    {data: sector?.updatedDate, dataLabel: 'updatedDate'},
];


export const newSectorFormDefinition = [
    {
        name: 'name',
        label: 'name',
        required: false,
        type: fieldTypes.text
    },


];

export const editSectorFormDefinition = [
    ...newSectorFormDefinition
];

export const prepareNewSectorForServer = (form) => {
    const sector= {};

    copyPropertyOrThrow(form, sector, 'name', 'Debes agregar el nombre del sector');
    copyPropertyIdOrNull(form, sector, 'orchard', 'Debes agregar orchard de sector');
    copyPropertyOrNull(form, sector, 'pendant');
    copyPropertyOrNull(form, sector, 'height');
    copyPropertyOrNull(form, sector, 'surface');
    copyPropertyOrNull(form, sector, 'machinery');
    copyPropertyOrNull(form, sector, 'irrigationSystem');
    copyPropertyOrNull(form, sector, 'macroTunnel');
    copyPropertyOrNull(form, sector, 'greenhouses');

    if(sector.pendant)
        sector.pendant=parseFloat(sector.pendant);
    
    if(sector.height)
        sector.height=parseFloat(sector.height);

    if(sector.surface)
        sector.surface=parseFloat(sector.surface);
 
    return sector;
}

export const prepareEditedSectorForServer = (form) => {
    return prepareNewSectorForServer(form);
};

export const sectorToFormData = (sector) => {
    return {
        ...sector
    }
}

export const getEmptySector = () => ({
    id: null,
    orchard: {
        id: null,
    },
    name: '',
    pendant: 0,
    height: 0,
    surface: 0,
    machinery: false,
    irrigationSystem: false,
    macroTunnel: false,
    greenhouses: false,
    documents: [],
    performance: [
        {
            expectedPerformance: null,
            pastPerformance: null,
            currentWeek: null,
            pastWeek: null,
            expectedProduction: null,
            pastProduction: null,
            expectedTon: null,
            pastTon: null
        }
    ],
    analysisFiles: [],
    waterAnalysisFile: null,
    otherFiles: [],
    usaOrganicCertification: getEmptyCertification(),
    mexOrganicCertification: getEmptyCertification(),
    euroOrganicCertification: getEmptyCertification(),
    otherCertifications: [{}],
});


export const prepareSectorFilesForServer = (form) => {
    const sectorFiles = {};

    sectorFiles['waterAnalysisFile'] = getFileFromForm(form, 'waterAnalysisFile');
    
    Object.keys(sectorFiles).forEach(key => {
        if (sectorFiles[key] === undefined || sectorFiles[key] === null) {
          delete sectorFiles[key];
        }
    });

    return sectorFiles;
}

export const prepareSectorCertificationsForServer = (form) => {
    const sectorCertifications = {};

    sectorCertifications['certification'] = getFileFromForm(form, 'certification');
    
    Object.keys(sectorCertifications).forEach(key => {
        if (sectorCertifications[key] === undefined || sectorCertifications[key] === null) {
          delete sectorCertifications[key];
        }
    });

    return sectorCertifications;
}

export const getUsaOrganicCertification = (form) => {
    const usaOrganicCertification = getEmptyCertification();

    copyPropertyOrNull(form, usaOrganicCertification, 'certification');

    return usaOrganicCertification;
}

export const prepareSectorwithCertificationFilesForServer = (form) => {
    const files = {
        usaOrganicCertification: getFileFromForm(form.usaOrganicCertification, 'file'),
        mexOrganicCertification: getFileFromForm(form.mexOrganicCertification, 'file'),
        euroOrganicCertification: getFileFromForm(form.euroOrganicCertification, 'file'),
        ...prepareSectorFilesForServer(form)
    }

    Object.keys(files).forEach(key => {
        if (files[key] === undefined || files[key] === null) {
          delete files[key];
        }
    });

    return files;
}

