import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next';
import { ApiContext } from '../../../../../services/api/api-config';
import { ADMIN_ROLE_ID } from '../../../../../services/models/userUtils';
import {ReactComponent as RoleAdminIcon} from "../../../../../assets/images/icons/role-admin.svg";
import './UserAvatar.scss';

const UserAvatar = ({ user, onClick }) => {
    const api = useContext(ApiContext);
    const {t} = useTranslation();
    const avatarImage = user.avatar ? api.appFiles.getUrl(user.avatar) : null;
    const avatarBackground = user.avatar ? { backgroundImage: `url(${avatarImage})` } : null;
    const isAdmin = user.role?.id === ADMIN_ROLE_ID;

    return (
        <div className='UserAvatar'>
            {avatarImage ?
                <div className='UserAvatar__avatar-image' style={avatarBackground} />
                :
                <div className='UserAvatar__avatar-image UserAvatar__avatar-image--empty'>
                    <span>{user.name[0]}</span>
                </div>
            }
            <div className='UserAvatar__user-name'>
                <div className='UserAvatar__name-and-role'>
                    { user.name } { user.pat_last_name } 
                    {isAdmin && <RoleAdminIcon />}
                </div>
                { onClick && 
                    <div className='details-link' onClick={onClick}>
                        {t('More-details')}
                    </div>}
            </div>
        </div>
    );
}

export default UserAvatar