import React, {useState} from 'react'

import {SideMenu} from "../../../components/Dashboard/SideMenu/SideMenu";
import TopBar from "../../../components/Dashboard/TopBar/TopBar";
import {SectionTitle} from "../../../components/Dashboard/SectionTitle/SectionTitle";
import {SearchTextbox} from "../../../components/forms/SearchTextbox/SearchTextbox";
import {t} from "i18next";
import FilterButton from "../../../components/forms/FilterButton/FilterButton";
import ProductCard from "../../../components/Dashboard/ProductCard/ProductCard";
import CardWithImage from "../../../components/Dashboard/CardWithImage/CardWithImage";
import ImageWithLabel from "../../../components/Dashboard/ImageWithLabel/ImageWithLabel";
import RightFilterBar from "../../../components/Dashboard/RightFilterBar/RightFilterBar";
import avocadoImage from "../../../assets/images/dummy/avocado.jpg";
import avocadoProductImage from '../../../assets/images/dummy/product-avocado.png';
import strawberryProductImage from '../../../assets/images/dummy/product-strawberry.png';
import blackberryProductImage from '../../../assets/images/dummy/product-blackberry.png';
import bellpepperProductImage from '../../../assets/images/dummy/product-bellpepper.png';
import mangoProductImage from '../../../assets/images/dummy/product-mango.png';
import {ReactComponent as StatusOk} from "../../../assets/images/icons/status-ok.svg";

import './Marketplace.scss';

const productFilters = [
    {
        name: "Avocado",
        picture: avocadoProductImage
    },
    {
        name: "Strowberry",
        picture: strawberryProductImage
    },
    {
        name: "Blackberry",
        picture: blackberryProductImage
    },
    {
        name: "Bell pepper",
        picture: bellpepperProductImage
    },
    {
        name: "Mango",
        picture: mangoProductImage
    }
];

const Marketplace = () => {
    const [openFilterBar, setOpenFilterBar] = useState(true);

    return (
        <div className="Marketplace">
            <SideMenu />
            <div className='right-content'>
                <TopBar />
                <div className='main-content'>
                    <SectionTitle>{t('Marketplace')}</SectionTitle>

                    <div className='row'>
                        <SearchTextbox
                            placeholder={t('Search by name, product...')}
                        />

                        <FilterButton
                            label={t("filter-by")} onClick={() => setOpenFilterBar(true)}
                        />
                    </div>

                    <div className='row product-filters'>
                        {productFilters.map((product, index) =>
                            <div className={"col product-column"}>
                                <ProductCard
                                    product={product}
                                    onClick={() => {}}
                                />
                            </div>
                        )}
                    </div>

                    <SectionTitle>Featured Products</SectionTitle>

                    <div className="row">
                        <div className="col-xs-6 col-sm-6 col-lg-3">
                            <CardWithImage
                                image={avocadoImage}
                                title={"Hass Avocado"}
                                subTitle={"$13.44 US"}
                                unit={"kg"}
                                showLikeButton={true}
                                productId={'11111'}
                                onLike={() => {}}                                
                            >
                                <ImageWithLabel
                                    image={<img src='https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50?s=200' alt='Juan Alvarez' />}
                                    label={'Juan Alvarez'}
                                    align={'left'}
                                    link={"#"}
                                    statusIcon={<StatusOk />}
                                />
                            </CardWithImage>
                        </div>
                        <div className="col-xs-6 col-sm-6 col-lg-3">
                            <CardWithImage
                                image={avocadoImage}
                                title={"Hass Avocado"}
                                subTitle={"$13.44 US"}
                                unit={"kg"}
                                showLikeButton={true}
                                productId={'22211'}
                                onLike={() => {}}
                            >
                                <ImageWithLabel
                                    image={<img src='https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50?s=200' alt='Juan Alvarez' />}
                                    label={'Juan Alvarez'}
                                    align={'left'}
                                    link={"#"}
                                    statusIcon={<StatusOk />}
                                />
                            </CardWithImage>
                        </div>
                        <div className="col-xs-6 col-sm-6 col-lg-3">
                            <CardWithImage
                                image={avocadoImage}
                                title={"Hass Avocado"}
                                subTitle={"$13.44 US"}
                                unit={"kg"}
                                showLikeButton={true}
                                productId={'32211'}
                                onLike={() => {}}
                            >
                                <ImageWithLabel
                                    image={<img src='https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50?s=200' alt='Juan Alvarez' />}
                                    label={'Juan Alvarez'}
                                    align={'left'}
                                    link={"#"}
                                    statusIcon={<StatusOk />}
                                />
                            </CardWithImage>
                        </div>
                        <div className="col-xs-6 col-sm-6 col-lg-3">
                            <CardWithImage
                                image={avocadoImage}
                                title={"Hass Avocado"}
                                subTitle={"$13.44 US"}
                                unit={"kg"}
                                showLikeButton={true}
                                productId={'44455'}
                                onLike={() => {}}
                            >
                                <ImageWithLabel
                                    image={<img src='https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50?s=200' alt='Juan Alvarez' />}
                                    label={'Juan Alvarez'}
                                    align={'left'}
                                    link={"#"}
                                    statusIcon={<StatusOk />}
                                />
                            </CardWithImage>
                        </div>
                    </div>

                    <SectionTitle>Promotion</SectionTitle>

                    <div className="row">
                        <div className="col-xs-6 col-sm-6 col-lg-3">
                            <CardWithImage
                                image={avocadoImage}
                                title={"Hass Avocado"}
                                subTitle={"$13.44 US"}
                                unit={"kg"}
                                showLikeButton={true}
                                productId={'11111'}
                                onLike={() => {}}
                                promotionValue={'10'}
                            >
                                <ImageWithLabel
                                    image={<img src='https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50?s=200' alt='Juan Alvarez' />}
                                    label={'Juan Alvarez'}
                                    align={'left'}
                                    link={"#"}
                                    statusIcon={<StatusOk />}
                                />
                            </CardWithImage>
                        </div>
                        <div className="col-xs-6 col-sm-6 col-lg-3">
                            <CardWithImage
                                image={avocadoImage}
                                title={"Hass Avocado"}
                                subTitle={"$13.44 US"}
                                unit={"kg"}
                                showLikeButton={true}
                                productId={'22211'}
                                onLike={() => {}}
                                promotionValue={'20'}
                            >
                                <ImageWithLabel
                                    image={<img src='https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50?s=200' alt='Juan Alvarez' />}
                                    label={'Juan Alvarez'}
                                    align={'left'}
                                    link={"#"}
                                />
                            </CardWithImage>
                        </div>
                        <div className="col-xs-6 col-sm-6 col-lg-3">
                            <CardWithImage
                                image={avocadoImage}
                                title={"Hass Avocado"}
                                subTitle={"$13.44 US"}
                                unit={"kg"}
                                showLikeButton={true}
                                productId={'32211'}
                                onLike={() => {}}
                                promotionValue={'40'}
                            >
                                <ImageWithLabel
                                    image={<img src='https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50?s=200' alt='Juan Alvarez' />}
                                    label={'Juan Alvarez'}
                                    align={'left'}
                                    link={"#"}
                                />
                            </CardWithImage>
                        </div>
                        <div className="col-xs-6 col-sm-6 col-lg-3">
                            <CardWithImage
                                image={avocadoImage}
                                title={"Hass Avocado"}
                                subTitle={"$13.44 US"}
                                unit={"kg"}
                                showLikeButton={true}
                                productId={'44455'}
                                onLike={() => {}}
                                promotionValue={'5'}
                            >
                                <ImageWithLabel
                                    image={<img src='https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50?s=200' alt='Juan Alvarez' />}
                                    label={'Juan Alvarez'}
                                    align={'left'}
                                    link={"#"}
                                />
                            </CardWithImage>
                        </div>
                    </div>
                </div>
            </div>
            <RightFilterBar
                openFilterBar={openFilterBar}
                setOpenFilterBar={setOpenFilterBar}
            />
        </div>
    );
}

export default Marketplace;