import React, {useContext, useMemo} from 'react'
import MmSelect from '../../../../forms/MmSelect/MmSelect';
import { SecurityContext } from '../../../../../services/SecurityManager';
import './LanguageSelector.scss';
import useFormState from '../../../../../hooks/useFormState';
import { useTranslation } from 'react-i18next';
import { changeLanguage, enabledLanguages } from '../../../../../i18n/config';
import { ApiContext } from '../../../../../services/api/api-config';
import { useEffect } from 'react';
import { getNotifier } from '../../../../../services/notifier';

const LanguageSelector = () => {
    const {t} = useTranslation();
    const api = useContext(ApiContext);
    const {me} = useContext(SecurityContext);
    const { form, setForm } = useFormState();
    
    const translatedEnabledLanguages = useMemo(() => enabledLanguages.map(l => ({
        label: t(l.label),
        value: l.value
    })), [t]);

    const onChangue = (language) => {
        const locale = language.value;
        changeLanguage(language.value);
        setForm({locale: translatedEnabledLanguages.find(l => l.value === language.value)||''});
        api.me.locale({ params:{locale}, method:'PUT' })
            .then(()=>changeLanguage(locale))
            .catch(e=>getNotifier().error(e.detail));
    }

    useEffect(() => {
        const selectedLanguage = translatedEnabledLanguages.find(l => l.value === me.locale)||'';
        setForm({locale: selectedLanguage});
    }, [me, setForm, translatedEnabledLanguages]);

    return (
        <div className='LanguageSelector'>
            <MmSelect
                placeholder={t('Language')}
                name='language'
                label='Language'
                options={translatedEnabledLanguages}
                onChange={onChangue}
                value={form.locale}
                addEmptyOption={false}
            />
        </div>
    );
}

export default LanguageSelector;