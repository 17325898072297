import React, { useEffect, useContext } from 'react'
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Card from '../../../components/Dashboard/Card/Card';
import { SectionTitle } from '../../../components/Dashboard/SectionTitle/SectionTitle';
import { SideMenu } from '../../../components/Dashboard/SideMenu/SideMenu';
import TopBar from '../../../components/Dashboard/TopBar/TopBar';
import TextInput from '../../../components/forms/TextInput/TextInput';
import Button from '../../../components/molecules/Button/Button';
import useFormState from '../../../hooks/useFormState';
import { ApiContext } from '../../../services/api/api-config';
import { activeIngredientsTableSGruops, prepareActiveIngredientForServer } from '../../../services/models/activeIngredientsUtils';
import { getNotifier } from '../../../services/notifier';
import { paths } from '../../../services/routes/appRoutes';
import './ActiveIngredientsForm.scss';

const ActiveIngredientsForm = () => {
    const {t} = useTranslation();
    const { id } = useParams();
    const api = useContext(ApiContext);
    const navigate = useNavigate();
    const { form, setForm, bindInput } = useFormState({});
    const loadingId = `ActiveIngredients.form.${id}`;

    // -- load data
    useEffect(() => {
        // load activeIngredient data
        if (id) {
            api.activeIngredients.get({id, params: {sGroups: activeIngredientsTableSGruops}})
                .then(async(activeIngredient) => {
                    setForm( activeIngredient );
                })
                .catch(error => getNotifier().error(error.detail||error.message));
        }
    }, [api, id, setForm, t]);

    const handleCreateOrUpdateActiveIngredient = useCallback(() => {
        const {id} = form;
        const method = id ? 'update' : 'create';
        let activeIngredient = null;

        try {
            activeIngredient = prepareActiveIngredientForServer(form, t);
        } catch (error) {
            return getNotifier().error(error.message||error.detail);            
        }
        
        api.activeIngredients[method]({ id, params: activeIngredient })
            .then(activeIngredient => {
                getNotifier().success( id ? t('Active-ingredient-updated-successfully') : t('Active-ingredient-created-successfully'));
                navigate(paths.mm.activeIngredients);
            })
            .catch(error => getNotifier().error(error.message||error.detail));
    }, [api, form, navigate, t]);

    const loading = useSelector(s=>!!s.loadingIds[loadingId]);

    return (
        <div className="ActiveIngredientsForm dashboard-container">
            <SideMenu />
            <div className='right-content'>
                <TopBar />
                <div className='main-content'>
                    <SectionTitle>{ !id ? t('Add-new-active-ingredient') : t('Edit-active-ingredient')}</SectionTitle>

                    <div className='row'>
                        <Card>
                            <div className='row mt-3'>
                                <div className='col-sm-12 margin-top-mobile'>
                                    <TextInput
                                        label={t('Name')}
                                        placeholder={t('Name')}
                                        {...bindInput('name')}
                                    />
                                </div>
                            </div>
                            
                            <div className='row d-flex justify-content-center align-items-center buttons-container'>
                                <Button
                                    className={'btn-primary btn-outline'}
                                    onClick={() => navigate(paths.mm.brands) }
                                    design={'clear'}
                                >
                                    {t('Cancel')}
                                </Button>

                                <Button 
                                    className={`btn-primary`}
                                    onClick={handleCreateOrUpdateActiveIngredient}
                                    disabled={loading}
                                >
                                    { id ? t('Update') : t('Create') }
                                </Button>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ActiveIngredientsForm;