import React from 'react'
import Select from "react-select";
import CreatableSelect from 'react-select/creatable';
import classNames from "classnames";
import { useTranslation } from 'react-i18next';
import './MmSelect.scss';

const MmSelect = ({ 
        value, onChange, options=[], creatable=false, placeholder, className, inputProps={}, addEmptyOption=true}) => {
    const {t} = useTranslation();

    const selectOptions = addEmptyOption ? [{label: t('Select-any-option'), value: ''}, ...options] : options;
    const SelectElement = creatable ? CreatableSelect : Select;
    
    return <SelectElement
                className={classNames(className, 'MmSelect', 'react-select')}
                classNamePrefix='react-select'
                isClearable
                onChange={onChange}
                options={selectOptions}
                placeholder={placeholder}
                value={value}
                {...inputProps}
            />;
}

export default MmSelect;