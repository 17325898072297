import React from 'react'
import Card from '../Card/Card';
import dummyImage from '../../../assets/images/dummy/card-image.png';
import Typography from '../Typography/Typography';
import {useTranslation} from "react-i18next";

import './TransactionCard.scss';

export const TransactionCard = ({credits=0, qtyResult, backgroundColor='dark-green'}) => {
    const backgroundColorClass = backgroundColor ? `bg-${backgroundColor}` : 'bg-white';
    const {t} = useTranslation();

    return (
        <div className="TransactionCard">
            <Card sx={{height: '100%', minHeight: '161px'}} className={`${backgroundColorClass}`}>
                <div className='TransactionCard__container'>
                    <div className='ResultsCard__left-content'>
                        <img src={dummyImage} alt="" className='img-fluid' />
                    </div>
                    <div className='TransactionCard__right-content'>
                        <div className='flex-base-container'>
                            <Typography tag={"div"} className={'TransactionCard__credits'}>
                                {credits}
                            </Typography>
                            <Typography tag={"div"} className={'TransactionCard__credits-label'}>
                                {t('credits')}
                            </Typography>
                        </div>
                        <div className='flex-base-container flex-base-container--left'>
                            {t('available')}
                        </div>
                    </div>
                </div>
                <div className='row TransactionCard__container--mt12'>
                    <div className='col-md-6 TransactionCard__left-content TransactionCard__left-content--half'>
                        <Typography tag={"div"} className={'TransactionCard__main-title'}>
                        {t('Last transaction')}
                        </Typography>
                        <Typography tag={"h2"} className={'TransactionCard__sub-title-mini'}>
                            12/07/2022
                        </Typography>
                    </div>
                    <div className='col-md-6 TransactionCard__right-content TransactionCard__left-content--half'>
                        <Typography tag={"div"} className={'TransactionCard__main-title'}>
                            {t('My-account')}
                        </Typography>
                        <Typography tag={"div"} className={'TransactionCard__sub-title'}>
                            *******1234
                        </Typography>
                    </div>
                </div>
            </Card>
        </div>
    );
}
