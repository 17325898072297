import classNames from 'classnames';
import React, { useContext, useId } from 'react'
import { useTranslation } from 'react-i18next';
import { getNotifier } from '../../../services/notifier';
import UploadAvatarButton from './UploadAvatarButton/UploadAvatarButton';
import './AvatarUpload.scss';
import { ApiContext } from '../../../services/api/api-config';

const AvatarUpload = ({ value, onChange, btnLabel, maxFileSizeInMB=null, fileTypes=[] }) => {
    const id = useId(); 
    const {t} = useTranslation();
    const api = useContext(ApiContext);
    const bytesToMegaBytes = bytes => bytes / (1024 ** 2);
    
    const handleChange = e => {
        if (e.target.files.length) {
            if(maxFileSizeInMB){
                const sizeInMB = bytesToMegaBytes(e.target?.files[0]?.size);
                if(maxFileSizeInMB<sizeInMB)
                    return getNotifier().error(`${t('MaxFileSizeExceed')} ${maxFileSizeInMB}MB`);
            }

            onChange(e.target?.files[0]);
        }
    };

    const getStyles = () => {
        let imageStyles = {
            backgroundImage: '',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            borderRadius: '8px',
        };
        
        if(value?.id) {
            imageStyles.backgroundImage = `url(${api.appFiles.getUrl(value)})`;

        } else if(value?.name && !value?.id) {
            imageStyles.backgroundImage = `url(${URL.createObjectURL(value)})`;
        }

        return imageStyles;
    }

    return (
        <>
        <div className={classNames('UploadFile')}>
            <div className='UploadFile__control'>
                <label htmlFor={id} role='button' style={getStyles()}>
                    <UploadAvatarButton btnLabel={btnLabel} />
                </label>
                <input
                    id={id}
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleChange}
                    multiple={false}
                    accept={fileTypes.map(f => `.${f.toLowerCase()}`).join(', ')}
                />
            </div>
        </div>
        {fileTypes &&
            <div className="file-label">
                {maxFileSizeInMB && <div>{t('Max-size')}: {maxFileSizeInMB}MB</div>}                        
                <div>Types: <span>{fileTypes.map(f => `.${f.toLowerCase()}`).join(', ')}</span></div>
            </div>}
        </>
    );
}

export default AvatarUpload;