import React from 'react'
import uploadButton from "../../../../assets/images/buttons/upload-btn-clean.png";

import './UploadButton.scss';

const UploadButton = ({legend, btnLabel}) => {
    return (
        <div className='UploadButton'>
            <img src={uploadButton} alt="upload button" width="120" height="120" />
            {legend}
            <div className='UploadButton__text'>
                {btnLabel}
            </div>
        </div>
    );
}

export default UploadButton;