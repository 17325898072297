import {
    copyPropertyOrNull,
    copyPropertyOrThrow, copyPropertyValueOrThrow
} from "../formUtils";
import { foliarApplicationDetailToFormData, prepareFoliarApplicationDetailToServer } from "./foliarApplicationDetailUtils";

export const getEmptyFoliarApplication = ({productor, orchard}) => {
    return {
        date: new Date(),
        dose: null,
        reason: null,
        unit: null,
        sectors: [],
        productor: {label: `${productor?.businessName||''} - ${productor?.contactEmail||''}`, value: productor?.id},
        orchard: {label: orchard?.name, value: orchard?.id},
        foliarApplicationDetails: [
            {id: null, product: null, dose: null, unit: null}
        ]
    }
};

export const applicationUnits = [
    'ml',
    'l',
    'kg',
    'g',
    'mg',
    'oz',
    'lb'
];

export const applicationUnitOptions = applicationUnits.map(applicationUnit => { 
    return { 
        value: applicationUnit, label: applicationUnit 
    } 
});

export const prepareFoliarApplicationToServer = (form, sectors, t) => {
    const foliarApplication = {};

    copyPropertyValueOrThrow(form, foliarApplication, 'productor', t('Please-select-a-productor'));
    copyPropertyValueOrThrow(form, foliarApplication, 'orchard', t('Please-select-an-orchard'));
    copyPropertyOrThrow(form, foliarApplication, 'date', t('Please-select-a-date'));
    copyPropertyOrNull(form, foliarApplication, 'otherComments');

    if(!foliarApplication.otherComments) foliarApplication.otherComments = '';

    // copy sectors
    let _sectors = [];
    sectors.forEach(sector => {
        if(form[`sectors.${sector.id}`]) {
            _sectors.push(sector.id);
        }
    });

    foliarApplication.foliarApplicationDetails = form.foliarApplicationDetails.map(foliarApplicationDetail => {
        const detail = prepareFoliarApplicationDetailToServer(foliarApplicationDetail, t);

        return detail;
    });

    //if(sectors.length === 0) {
    //    throw new Error(t('Please-select-at-least-one-sector'));
    //}

    foliarApplication.sectors = _sectors;

    return foliarApplication;
}

export const foliarApplicationsTableSGroups = [
    'foliar_application_read',
        'active_ingredient_read',
        'orchard_read_id',
        'orchard_read_name',
        'read_created_by',
            'user_read_id',
            'user_read_first_name',
            'user_read_last_name',
            'user_read_email',
        'orchard_read_company',
            'company_read_id',
            'company_read_business_name',
            'company_read_contact_email',
            'company_read_orchard',
        'sector_read'
];

export const foliarApplicationsDetailSGroups = [
    'foliar_application_read',
        'active_ingredient_read',
        'orchard_read_id',
        'orchard_read_name',
        'orchard_read_company',
            'company_read_id',
            'company_read_business_name',
            'company_read_contact_email',
        'sector_read',
        'foliar_application_detail_read_id',
        'foliar_application_detail_read_dose',
        'foliar_application_detail_read_unit',
        'foliar_application_detail_read_active_ingredient',
        'foliar_application_detail_read_reason',
];

export const foliarApplicationToFormData = (foliarApplication) => {
    const formData = {
        id: foliarApplication.id,
        date: foliarApplication.date,
        dose: foliarApplication.dose,
        reason: foliarApplication.reason,
        unit: { value: foliarApplication.unit, label: foliarApplication.unit },
        otherComments: foliarApplication.otherComments,
        productor: {label: `${foliarApplication.orchard?.company?.businessName} - ${foliarApplication.orchard?.company?.contactEmail}`, value: foliarApplication.company?.id},
        orchard: {label: foliarApplication.orchard?.name, value: foliarApplication.orchard?.id},
        activeIngredient: {label: foliarApplication.activeIngredient?.name, value: foliarApplication.activeIngredient?.id},
        sectors: foliarApplication.sectors,
        foliarApplicationDetails: foliarApplication.foliarApplicationDetails.map(foliarApplicationDetail => foliarApplicationDetailToFormData(foliarApplicationDetail))
    };

    foliarApplication.sectors.forEach(sector => {
        formData[`sectors.${sector.id}`] = true;
    });

    return formData;
}