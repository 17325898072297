import React from 'react'
import {useTranslation} from "react-i18next";
import {ReactComponent as InfoIcon} from "../../../../../../../assets/images/icons/info.svg";
import Button from '../../../../../../../components/molecules/Button/Button';

import './EmptyProductsMessage.scss';

const EmptyProductsMessage = ({ onClick }) => {
    const {t} = useTranslation();

    return (
        <div className='EmptyProductsMessage'>
            <InfoIcon />

            <div className='EmptyProductsMessage__text'>
                {t('You-need-to-Add-a-new-Sector-to-add-products')}
            </div>

            <Button
                onClick={onClick}
                className='mt-3'
            >
                {t('Add-myfirst-Sector')}
            </Button>
        </div>
    );
}

export default EmptyProductsMessage;