import React from 'react'
import {ReactComponent as PlusIcon} from "../../../assets/images/icons/plus.svg";
import './FilterButton.scss';

const FilterButton = ({label = 'Filter', onClick}) => {
    return (
        <button className='FilterButton' onClick={onClick}>
            <PlusIcon /> {label}
        </button>
    );
}

export default FilterButton;