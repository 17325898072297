import { copyPropertyOrThrow } from "../formUtils";

export const activeIngredientsTableSGruops = [
    'active_ingredient_read',
    'read_created_by',
    'read_updated_by',
    'read_created_date',
    'read_updated_date',
];

export const prepareActiveIngredientForServer = (form, t) => {
    const activeIngredient = {};

    copyPropertyOrThrow(form, activeIngredient, 'name', t('Must-write-name'));

    return activeIngredient;
}