import React, { useContext } from 'react'
import { FileUploader } from "react-drag-drop-files";
import {useTranslation} from "react-i18next";
import {ReactComponent as UploadIcon} from "../../../assets/images/icons/upload.svg";
import { ApiContext } from '../../../services/api/api-config';
import { getNotifier } from '../../../services/notifier';
import './MultipleFileUpload.scss';
import FilePreview from '../../FilePreview/FilePreview';

const MultipleFileUpload = ({fileTypes = ["JPG", "PNG", "GIF"], maxFileSizeInMB, multiple=false, legend, preview=false, onChange, files}) => {
    const {t} = useTranslation();
    const api = useContext(ApiContext);
    
    const handleChange = (newFiles) => {
        if(multiple)
            onChange([...files, ...newFiles]);
        else 
            onChange(newFiles);
    };

    const arrayFiles = files ? Array.from(files).filter( f => f ) : null;

    const onSizeError = (e) => {
        return getNotifier().error(`${t('MaxFileSizeExceed')} ${maxFileSizeInMB}MB`);
    }

    const existsAnyFile = arrayFiles?.filter( f => f?.id ).length > 0;

    const handleDelete = () => {
        onChange(null);
    }

    return (
        <>
            {(!existsAnyFile || files) &&
                <div className='MultipleFileUpload'>
                    <FileUploader 
                        handleChange={handleChange} 
                        name="file" 
                        types={fileTypes} 
                        multiple={multiple}
                        className='MultipleFileUpload__uploader'
                        maxSize={maxFileSizeInMB}
                        onSizeError={onSizeError}
                        onTypeError={(e) => getNotifier().error(`${t('FileTypeError')} ${e.type}`)}
                    >
                        <div className="drop-content">
                            <UploadIcon />
                            {t('Drag-and-drop-files')}
                        </div>
                    </FileUploader>
                </div>}

            {preview && arrayFiles?.map((file, index) => {
                return (
                    <div key={index}>
                        {(file && !file?.id) && 
                            <FilePreview file={file} onDelete={handleDelete} />}
                        
                        {(file && file?.id) &&
                            <a className="MultipleFileUpload__download" 
                                href={api.appFiles.getUrl(file)}
                                download={file.name}
                                target="_blank"
                                rel="noreferrer"                                
                            >
                                {file.originalName}
                            </a>}
                    </div>
                )}
            )}
        </>
    );
}

export const PreviewImage = ({picture, index, onDelete}) => {
    return (
        <div className='MultipleFileUpload__container-preview'>
            <button onClick={() => onDelete(index)} className="MultipleFileUpload__delete">
                Delete
            </button>
            <div className='MultipleFileUpload__preview'>
                <img 
                    alt="Preview"
                    src={URL.createObjectURL(picture)}
                />    
            </div>            
        </div>
    );
}

export default MultipleFileUpload;