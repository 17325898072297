import classNames from 'classnames';
import React, {useState, useId} from 'react'
import { useTranslation } from 'react-i18next';
import { getNotifier } from '../../../services/notifier';
import UploadButton from './UploadButton/UploadButton';
import './UploadFile.scss';

const UploadFile = ({ onChange, showImage=true, label, btnLabel, maxFileSizeInMB=null, multiple=false, children, fileTypes=[], preview=false }) => {
    const id = useId(); 
    const {t} = useTranslation();
    const [images, setImages] = useState();
    const bytesToMegaBytes = bytes => bytes / (1024 ** 2);

    const handleChange = e => {
        if (e.target.files.length) {
            const currentImages = (images ? [...images] : []);
            let newImages = null;

            if(maxFileSizeInMB){
                const sizeInMB = bytesToMegaBytes(e.target?.files[0]?.size);
                if(maxFileSizeInMB<sizeInMB)
                    return getNotifier().error(`${t('MaxFileSizeExceed')} ${maxFileSizeInMB}MB`);
            }            

            if(multiple){
                newImages = [...currentImages, {
                    preview: URL.createObjectURL(e.target.files[0]),
                    raw: e.target.files[0],
                    name: e.target.files[0].name,
                    filename: e.target.files[0].name,
                    ...e.target?.files[0]
                }];
                setImages(newImages);
                onChange(newImages);
            } else {
                newImages = [{
                    preview: URL.createObjectURL(e.target.files[0]),
                    raw: e.target.files[0],
                    name: e.target.files[0].name,
                    filename: e.target.files[0].name,
                    ...e.target?.files[0]
                }];
                setImages(newImages);
                onChange(newImages);
            }
        }
    };

    return (
        <>
        <div className={classNames('UploadFile', !showImage&&'UploadFile--withContainer')}>
            <div className='UploadFile__control'>
                <label htmlFor={id} role='button'>
                    {children && <> { images?.map((image, index) => <div key={index}> {<label className="upload-file-label">{image.name}</label>} </div>)}</>}

                    {children ? children : ''}
                    
                    {!children && <>
                        <span className='UploadFile__title'>{label}</span>
                        
                        { (multiple || showImage) && <UploadButton btnLabel={btnLabel} />}
                        {!showImage && !images && <div className='btn-label'>{label}</div>}

                        { images?.map((image, index) => 
                                    <div key={index} className="file-label">
                                        <label>{image.name}</label>
                                    </div>)}
                    </>}
                </label>
                <input
                    id={id}
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleChange}
                    multiple={false}
                    accept={fileTypes.map(f => `.${f.toLowerCase()}`).join(', ')}
                />
            </div>
            
            {showImage && preview &&
                <div className="UploadFile__preview">
                    {images?.map((image, index) =>  
                        <img 
                            key={index} 
                            src={image.preview} 
                            alt={image.name} 
                            className="UploadFile__preview-image"
                        />)}
                </div>}
        </div>
        {fileTypes &&
            <div className="file-label">
                {maxFileSizeInMB && <div>{t('Max-size')}: {maxFileSizeInMB}MB</div>}                        
                <div>
                    {fileTypes.length>0 ? `${t('Types')}:` : '' } <span>{fileTypes.map(f => `.${f.toLowerCase()}`).join(', ')}</span>
                </div>
            </div>}
        </>
    );
}

export default UploadFile;