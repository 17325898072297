import React, { useContext } from 'react';
import logo from '../../../assets/images/logos/menu-logo.png';
import Indicator from './components/Indicator/Indicator';
import {SecurityContext} from "../../../services/SecurityManager";
import getAppRoutes from "../../../services/routes/appRoutes";
import {Link, useLocation} from 'react-router-dom';
import './SideMenu.scss';

export const SideMenu = () => {
    const security = useContext(SecurityContext);
    const location = useLocation();
    const routes = getAppRoutes(security);

    const isActiveRoute = (path) => {
        if (!path) return '';
        const pathToRegex = path.replace(/\/:[^/]*/g, "[^/]*") + "($|/)";
        const regex = new RegExp(pathToRegex);
        return location.pathname.match(regex) ? 'active' : '';
    };

    const helpRoute = routes.find(route => route.name === 'Help');

    return (
        <div className='SideMenu'>
            <nav className='sidebar'>
                <div className='title'>
                    <img src={logo} alt='logo' className='logo' />
                </div>

                <ul className='list-menu-items list-menu-items--margin-top'>
                    {routes.filter(r => !r.hidden).map((item, index) => 
                        <li className={isActiveRoute(item.path) ? 'menu-item menu-item--active' : 'menu-item'} key={index} id={`side-bar--${item.name}`}>
                            <a href={item.path} key={index} className="menu-item-row">
                                <span>
                                    <div className='icon-container'>
                                        {isActiveRoute(item.path) ? item.activeIcon : item.icon}
                                    </div>
                                    <span className='menu-item-text'>{item.text}</span>
                                </span>

                                {item.alerts>0 && <Indicator value={item.alerts} />}
                            </a>
                        </li>)}
                </ul>

                {helpRoute &&
                    <div className={isActiveRoute(helpRoute.path) ? 'get-help get-help--active' : 'get-help'}>
                        <Link to={helpRoute.path}>
                            <div className='icon-container'>
                                {helpRoute.icon}
                            </div>
                            <span className='menu-item-text'>{helpRoute.text}</span>
                        </Link>
                    </div>}
            </nav>
        </div>
    );
}
