import classNames from 'classnames';
import React from 'react'

import './SectionSubTitle.scss';

const SectionSubTitle = ({children, className, ...props}) => {
    return (
        <div className={classNames('SectionSubTitle', className)} {...props}>{children}</div>
    );
}

export default SectionSubTitle;
