import React, { useState, useContext, useEffect, useCallback } from 'react'
import { SectionTitle } from '../../../../../components/Dashboard/SectionTitle/SectionTitle';
import {useTranslation} from "react-i18next";
import Switch from '../../../../../components/forms/Switch/Switch';
import Button from '../../../../../components/molecules/Button/Button';
import TextInput from '../../../../../components/forms/TextInput/TextInput';
import Textarea from '../../../../../components/forms/Textarea/Textarea';
import {ReactComponent as PlusIcon} from "../../../../../assets/images/icons/plus.svg";
import {ReactComponent as TrashIcon} from "../../../../../assets/images/icons/trash-2.svg";
import { useNavigate, useParams } from 'react-router-dom';
import {dependantsOptions, getMaritalStatusOptions, prepareUserFilesForServer, prepareUserFromNewGrower} from "../../../../../services/models/userUtils";
import SectionSubTitle from '../../../../../components/Dashboard/SectionSubTitle/SectionSubTitle';
import FileUploader from '../../../../../components/forms/FileUploader/FileUploader';
import SearchBarDateFilter from '../../../../../components/SearchBar/filters/SearchBarDateFilter/SearchBarDateFilter';
import { ApiContext } from '../../../../../services/api/api-config';
import GrowerChangeStatusButtons from '../GrowerChangeStatusButtons/GrowerChangeStatusButtons';
import { companyDetailSGroups, companyToFormData, prepareGrowerForServerStep2 } from '../../../../../services/models/companyUtils';
import { getNotifier } from '../../../../../services/notifier';
import { prepareOrchardFilesForServer } from '../../../../../services/models/orchardUtils';
import useFormState from '../../../../../hooks/useFormState';
import {ReactComponent as NextIcon} from "../../../../../assets/images/icons/arrow-details.svg";
import './NewGrowerFormStep2.scss';
import { paths } from '../../../../../services/routes/appRoutes';
import SelectWithLabel from '../../../../../components/forms/SelectWithLabel/SelectWithLabel';
import { useSelector } from 'react-redux';

const avatarFileTypes = ["JPG", "JPEG", "PNG"];
const avatarMaxFileSizeInMB = 5;

const NewGrowerFormStep2 = ({gotoStep3, growerOriginal}) => {
    const {t} = useTranslation();
    const api = useContext(ApiContext);
    const { id, step } = useParams();
    const navigate = useNavigate();
    const [dependants, setDependants] = useState([]);
    const [selectedDependant, setSelectedDependant] = useState();
    const { form, bindSimple, bindInput, handleSimpleChange, setForm } = useFormState({});
    const [grower, setGrower] = useState();
    const loadingId = `Grower.form.step2.${id}`;

    const isEditingMode = id !== undefined && id !== null && !step;

    const loadGrower = useCallback(() => {
        const growerId = isEditingMode||step ? id : growerOriginal?.id;

        if(!growerId || growerId=== undefined) return;

        api.companies.get({ params:{id: growerId, sGroups: companyDetailSGroups} })
            .then(grower => {
                setGrower(grower[0]);
                setForm({...companyToFormData(grower[0], t)});
            }).catch(err => getNotifier().error(err.message));
    }, [api, step, isEditingMode, id, growerOriginal, setForm, t]);
    
    // load data
    useEffect(() => {
        loadGrower();
    }, [loadGrower]);

    const handleRemoveDependant = (index) => {
        const newDependants = dependants.filter((_, i) => i !== index);
        setDependants(newDependants);
        setForm({...form, dependants: newDependants});
    }

    const handleAddDependant = () => {
        setDependants([...dependants, selectedDependant]);
        setForm({...form, dependants: [...dependants, selectedDependant]});
    }

    const handleCreateOrUpdateMainContactAndGrower = async () => {
        let user = null;
        let _grower = null;
        const mainContactId = grower?.mainContact?.id;
        const apiMethod = grower?.id ? 'update' : 'create';
        try{
            user = prepareUserFromNewGrower(form);
            _grower = prepareGrowerForServerStep2(form, t);
        } catch (e){
            console.log(e);
            return getNotifier().error(e.message||e.detail);
        }

        user.company = grower.id;

        const files = prepareUserFilesForServer({...form});
        const orchardFiles = prepareOrchardFilesForServer({...form});

        try {
            const userApiMethod = mainContactId ? 'update' : 'create';

            if(mainContactId)
                delete user.email;

            await api.users[userApiMethod]({id: mainContactId, params: user, files, useCommonPath: !!mainContactId, loadingId});

            // update grower
            await api.companies[apiMethod]({id: grower.id, params: _grower, files: orchardFiles, loadingId});

            if(isEditingMode) {
                getNotifier().success(t('Saved-changes'));
                loadGrower();
            } else {
                getNotifier().success(t('Grower-created-successfully'));
                gotoStep3(grower.id);
            }
        } catch (e) {
            return getNotifier().error(e.message||e.detail);
        }
    }

    const loading = useSelector(s=>!!s.loadingIds[loadingId]);

    return (
        <div className={"NewGrowerFormStep2"}>
            <div className='d-flex align-items-center justify-content-between'>
                <SectionTitle className='mt-0 mb-0'>{t('General-information-owner')}</SectionTitle>
                
                {isEditingMode &&
                    <GrowerChangeStatusButtons
                        grower={grower}
                        onUpdateStatus={loadGrower}
                    />}
            </div>

            <div className='row mt-3'>
                <div className='col-sm-12 col-lg-12 margin-top-mobile'>
                    <FileUploader 
                        fileTypes={avatarFileTypes}
                        key={form.mainContact?.avatar?.name}
                        btnLabel={t("Profile-image")}
                        maxFileSizeInMB={avatarMaxFileSizeInMB}
                        {...bindSimple('avatar')}
                    />
                </div>
            </div>
            
            <div className='row section-margin-top'>
                <div className='col-sm-6 col-lg-3 margin-top-mobile'>
                    <TextInput 
                        label={t('Name')}
                        placeholder={t('Name')}
                        {...bindInput('mainContactName')}
                    />
                </div>
                <div className='col-sm-6 col-lg-3 margin-top-mobile'>
                    <TextInput 
                        label={t('LastName')}
                        placeholder={t('LastName')}
                        {...bindInput('mainContactLastName')}
                    />
                </div>
                <div className='col-sm-6 col-lg-3 margin-top-mobile'>
                    <SelectWithLabel
                        label={t("Marital-status")}
                        inputProps={{
                            options: getMaritalStatusOptions(t),
                            ...bindSimple('maritalStatus'),
                        }}
                    />
                </div>
                <div className='col-sm-6 col-lg-3 margin-top-mobile'>
                    <TextInput 
                        label={t('Email')}
                        placeholder={'Email'}
                        {...bindInput('mainContactEmail')}
                    />
                </div>
            </div>

            <div className='row '>
                <div className='col-sm-6 col-lg-3 margin-top-mobile'>
                    <SearchBarDateFilter
                        label={t('Birthday-date')}
                        withIcon={true}
                        className={'no-margin-top'}
                        {...bindSimple('birthday')}
                    />
                </div>
            </div>
                        
            <SectionSubTitle>{t('Socio-economic-information')}</SectionSubTitle>

            <div className='row mt-3'>
                <div className='col-sm-2 margin-top-mobile'>
                    {t('Own-family')}
                </div>
                <div className='col-sm-2 margin-top-mobile'>
                    <Switch 
                        checked={form.hasFamily||false}
                        name='hasFamily'
                        id='hasFamily'
                        onChange={handleSimpleChange('hasFamily')}
                        value={form.hasFamily || ''}
                    />        
                </div>
            </div>
            <div className='row mt-3 section-margin-bottom'>
                <div className='col-sm-2 margin-top-mobile'>
                    {t('Own-vehicle')}
                </div>
                <div className='col-sm-2 margin-top-mobile'>
                    <Switch 
                        checked={form.hasVehicle||false}
                        name='hasVehicle'
                        id='hasVehicle'
                        onChange={handleSimpleChange('hasVehicle')}
                        value={form.hasVehicle || ''}
                    />        
                </div>
            </div>

            {dependants.map((dependant, index) =>
                <div className='row' key={index}>
                    <div className='col-sm-6 col-lg-4 margin-top-mobile'>
                        <SelectWithLabel
                            label={t('Dependant')}
                            key={index}                               
                            inputProps={{
                                options: dependantsOptions,
                                value: dependant,
                                onChange: setSelectedDependant
                            }}
                            className='mt-2'
                        />
                    </div>
                    <div className='col-sm-6 col-lg-4 margin-top-mobile d-flex align-items-center justify-items-center'>
                        <Button 
                            design={'clear'}
                            className={'btn-no-border'} onClick={() => handleRemoveDependant(index)}
                        >
                            <TrashIcon /> {t('Delete')}
                        </Button>
                    </div>
                </div>)}

            <div className='row mt-3'>
                <div className='col-sm-6 col-lg-4 margin-top-mobile'>
                    <SelectWithLabel
                        label={t('Dependant')}
                        inputProps={{
                            options: dependantsOptions,
                            onChange: setSelectedDependant
                        }}
                    />
                </div>
                <div className='col-sm-6 col-lg-4 margin-top-mobile'>
                    <Button 
                        design={'clear'}
                        className={'btn-no-border'}
                        onClick={handleAddDependant}
                    >
                        <PlusIcon /> {t('Add-more')}
                    </Button>
                </div>
            </div>

            <div className='row section-margin-top'>
                <div className='col-md-8 margin-top-mobile'>
                    <TextInput
                        label={t('Other-activities')}
                        placeholder={t('Add-hobbies-extra-activities-etc')}
                        {...bindInput('otherActivities')}
                    />
                </div>
            </div>

            <SectionSubTitle>{t('Use-of-technology')}</SectionSubTitle>

            <div className='row section-margin-bottom mt-3'>
                <div className='col-sm-3 margin-top-mobile'>
                    {t('Has-computer')}
                </div>
                <div className='col-sm-3 margin-top-mobile'>
                    <Switch 
                        checked={form.hasComputer||false}
                        name='hasComputer'
                        id='hasComputer'
                        onChange={handleSimpleChange('hasComputer')}
                        value={form.hasComputer || ''}
                    />        
                </div>
            </div>
            <div className='row section-margin-bottom'>
                <div className='col-sm-3 margin-top-mobile'>
                    {t('Has-email')}
                </div>
                <div className='col-sm-3 margin-top-mobile'>
                    <Switch 
                        checked={form.hasEmail||false}
                        name='hasEmail'
                        id='hasEmail'
                        onChange={handleSimpleChange('hasEmail')}
                        value={form.hasEmail || ''}
                    />        
                </div>
            </div>
            <div className='row section-margin-bottom'>
                <div className='col-sm-3 margin-top-mobile'>
                    {t('Has-internet')}
                </div>
                <div className='col-sm-3 margin-top-mobile'>
                    <Switch 
                        checked={form.hasInternet||false}
                        name='hasInternet'
                        id='hasInternet'
                        onChange={handleSimpleChange('hasInternet')}
                        value={form.hasInternet || ''}
                    />        
                </div>
            </div>
            <div className='row section-margin-bottom'>
                <div className='col-sm-3 margin-top-mobile'>
                    {t('Has-smartphone')}
                </div>
                <div className='col-sm-3 margin-top-mobile'>
                    <Switch 
                        checked={form.hasSmartphone||false}
                        name='hasSmartphone'
                        id='hasSmartphone'
                        onChange={handleSimpleChange('hasSmartphone')}
                        value={form.hasSmartphone || ''}
                    />        
                </div>
            </div>
            <div className='row section-margin-bottom'>
                <div className='col-sm-3 margin-top-mobile'>
                    {t('Has-data-plan')}
                </div>
                <div className='col-sm-3 margin-top-mobile'>
                    <Switch 
                        checked={form.hasDataPlan||false}
                        name='hasDataPlan'
                        id='hasDataPlan'
                        onChange={handleSimpleChange('hasDataPlan')}
                        value={form.hasDataPlan || ''}
                    />        
                </div>
            </div>
            <div className='row section-margin-bottom'>
                <div className='col-sm-3 margin-top-mobile'>
                    {t('Use-whatsapp')}
                </div>
                <div className='col-sm-3 margin-top-mobile'>
                    <Switch 
                        checked={form.useWhatsapp||false}
                        name='useWhatsapp'
                        id='hasDauseWhatsapptaPlan'
                        onChange={handleSimpleChange('useWhatsapp')}
                        value={form.useWhatsapp || ''}
                    />        
                </div>
            </div>

            <div className='row mt-5'>
                <div className='col-md-8 margin-top-mobile'>
                    <Textarea
                        label={t('Other-comments')}
                        placeholder={t('Add-other-comments-here')}
                        {...bindInput('otherComments')}
                    />
                </div>
            </div>

            <div className='row NextStepButtons'>
                { isEditingMode ?
                    <Button
                        className={'btn-primary btn-outline NextStepButtons__button'}
                        onClick={() => navigate(paths.mm.growers) }
                        design={'clear'}
                    >
                        {t('Cancel-changes')}
                    </Button>
                    :
                    <Button
                        className={'btn-primary btn-outline NextStepButtons__button'}
                        onClick={() => navigate(paths.mm.growersEdit.replace(':id', grower?.id)) }
                        design={'clear'}
                    >
                        {t('Back')}
                    </Button>}


                <Button 
                    className={`btn-primary btnNextStep NextStepButtons__button NextStepButtons__button--0`}
                    onClick={handleCreateOrUpdateMainContactAndGrower}
                    disabled={loading}
                >
                    { isEditingMode ? t('Save') : t('Save-and-continue') }
                    { !isEditingMode && <NextIcon /> }
                </Button>
            </div>
        </div>
    );
}

export default NewGrowerFormStep2;