import moment from "moment";
import fieldTypes from "../../components/FormModal/fields/fieldTypes";
import {
    copyBoolean,
    copyPropertyOrNull,
} from "../formUtils";
import { getFileFromForm } from "./companyUtils";

export const certificationTableSGroups = [
    'certification_read',
    'certification_read_id',
    'app_file_read',
    'certification_read_active',
    'read_created_by',
    'user_read',
    'read_updated_by',
    'read_created_date',
    'read_updated_date',
];

export const certificationDetailSGroups = [
    'certification_read',
    'certification_read_id',
    'app_file_read',
    'certification_read_active',
    'read_created_by',
    'user_read',
    'read_updated_by',
    'read_created_date',
    'read_updated_date',
];

export const certificationTableColumns = [
    {
        Header: 'id',
        accessor: 'id'
    },
     {
        Header: 'file',
        accessor: 'file?.id'
    },
     {
        Header: 'validity',
        accessor: 'validity'
    },
     {
        Header: 'links',
        accessor: 'links'
    },
     {
        Header: 'active',
        accessor: 'active'
    },
     {
        Header: 'createdBy',
        accessor: 'createdBy?.id'
    },
     {
        Header: 'updatedBy',
        accessor: 'updatedBy?.id'
    },
     {
        Header: 'createdDate',
        accessor: 'createdDate'
    },
     {
        Header: 'updatedDate',
        accessor: 'updatedDate'
    },
 ];

export const certificationDataDetailsArray = (certification) => [
    {data: certification?.id, dataLabel: 'id'},
    {data: certification?.file?.id, dataLabel: 'file'},
    {data: certification?.validity, dataLabel: 'validity'},
    {data: certification?.links, dataLabel: 'links'},
    {data: certification?.active, dataLabel: 'active'},
    {data: certification?.createdBy?.id, dataLabel: 'createdBy'},
    {data: certification?.updatedBy?.id, dataLabel: 'updatedBy'},
    {data: certification?.createdDate, dataLabel: 'createdDate'},
    {data: certification?.updatedDate, dataLabel: 'updatedDate'},
];


export const newCertificationFormDefinition = [
    {
        name: 'file',
        label: 'file',
        required: false,
        type: fieldTypes.entity,
        entity: 'appFiles'
    },

    {
        name: 'validity',
        label: 'validity',
        required: false,
        type: fieldTypes.date
    },

    {
        name: 'links',
        label: 'links',
        required: false,
        type: fieldTypes.text
    },

    {
        name: 'active',
        label: 'active',
        required: false,
        type: fieldTypes.boolean
    },

];

export const editCertificationFormDefinition = [
    ...newCertificationFormDefinition
];

export const prepareNewCertificationForServer = (form) => {
    const certification= {};

    if(typeof form === 'undefined' || !form)
        return certification;

    if(form.name)
        copyPropertyOrNull(form, certification, 'name');

    copyPropertyOrNull(form, certification, 'validity');
    copyPropertyOrNull(form, certification, 'links');
    copyBoolean(form, certification, 'active');
    certification.id = form.id;

    if(certification.validity)
        certification.validity = moment (certification.validity).format( "YYYY-MM-DD" );

    certification.id = form.id;

    return certification;
};

export const prepareEditedCertificationForServer = (form) => {
    return prepareNewCertificationForServer(form);
};

export const certificationToFormData = (certification) => {
    return {
        ...certification,
        validity: certification?.validity ? moment(certification?.validity).format("YYYY-MM-DD") : null,
    }
}

export const getEmptyCertification = () => {
    return {
        id: null,
        file: null,
        validity: null,
        links: '',
        active: false,
        name: null
    }
}

export const prepareCertificationForServer = (form) => {
    const certification= {};

    if(typeof form === 'undefined' || !form)
        return certification;

    copyPropertyOrNull(form, certification, 'validity');
    copyPropertyOrNull(form, certification, 'links');
    copyBoolean(form, certification, 'active');

    if(certification.validity)
        certification.validity = moment (certification.validity).format( "YYYY-MM-DD" );

    return certification;
};

export const prepareCertificationFilesForServer = (form) => {
    const files = {
        usaOrganicCertification: getFileFromForm(form.usaOrganicCertification, 'file'),
        mexOrganicCertification: getFileFromForm(form.mexOrganicCertification, 'file'),
        euroOrganicCertification: getFileFromForm(form.euroOrganicCertification, 'file')
    }

    form.otherCertifications?.map((certification, index) => {
        const id = certification.id||index;
        return files[id] = getFileFromForm(certification, 'file');
    });

    Object.keys(files).forEach(key => {
        if (files[key] === undefined || files[key] === null) {
          delete files[key];
        }
    });

    return files;
}