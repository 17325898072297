import classNames from 'classnames';
import React from 'react'
import Card from '../../../../../components/Dashboard/Card/Card';

import './GrowerHorizontalMenu.scss';

const GrowerHorizontalMenu = ({children}) => {
    return (
        <Card className='GrowerHorizontalMenu'>
            {children}
        </Card>
    );
}

export const MenuElement = ({children, onClick, isActive}) => {
    return (
        <div className={classNames('MenuElement', isActive?'MenuElement--active':'')}
            onClick={onClick}
        >
            {children}
        </div>
    );
}

export default GrowerHorizontalMenu;
