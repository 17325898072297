import React from 'react'

import './SectorOrchard.scss';

const SectorOrchard = ({children}) => {
    return (
        <span className="SectorOrchard">
            {children}
        </span>
    );
}

export default SectorOrchard;
