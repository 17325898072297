import React from 'react'
import { useTranslation } from 'react-i18next';
import MiniTitle from '../../../../../components/Dashboard/MiniTitle/MiniTitle';
import { moneyFormatter } from '../../../../../services/numberUtils';

import './SummaryByProductor.scss';

const SummaryByProductor = ({productOffer}) => {
    const {t} = useTranslation();

    let total = productOffer.productors?.reduce(function(acc, p) { 
        return acc + (Number(p?.pricePerBox) * Number(p?.boxes)); 
    }, 0);

    return (
        <div className='SummaryByProductor mt-3'>
            {productOffer?.productors?.map((productor, index) => 
                <div key={index}>
                    <MiniTitle>Productor {index+1}</MiniTitle>
                    <MiniTitle design='small' className="label-productor">{t('Productor')}</MiniTitle>
                    
                    <MiniTitle design='label'>{productor?.sellerCompany?.label}</MiniTitle>
                    
                    <div className='row mt-3'>
                        <div className='col-md-6'>
                            {t('No. boxes')}
                            
                            <MiniTitle design='label'>{productor?.boxes}</MiniTitle>
                        </div>
                        <div className='col-md-6'>
                            {t('Price')}

                            <MiniTitle design='label'>$ { moneyFormatter( productor?.pricePerBox )}</MiniTitle>
                        </div>
                    </div>                    
                    <div className='SummaryByProductor__subtotal'>
                        <div className='SummaryByProductor__subtotal__label'>Subtotal</div>
                        <div className='SummaryByProductor__subtotal__value'>$ { isNaN(productor?.pricePerBox * productor?.boxes) ? 0 : moneyFormatter(productor?.pricePerBox * productor?.boxes) }</div>
                    </div>
                    <hr/>
                </div>)}

                <div className='SummaryByProductor__total'>
                    <div className='SummaryByProductor__subtotal__label'>Total</div>
                    <div className='SummaryByProductor__subtotal__value'>$ { isNaN(total) ? 0 : moneyFormatter( Number(total) ) }</div>
                </div>
        </div>
    );
}

export default SummaryByProductor;