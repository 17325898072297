import React, { useContext, useState, useEffect } from 'react';
import { useMemo } from 'react';
import {useTranslation} from "react-i18next";
import MiniTitle from '../../../../../components/Dashboard/MiniTitle/MiniTitle';
import SelectWithLabel from '../../../../../components/forms/SelectWithLabel/SelectWithLabel';
import Textarea from '../../../../../components/forms/Textarea/Textarea';
import TextInput from '../../../../../components/forms/TextInput/TextInput';
import { ApiContext } from '../../../../../services/api/api-config';
import { handlingTypesOptions } from '../../../../../services/models/companyProductUtils';
import { variationAttributesDetailSGroups } from '../../../../../services/models/variationAttributeUtils';
import { getNotifier } from '../../../../../services/notifier';

import './ProductMainInfo.scss';

const ProductMainInfo = ({orderProduct, handleInputChange, handleChange, brands}) => {
    const {t} = useTranslation();
    const api = useContext(ApiContext);
    const [variants, setVariants] = useState([]);

    const requestVariationAttributesParams = useMemo(() => {
        return {
            sGroups: variationAttributesDetailSGroups,
            product: orderProduct.product?.id,
        }
    }, [orderProduct]);

    useEffect(() => {
        /* Checking if the product id is present and then it is getting the variation attributes and
        setting the variants. */
        if(orderProduct.product?.id)
            api.variationAttributes.get({params: requestVariationAttributesParams})
                .then(setVariants)
                .catch(error => getNotifier().error(error.message||error.detail));
    } , [api, orderProduct, requestVariationAttributesParams]);

    return (
        <>
            <div className='row mt-4'>
                <div className='col-md-6'>
                    <TextInput 
                        label={t('No. boxes')}
                        placeholder={'0'}
                        onChange={handleInputChange('noBoxes')}
                        value={orderProduct?.noBoxes}
                    />
                </div>
                <div className='col-md-6'>
                    <TextInput 
                        label={t('Boxes/Pallet')}
                        placeholder={'12'}
                        type="number"
                        onChange={handleInputChange('boxesPerPallet')}
                        value={orderProduct?.boxesPerPallet}
                    />
                </div>                               
            </div>

            <div className='row mt-4'>
                <div className='col-md-6'>
                    <SelectWithLabel
                        label={t('Brand')}
                        inputProps={{
                            options: brands,
                            onChange: handleChange('brand'),
                            value: orderProduct?.brand
                        }}
                    />
                </div>  
                <div className='col-md-6'>
                    <SelectWithLabel
                        label={t('Handling')}
                        inputProps={{
                            options: handlingTypesOptions,
                            onChange: handleChange('handling'),
                            value: orderProduct?.handling
                        }}
                    />
                </div>                               
            </div>

            <div className='row mt-3'>
                <div className='col-md-12'>
                    <Textarea
                        label={t('Packing-comments')}
                        onChange={handleInputChange('packingComments')}
                        value={orderProduct.packingComments||''}
                    />
                </div>  
            </div>

            <div className='row mt-4'>
                <div className='col-md-6'>
                    <MiniTitle>{t('Variants')}</MiniTitle>
                </div>                               
            </div>
            <div className='row'>
                {variants.map(variant => 
                    <div className='col-md-6 mt-3' key={variant.id}>
                        <SelectWithLabel
                            label={variant.attribute}
                            inputProps={{
                                options: variant.variationValues.map(variantOption => ({ value: variantOption.id, label: variantOption.value })),
                                onChange: handleChange( `variantAttribute_${variant.id}` ),
                                value: orderProduct[`variantAttribute_${variant.id}`]||''
                            }}
                        />
                    </div>
                )}
            </div>
        </>
    );
}

export default ProductMainInfo;