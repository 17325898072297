import React, { useEffect, useContext, useMemo } from 'react'
import { useState } from 'react';
import SimpleList from '../../../../../components/Dashboard/SimpleList/SimpleList';
import { ApiContext } from '../../../../../services/api/api-config';
import './GrowerDocumentsList.scss';
import moment from 'moment';
import ActionsSeeAndDownload from './components/ActionsSeeAndDownload/ActionsSeeAndDownload';
import ActionUpload from './components/ActionUpload/ActionUpload';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

const GrowerDocumentsList = ({grower, docsFileTypes, maxFileSizeInMB, form, setForm}) => {
    const api = useContext(ApiContext);
    const {t} = useTranslation();
    const [data, setData] = useState([]);

    const getDownloadLink = useCallback((file, label) => {
        return <a href={api?.appFiles.getUrl(file)} target="_blank" rel='noreferrer'>{label}</a>;
    }, [api]);

    const columns = useMemo(() => [
        {
            Header: t('Document-name'),
            accessor: "name"
        },
        {
            Header: t('File'),
            accessor: "file"
        },
        {
            Header: t('Uploaded-by'),
            accessor: "uploadedBy"
        },
        {
            Header: t('Date-uploaded'),
            accessor: 'dateUploaded'
        },
        {
            Header: t('Actions'),
            accessor: "actions"
        },
    ], [t]);

    const growerDocuments = useMemo(() => [
        {
            name: 'CIEC',
            file: 'ciec'
        },
        {
            name: t('Billing-Constancy'),
            file: 'billingConstancy'
        },
        {
            name: t('Contitutional-act'),
            file: 'contitutionalAct'
        },
        {
            name: t('RPPyC-legal-representant'),
            file: 'rppycLegalRepresentant'
        },
        {
            name: t('RPPyC-registration'),
            file: 'rppycRegistration'
        } 
    ], [t]);

    useEffect(() => {
        let documents = [];
        growerDocuments.forEach(document => {
            let linkSee = null;
            let linkDownload = null;
            let file = null;

            if(grower?.[document.file]){
                file = grower[document.file];
                linkSee = api?.appFiles.getUrl(file);
                linkDownload = api?.appFiles.getUrl(file);
            }

            //file: (file ? getDownloadLink(grower?.[document.file], grower?.[document.file].name) : <PendingLabel />),
            
            const _document = {
                name: document.name,
                file: (file ? getDownloadLink(grower?.[document.file], grower?.[document.file].originalName) : <PendingLabel t={t} />),
                uploadedBy: (file ? grower?.[document.file].createdBy?.name : '-'),
                dateUploaded: (file ? moment(grower?.[document.file].updatedDate).format('DD/MM/YYYY') : '-'),
                actions: (file ? 
                            <ActionsSeeAndDownload 
                                linkSee={linkSee} 
                                linkDownload={linkDownload}
                            /> 
                            : 
                            <ActionUpload 
                                docsFileTypes={docsFileTypes} 
                                form={form} setForm={setForm} 
                                fieldName={document.file} 
                                maxFileSizeInMB={maxFileSizeInMB}
                            />
                        ),
            };

            documents.push(_document);
        });

        setData(documents);
    }, [api, form, setForm, docsFileTypes, getDownloadLink, grower, maxFileSizeInMB, t, growerDocuments]);
    
    return (
        <div className="GrowerDocumentsList">
            <SimpleList
                columns={columns} 
                data={data}
            />
        </div>
    );
}

const PendingLabel = ({t}) => <span className='PendingLabel'>{t('Pending')}</span>;

export default GrowerDocumentsList;
