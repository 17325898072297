import classNames from 'classnames';
import React from 'react'
import { useTranslation } from 'react-i18next';
import MmSelect from '../MmSelect/MmSelect';

import './SelectWithLabel.scss';

const SelectWithLabel = ({inputProps, className='', label}) => {
    const {t} = useTranslation();
    
    return (
        <div className={classNames('SelectWithLabel', className)}>
            <label className='select-label'>{label}</label>

            <MmSelect 
                placeholder={t('Select-any-option')}
                {...inputProps}
            />
        </div>
    );
}

export default SelectWithLabel;