import React from 'react'
import { SectionTitle } from '../../../components/Dashboard/SectionTitle/SectionTitle';
import { SideMenu } from '../../../components/Dashboard/SideMenu/SideMenu';
import TopBar from '../../../components/Dashboard/TopBar/TopBar';
import './Dashboard.scss';


const Dashboard = () => {
    return (
        <div className="Dashboard">
            <SideMenu />
            <div className='right-content'>
                <TopBar />
                <div className='main-content'>
                    <SectionTitle>Dashboard</SectionTitle>
                </div>
            </div>
        </div>
    )
}

export default Dashboard;