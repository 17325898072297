import React, { useState } from 'react';
import MiniTitle from '../MiniTitle/MiniTitle';
import {ReactComponent as ArrowUpIcon} from "../../../assets/images/icons/chevron-up-orange.svg";
import {ReactComponent as ArrowDownIcon} from "../../../assets/images/icons/chevron-down-orange.svg";

import './SimpleAcordeon.scss';
import classNames from 'classnames';

const SimpleAcordeon = ({title, isOpen=true, children, alwaysOpen}) => {
    const [showContent, setShowContent] = useState(isOpen);

    const toggleShowContent = () => {
        if(alwaysOpen) return;

        setShowContent(!showContent);
    }

    return (
        <div className='SimpleAcordeon'>
            <div className={classNames('SimpleAcordeon__header', alwaysOpen?'no-link':'')} onClick={toggleShowContent}>
                <MiniTitle>{title}</MiniTitle>
                {showContent ? <ArrowUpIcon /> : <ArrowDownIcon /> }
            </div>
            {(showContent || alwaysOpen) &&
                <div className='SimpleAcordeon__content'>
                    {children}
                </div>}
        </div>
    );
}

export default SimpleAcordeon;