import React from 'react'
import {ReactComponent as PlusIcon} from "../../../assets/images/icons/plus.svg";
import './AddButton.scss';

const AddButton = ({children, ...props}) => {
    return (
        <button className='AddButton' {...props}>
            <PlusIcon />
            {children}
        </button>
    );
}

export default AddButton;
