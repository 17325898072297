import React, { useContext } from 'react'
import {ReactComponent as NotificationIcon} from "../../../../../../../assets/images/icons/notification.svg";
import {ReactComponent as ArrowUpIcon} from "../../../../../../../assets/images/icons/chevron-up.svg";
import {ReactComponent as ArrowDownIcon} from "../../../../../../../assets/images/icons/chevron-down.svg";
import {ReactComponent as NextStepIcon} from "../../../../../../../assets/images/icons/arrow-details.svg";
import useBoolean from "../../../../../../../hooks/useBoolean";
import {useTranslation} from "react-i18next";
import SectorName from '../SectorName/SectorName';
import SectorOrchard from '../SectorOrchard/SectorOrchard';
import SectorFields from '../SectorFields/SectorFields';
import Button from '../../../../../../../components/molecules/Button/Button';
import useFormState from '../../../../../../../hooks/useFormState';
import { prepareNewSectorForServer, prepareSectorwithCertificationFilesForServer } from '../../../../../../../services/models/sectorUtils';
import { getNotifier } from '../../../../../../../services/notifier';
import { ApiContext } from '../../../../../../../services/api/api-config';
import './Sector.scss';
import { useEffect } from 'react';

const Sector = ({index, sector, orchard, loadOrchardData}) => {
    const api = useContext(ApiContext);
    const { form, bindSimple, bindInput, setForm } = useFormState(sector);
    const {t} = useTranslation();
    const [showForm, open, close] = useBoolean();
    const {id} = sector;

    useEffect(() => {
        // if is new sector set open to true
        if(!sector.id)
            open();
    }, [sector, open]);

    const handleSaveSector = () => {
        let sectorData = null;

        try {
            sectorData = prepareNewSectorForServer(form);
        } catch (error) {
            // show notfication error
            return getNotifier().error(error.message);
        }

        const files = prepareSectorwithCertificationFilesForServer(form);

        sectorData.orchard = orchard.id;
        if(id)
            sectorData.id = id;

        api.sectors.createWithCertifications({params: sectorData, files: files, method: "POST"})
            .then(() => {
                getNotifier().success(t('Sector-created-successfully'));
                loadOrchardData();
            })
            .catch((error) => getNotifier().error(error.message));
    }

    return (
        <div className="Sector mt-3">
            <div className="Sector__header">
                <div className='Sector__icon'>
                    <NotificationIcon />
                </div>
                <div className='Sector__content'>
                    <div className='w-50'>
                        <SectorName>Sector</SectorName>
                        <SectorOrchard>{orchard?.name || t('Pending-orchard-id')}</SectorOrchard>
                    </div>
                    <div className='w-50'>
                        {t('Quick-explanation-sector')}
                    </div>
                </div>
                <div className='Sector__commands'>
                    {showForm ? <ArrowUpIcon onClick={close} /> : <ArrowDownIcon onClick={open} />}
                </div>
            </div>

            {showForm && 
                <>
                    <SectorFields
                        sector={sector}
                        bindInput={bindInput}
                        index={index}
                        onChange={bindInput}
                        form={form}
                        setForm={setForm}
                        bindSimple={bindSimple}
                    />

                    <div className='row NextStepButtons'>
                        <Button
                            className={'btn-primary btn-outline NextStepButtons__button'}
                            onClick={() => {}}
                            design={'clear'}
                        >
                            {t('Cancel')}
                        </Button>
                        <Button 
                            className={'btn-primary btnNextStep NextStepButtons__button'}
                            onClick={handleSaveSector}
                        >
                            {t('Save-and-continue')} <NextStepIcon />
                        </Button>
                    </div>
                </>}
        </div>
    );
}

export default Sector;