import React, {useContext, useState, useEffect} from 'react';
import { SectionTitle } from '../../../components/Dashboard/SectionTitle/SectionTitle';
import { SideMenu } from '../../../components/Dashboard/SideMenu/SideMenu';
import TopBar from '../../../components/Dashboard/TopBar/TopBar';
import {useTranslation} from "react-i18next";
import Breadcrumbs from '../../../components/Dashboard/Breadcrumbs/Breadcrumbs';
import getAppRoutes, { paths } from '../../../services/routes/appRoutes';
import {SecurityContext} from "../../../services/SecurityManager";
import Steps from '../../../components/Dashboard/Steps/Steps';
import { useNavigate, useParams } from 'react-router-dom';
import Card from '../../../components/Dashboard/Card/Card';
import NewGrowerFormStep1 from './components/NewGrowerFormStep1/NewGrowerFormStep1';
import NewGrowerFormStep2 from './components/NewGrowerFormStep2/NewGrowerFormStep2';
import NewGrowerFormStep3 from './components/NewGrowerFormStep3/NewGrowerFormStep3';
import OrchardsMenu from './components/OrchardsMenu/OrchardsMenu';
import GrowerHorizontalMenu, { MenuElement } from './components/GrowerHorizontalMenu/GrowerHorizontalMenu';
import './GrowersForm.scss';
import { ApiContext } from '../../../services/api/api-config';
import { companyDetailSGroups } from '../../../services/models/companyUtils';
import { getNotifier } from '../../../services/notifier';
import { getEmptyOrchard, orchardDetailSGroups, orchardToFormData } from '../../../services/models/orchardUtils';

const GrowersForm = () => {
    const {t} = useTranslation();
    const { id, step, orchardId } = useParams();
    const api = useContext(ApiContext);
    const security = useContext(SecurityContext);
    const routes = getAppRoutes(security);
    const [currentStep, setCurrentStep] = useState();
    const [orchards, setOrchards] = useState([]);
    const [orchard, setOrchard] = useState(getEmptyOrchard());
    const [grower, setGrower] = useState();
    const navigate = useNavigate();

    const isEditingMode = ((id !== undefined && id !== null) && (!step || orchardId>0 ));

    useEffect(() => {
        setCurrentStep(step ? parseInt(step)-1 : 0);
    }, [step]);

    useEffect(() => {
        if (isEditingMode || step) {
            api.companies.get({ id, params:{sGroups: companyDetailSGroups}})
                .then(setGrower)
                .catch(err => getNotifier().error(err.message));
            
            api.orchards.get({ params: { company: id, sGroups: orchardDetailSGroups }})
                .then(orchards => {
                    setOrchards(orchards.map(orchard => orchardToFormData(orchard)));
                    const selectedOrchard = orchards.find(orchard => orchard.id === parseInt(orchardId)) || getEmptyOrchard() ;
                    setOrchard( orchardToFormData(selectedOrchard) );
            }).catch(error => getNotifier().error(error.message));
        }
    }, [api, id, isEditingMode, step, orchardId]);
    
    const steps = [
        {
            name: t('Create-an-account'),
            buttonNextText: t('Save-and-continue'),
            hidden: false,
        },
        {
            name: t('General-Information'),
            buttonNextText: t('Save-and-continue'),
            hidden: false,
        },
        {
            name: t('Orchards-sectors-and-products'),
            buttonNextText: t('Create-orchard-and-continue'),
            hidden: false,
        }
    ];

    const crumbs = [
        routes.find(route => route.name === 'Productors'),
        (isEditingMode ? 
                routes.find(route => route.name === 'NewProductorStep')
                :
                routes.find(route => route.name === 'NewProductor'))
    ];

    const reloadGrower = () => {
        api.companies.get({ id, params:{sGroups: companyDetailSGroups}})
                .then(grower => {
                    setGrower(grower);
                })
                .catch(err => getNotifier().error(err.message));
    }

    const gotoStep = (growerId, nextStep) => {
        const replace = nextStep !== 0 ? true : false;
        navigate(paths.mm.growersFormStep.replace(":id", growerId).replace(":step", nextStep), { replace });
    }

    return (
        <div className="GrowersForm dashboard-container" key={currentStep}>
            <SideMenu />
            <div className='right-content'>
                <TopBar />
                <div className='main-content'>
                    <Breadcrumbs crumbs={crumbs} />
                        {isEditingMode ? 
                            <SectionTitle 
                                data-title-id="add-new-productor"
                            >
                                {grower?.companyName}
                            </SectionTitle>
                        :
                            <SectionTitle 
                                data-title-id="add-new-productor"
                            >
                                {t('add-new-productor')}
                            </SectionTitle>
                        }

                    {isEditingMode ? 
                        <GrowerHorizontalMenu grower={grower} >
                            {steps.map((step, index) => 
                                <MenuElement 
                                    isActive={currentStep===index}
                                    key={index}
                                    onClick={() => setCurrentStep(index)}
                                >
                                    {step.name}
                                </MenuElement>)}
                        </GrowerHorizontalMenu>
                        :
                        <Steps steps={steps} currentStep={currentStep} />
                    }           
                    
                    {currentStep===2 && id && orchards?.length>0 && 
                        <OrchardsMenu 
                            orchards={orchards} 
                            orchardActive={orchard}
                            onSelectOrchard={orchard => setOrchard(orchard)} 
                        />}

                    <Card className={"mt-3"}>
                        {currentStep===0 && 
                            <NewGrowerFormStep1 
                                gotoStep2={growerId => gotoStep(growerId, 2)} 
                                updateGrower={setGrower} 
                            />}

                        {currentStep===1 && 
                            <NewGrowerFormStep2 
                                growerOriginal={grower} 
                                gotoStep3={growerId => gotoStep(growerId, 3)} 
                            />}

                        {currentStep===2 && 
                            <NewGrowerFormStep3 
                                setOrchards={setOrchards}
                                setOrchard={setOrchard}
                                orchard={orchard}
                                grower={grower} 
                                gotoStep2={() => setCurrentStep(1)}
                                reloadGrower={reloadGrower}
                            />}
                    </Card>
                </div>
            </div>
        </div>
    );
}

export default GrowersForm;