export const neighbourhoodTableSGroups = [
    'neighbourhood_read',
    'neighbourhood_read_id',
    'neighbourhood_read_name',
    'neighbourhood_read_zip_code',
    'neighbourhood_read_municipality',
    'municipality_read',
    'read_created_by',
    'user_read',
    'read_updated_by',
    'read_created_date',
    'read_updated_date',
];

export const neighbourhoodDetailSGroups = [
    'neighbourhood_read',
    'neighbourhood_read_id',
    'neighbourhood_read_name',
    'neighbourhood_read_zip_code',
    'neighbourhood_read_municipality',
    'municipality_read',
    'read_created_by',
    'user_read',
    'read_updated_by',
    'read_created_date',
    'read_updated_date',
];

export const neighbourhoodFullDatasSGroups = [
    'neighbourhood_read_id',
    'neighbourhood_read_name',
    'neighbourhood_read_zip_code',
    'neighbourhood_read_municipality',
        'municipality_read_id',
        'municipality_read_name',
        'municipality_read_state',
            'state_read_id',
            'state_read_name',
            'state_read_country',
                'country_read_id',
                'country_read_name'
];


export const neighbourhoodTableColumns = [
    {
        Header: 'id',
        accessor: 'id'
    },
     {
        Header: 'name',
        accessor: 'name'
    },
     {
        Header: 'zipCode',
        accessor: 'zipCode'
    },
     {
        Header: 'municiaplity',
        accessor: 'municiaplity?.id'
    },
     {
        Header: 'createdBy',
        accessor: 'createdBy?.id'
    },
     {
        Header: 'updatedBy',
        accessor: 'updatedBy?.id'
    },
     {
        Header: 'createdDate',
        accessor: 'createdDate'
    },
     {
        Header: 'updatedDate',
        accessor: 'updatedDate'
    },
 ];

export const neighbourhoodDataDetailsArray = (neighbourhood) => [
    {data: neighbourhood?.id, dataLabel: 'id'},
    {data: neighbourhood?.name, dataLabel: 'name'},
    {data: neighbourhood?.zipCode, dataLabel: 'zipCode'},
    {data: neighbourhood?.municiaplity?.id, dataLabel: 'municiaplity'},
    {data: neighbourhood?.createdBy?.id, dataLabel: 'createdBy'},
    {data: neighbourhood?.updatedBy?.id, dataLabel: 'updatedBy'},
    {data: neighbourhood?.createdDate, dataLabel: 'createdDate'},
    {data: neighbourhood?.updatedDate, dataLabel: 'updatedDate'},
];


export const newNeighbourhoodFormDefinition = [
];

export const editNeighbourhoodFormDefinition = [
    ...newNeighbourhoodFormDefinition
];

export const prepareNewNeighbourhoodForServer = (form) => {
    const neighbourhood= {};


    return neighbourhood;
};

export const prepareEditedNeighbourhoodForServer = (form) => {
    return prepareNewNeighbourhoodForServer(form);
};

export const neighbourhoodToFormData = (neighbourhood) => {
    return {
        ...neighbourhood
    }
}

