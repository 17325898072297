import React from 'react'
import TextInput from '../../../../../../../components/forms/TextInput/TextInput';
import Modal from '../../../../../../../components/Modal/Modal';

const NewSectorModal = ({onClose, secondaryButtonAction}) => {
    return (
        <Modal
            title={'Add new sector'}
            secondaryButtonAction={secondaryButtonAction}
            mainButtonAction={() => {}}
            onClose={onClose}
        >
            <div>Quick explanation of what a sector is</div>

            <div className='row'>
                <div className='col-md-12'>
                    <TextInput
                        label={'Name'}
                        placeholder={'Sector name here'}
                        className={'mt-modal-input TextInput--max-height'}
                    />        
                </div>
            </div>

            <div className='row'>
                <div className='col-md-6'>
                    <TextInput
                        label={'Orchard'}
                        placeholder={'Orchard 1'}
                        className={'TextInput--max-height'}
                    />        
                </div>
                <div className='col-md-6'>
                    <TextInput
                        label={'Orchard ID'}
                        placeholder={'A0192902'}
                        className={'TextInput--max-height'}
                    />        
                </div>                    
            </div>                

        </Modal>
    );
}

export default NewSectorModal;
