import React from 'react'
import { SideMenu } from '../../../components/Dashboard/SideMenu/SideMenu';
import TopBar from '../../../components/Dashboard/TopBar/TopBar';
import {useTranslation} from "react-i18next";
import Steps from '../../../components/Dashboard/Steps/Steps';
import OrderStep1 from './components/OrderStep1/OrderStep1';
import './OrdersForm.scss';
import { getOrderSteps } from '../../../services/models/productOfferUtils';
import { useParams } from 'react-router-dom';
import OrdersMenu from './components/OrdersMenu/OrdersMenu';

const OrdersForm = () => {
    const {t} = useTranslation();
    const {id} = useParams();

    return (
        <div className="OrdersForm dashboard-container">
            <SideMenu />
            <div className='right-content'>
                <TopBar />
                <div className='main-content'>
                    {id ? <OrdersMenu orderId={id} steps={getOrderSteps(t)} currentStep={0}  /> : <Steps steps={getOrderSteps(t)} currentStep={0} />}
                    <OrderStep1 />
                </div>
            </div>
        </div>
    );
}

export default OrdersForm;