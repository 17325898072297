import React, {useState, useContext, useEffect} from 'react'
import { useTranslation } from 'react-i18next';
import MiniTitle from '../../../../../components/Dashboard/MiniTitle/MiniTitle';
import MmSelect from '../../../../../components/forms/MmSelect/MmSelect';
import TextInput from '../../../../../components/forms/TextInput/TextInput';
import Button from '../../../../../components/molecules/Button/Button';
import SearchBarDateFilter from '../../../../../components/SearchBar/filters/SearchBarDateFilter/SearchBarDateFilter';
import useFormState from '../../../../../hooks/useFormState';
import FoliarApplications from '../FoliarApplications/FoliarApplications';
import Properties from '../Properties/Properties';
import Quality from '../Quality/Quality';
import { ApiContext } from '../../../../../services/api/api-config';
import moment from 'moment';
import { ADMIN_ROLE_ID, userListSGroups } from '../../../../../services/models/userUtils';
import { getFenologicalStageOptions, prepareSectorVisitForServer } from '../../../../../services/models/orchardVisitUtils';
import { getNotifier } from '../../../../../services/notifier';
import { paths } from '../../../../../services/routes/appRoutes';
import { useNavigate } from 'react-router-dom';

const initialState = {
    companyProduct: null,
    applicationDate: moment().format('YYYY-MM-DD'),
    applicationUnit: '',
    foliarApplications: []
};

const createdAt = moment().format('YYYY-MM-DD HH:mm:ss');

const FoliarApplicationsForm = ({companyProductOptions}) => {
    const api = useContext(ApiContext);
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {form, setForm, bindInput, bindSimple} = useFormState(initialState);
    const [users, setUsers] = useState([]);

    useEffect(() => {
        const getUsers = async () => {
            try {
                const users = await api.users.get({params: {role: ADMIN_ROLE_ID, sGroups: userListSGroups }});
                setUsers(users);
            } catch (error) {
                console.log(error);
            }
        }

        getUsers();
    }, [api.users]);
    
    const companyProduct = form.companyProduct;

    const handleProductChange = (e) => {
        setForm({...form, companyProduct: e});
    }

    const userInChargeOptions = users.map(user => { return { value: user.id, label: user.email } });

    const handleInsertNewFoliarApplication = (foliarApplication) => {
        const newFoliarApplications = [...form.foliarApplications];
        newFoliarApplications.push(foliarApplication);
        setForm({ ...form, foliarApplications: newFoliarApplications });
    }

    const handleSaveVisit = () => {
        let visit = {};

        try {
            visit = prepareSectorVisitForServer(form);
        } catch (error) {
            return getNotifier().error(error.message||error.detail);
        }

        api.orchardVisits.create({params: visit}).then(showNotificationAndRedirect).catch(error => getNotifier().error(error.message||error.detail));
    }

    const showNotificationAndRedirect = (newVisit) => {
        getNotifier().success(`${t('visit.created')} ${t('visit.cantrack')}`);
        navigate(paths.mm.orders);
    }

    return (
        <div>
            <MiniTitle className="product-title">{t('Product')} </MiniTitle>
                <div className='row mt-3'>
                    <div className='col-md-12'>
                        <MmSelect
                            label={t('Product')}
                            options={companyProductOptions}
                            value={companyProduct}
                            onChange={handleProductChange}
                        />
                    </div>  
                </div>

                <div className='row mt-3'>
                    <div className='col-md-6'>
                        <TextInput
                            label={t('Orchard')}
                            value={companyProduct?.product?.companyProduct?.sector?.orchard?.name||''}
                            inputProps={{readOnly: true}}
                        />
                    </div>
                    <div className='col-md-6'>
                        <TextInput
                            label={t('Sector')}
                            value={companyProduct?.product?.companyProduct?.sector?.name||''}
                            inputProps={{readOnly: true}}
                        />
                    </div>  
                </div>

                <div className='row mt-3'>
                    <div className='col-md-6'>
                        <TextInput
                            label={t('Productor-name')}
                            value={companyProduct?.product?.companyProduct?.sector?.orchard?.company?.companyName||''}
                            inputProps={{readOnly: true}}
                        />
                    </div>
                    <div className='col-md-6'>
                        <TextInput
                            label={t('ProductorId')}
                            value={companyProduct?.product?.companyProduct?.sector?.orchard?.company?.id||''}
                            inputProps={{readOnly: true}}
                        />
                    </div>  
                </div>

                <MiniTitle className="product-title mt-4">{t('Visit-details')}</MiniTitle>

                <div className='row mt-3'>
                    <div className='col-md-6'>
                        <TextInput
                            label={t('Timestamp')}
                            value={createdAt}
                            inputProps={{readOnly: true}}
                        />
                    </div>
                    <div className='col-md-6'>
                        <SearchBarDateFilter
                            label={t('Date')}
                            name='date'
                            {...bindSimple('date')}
                        />
                    </div>  
                </div>

                <div className='row mt-3'>
                    <div className='col-md-6'>
                        <MmSelect
                            placeholder={t('Engineer-in-charge')}
                            options={userInChargeOptions}
                            {...bindSimple('userInCharge')}
                        />
                    </div>
                    <div className='col-md-6'>
                        <MmSelect
                            placeholder={t('Phenological-Stage')}
                            options={getFenologicalStageOptions(t)}
                            {...bindSimple('phenologicalStage')}
                        />
                    </div>  
                </div>

                <FoliarApplications
                    onInsert={handleInsertNewFoliarApplication}
                    foliarApplications={form.foliarApplications}
                    mainData={form}
                />

                <Quality 
                    bindInput={bindInput}
                    bindSimple={bindSimple}
                />

                <Properties 
                    bindInput={bindInput}
                    bindSimple={bindSimple}
                />

                <div className='row mt-4'>
                    <div className='col-md-12 d-flex justify-content-center align-items-center gap-3 mt-3'>
                        <Button
                            design='clear'
                            onClick={()=> {}}
                            className="footer-button"
                        >
                            {t('Back')}
                        </Button>

                        <Button
                            onClick={handleSaveVisit}
                            className="footer-button"
                        >
                            {t('Save-and-continue')}
                        </Button>
                    </div>
                </div>
                <div className='row mt-3'>
                    <div className='col-md-12 d-flex justify-content-center align-items-center gap-3'>
                        <Button className='link' onClick={() => navigate(paths.mm.orders)}>
                            {t('Continue-later')}
                        </Button>
                    </div>
                </div>

        </div>
    );
}

export default FoliarApplicationsForm;