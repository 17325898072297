import classNames from 'classnames';
import React from 'react'

import './Step.scss'

const Step = ({step, selected, isCurrrent}) => {
    return (
        <div className='Step'>
            <div className={classNames('Step__title', selected&&'Step__title--active', isCurrrent&&'Step__title--current')}>
                <span>{ step.name }</span>
            </div>
            <div className={classNames('Step__bar', selected&&'Step__bar--active')}></div>
        </div>
    );
}

export default Step;