import React, {useContext} from 'react'
import { ApiContext } from '../../../../services/api/api-config';
import './ClientLogo.scss';

const ClientLogo = ({logo}) => {
    const api = useContext(ApiContext);

    return (
        <div className='ClientLogo'>
            <img src={ api?.appFiles.getUrl(logo) } className="ClientLogo__image" alt="Logo" />
        </div>
    );
}

export default ClientLogo;
