import fieldTypes from "../../components/FormModal/fields/fieldTypes";
import {
    copyPropertyOrThrow,
    copyPropertyOrNull,
    copyPropertyIdOrNull,
    copyPropertyIdOrThrow
} from "../formUtils";
import { addressToFormData, prepareNewAddressForServer } from "./addressUtils";
import { certificationToFormData, prepareNewCertificationForServer } from "./certificationUtils";
import { getFileFromForm } from "./companyUtils";

export const orchardTableSGroups = [
    'orchard_read',
    'orchard_read_id',
    'orchard_read_name',
    'orchard_read_hectares',
    'orchard_read_company',
    'company_read',
    'orchard_read_address',
    'address_read',
    'orchard_read_photo',
    'app_file_read',
    'orchard_read_visits',
    'orchard_visit_read',
    'orchard_read_product_offers',
    'product_offer_read',
    'orchard_read_sector',
    'sector_read',
    'orchard_read_surface',
    'orchard_read_height',
    'orchard_read_pendant',
    'orchard_read_foliar_date',
    'orchard_read_foliar_product',
    'orchard_read_foliar_dose',
    'orchard_read_analysis_files',
    'read_created_by',
    'user_read',
    'read_updated_by',
    'read_created_date',
    'read_updated_date',
];

export const orchardDetailSGroups = [
    'orchard_read',
    'orchard_read_id',
    'orchard_read_name',
    'orchard_read_hectares',
    'orchard_read_company',
    'orchard_visit_read',
    'orchard_read_address_references',
    'orchard_read_address',
        'address_read',
        'address_read_neighbourhood',
            'neighbourhood_read',
            'neighbourhood_read_municipality',
                'municipality_read',
                'municipality_read_state',
                    'state_read',
                    'state_read_country',
                        'country_read',
        'app_file_read',
        'sector_read',
    'orchard_read_usa_organic_certification',
    'orchard_read_mex_organic_certification',
    'orchard_read_euro_organic_certification',
    'orchard_read_other_certifications',
    'certification_read',
    'foliar_application_read_foliar_applications_details'
];

export const orchardTableColumns = [
    {
        Header: 'id',
        accessor: 'id'
    },
     {
        Header: 'name',
        accessor: 'name'
    },
     {
        Header: 'hectares',
        accessor: 'hectares'
    },
     {
        Header: 'company',
        accessor: 'company?.id'
    },
     {
        Header: 'address',
        accessor: 'address?.id'
    },
     {
        Header: 'photo',
        accessor: 'photo?.id'
    },
     {
        Header: 'orchardVisits',
        accessor: 'orchardVisits?.id'
    },
     {
        Header: 'productOffers',
        accessor: 'productOffers?.id'
    },
     {
        Header: 'sector',
        accessor: 'sector?.id'
    },
     {
        Header: 'surface',
        accessor: 'surface'
    },
     {
        Header: 'height',
        accessor: 'height'
    },
     {
        Header: 'pendant',
        accessor: 'pendant'
    },
     {
        Header: 'foliarDate',
        accessor: 'foliarDate'
    },
     {
        Header: 'foliarProduct',
        accessor: 'foliarProduct'
    },
     {
        Header: 'foliarDose',
        accessor: 'foliarDose'
    },
     {
        Header: 'analysisFiles',
        accessor: 'analysisFiles?.id'
    },
     {
        Header: 'createdBy',
        accessor: 'createdBy?.id'
    },
     {
        Header: 'updatedBy',
        accessor: 'updatedBy?.id'
    },
     {
        Header: 'createdDate',
        accessor: 'createdDate'
    },
     {
        Header: 'updatedDate',
        accessor: 'updatedDate'
    },
 ];

export const orchardDataDetailsArray = (orchard) => [
    {data: orchard?.id, dataLabel: 'id'},
    {data: orchard?.name, dataLabel: 'name'},
    {data: orchard?.hectares, dataLabel: 'hectares'},
    {data: orchard?.company?.id, dataLabel: 'company'},
    {data: orchard?.address?.id, dataLabel: 'address'},
    {data: orchard?.photo?.id, dataLabel: 'photo'},
    {data: orchard?.orchardVisits?.id, dataLabel: 'orchardVisits'},
    {data: orchard?.productOffers?.id, dataLabel: 'productOffers'},
    {data: orchard?.sector?.id, dataLabel: 'sector'},
    {data: orchard?.surface, dataLabel: 'surface'},
    {data: orchard?.height, dataLabel: 'height'},
    {data: orchard?.pendant, dataLabel: 'pendant'},
    {data: orchard?.foliarDate, dataLabel: 'foliarDate'},
    {data: orchard?.foliarProduct, dataLabel: 'foliarProduct'},
    {data: orchard?.foliarDose, dataLabel: 'foliarDose'},
    {data: orchard?.analysisFiles?.id, dataLabel: 'analysisFiles'},
    {data: orchard?.createdBy?.id, dataLabel: 'createdBy'},
    {data: orchard?.updatedBy?.id, dataLabel: 'updatedBy'},
    {data: orchard?.createdDate, dataLabel: 'createdDate'},
    {data: orchard?.updatedDate, dataLabel: 'updatedDate'},
];


export const newOrchardFormDefinition = [
    {
        name: 'name',
        label: 'name',
        required: true,
        type: fieldTypes.text
    },

    {
        name: 'hectares',
        label: 'hectares',
        required: true,
        type: fieldTypes.number
    },

    {
        name: 'company',
        label: 'company',
        required: true,
        type: fieldTypes.entity,
        entity: 'companies'
    },

    {
        name: 'address',
        label: 'address',
        required: false,
        type: fieldTypes.entity,
        entity: 'addresses'
    },

    {
        name: 'photo',
        label: 'photo',
        required: false,
        type: fieldTypes.entity,
        entity: 'appFiles'
    },

    {
        name: 'sector',
        label: 'sector',
        required: false,
        type: fieldTypes.entity,
        entity: 'sectors'
    },

    {
        name: 'surface',
        label: 'surface',
        required: false,
        type: fieldTypes.number
    },

    {
        name: 'height',
        label: 'height',
        required: false,
        type: fieldTypes.number
    },

    {
        name: 'pendant',
        label: 'pendant',
        required: false,
        type: fieldTypes.number
    },

    {
        name: 'foliarDate',
        label: 'foliarDate',
        required: false,
        type: fieldTypes.date
    },

    {
        name: 'foliarProduct',
        label: 'foliarProduct',
        required: false,
        type: fieldTypes.text
    },

    {
        name: 'foliarDose',
        label: 'foliarDose',
        required: false,
        type: fieldTypes.text
    },


];

export const editOrchardFormDefinition = [
    ...newOrchardFormDefinition
];

export const prepareNewOrchardForServer = (form) => {
    const orchard= {};

    copyPropertyOrThrow(form, orchard, 'name', 'Debes agregar name de orchard');
    copyPropertyOrThrow(form, orchard, 'hectares', 'Debes agregar hectares de orchard');
    copyPropertyIdOrThrow(form, orchard, 'company', 'Debes agregar company de orchard');
    copyPropertyIdOrNull(form, orchard, 'address', 'Debes agregar address de orchard');
    copyPropertyIdOrNull(form, orchard, 'photo', 'Debes agregar photo de orchard');
    copyPropertyIdOrNull(form, orchard, 'sector', 'Debes agregar sector de orchard');
    copyPropertyOrNull(form, orchard, 'surface');
    copyPropertyOrNull(form, orchard, 'height');
    copyPropertyOrNull(form, orchard, 'pendant');
    copyPropertyOrNull(form, orchard, 'foliarDate');
    copyPropertyOrNull(form, orchard, 'foliarProduct');
    copyPropertyOrNull(form, orchard, 'foliarDose');
    copyPropertyIdOrNull(form, orchard, 'analysisFiles', 'Debes agregar analysisFiles de orchard');

    return orchard;
};

export const prepareEditedOrchardForServer = (form) => {
    return prepareNewOrchardForServer(form);
};

export const orchardToFormData = (orchard) => {
    return {
        ...orchard,
        address: addressToFormData(orchard.address),
        usaOrganicCertification: certificationToFormData(orchard.usaOrganicCertification),
        mexOrganicCertification: certificationToFormData(orchard.mexOrganicCertification),
        euroOrganicCertification: certificationToFormData(orchard.euroOrganicCertification),
    }
}

export const prepareNewOrchardForServerFromGrower = (form) => {
    const orchard= {};

    copyPropertyOrThrow(form, orchard, 'name', 'Debes agregar name de orchard', 'name');
    copyPropertyOrThrow(form, orchard, 'hectares', 'Debes agregar hectares de orchard');
    
    copyPropertyIdOrNull(form, orchard, 'address');
    copyPropertyIdOrNull(form, orchard, 'addressReferences');
    copyPropertyIdOrNull(form, orchard, 'photo');
    copyPropertyIdOrNull(form, orchard, 'sector');
    copyPropertyOrNull(form, orchard, 'surface');
    copyPropertyOrNull(form, orchard, 'height');
    copyPropertyOrNull(form, orchard, 'pendant');
    copyPropertyOrNull(form, orchard, 'foliarDate');
    copyPropertyOrNull(form, orchard, 'foliarProduct');
    copyPropertyOrNull(form, orchard, 'foliarDose');
    copyPropertyIdOrNull(form, orchard, 'analysisFiles', 'Debes agregar analysisFiles de orchard');

    copyPropertyOrNull(form, orchard, 'latitude');
    copyPropertyOrNull(form, orchard, 'longitude');

    try {
        orchard.address = prepareNewAddressForServer(form.address);
    } catch (error) {
        
    }

    if(orchard.hectares)
        orchard.hectares = parseFloat(orchard.hectares);

    if(orchard.latitude)
        orchard.latitude = parseFloat(orchard.latitude);

    if(orchard.longitude)
        orchard.longitude = parseFloat(orchard.longitude);

    // certifications
    orchard.usaOrganicCertification = prepareNewCertificationForServer(form.usaOrganicCertification);
    orchard.mexOrganicCertification = prepareNewCertificationForServer(form.mexOrganicCertification);
    orchard.euroOrganicCertification = prepareNewCertificationForServer(form.euroOrganicCertification);

    // other certifications
    orchard.otherCertifications = form.otherCertifications?.map(certification => {
        return (certification.name ? prepareNewCertificationForServer(certification) : null);
    });

    // remove empty other certifications
    if(orchard.otherCertifications)
        Object.keys(orchard.otherCertifications).forEach(key => {
            if (orchard.otherCertifications[key] === undefined || !orchard.otherCertifications[key]) {
                delete orchard.otherCertifications[key];
            }
        });

    Object.keys(orchard).forEach(key => {
        if (orchard[key] === undefined || !orchard[key]) {
            delete orchard[key];
        }
    });

    return orchard;
};

export const getEmptyOrchard = () => {
    return {
        name: '',
        hectares: 0,
        company: null,
        address: null,
        sectors: [],
        products: [],
    }
}

export const prepareOrchardFilesForServer = (form) => {
    let orchardFiles = {};

    orchardFiles.groundAnalysis = getFileFromForm(form, 'groundAnalysis');
    orchardFiles.ciec = getFileFromForm(form, 'ciec');
    orchardFiles.billingConstancy = getFileFromForm(form, 'billingConstancy');
    orchardFiles.contitutionalAct = getFileFromForm(form, 'contitutionalAct');
    orchardFiles.rppycLegalRepresentant = getFileFromForm(form, 'rppycLegalRepresentant');
    orchardFiles.rppycRegistration = getFileFromForm(form, 'rppycRegistration');

    Object.keys(orchardFiles).forEach(key => {
        if (orchardFiles[key] === undefined || orchardFiles[key] === null) {
          delete orchardFiles[key];
        }
    });

    return orchardFiles;
}
