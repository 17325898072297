import {createContext} from 'react';
import _ from 'lodash';


export default class SecurityManager{

    constructor(me){

        if( !me )
            throw new Error("The Security object requires an user to be initialized");

        this.me = me;
        this.role = (me.role && me.role.name) ? me.role.name:'';
        this.permissions = me.permissionsArray||[];
    }

    havePermission($permission){
        if(this.role==='SUPER_ADMIN'){
            return true;
        }
        return _.includes(this.permissions, $permission);
    }

    haveAllPermissions= (permissions=[])=>
        this.role==='SUPER_ADMIN' || permissions.reduce((haveAll, permission)=>haveAll&&this.havePermission(permission), true);

    haveAtLeastOnePermission= (permissions)=>
        this.role==='SUPER_ADMIN' || !!_.intersection(this.permissions, permissions).length>0;

    isActualUser= (user)=> !!(user && user.id===this.me.id );

    canSeeEntity= ()=>true;

    canAdminOrders() {
        return this.haveAllPermissions([
            'PRODUCT_OFFER_LIST',
            'PRODUCT_OFFER_SHOW',
            'PRODUCT_OFFER_CREATE',
            'PRODUCT_OFFER_UPDATE',
            'PRODUCT_OFFER_DELETE',
        ]);
    }

    canApproveProductors() {
        return this.canAdminProductors() && this.havePermission('APPROVE_COMPANY');
    }

    canEditOrdersAndAdminVisits() {
        return this.canAdminOrders() && this.canAdminVisits();
    }

    canAdminVisits() {
        return this.haveAllPermissions([
            'FOLIAR_APPLICATION_LIST',
            'FOLIAR_APPLICATION_SHOW',
            'FOLIAR_APPLICATION_CREATE',
            'FOLIAR_APPLICATION_UPDATE',
            'FOLIAR_APPLICATION_DELETE',
            'VISIT_SECTOR_DETAIL_LIST',
            'VISIT_SECTOR_DETAIL_SHOW',
            'VISIT_SECTOR_DETAIL_CREATE',
            'VISIT_SECTOR_DETAIL_UPDATE',
            'VISIT_SECTOR_DETAIL_DELETE',
            'SECTOR_LIST',
			'SECTOR_SHOW',
        ]);
    }

    canAdminProductors() {
        return this.haveAllPermissions([
            'COMPANY_LIST',
            'COMPANY_SHOW',
            'COMPANY_CREATE',
            'COMPANY_UPDATE',
            'COMPANY_DELETE',
            'COMPANY_PRODUCT_LIST',
            'COMPANY_PRODUCT_SHOW',
            'COMPANY_PRODUCT_CREATE',
            'COMPANY_PRODUCT_UPDATE',
            'COMPANY_PRODUCT_DELETE',
            'ORCHARD_LIST',
            'ORCHARD_SHOW',
            'ORCHARD_CREATE',
            'ORCHARD_UPDATE',
            'ORCHARD_DELETE',
            'ORCHARD_VISIT_LIST',
            'ORCHARD_VISIT_SHOW',
            'ORCHARD_VISIT_CREATE',
            'ORCHARD_VISIT_UPDATE',
            'ORCHARD_VISIT_DELETE',
            'SOCIOECONOMIC_STUDY_LIST',
            'SOCIOECONOMIC_STUDY_SHOW',
            'SOCIOECONOMIC_STUDY_CREATE',
            'SOCIOECONOMIC_STUDY_UPDATE',
            'SOCIOECONOMIC_STUDY_DELETE',
            'SECTOR_LIST',
            'SECTOR_SHOW',
            'SECTOR_CREATE',
            'SECTOR_UPDATE',
            'SECTOR_DELETE',
            'CERTIFICATION_LIST',
            'CERTIFICATION_SHOW',
            'CERTIFICATION_CREATE',
            'CERTIFICATION_UPDATE',
            'CERTIFICATION_DELETE',
            'PERFORMANCE_LIST',
            'PERFORMANCE_SHOW',
            'PERFORMANCE_CREATE',
            'PERFORMANCE_UPDATE',
            'PERFORMANCE_DELETE',
            'FOLIAR_APPLICATION_LIST',
            'FOLIAR_APPLICATION_SHOW',
            'FOLIAR_APPLICATION_CREATE',
            'FOLIAR_APPLICATION_UPDATE',
            'FOLIAR_APPLICATION_DELETE',
            'VISIT_SECTOR_DETAIL_LIST',
            'VISIT_SECTOR_DETAIL_SHOW',
            'VISIT_SECTOR_DETAIL_CREATE',
            'VISIT_SECTOR_DETAIL_UPDATE',
            'VISIT_SECTOR_DETAIL_DELETE',
            'FOLIAR_APPLICATION_DETAIL_LIST',
            'FOLIAR_APPLICATION_DETAIL_SHOW',
            'FOLIAR_APPLICATION_DETAIL_CREATE',
            'FOLIAR_APPLICATION_DETAIL_UPDATE',
            'FOLIAR_APPLICATION_DETAIL_DELETE',
        ]);
    }
}

export const SecurityContext = createContext(null);
