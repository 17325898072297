import React from 'react'
import {useTranslation} from "react-i18next";
import { SectionTitle } from '../../../../../../../components/Dashboard/SectionTitle/SectionTitle';
import Switch from '../../../../../../../components/forms/Switch/Switch';
import TextInput from '../../../../../../../components/forms/TextInput/TextInput';
import UploadFile from '../../../../../../../components/forms/UploadFile/UploadFile';
import {ReactComponent as UploadIcon} from "../../../../../../../assets/images/icons/upload.svg";
import './SectorFields.scss';

const SectorFields = ({bindInput, bindSimple, form, setForm}) => {
    const {t} = useTranslation();

    return (
        <div className='SectorFields mt-3'>
            <div className='row'>
                <div className='col'>
                    <SectionTitle>{t('General-Information')}</SectionTitle>
                    <div className='row mt-3'>
                        <div className='col-md-3 margin-top-mobile'>
                            <TextInput
                                label={t('Name')}
                                placeholder={t('Sector-name')}
                                {...bindInput('name')}
                            />
                        </div>
                        <div className='col-md-3 margin-top-mobile'>
                            <TextInput
                                label={'ID'}
                                placeholder={t('ID')}
                                inputProps={{disabled: true}}
                                value={form.id||''}
                            />
                        </div>
                    </div>

                    <SectionTitle>{t('Documents')}</SectionTitle>
                    <div className='row mt-3'>
                        <div className='col-md-12 margin-top-mobile'>
                            {t('document-specs')}
                        </div>
                    </div>
                    <div className='row mt-3'>
                        <div className='col-md-3 margin-top-mobile'>
                            <UploadFile
                                multiple={false}
                                onChange={file => setForm({...form, waterAnalysisFile: file})}
                                showImage={false}
                                maxFileSizeInMB={5}
                                fileTypes={['pdf', 'doc', 'docx']}
                            >
                                {t('Water-analysis')} <span style={{width: '15px'}}><UploadIcon /></span>
                            </UploadFile>
                        </div>
                    </div>


                    <SectionTitle>{t('Dimentions')}</SectionTitle>
                    <div className='row mt-3'>
                        <div className='col-md-3 margin-top-mobile'>
                            <TextInput
                                label={t('Pendant')}
                                placeholder='°'
                                {...bindInput('pendant')}
                                type="number"
                            />
                        </div>
                        <div className='col-md-3 margin-top-mobile'>
                            <TextInput
                                label={t('Height')}
                                placeholder={t('m')}
                                {...bindInput('height')}
                                type="number"
                            />
                        </div>
                        <div className='col-md-3 margin-top-mobile'>
                            <TextInput
                                label={'Surface'}
                                placeholder={t('m²')}
                                {...bindInput('surface')}
                                type="number"
                            />
                        </div>
                    </div>

                    <SectionTitle>{t('Infraestructure')}</SectionTitle>
                    <div className='row mt-3'>
                        <div className='col-6 col-md-6 col-lg-3 margin-top-mobile d-flex justify-content-start align-items-center gap-2'>
                            <Switch 
                                {...bindSimple('machinery')}
                                name='machinery'
                                id='machinery'
                            />
                            <div>{t('Machinery')}</div>
                        </div>
                        <div className='col-6 col-md-6 col-lg-3 margin-top-mobile d-flex justify-content-start align-items-center gap-2'>
                            <Switch 
                                {...bindSimple('irrigationSystem')}
                                name='irrigationSystem'
                                id='irrigationSystem'
                            />
                            <div>{t('Irrigation-system')}</div>
                        </div>
                        <div className='col-6 col-md-6 col-lg-3 margin-top-mobile d-flex justify-content-start align-items-center gap-2'>
                            <Switch 
                                {...bindSimple('macroTunnel')}
                                name='macroTunnel'
                                id='macroTunnel'
                            />
                            <div>{t('Macro-tunnel')}</div>
                        </div>
                        <div className='col-6 col-md-6 col-lg-3 margin-top-mobile d-flex justify-content-start align-items-center gap-2'>
                            <Switch 
                                {...bindSimple('greenhouses')}
                                name='greenhouses'
                                id='greenhouses'
                            />
                            <div>{t('Greenhouses')}</div>
                        </div>
                    </div> 
                </div>

                
            </div>            
        </div>
    );
}

export default SectorFields;
