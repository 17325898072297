import React, {useMemo, useState, useCallback, useContext } from 'react'
import { SectionTitle } from '../../../components/Dashboard/SectionTitle/SectionTitle';
import { SideMenu } from '../../../components/Dashboard/SideMenu/SideMenu';
import TopBar from '../../../components/Dashboard/TopBar/TopBar';
import {useTranslation} from "react-i18next";
import { SearchTextbox } from '../../../components/forms/SearchTextbox/SearchTextbox';
import Button from '../../../components/molecules/Button/Button';
import useTideTable from "../../../components/utility/TideReactTable/useTideTable";
import TideReactTable from "../../../components/utility/TideReactTable/TideReactTable";
import { companyTypes, growerTableSGroups } from '../../../services/models/companyUtils';
import { ApiContext } from '../../../services/api/api-config';
import debounce from 'lodash.debounce';
import { getUserLabelWithAvatar } from '../../../services/models/userUtils';
import LabelStatus from '../../../components/Dashboard/LabelStatus/LabelStatus';
import {paths} from "../../../services/routes/appRoutes";
import { useNavigate } from 'react-router-dom';
import {ReactComponent as ArrowDetailsIcon} from "../../../assets/images/icons/arrow-details.svg";
import { Link } from 'react-router-dom';
import './GrowersList.scss';

const customProp = "GrowersList";

const GrowersList = () => {
    const api = useContext(ApiContext);
    const [textFilter, setTextFilter] = useState('');
    const {t} = useTranslation();
    const navigate = useNavigate();

    const growerTableColumns = useMemo(() => [
        {
            Header: t('Name'),
            accessor: row => getUserLabelWithAvatar(row, api)
        },
        {
            Header: 'ID',
            accessor: 'id'
        },
        {
            Header: "Email",
            accessor: "contactEmail"
        },
        {
            Header: t("Status"),
            id: 'registryStatus',
            accessor: row => <LabelStatus status={row.registryStatus} statusText={ t(row.registryStatus) } />
        },
        {
            Header: t("financingProfile"),
            accessor: "financingProfile"
        },
        {
            Header: t("exportProgram"),
            accessor: row => ( row.exportProgram ? t('yes') : 'No' )
        },
        {
            Header: t("Actions"),
            accessor: company => 
                <Link to={paths.mm.growersEdit.replace(':id', company.id)}
                    className="d-flex justify-content-center align-items-center gap-3">
                        {t('See')} <ArrowDetailsIcon />
                </Link>
        }
    ], [api, t]);

    const searchFilter = useMemo(() => {
        return {
            search: textFilter
        }
    }, 
    [textFilter]);

    const requestFilters = useMemo(() => ({
        sGroups: growerTableSGroups,
        type: companyTypes.TYPE_GROWER,
        ...searchFilter
    }), [searchFilter]);

    const handleSearchTextChange = useCallback((e) => {
        setTextFilter(e.target.value);
    }, []);

    const debouncedChangeHandler = debounce(handleSearchTextChange, 400); 

    const requestOptions = useMemo(() => ({ customProp }), []);
    const table = useTideTable({
        entity: 'companies',
        columns: growerTableColumns,
        requestFilters,
        requestOptions
    });

    const handleNewGrower = useCallback(()=>navigate(paths.mm.growersForm), [navigate]);

    return (
        <div className="GrowersList dashboard-container">
            <SideMenu />
            <div className='right-content'>
                <TopBar />
                <div className='main-content'>
                    <SectionTitle>{t('Productors')}</SectionTitle>

                    <div className='row'>
                        <div className='col d-flex justify-content-between align-items-center gap-3'>
                            <SearchTextbox
                                placeholder={t('Search-by-name, email, id')}
                                onChange={debouncedChangeHandler}
                            />

                            <Button 
                                className={'submit btnNewProducer'} 
                                type='button' 
                                onClick={handleNewGrower}
                                id="add-new-productor"
                            >
                                + {t('add-new-productor')}
                            </Button>
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="">
                            <TideReactTable 
                                {...table.tableProps} 
                                className="productos-table"
                            />
                        </div>
                    </div>   
                </div>
            </div>
        </div>
    );
}

export default GrowersList;