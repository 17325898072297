import React from 'react'

import './SearchBarTextFilter.scss';

const SearchBarTextFilter = () => {
    return (
        <div>SearchBarTextFilter</div>
    );
}

export default SearchBarTextFilter;