import React, { useState } from 'react'

import './CustomCheckbox.scss';

const CustomCheckbox = ({children}) => {
    const [selected, setSelected] = useState(false);

    const toggleSelectValue = () => {
        setSelected(!selected);
    }

    return (
        <div className='CustomCheckbox' onClick={toggleSelectValue}>
            
                <input 
                    type="checkbox" 
                    checked={selected}
                    onChange={toggleSelectValue}
                />
                
                <label htmlFor="cb1">{children}</label>
                
        </div>
    )
}

export default CustomCheckbox;