import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import TextInput from '../../../components/forms/TextInput/TextInput';
import Modal from '../../../components/Modal/Modal';
import useFormState from '../../../hooks/useFormState';
import { ApiContext } from '../../../services/api/api-config';
import { getEmptyUser, prepareUserToServer, usersDetailsSGroups, userToForm } from '../../../services/models/userUtils';
import './UsersForm.scss';
import Switch from '../../../components/forms/Switch/Switch';
import { getNotifier } from '../../../services/notifier';
import { useSelector } from 'react-redux';

const UsersForm = ({onClose, selectedUserId}) => {
    const {t} = useTranslation();
    const api = useContext(ApiContext);
    const { form, setForm, bindInput } = useFormState(getEmptyUser());
    const loadingId = `user-form-${selectedUserId}`;

    // load user data
    useEffect(() => {
        if (selectedUserId) {
            api.users.get({ id:selectedUserId.toString(), params: { sGroups: usersDetailsSGroups }, customProp: loadingId}).then(user => {
                setForm( userToForm(user) );
            });
        }
    }, [selectedUserId, api, setForm, loadingId]);

    const handleNewUser = () => {
        const id = form.id;
        const method = id ? 'update' : 'create';
        let user = null;

        try {
            user = prepareUserToServer(form, t);    
        } catch (error) {
            return getNotifier().error(error.message||error.detail);
        }

        api.users[method]({id, params: user})
            .then(() => {
                getNotifier().success( t(`user.${method}.success`));  
                onClose();
            })
            .catch(error => {
                getNotifier().error(error.message||error.detail);                
            });
    };

    const loading = useSelector(s=>!!s.loadingIds[loadingId]);

    return (
        <div className="UsersForm">
            <Modal
                title={t("Add-new-user")}
                mainButtonText={t('Confirm')}
                mainButtonColor='success'
                mainButtonAction={handleNewUser}
                secondaryButtonText={'Cancel'}
                secondaryButtonAction={onClose}
                className="UsersForm__modal"
                onClose={onClose}
                loading={loading}
            >
                <form className='UsersForm'>
                    <div className='mt-3'>
                        <div className="row">
                            <div className="col">
                                <TextInput
                                    label={t('Name')}
                                    placeholder={t('Name')}
                                    {...bindInput('name')}
                                />
                            </div>
                            <div className="col">
                                <TextInput
                                    label={t('Last-name')}
                                    placeholder={t('LastName')}
                                    {...bindInput('lastName')}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <TextInput
                                    label={t('Phone')}
                                    placeholder={t('Phone')}
                                    {...bindInput('phone')}
                                    inputProps={{
                                        maxLength: 10
                                    }}
                                />
                            </div>
                            <div className="col">
                                <TextInput
                                    label={t('Email')}
                                    placeholder={t('Email')}
                                    {...bindInput('email')}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                {t('isAdministrator')}
                                <Switch
                                    label={t('Active')}
                                    onChange={(value) => setForm({...form, isAdministrator: value})}
                                    value={form.isAdministrator||false}
                                />
                            </div>                            
                        </div>
                    </div>
                </form>
            </Modal>
        </div>
    );
}

export default UsersForm