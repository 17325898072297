import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment';
import { ApiContext } from '../../../services/api/api-config';
import { companyProductDetailSGroups } from '../../../services/models/companyProductUtils';
import {useTranslation} from "react-i18next";
import './ProductRowCard.scss';

const ProductRowCard = ({productId, onClick, reloadProducts}) => {
    const [product, setProduct] = useState();
    const api = useContext(ApiContext);
    const {t} = useTranslation();

    // load full product data
    useEffect(()=>{
        api.companyProducts.get({ params:{id:productId, sGroups: companyProductDetailSGroups}})
        .then(products => {
            setProduct(products[0]);
        });
    },[api, productId]);

    const backgroundImage = product?.product?.image ? api.appFiles.getUrl(product?.product?.image) : null ;

    return (
        <>
            {product &&
            <div className='ProductRowCard'>
                <div className='ProductRowCard__image' 
                    style={{ backgroundImage: `url(${backgroundImage})`  }}
                    onClick={() => onClick(product)}
                />
                <div className='ProductRowCard__content'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='ProductRowCard__title'>
                                {product.name}
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-4 product-label'>
                            ID {product.id}
                        </div>
                        <div className='col-md-8'>
                            {t('Serial')} no. <b>{product.serial}</b>
                        </div>
                    </div>
                    <div className='row product-data'>
                        <div className='col-6 col-sm-6 col-md-6 col-lg-3 col-xl-2 margin-top-mobile'>
                            <div className='product-label'>{t('Productor')}</div>
                            <div className='product-value'>{product.name}</div>
                        </div>
                        <div className='col-6 col-sm-6 col-md-6 col-lg-4 col-xl-2 margin-top-mobile'>
                            <div className='product-label'>{t('Orchard-date')}</div>
                            <div className='product-value'>{moment(product.orchardDate).format('YYYY-MM-DD')}</div>
                        </div>
                        <div className='col-6  col-sm-6 col-md-6 col-lg-4 col-xl-3 margin-top-mobile'>
                            <div className='product-label'>{t('Orchard-name')}</div>
                            <div className='product-value'>{product.sector?.orchard?.name}</div>
                        </div>
                        <div className='col-6 col-sm-6 col-md-6 col-lg-4 col-xl-2 margin-top-mobile'>
                            <div className='product-label'>Sector</div>
                            <div className='product-value'>{product.sector?.name}</div>
                        </div>
                        <div className='col-6 col-sm-6 col-md-6 col-lg-3 col-xl-2 margin-top-mobile'>
                            <div className='product-label'>{product.handling}</div>
                        </div>                        
                    </div>
                </div>
                {/*<div className='ProductRowCard__content-right'>
                    <!-- 
                        They do not know if it is going to be put or not, 
                        it is in figma but the data they do not know where it would be obtained from 
                    -->

                    <div className='ProductRowCard__product-detail product-label'>
                        <img src={avocadoImage} className='circle-image' alt="Avocado HAS" />
                        <div>Aguacates de calidad</div>
                    </div>
                    <div  className='ProductRowCard__product-origin'>
                        Product of México
                    </div>
                </div>*/}
            </div>}
        </>
    );
}

export default ProductRowCard;
