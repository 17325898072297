import fieldTypes from "../../components/FormModal/fields/fieldTypes";
import {
    copyPropertyOrThrow,
    copyPropertyOrNull,
    copyPropertyValueOrThrow,
} from "../formUtils";

export const productTableSGroups = [
    'product_read',
    'product_read_id',
    'product_read_name',
    'read_file',
    'product_read_base_product',
        'base_product_read',
    'read_created_by',
    'read_updated_by',
    'read_created_date',
    'read_updated_date',
];

export const productEditSGroups = [
    'product_read',
    'product_read_base_product',
        'base_product_read',
    'product_read_variation_values',
        'variation_attribute_read_variation_values',
        'variation_attribute_read_id',
        'variation_attribute_read_values_value',
        'variation_attribute_read_variation_attribute',
        'variation_attribute_read_attribute',
    'product_read_variation_values',
        'variation_value_read_value',
        'variation_value_read_id',
        'read_file',
];

export const productDetailSGroups = [
    'product_read',
    'product_read_base_product',
        'base_product_read',
    'product_read_variation_values',
        'variation_attribute_read_id',
        'variation_attribute_read_values_value',
        'variation_attribute_read_variation_attribute',
        'variation_attribute_read_attribute',
        'variation_value_read_variation_attribute',
        'variation_value_read_value',
        'variation_value_read_id',
        'read_file',
];

export const productSelectSGroups = [
    ...productDetailSGroups
];

export const productTableColumns = [
    {
        Header: 'id',
        accessor: 'id'
    },
     {
        Header: 'name',
        accessor: 'name'
    },
     {
        Header: 'productCode',
        accessor: 'productCode'
    },
     {
        Header: 'productCategory',
        accessor: 'productCategory?.id'
    },
     {
        Header: 'createdBy',
        accessor: 'createdBy?.id'
    },
     {
        Header: 'updatedBy',
        accessor: 'updatedBy?.id'
    },
     {
        Header: 'createdDate',
        accessor: 'createdDate'
    },
     {
        Header: 'updatedDate',
        accessor: 'updatedDate'
    },
 ];

export const productDataDetailsArray = (product) => [
    {data: product?.id, dataLabel: 'id'},
    {data: product?.name, dataLabel: 'name'},
    {data: product?.productCode, dataLabel: 'productCode'},
    {data: product?.productCategory?.id, dataLabel: 'productCategory'},
    {data: product?.createdBy?.id, dataLabel: 'createdBy'},
    {data: product?.updatedBy?.id, dataLabel: 'updatedBy'},
    {data: product?.createdDate, dataLabel: 'createdDate'},
    {data: product?.updatedDate, dataLabel: 'updatedDate'},
];


export const newProductFormDefinition = [
    {
        name: 'name',
        label: 'name',
        required: true,
        type: fieldTypes.text
    },

    {
        name: 'productCategory',
        label: 'productCategory',
        required: false,
        type: fieldTypes.entity,
        entity: 'productCategories'
    },

];

export const editProductFormDefinition = [
    ...newProductFormDefinition
];

export const prepareNewProductForServer = (form, t) => {
    const product= {};

    //copyPropertyOrThrow(form, product, 'name', t('Must-add-the-name-of-the-product'));
    copyPropertyValueOrThrow(form, product, 'baseProduct', t('Must-select-the-base-product'));
    copyPropertyOrThrow(form, product, 'productCode', t('Must-add-the-code-of-the-product'));
    //copyPropertyValueOrThrow(form, product, 'productCategory', t('Must-select-the-category-of-the-product'));
    copyPropertyOrNull(form, product, 'variationAttributes');

    product.variationAttributes = product.variationAttributes.map( (variationAttribute) => {
        return {
            id: variationAttribute.id,
            attribute: variationAttribute.attribute,
        }
    });

    let variationValues = [];
    form.variationAttributes?.forEach( (variationAttribute) => {
        variationAttribute.variationValues?.forEach( (variationValue) => {
            if(variationValue.active) 
                variationValues.push({
                    id: variationValue.id,
                    value: variationValue.value,
                });
        });
    });

    product.variationValues = variationValues;

    return product;
};

export const prepareEditedProductForServer = (form) => {
    return prepareNewProductForServer(form);
};

export const productToFormData = (product) => {
    let variationAttributes = [];

    product.variationValues?.forEach( (variationValue) => {
        let variationAttribute = variationAttributes.find( (va) => va.id === variationValue.variationAttribute.id);

        if(!variationAttribute) {
            variationAttribute = {
                id: variationValue.variationAttribute.id,
                attribute: variationValue.variationAttribute.attribute|'---',
                variationValues: [],
            };
        
            variationAttributes.push(variationAttribute);
        }

        variationAttribute.variationValues.push({
            id: variationValue.id,
            value: variationValue.value||'---',
            active: true,
        });
    });

    return {
        ...product,
        baseProduct: {label: product?.baseProduct?.name, value: product?.baseProduct?.id},
        variationAttributes
    }
}

export const getEmptyProduct = () => {
    return {
        name: '',
        productCategory: {label: '', value: ''},
        variationAttributes: [], 
        variationValues: []
    }
}

export const getProductFiles = (form) => {
    let files = null;

    if(form.image && !form.image?.id) {
        files = {
            image: form.image
        };
    }

    return files;
}
