import fieldTypes from "../../components/FormModal/fields/fieldTypes";
import {
    copyPropertyOrThrow,
    copyPropertyOrNull,
    copyPropertyIdOrThrow,
    copyPropertyValueOrThrow
} from "../formUtils";
import { prepareVisitSectorDetailForServer, visitSectorDetailsToFormData } from "./visitSectorDetailUtils";

export const orchardVisitTableSGroups = [
    'orchard_visit_read',
    'read_created_by',
        'user_read_id',
        'user_read_email',
    'orchard_visit_read_date',
    'orchard_visit_read_orchard',
        'orchard_read',
            'company_read',
];

export const orchardVisitDetailSGroups = [
    'orchard_visit_read',
    'orchard_visit_read_user_in_charge',
    'read_created_by',
        'user_read_id',
        'user_read_email',
    'orchard_visit_read_date',
    'orchard_visit_read_orchard',
        'orchard_read',
            'company_read_id',
            'company_read_name',
            'company_read_address',
            'company_read_contact_email',
            'company_read_business_name',
            'company_read_company_name',
    'orchard_visit_read_visit_sector_details',
        'visit_sector_detail_read',
        'visit_sector_detail_read_sector',
             'sector_read_id',
             'sector_read_name',
];

export const orchardVisitTableColumns = [
    {
        Header: 'id',
        accessor: 'id'
    },
     {
        Header: 'phenologicalStage',
        accessor: 'phenologicalStage'
    },
     {
        Header: 'status',
        accessor: 'status'
    },
     {
        Header: 'shceduledBy',
        accessor: 'shceduledBy?.id'
    },
     {
        Header: 'orchard',
        accessor: 'orchard?.id'
    },
     {
        Header: 'userInCharge',
        accessor: 'userInCharge?.id'
    },
     {
        Header: 'companyProduct',
        accessor: 'companyProduct?.id'
    },
     {
        Header: 'createdBy',
        accessor: 'createdBy?.id'
    },
     {
        Header: 'updatedBy',
        accessor: 'updatedBy?.id'
    },
     {
        Header: 'createdDate',
        accessor: 'createdDate'
    },
     {
        Header: 'updatedDate',
        accessor: 'updatedDate'
    },
 ];

export const orchardVisitDataDetailsArray = (orchardVisit) => [
    {data: orchardVisit?.id, dataLabel: 'id'},
    {data: orchardVisit?.phenologicalStage, dataLabel: 'phenologicalStage'},
    {data: orchardVisit?.status, dataLabel: 'status'},
    {data: orchardVisit?.shceduledBy?.id, dataLabel: 'shceduledBy'},
    {data: orchardVisit?.orchard?.id, dataLabel: 'orchard'},
    {data: orchardVisit?.userInCharge?.id, dataLabel: 'userInCharge'},
    {data: orchardVisit?.companyProduct?.id, dataLabel: 'companyProduct'},
    {data: orchardVisit?.createdBy?.id, dataLabel: 'createdBy'},
    {data: orchardVisit?.updatedBy?.id, dataLabel: 'updatedBy'},
    {data: orchardVisit?.createdDate, dataLabel: 'createdDate'},
    {data: orchardVisit?.updatedDate, dataLabel: 'updatedDate'},
];


export const newOrchardVisitFormDefinition = [
    {
        name: 'phenologicalStage',
        label: 'phenologicalStage',
        required: true,
        type: fieldTypes.text
    },

    {
        name: 'status',
        label: 'status',
        required: false,
        type: fieldTypes.text
    },

    {
        name: 'orchard',
        label: 'orchard',
        required: true,
        type: fieldTypes.entity,
        entity: 'orchards'
    },

    {
        name: 'userInCharge',
        label: 'userInCharge',
        required: true,
        type: fieldTypes.entity,
        entity: 'users'
    },

    {
        name: 'companyProduct',
        label: 'companyProduct',
        required: true,
        type: fieldTypes.entity,
        entity: 'companyProducts'
    },

];

export const editOrchardVisitFormDefinition = [
    ...newOrchardVisitFormDefinition
];

export const prepareNewOrchardVisitForServer = (form) => {
    const orchardVisit= {};

    copyPropertyOrThrow(form, orchardVisit, 'phenologicalStage', 'Debes agregar phenologicalStage de orchardVisit');
    copyPropertyOrNull(form, orchardVisit, 'status');
    copyPropertyIdOrThrow(form, orchardVisit, 'orchard', 'Debes agregar orchard de orchardVisit');
    copyPropertyIdOrThrow(form, orchardVisit, 'userInCharge', 'Debes agregar userInCharge de orchardVisit');
    copyPropertyIdOrThrow(form, orchardVisit, 'companyProduct', 'Debes agregar companyProduct de orchardVisit');

    return orchardVisit;
};

export const prepareEditedOrchardVisitForServer = (form) => {
    return prepareNewOrchardVisitForServer(form);
};

export const orchardVisitToFormData = (orchardVisit, t) => {
    const visitSectorDetails = orchardVisit?.visitSectorDetails?.map(visitSectorDetail => visitSectorDetailsToFormData(visitSectorDetail, t));

    return {
        ...orchardVisit,
        company: orchardVisit?.orchard?.company,
        userInCharge: {label: orchardVisit?.userInCharge?.email, value: orchardVisit?.userInCharge?.id},
        visitSectorDetails
    }
}

export const fenologicalStages = [
    'plant-development',
    'flower-bud-maturity',
    'budding',
    'buttoning',
    'bloom',
    'harvest-fruiting'
]

export const getFenologicalStageOptions = (t) => fenologicalStages.map(fenologicalStage => {
    return {
        value: fenologicalStage,
        label: t(fenologicalStage)
    }
});

export const applicationUnits = [
    'ml',
    'l',
    'kg',
    'g',
    'mg',
    'oz',
    'lb'
];

export const applicationUnitOptions = applicationUnits.map(applicationUnit => { 
    return { 
        value: applicationUnit, label: applicationUnit 
    } 
});

export const prepareFoliarApplicationToServer = (form) => {
    const foliarApplication= {};

    copyPropertyOrThrow(form, foliarApplication, 'dose', 'Debes agregar la dosis');
    copyPropertyValueOrThrow(form, foliarApplication, 'unit', 'Debes seleccionar la unidad');
    copyPropertyOrThrow(form, foliarApplication, 'reason', 'Debes agregar la razón');
    copyPropertyOrThrow(form, foliarApplication, 'date', 'Debes seleccionar la fecha');

    foliarApplication.dose = parseInt( foliarApplication.dose );

    return foliarApplication;
}


export const prepareSectorVisitForServer = (form) => {
    const sectorVisit= {};

    copyPropertyValueOrThrow(form, sectorVisit, 'product', 'Debes seleccionar el producto'); // *
    copyPropertyValueOrThrow(form, sectorVisit, 'orchard', 'Debes seleccionar la huerta'); // *
    copyPropertyOrThrow(form, sectorVisit, 'date', 'Debes seleccionar la fecha de la visita'); // *
    copyPropertyValueOrThrow(form, sectorVisit, 'userInCharge', 'Debes seleccionar el ingeniero a cargo'); // *
    copyPropertyValueOrThrow(form, sectorVisit, 'phenologicalStage', 'Debes el estado fenológico'); // *
    copyPropertyValueOrThrow(form, sectorVisit, 'caliber', 'Debes seleccionar el calibre');
    copyPropertyOrNull(form, sectorVisit, 'caliberComments');
    copyPropertyValueOrThrow(form, sectorVisit, 'consistency', 'Debes seleccionar la consistencia');
    copyPropertyOrNull(form, sectorVisit, 'consistencyComments');
    copyPropertyValueOrThrow(form, sectorVisit, 'madurity', 'Debes seleccionar la maduración');
    copyPropertyOrThrow(form, sectorVisit, 'plagueDamage', 'Debes seleccionar el daño por plaga');
    copyPropertyOrThrow(form, sectorVisit, 'climateDamage', 'Debes seleccionar el daño por clima');
    copyPropertyOrThrow(form, sectorVisit, 'foliarApplicationDamage', 'Debes seleccionar el daño por aplicación foliar');

    sectorVisit.plagueDamage = parseInt( sectorVisit.plagueDamage );
    sectorVisit.climateDamage = parseInt( sectorVisit.climateDamage );
    sectorVisit.foliarApplicationDamage = parseInt( sectorVisit.foliarApplicationDamage );
    sectorVisit.madurity = parseInt( sectorVisit.madurity );
    
    sectorVisit.foliarApplications = form.foliarApplications?.map(foliarApplication => {
        return prepareFoliarApplicationToServer(foliarApplication);
    })||[];

    return sectorVisit;
}

export const getEmptyOrchardVisit = () => ({
    company: null,
    date: new Date(),
    foliarApplications: [],
    sectors: [],
    visitSectorDetails: []
})

export const getOrchardSectorsColumns = (t) => [
    {
        Header: t('Sector'),
        accessor: "name"
    },
    {
        Header: t('Details'),
        accessor: "command"
    }
];

export const prepareOrchardVisitForServer = (form, t) => {
    const orchardVisit= {};

    copyPropertyIdOrThrow(form, orchardVisit, 'orchard', 'Debes seleccionar la huerta'); // *
    copyPropertyOrThrow(form, orchardVisit, 'date', 'Debes seleccionar la fecha de la visita'); // *
    copyPropertyValueOrThrow(form, orchardVisit, 'userInCharge', 'Debes seleccionar el ingeniero a cargo'); // *
    
    // get sector visit details
    orchardVisit.visitSectorDetails = form.visitSectorDetails?.map(sector => {
            return prepareVisitSectorDetailForServer(sector, t);
        })||[];

    return orchardVisit;
}