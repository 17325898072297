import React from 'react'

import './ImageWithLabel.scss';
import {Link} from "react-router-dom";

const ImageWithLabel = ({label, image, align, link, statusIcon}) => {
    const style = {
        justifyContent: align === 'left' ? 'flex-start' : ( align === 'right' ? 'flex-end' : 'center')
    }

    return (
        <div className='ImageWithLabel' style={style}>
            <div className='image-container'>
                {image}
            </div>
            { link ? <Link to={link} alt={label} >{label}</Link> : label}
            {statusIcon} 
        </div>
    );
}

export default ImageWithLabel;