import React, {useMemo, useState, useCallback } from 'react'
import { SectionTitle } from '../../../components/Dashboard/SectionTitle/SectionTitle';
import { SideMenu } from '../../../components/Dashboard/SideMenu/SideMenu';
import TopBar from '../../../components/Dashboard/TopBar/TopBar';
import {useTranslation} from "react-i18next";
import { SearchTextbox } from '../../../components/forms/SearchTextbox/SearchTextbox';
import Button from '../../../components/molecules/Button/Button';
import useTideTable from "../../../components/utility/TideReactTable/useTideTable";
import TideReactTable from "../../../components/utility/TideReactTable/TideReactTable";
import debounce from 'lodash.debounce';
import {paths} from "../../../services/routes/appRoutes";
import { Link, useNavigate } from 'react-router-dom';
import './WarehousesList.scss';
import { warehouseTableSGroups } from '../../../services/models/warehousesUtils';
import moment from 'moment';
import { generalDatesFormat } from '../../../services/generalConfig';

const customProp = "WarehousesList";

const WarehousesList = () => {
    const [textFilter, setTextFilter] = useState('');
    const {t} = useTranslation();
    const navigate = useNavigate();

    const warehousesTableColumns = useMemo(() => [
        {
            Header: 'ID',
            accessor: 'id'
        },
        {
            Header: t('Name'),
            accessor: 'name'
        },
        {
            Header: t('Created'),
            accessor: warehouse => warehouse.createdDate ? moment(warehouse.createdDate).format(generalDatesFormat) : ''
        },
        {
            Header: t('Updated'),
            accessor: warehouse => warehouse.updatedDate ? moment(warehouse.updatedDate).format(generalDatesFormat) : ''
        },
        {
            Header: t('State'),
            accessor: t('address.neighbourhood.municiaplity.state.name')
        },
        {
            Header: t("Actions"),
            accessor: warehouse => 
                    <div className="actions">
                        <Link 
                            to={ paths.mm.warehousesEdit.replace(":id", warehouse.id) }
                            className="btn-primary"
                        >
                            {t('Edit')}
                        </Link>
                    </div>
        }
    ], [t]);

    const searchFilter = useMemo(() => {
        return {
            search: textFilter
        }
    }, 
    [textFilter]);

    const requestFilters = useMemo(() => ({
        sGroups: warehouseTableSGroups,
        'exists[fillingOrder]': false,
        ...searchFilter
    }), [searchFilter]);

    const handleSearchTextChange = useCallback((e) => {
        setTextFilter(e.target.value);
    }, []);

    const debouncedChangeHandler = debounce(handleSearchTextChange, 400); 

    const requestOptions = useMemo(() => ({ customProp }), []);
    const table = useTideTable({
        entity: 'warehouses',
        columns: warehousesTableColumns,
        requestFilters,
        requestOptions
    });

    const handleNewWarehouse = useCallback(()=>navigate(paths.mm.warehousesForm), [navigate]);

    return (
        <div className="WarehousesList dashboard-container">
            <SideMenu />
            <div className='right-content'>
                <TopBar />
                <div className='main-content'>
                    <SectionTitle>{t('My-warehouses')}</SectionTitle>

                    <div className='row'>
                        <div className='col d-flex justify-content-between align-items-center gap-3'>
                            <SearchTextbox
                                placeholder={t('Search-by-name, state')}
                                onChange={debouncedChangeHandler}
                            />

                            <Button 
                                className={'submit btnNewWarehouse'} 
                                type='button' 
                                onClick={handleNewWarehouse}
                                id="add-new-warehouse"
                            >
                                + {t("Add-new-warehouse")}
                            </Button>
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="">
                            <TideReactTable 
                                {...table.tableProps} 
                                className="productos-table"
                            />
                        </div>
                    </div>   
                </div>
            </div>
        </div>
    );
}

export default WarehousesList;