import React from 'react'
import Card from '../Card/Card';
import Step from './components/Step';

import './Steps.scss';

const Steps = ({steps, currentStep}) => {
    return (
        <Card className='Steps'>
            {steps.filter(step => !step.hidden).map((step, index) => 
                <Step 
                    key={step.name} 
                    step={step} 
                    selected={index<=currentStep} 
                    isCurrrent={index===currentStep}
                />)}
        </Card>
    );
}

export default Steps;