import React from "react";
import { Link } from "react-router-dom";
import './Breadcrumbs.scss';

const Breadcrumbs = ({ crumbs }) => {
    // Don't render a single breadcrumb.
    if (crumbs.length <= 1) {
        return null;
    }

    return (
        <div className="Breadcrumbs">
            {/* Link back to any previous steps of the breadcrumb. */}
            {crumbs.map(({ text, path }, key) =>
                key + 1 === crumbs.length ? (
                    <span key={key}>
                        {text}
                    </span>
                ) : (
                    <div className="Breadcrumbs__Link" key={key}>
                        <Link key={key} to={path}>
                            {text}
                        </Link>
                        <div className="Breadcrumbs__separator">
                            {">"}
                        </div>
                    </div>
                )
            )}
        </div>
    );
};
export default Breadcrumbs;