import React from 'react'
import { SectionTitle } from '../../../components/Dashboard/SectionTitle/SectionTitle';
import { SideMenu } from '../../../components/Dashboard/SideMenu/SideMenu';
import TopBar from '../../../components/Dashboard/TopBar/TopBar';
import LabelStatus from '../../../components/Dashboard/LabelStatus/LabelStatus';
import useTideTable from "../../../components/utility/TideReactTable/useTideTable";
import TideReactTable from "../../../components/utility/TideReactTable/TideReactTable";
import CustomCheckbox from '../../../components/Dashboard/CustomCheckbox/CustomCheckbox';
import SearchBar from '../../../components/SearchBar/SearchBar';
import { SearchTextbox } from '../../../components/forms/SearchTextbox/SearchTextbox';
import { t } from 'i18next';
import './Requests.scss';
import {filterTypes} from "../../../services/searchUtils";

const tableColumns = [
    {
        Header: <>
                    <CustomCheckbox> 
                        Quantity
                    </CustomCheckbox>                        
                </>,
        accessor: "quantity"
    },
    {
        Header: "Package",
        accessor: "Package"
    },
    {
        Header: "Product",
        accessor: "product"
    },
    {
        Header: "Buyer",
        accessor: "buyer"
    },
    {
        Header: "Status",
        id: 'status',
        accessor: (row) => <LabelStatus status={row.status} statusText={row.status} />
    },
    {
        Header: "Order Created",
        accessor: "orderCreated"
    },
    {
        Header: "",
        accessor: "commands"
    }
]

const filtersConfig = {
    filters: [
        {
            type: filterTypes.SELECT,
            field: 'value',
            label:'Productos',
            options:[{'label': 'Avocado', 'value': 'Avocado'}, {'label': 'Onion', 'value': 'Onion'}],
            placeholder: "All Products"
        },
        {
            type: filterTypes.SELECT,
            field: 'value',
            label:'Status',
            options:[{'label': 'PENDING', 'value': 'PENDING'}, {'label': 'DELIVERED', 'value': 'DELIVERED'}],
            placeholder: "All Status"
        },
        {
            type: filterTypes.SELECT,
            field: 'value',
            label:'Status',
            options:[{'label': 'date1', 'value': 'date1'}, {'label': 'date2', 'value': 'date2'}],
            placeholder: "All Dates"
        }
    ]
};

const Requests = () => {
    const table = useTideTable({
        entity: 'users',
        columns: tableColumns
    });

    return (
        <div className="Requests">
            <SideMenu />
            <div className='right-content'>
                <TopBar />
                <div className='main-content'>
                    <SectionTitle>My Requests</SectionTitle>

                    <div className='row'>
                        <SearchTextbox 
                            placeholder={t('Search by name, product...')}
                        />
                    </div>

                    <div className="row">
                        <div className="">
                            <SearchBar filtersConfig={filtersConfig} />
                        </div>
                    </div>   
                    <div className="row">
                        <div className="">
                            <TideReactTable {...table.tableProps} />
                        </div>
                    </div>   
                </div>
            </div>
        </div>
    )
}

export default Requests;