import React, { useContext } from 'react'
import { ApiContext } from '../../../services/api/api-config';

const FileLink = ({file, label}) => {
    const api = useContext(ApiContext);

    return (
        <div>
            <a href={api?.appFiles.getUrl({id: file.id})} target="_blank" rel="noreferrer">
                {label}
            </a>
        </div>
    );
}

export default FileLink;