import React, {useCallback} from 'react';
import './Checkbox.scss';
import classNames from "classnames";
import {ReactComponent as Check} from "../../../assets/images/icons/check.svg";

const Checkbox = ({ value, onChange, className }) => {

    const handleClick = useCallback(()=>onChange && onChange(!value), [onChange, value])

    return (
        <div className={classNames("Checkbox", className, !!value&&'active')} >
            {!!value && <span className='icon-cont'><Check /></span>}
            <input className='check-input' checked={!!value} onChange={handleClick} type={"checkbox"} />
        </div>
    );
};

export default Checkbox;
