import React from 'react'
import { useTranslation } from 'react-i18next';
import MiniTitle from '../../../../../components/Dashboard/MiniTitle/MiniTitle';
import SliderWithLabel from '../SliderWithLabel/SliderWithLabel';

import './Properties.scss';

const Properties = ({ bindSimple, bindInput }) => {
    const {t} = useTranslation();

    return (
        <div className='Properties mt-3'>
            <MiniTitle className="product-title">{t('Properties')}</MiniTitle>

            <div className='row'>
                <div className='col-md-6'>
                    <SliderWithLabel
                        label={t('Madurity')}
                        className='mt-4'
                        {...bindSimple('madurity')}
                    />
                </div>
                <div className='col-md-6'>
                    <SliderWithLabel
                        label={t('Plague-demage')}
                        className='mt-4'
                        {...bindSimple('plagueDamage')}
                    />
                </div>
            </div>

            <div className='row'>
                <div className='col-md-6'>
                    <SliderWithLabel
                        label={t('Climate-damage')}
                        className='mt-4'
                        {...bindSimple('climateDamage')}
                    />
                </div>
                <div className='col-md-6'>
                    <SliderWithLabel
                        label={t('Foliar-application-damage')}
                        className='mt-4'
                        {...bindSimple('foliarApplicationDamage')}
                    />
                </div>
            </div>
        </div>
    );
}

export default Properties;