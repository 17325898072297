import React, {useMemo, useState, useContext, useEffect } from 'react'
import { SectionTitle } from '../../../components/Dashboard/SectionTitle/SectionTitle';
import { SideMenu } from '../../../components/Dashboard/SideMenu/SideMenu';
import TopBar from '../../../components/Dashboard/TopBar/TopBar';
import {useTranslation} from "react-i18next";
import Button from '../../../components/molecules/Button/Button';
import useTideTable from "../../../components/utility/TideReactTable/useTideTable";
import TideReactTable from "../../../components/utility/TideReactTable/TideReactTable";
import { ApiContext } from '../../../services/api/api-config';
import moment from 'moment';
import { generalDatesFormat } from '../../../services/generalConfig';
import SelectWithLabel from '../../../components/forms/SelectWithLabel/SelectWithLabel';
import { foliarApplicationsTableSGroups } from '../../../services/models/foliarApplicationUtils';
import FoliarApplicationsForm from '../../../components/FoliarApplicationsForm/FoliarApplicationsForm';
import './FoliarApplicationsList.scss';
import { orchardDetailSGroups } from '../../../services/models/orchardUtils';
import useFormState from '../../../hooks/useFormState';

const customProp = 'applicationForm';

const FoliarApplicationsList = () => {
    const api = useContext(ApiContext);
    const {t} = useTranslation();

    // states
    const [orchards, setOrchards] = useState();
    const {form, bindSimple} = useFormState({});
    const [selectedApplication, setSelectedApplication] = useState();
    const [showApplicationForm, setShowApplicationForm] = useState();

    useEffect(() => {
        api.orchards.get({ params: {sGroups: orchardDetailSGroups, pagination: false}}).then(setOrchards);
    }, [api]);

    const foliarApplicationsTableColumns = useMemo(() => [
        {
            Header: 'ID',
            accessor: 'id'
        },
        {
            Header: t('Orchard'),
            accessor: 'orchard.name'
        },
        {
            Header: t('Sectors'),
            accessor: application => <div>{application.sectors.map(s => s.name).join(', ')}</div>
        },
        {
            Header: t('Created-by'),
            accessor: 'createdBy.email'
        },
        {
            Header: t('Application-date'),
            accessor: application => moment(application.date).format(generalDatesFormat)
        },
        {
            Header: t("Actions"),
            accessor: application => 
                    <div className="actions">
                        <Button 
                            onClick={() => {
                                setSelectedApplication(application);
                                setShowApplicationForm(true);
                            }} 
                            className="btn-primary"
                        >
                            {t('Edit')}
                        </Button>
                    </div>
        }
    ], [t]);

    const orchardFilter = useMemo(() =>{
        let filter = null;

        if(form.orchard?.value){
            filter = {
                orchard: form.orchard.value
            }
        }

        return filter;
    }, [form]);


    const requestFilters = useMemo(() => ({
        sGroups: foliarApplicationsTableSGroups,
        ...orchardFilter
    }), [orchardFilter]);

    const requestOptions = useMemo(() => ({
        customProp
    }), []);

    const table = useTideTable({
        entity: 'foliarApplications',
        columns: foliarApplicationsTableColumns,
        requestFilters,
        requestOptions
    });

    const orchardOptions = useMemo(() => orchards?.map(orchard => ({value: orchard.id, label: orchard.name})), [orchards]);

    return (
        <div className="FoliarApplicationsList dashboard-container">
            <SideMenu />
            <div className='right-content'>
                <TopBar />
                <div className='main-content'>
                    <SectionTitle>{t('Foliar-applications')}</SectionTitle>

                    <div className='filter-container'>
                        <div className='row'>
                            <div className='col d-flex justify-content-between align-items-center gap-3'>
                                <SelectWithLabel
                                    className='select-filter'
                                    label={t('Orchard')}
                                    inputProps={{
                                        options: orchardOptions,
                                        ...bindSimple('orchard')
                                    }}
                                />

                                <Button 
                                    className={'submit btnNewProducer'} 
                                    type='button' 
                                    onClick={() => {
                                        setShowApplicationForm(true);
                                        setSelectedApplication(null);
                                    }}
                                    id="add-new-foliar-application"
                                >
                                    + {t("Add-new-foliar-application")}
                                </Button>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="">
                            <TideReactTable 
                                {...table.tableProps} 
                                className="productos-table"
                            />
                        </div>
                    </div>   
                </div>
            </div>

            {showApplicationForm && 
                <FoliarApplicationsForm 
                    onClose={() => setShowApplicationForm(false)}
                    reloadTable={table.reload}
                    application={selectedApplication}
                    orchards={orchards}
                />}
        </div>
    );
}

export default FoliarApplicationsList;