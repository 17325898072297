import React, { useEffect, useState, useContext, useCallback, useMemo } from 'react'
import {useTranslation} from "react-i18next";
import { SectionTitle } from '../../../../../components/Dashboard/SectionTitle/SectionTitle';
import { ApiContext } from '../../../../../services/api/api-config';
import { getNotifier } from '../../../../../services/notifier';
import TextInput from '../../../../../components/forms/TextInput/TextInput';
import GoogleMapReact from 'google-map-react';
import Autocomplete from './components/Autocomplete/Autocomplete';
import AddButton from '../../../../../components/forms/AddButton/AddButton';
import ProductRowCard from '../../../../../components/Dashboard/ProductRowCard/ProductRowCard';
import Marker from './components/Marker/Marker';
import NewSectorModal from './components/NewSectorModal/NewSectorModal';
import SectorsForm from '../SectorsForm/SectorsForm';
import SectionSubTitle from '../../../../../components/Dashboard/SectionSubTitle/SectionSubTitle';
import EmptyProductsMessage from './components/EmptyProductsMessage/EmptyProductsMessage';
import GrowerChangeStatusButtons from '../GrowerChangeStatusButtons/GrowerChangeStatusButtons';
import {ReactComponent as PlusIcon} from "../../../../../assets/images/icons/plus.svg";
import { useParams, useNavigate } from 'react-router-dom';
import useFormState from '../../../../../hooks/useFormState';
import { getEmptyOrchard, prepareNewOrchardForServerFromGrower } from '../../../../../services/models/orchardUtils';
import Button from '../../../../../components/molecules/Button/Button';
import { companyProductDetailSGroups } from '../../../../../services/models/companyProductUtils';
import { getEmptySector, sectorDetailSGroups } from '../../../../../services/models/sectorUtils';
import {ReactComponent as NextIcon} from "../../../../../assets/images/icons/arrow-details.svg";
import { paths } from '../../../../../services/routes/appRoutes';
import './NewGrowerFormStep3.scss';
import { neighbourhoodFullDatasSGroups } from '../../../../../services/models/neighbourhoodUtils';
import Textarea from '../../../../../components/forms/Textarea/Textarea';
import { getAddressFromGoogleMapsAdressComponents } from '../../../../../services/models/addressUtils';
import { GoogleMapsApiKey } from '../../../../../services/generalConfig';
import Certification from '../SectorsForm/components/Certification/Certification';
import useCallbackCreator from 'use-callback-creator';
import _ from 'lodash';
import { getEmptyCertification, prepareCertificationFilesForServer } from '../../../../../services/models/certificationUtils';
import SectorProductForm from './components/SectorProductForm/SectorProductForm';
import { useSelector } from 'react-redux';

const defaultProps = {
    center: {
      lat: 19.2978263,
      lng: -99.2006389
    },
    zoom: 11
};

const NewGrowerFormStep3 = ({grower, reloadGrower, gotoStep2, setOrchards, setOrchard, orchard}) => {
    const api = useContext(ApiContext);
    const {t} = useTranslation();
    const { id, step, orchardId } = useParams();
    const navigate = useNavigate();
    const [mapInstance, setMapInstance] = useState();
    const [mapApi, setMapApi] = useState();
    const [mapPosition, setMapPosition] = useState(defaultProps);
    const [currentMarker, setCurrentMarker] = useState(defaultProps.center);
    const [showingNewSector, setShowingNewSector] = useState();
    const [showingNewProduct, setShowingNewProduct] = useState();
    const { form, bindSimple, bindInput, setForm, handleInputChange, handleSimpleChange } = useFormState({...orchard});
    const [product, setProduct] = useState();
    const isEditingMode = ((id !== undefined && id !== null) && (!step || orchardId ));
    const loadingId = `Grower.form.step3.${id}`;

    const loadOrchardData = useCallback(async () => {
        const products = await api.companyProducts.get({ params: { "sector.orchard": orchard.id, sGroups: companyProductDetailSGroups }});
        const sectors = await api.sectors.get({ params: { "orchard": orchard.id, sGroups: sectorDetailSGroups }});
        
        // set formattedAddress 
        let formattedAddress = '';

        if(orchard?.address && orchard?.address?.id) {
            formattedAddress = `${orchard?.address?.street} ${orchard?.address?.externalNumber}, ${orchard?.address?.neighbourhood?.label}, ${orchard?.address?.municipality?.label}, ${orchard?.address?.state?.label}, ${orchard?.address?.country?.label}`;
        }

        setForm({
            ...orchard,
            products: products.map(product => ({...product})),
            sectors: sectors.map(sector => ({...sector})),
            formattedAddress
        });
    }, [api, setForm, orchard]);

    // update map position
    useEffect(() => {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                setMapPosition({
                    center: [position.coords.latitude, position.coords.longitude],
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                    zoom: 11
                });
            });
        }

        setForm(prevState => ({...prevState, ...orchard}));
    }, [orchard, setForm]);

    // load orchards
    useEffect(() => {
        if ((isEditingMode||step) && orchard) {
            loadOrchardData();
        }
    }, [loadOrchardData, orchard, step, isEditingMode]);

    const onClickMap = async (e) => {
        let formattedAddress = '';
        let addressForm = null;

        setCurrentMarker({
            lat: e.lat,
            lng: e.lng,
            zoom: 11
        });

        // get address from latlng
        const geocoder = new window.google.maps.Geocoder();
        const latlng = new window.google.maps.LatLng(e.lat, e.lng);
        geocoder.geocode({'location': latlng}, async function(results, status) {
            if (status === 'OK') {
                if (results[0]) {
                    const addressComponents = results[0];
                    const zipCode = addressComponents.address_components.find(component => component.types.includes('postal_code'));
                    
                    // get data from api for this zip code
                    const neighborhood = await api.neighbourhoods.get({ params: { zipCode: zipCode?.long_name, sGroups: neighbourhoodFullDatasSGroups }});
                    addressForm = getAddressFromGoogleMapsAdressComponents(addressComponents, neighborhood[0]);

                    formattedAddress = results[0]?.formatted_address;
                }
            }

            setForm({...form, latitude: e.lat, longitude: e.lng, formattedAddress: formattedAddress, address: addressForm});
        });
    };    

    const apiHasLoaded = (map, maps) => {
        setMapInstance(map);
        setMapApi(maps);
    }

    const onCreateProduct = () => {
        setShowingNewProduct(false);
        loadOrchardData();
    }

    const handleNewOrchard = () => {
        setForm({...getEmptyOrchard(), latitude: null, longitude: null, address: null});
        setOrchard(null);
    }

    const handleCreateOrUpdateOrchard = () => {
        let _orchard = null;
        const id = form?.id;
        const apiMethod = id ? 'update' : 'create';

        try {
            _orchard = prepareNewOrchardForServerFromGrower({...form});
            _orchard.company = grower.id;
            _orchard.id = id;
        } catch(e) {
            return getNotifier().error(e.message||e.detail);
        }

        const files = prepareCertificationFilesForServer(form);

        api.orchards.withCertifications({params: _orchard, files, method: "POST"})
        .then(newOrchard => {
            setOrchard(newOrchard);
            const notifyMessage = apiMethod === 'create' ? t('Orchard-created-successfully') : t('Orchard-updated-successfully');
            getNotifier().success(t(notifyMessage));
        })
        .catch(e => getNotifier().error(e.message||e.detail));
    }

    const handleEditProduct = (product) => {
        setProduct(product);
        setShowingNewProduct(true);
    }

    const handleNewProduct = () => {
        setProduct(product);
        setShowingNewProduct(true);
    }

    const handleAddSectorForm = () => {
        setForm({
            ...form,
            sectors: [...form.sectors||[], getEmptySector()]
        });
    }

    const handleCertificationChange = useCallbackCreator((index, certification)=>{
        const newForm = _.cloneDeep(form);
        newForm.otherCertifications[index] = certification;
        setForm(newForm);
    }, [setForm, form]);

    const mapOptions = useMemo(() => {
        return {
            mapTypeId: mapApi?.MapTypeId.SATELLITE,
            mapTypeControl: true,
            mapTypeControlOptions: {
                position: mapApi?.ControlPosition.TOP_CENTER,
                style: mapApi?.MapTypeControlStyle.DROPDOWN_MENU,
                mapTypeIds: [
                    mapApi?.MapTypeId.ROADMAP,
                    mapApi?.MapTypeId.SATELLITE,
                    mapApi?.MapTypeId.HYBRID,                                        
                    mapApi?.MapTypeId.TERRAIN
                ]
            },
        };
    }, [mapApi]);

    const sectorsSaved = form.sectors.filter(sector => sector.id);
    const loading = useSelector(s=>!!s.loadingIds[loadingId]);

    return (
        <div className='NewGrowerFormStep3 mt-0'>
            <div className='d-flex align-items-center justify-content-between'>
                <SectionTitle className='mt-0 mb-0'>
                    { id && form?.name ? form?.name : `1. ${t('Add-orchard')}` }

                    {isEditingMode &&
                        <div onClick={handleNewOrchard} className='NewGrowerFormStep3__add-button'>
                            <PlusIcon /> {t('Add-new-orchard')}
                        </div>}
                </SectionTitle>
                
                {isEditingMode && false &&
                    <GrowerChangeStatusButtons
                        grower={grower}
                        onUpdateStatus={reloadGrower}
                    />}
            </div>

            <SectionTitle>{t('General-Information')}</SectionTitle>

            <div className='row mt-3'>
                <div className='col-md-3 col-md-6 margin-top-mobile'>
                    <TextInput
                        label={t('Name')}
                        placeholder={t('Orchard-name')}
                        {...bindInput('name')}
                    />
                </div>
            </div>

            <SectionTitle>{t('Location')}</SectionTitle>

            <div className='row'>
                <div className='col-md-8 margin-top-mobile'>
                    {mapApi && mapInstance && <Autocomplete map={mapInstance} mapApi={mapApi} addplace={() => {}} />}
                </div>
            </div>
            <div className='row mt-3'>
                <div className='col-md-8 margin-top-mobile'>
                    <div style={{ height: '304px', width: '100%' }}>
                        <GoogleMapReact
                            defaultCenter={mapPosition.center}
                            defaultZoom={mapPosition.zoom}
                            onClick={onClickMap}
                            yesIWantToUseGoogleMapApiInternals
                            onGoogleApiLoaded={({ map, maps }) => apiHasLoaded(map, maps)}
                            bootstrapURLKeys={{
                                key: GoogleMapsApiKey,
                                libraries: ['places', 'geometry'],
                            }}
                            options={mapOptions}
                        >
                            <Marker
                                lat={currentMarker.lat}
                                lng={currentMarker.lng}
                                text="Orchard"
                            />
                        </GoogleMapReact>
                    </div>
                </div>
                <div className='col-md-4 margin-top-mobile'>
                    <TextInput
                        label={t('Coordinates')}
                        placeholder={t('Coordinates')}
                        value={`${currentMarker.lat}, ${currentMarker.lng}`}
                        inputProps={{
                            disabled: true
                        }}
                    />
                    
                    <Textarea
                        className="mt-3"
                        label={t('Address')}
                        placeholder={t('Address')}
                        value={form?.formattedAddress||''}
                        inputProps={{
                            disabled: true
                        }}
                    />

                    <Textarea
                        className="mt-3"
                        label={t('References')}
                        placeholder={t('References')}
                        {...bindInput('addressReferences')}
                    />
                </div>
            </div>     
            
            <SectionTitle>{t('Surface')}</SectionTitle>
            
            <div className='row mt-3'>
                <div className='col-6 col-lg-3 margin-top-mobile'>
                    <TextInput
                        label={'Surface'}
                        placeholder={t('Surface Ha')}
                        {...bindInput('hectares')}
                        type="number"
                    />
                </div>
            </div> 

            <SectionTitle>{t('Certifications')}</SectionTitle>

            <Certification
                onChange={usaOrganicCertification => setForm({...form, usaOrganicCertification})}
                title={'USA Organic'}
                certification={form.usaOrganicCertification}
            />

            <Certification
                onChange={mexOrganicCertification => setForm({...form, mexOrganicCertification})}
                title={'Mex Organic'}
                certification={form.mexOrganicCertification}
            />

            <Certification
                onChange={euroOrganicCertification => setForm({...form, euroOrganicCertification})}
                title={'Europ Organic'}
                certification={form.euroOrganicCertification}
            />

            <>
                {form.otherCertifications?.map((certification, index) => (
                    <Certification
                        key={index}
                        onChange={handleCertificationChange(index)}
                        title={t('other')}
                        certification={certification}
                        isGeneric={true}
                    />
                ))}
                <Button
                    onClick={() => setForm({...form, otherCertifications: [...(form.otherCertifications||[]), getEmptyCertification()]})}
                    className='mt-3'
                    text={t('Add-other-certification')}
                >
                    Add Certification
                </Button>
            </>

            {form?.id &&
                <div>
                    <SectionSubTitle>2. {t('Create-sectors')}</SectionSubTitle>

                    <SectorsForm
                        form={form}
                        bindSimple={bindSimple}
                        bindInput={bindInput}
                        setForm={setForm}
                        handleInputChange={handleInputChange}
                        handleSimpleChange={handleSimpleChange}
                        orchard={form}
                        loadOrchardData={loadOrchardData}
                    />

                    
                    {sectorsSaved?.length > 0 ?
                        <>
                            <SectionSubTitle>3. {t('Add-products')}</SectionSubTitle>

                            <div className='row mt-3'>
                                <div className='col-md-4 margin-top-mobile'>
                                    <AddButton
                                        onClick={handleNewProduct}
                                    >
                                        {t('Add-product-here')}
                                    </AddButton>
                                </div>
                            </div>
                        </> :
                            <EmptyProductsMessage onClick={handleAddSectorForm} />
                        }
                    

                    {form?.products?.map((product) => 
                        <div className='row mt-4' key={product.id}>
                            <div className='col-md-12 margin-top-mobile'>
                                <ProductRowCard 
                                    productId={product?.id} 
                                    onClick={handleEditProduct}
                                />
                            </div>
                        </div>
                    )}
                </div>
            }

            <div className='row NextStepButtons'>
                { isEditingMode ?
                    <Button
                        className={'btn-primary btn-outline NextStepButtons__button'}
                        onClick={() => navigate(paths.mm.growers) }
                        design={'clear'}
                    >
                        {t('Cancel-changes')}
                    </Button>
                    :
                    <Button
                        className={'btn-primary btn-outline NextStepButtons__button'}
                        onClick={gotoStep2}
                        design={'clear'}
                    >
                        {t('Back')}
                    </Button>}


                <Button 
                    className={`btn-primary btnNextStep NextStepButtons__button NextStepButtons__button--0`}
                    onClick={handleCreateOrUpdateOrchard}
                    style={{maxWidth: '350px'}}
                    disabled={loading}
                >
                    { isEditingMode ? t('Save') : t('Create-orchard-and-continue') }
                    { !isEditingMode && <NextIcon /> }
                </Button>
            </div>

            {showingNewSector && <NewSectorModal onClose={() => setShowingNewSector(false)} secondaryButtonAction={() => setShowingNewSector(false)} />}

            {showingNewProduct && 
                <SectorProductForm
                    onClose={() => setShowingNewProduct(false)} 
                    onCreateProduct={onCreateProduct}
                    secondaryButtonAction={() => setShowingNewProduct(false)} 
                    orchard={form}
                    grower={grower}
                    productId={product?.id}
                />}
        </div>
    );
}

export default NewGrowerFormStep3;