import React from 'react'
import {useTranslation} from "react-i18next";
import {ReactComponent as InfoIcon} from "../../../../../assets/images/icons/info.svg";
import Button from '../../../../../components/molecules/Button/Button';

import './FirstApplicationMessage.scss';

const FirstApplicationMessage = ({ onClick }) => {
    const {t} = useTranslation();

    return (
        <div className='FirstApplicationMessage'>
            <InfoIcon />

            <div className='FirstApplicationMessage__text'>
                {t('Add-your-first-application-here')}
            </div>

            <Button
                onClick={onClick}
                className='mt-3'                
            >
                {t('Add-foliar-application')}
            </Button>
        </div>
    );
}

export default FirstApplicationMessage;