import React, { useContext, useState, useEffect } from 'react'
import { SideMenu } from '../../../components/Dashboard/SideMenu/SideMenu';
import Steps from '../../../components/Dashboard/Steps/Steps';
import TopBar from '../../../components/Dashboard/TopBar/TopBar';
import {useTranslation} from "react-i18next";
import { getOrderSteps, productOfferVisitsSectionSGroups } from '../../../services/models/productOfferUtils';
import Card from '../../../components/Dashboard/Card/Card';
import CardHeader from '../../../components/Dashboard/CardHeader/CardHeader';
import { SectionTitle } from '../../../components/Dashboard/SectionTitle/SectionTitle';
import { ApiContext } from '../../../services/api/api-config';
import './OrdersFormStep3.scss';
import { useParams } from 'react-router-dom';
import { getNotifier } from '../../../services/notifier';
import FoliarApplicationsForm from './components/FoliarApplicationsForm/FoliarApplicationsForm';
import VisitDetails from './components/VisitDetails/VisitDetails';

const OrdersFormStep3 = () => {
    const api = useContext(ApiContext);
    const {t} = useTranslation();
    const {id} = useParams();
    const [orderProducts, setOrderProducts] = useState([]);
    const [visits, setVisits] = useState([]);

    // load data from api
    useEffect(() => {
        let allVisits = [];

        const getOrderDetails = async (id) => {
            try {
                const orderHeaders = await api.productOffers.get({params: {
                    fillingOrder: id,
                    sGroups: productOfferVisitsSectionSGroups
                }, customProp: 'productOffers'});
    
                const orderProducts = await api.productOffers.get({params: {
                    fillingOrder: orderHeaders[0].id,
                    sGroups: productOfferVisitsSectionSGroups
                }, customProp: 'products'});
                
                orderProducts.forEach(orderProduct => {
                    orderProduct.companyProduct.orchardVisits.forEach(orchardVisit => {
                        orchardVisit.product = orderProduct;
                        orchardVisit.companyProduct = orderProduct.companyProduct;
                        allVisits.push(orchardVisit);
                    });
                });

                setVisits(allVisits);
                setOrderProducts(orderProducts);
            } catch (error) {
                getNotifier().error(error.message||error.detail);
            }
            

        }

        getOrderDetails(id);
        //api.orchardVisits.get({params: {companyProduct: 5, sGroups: orchardVisitDetailSGroups}}).then(setVisits).catch(error => {getNotifier().error(error.message||error.detail);});
    }, [id, api]);

    const companyProductOptions = orderProducts.map(orderProduct => {
        return {
            value: orderProduct.companyProduct.id,
            label: `${orderProduct.companyProduct.name} - Sector: ${orderProduct.companyProduct.sector.name}`,
            product: orderProduct
        }
    });

    return (
        <div className='OrdersFormStep2 dashboard-container'>
            <SideMenu />
            <div className='right-content'>
                <TopBar />
                <div className='main-content'>
                    <Steps steps={getOrderSteps(t)} currentStep={2} />

                    <div className='row'>
                        <div className='col-md-8'>
                            <Card>
                                <CardHeader>
                                    <SectionTitle>{t('Product-follow-up')}</SectionTitle>

                                    {/*<Button
                                        design='link'
                                        onClick={addNewVisit} >
                                            +{t('Add-new-visit')}
                                    </Button>*/}
                                </CardHeader>

                                <div>
                                    {visits.map((visit, index) => 
                                        <VisitDetails visit={visit} key={index} />
                                    )}
                                </div>

                                <FoliarApplicationsForm companyProductOptions={companyProductOptions} />
                            </Card>
                        </div>

                        
                        <div className='col-md-4'>
                            
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OrdersFormStep3;