import React from "react";
import classNames from "classnames";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import SearchBarSelectFilter from "../../SearchBar/filters/SearchBarSelectFilter/SearchBarSelectFilter";
import SearchBarDateFilter from "../../SearchBar/filters/SearchBarDateFilter/SearchBarDateFilter";
import {ReactComponent as CloseIcon} from "../../../assets/images/icons/close.svg";
import './RightFilterBar.scss';

const marks = {
    0: <strong>0</strong>,
    100: <strong>100</strong>,
    200: <strong>200</strong>,
    300: <strong>300</strong>,
    400: <strong>400</strong>,
    500: <strong>500</strong>,
};

const RightFilterBar = ({openFilterBar, setOpenFilterBar}) => {
    return (
        <div className={classNames("RightFilterBar", !!openFilterBar&&'RightFilterBar--open')}>
            <div className="RightFilterBar__close-container">
                <div className="RightFilterBar__close-button" onClick={()=>setOpenFilterBar(false)}>
                    <CloseIcon />
                </div>
            </div>
            <div className="RightFilterBar__titles">
                <div className={"RightFilterBar__title"}>Filter by</div>
                <div className={"RightFilterBar__clear-link"}>Clear all</div>
            </div>
            <div className={"RightFilterBar__filters"}>
                <label className={"RightFilterBar__filter-label"}>Fruit</label>
                <SearchBarSelectFilter
                    placeholder={"Select fruit"}
                    config={{
                        value: "",
                        options: [
                            "Apple",
                            "Banana",
                            "Orange",
                            "Pear"]
                    }}
                />

                <label className={"RightFilterBar__filter-label"}>Quantity (kg)</label>
                <Slider
                    range
                    marks={marks}
                    draggableTrack
                    defaultValue={[20, 40]}
                    min={0}
                    max={500}
                    step={50}
                />

                <label className={"RightFilterBar__filter-label"}>Packaging type</label>
                <SearchBarSelectFilter
                    placeholder={"Select package"}
                    config={{
                        value: "",
                        options: [
                            "Apple",
                            "Banana",
                            "Orange",
                            "Pear"]
                    }}
                />

                <label className={"RightFilterBar__filter-label"}>Country</label>
                <SearchBarSelectFilter
                    placeholder={"Country"}
                    config={{
                        value: "",
                        options: [
                            "Apple",
                            "Banana",
                            "Orange",
                            "Pear"]
                    }}
                />

                <label className={"RightFilterBar__filter-label"}>Availability</label>
                <SearchBarDateFilter
                    placeholder={"Select date"}
                    label={'From'}
                    withIcon={true}
                />
                <SearchBarDateFilter
                    placeholder={"Select date"}
                    label={'To'}
                    withIcon={true}
                />

                <div className={"ButtonsContainer"}>
                    <div className="cancelButton" onClick={()=>setOpenFilterBar(false)}>Cancel</div>
                    <button className={"Button Button--primary"}>Apply Filters</button>
                </div>
            </div>
        </div>
    );
}

export default RightFilterBar;