//Let's order the translations alphabetically
const translations = {
    "Accepted": "Accepted",
    "account-confirmation-error": "We couldn't confirm your account",
    "account-confirmed": "Your account has been confirmed successfully",
    "Actions": "Actions",
    "Active": "Active",
    "Active-certification": "Active certification",
    "Activate": "Activate",
    "Activate-user": "Activate user",
    "Active-ingredient": "Active ingredient",
    "Active-ingredients": "Active ingredients",
    "Active-ingredient-created-successfully": "Active ingredient created successfully",
    "Active-ingredient-updated-successfully": "Active ingredient updated successfully",
    "Add-document": "Add document",
    "Add-file": "Add file",
    "Add-foliar-application": "Add foliar application",
    "Add-foliar-application-to-sectors": "Add foliar application to sectors",
    "Add-hobbies-extra-activities-etc": "Add hobbies, extra activities, etc",
    "Add-Links": "Add Links",
    "add-Machinery": "Add Machinery",
    "Add-more": "Add more",
    "Add-myfirst-Sector": "Add my first Sector",
    "Add-new-active-ingredient": "Add new active ingredient",
    "add-new-application": "Add new application",
    "Add-new-brand": "Add new brand",
    "Add-new-foliar-application": "Add new foliar application",
    "Add-new-orchard": "Add new orchard",
    "add-new-order": "Add new order",
    "Add-new-product": "Add new product",
    "add-new-productor": "Add new productor",
    "Add-New-Productor": "Add new productor",
    "Add-new-sector": "Add new sector",
    "Add-new-user": "Add new user",
    "Add-new-visit": "Add new visit",
    "Add-new-warehouse": "Add new warehouse",
    "Add-orchard": "Add orchard",
    "Add-other-comments-here": "Add other comments here",
    "Add-product": "Add product",
    "Add-product-here": "Add product here",
    "Add-products": "Add products",
    "Add-sector-here": "Add sector here",
    "Add-value": "Add value",
    "Add-variant": "Add variant",
    "Add-variant-attribute": "Add variant attribute",
    "Add-variant-value": "Add variant value",
    "Add-variation": "Add variation",
    "Add-variety": "Add variety",
    "Add-variety-attribute": "Add variety attribute",
    "Add-new-followup": "Add new followup",
    "Add-variety-value": "Add variety value",
    "Add-visit-sector-details": "Add visit sector details",
    "Add-sector-details": "Add sector details",
    "Add-your-first-application-here": "Add your first application here",
    "Admin-user": "Admin user",
    "Are-you-sure-you-want-to-delete-this-productor": "Are you sure you want to delete this productor?",
    "Address": "Address",
    "address": "address",
    "address.createdBy": "created by",
    "address.createdDate": "created date",
    "address.externalNumber": "external number",
    "address.internalNumber": "internal number",
    "address.latitude": "latitude",
    "address.longitude": "longitude",
    "address.neighbourhood": "neighbourhood",
    "address.street": "street",
    "address.updatedBy": "updated by",
    "address.updatedDate": "updated date",
    "already-have-account": "Already have an account?",
    "Analysis": "Analysis",
    "An-error-occurred-while-creating-the-attribute": "An error occurred while creating the attribute",
    "An-error-occurred-while-creating-the-value": "An error occurred while creating the value",
    "appFile": "file",
    "appFile.createdBy": "created by",
    "appFile.createdDate": "created date",
    "appFile.mimeType": "mime type",
    "appFile.name": "name",
    "appFile.originalName": "original name",
    "appFile.size": "size",
    "appFile.updatedBy": "updated by",
    "appFile.updatedDate": "updated date",
    "Application no": "Application no",
    "Applications": "Applications",
    "Application-date": "Application date",
    "Approve-productors": "Approve productors",
    "Approve-productors-description": "User will be able to review created productor users and accept them or remove them.",
    "Are-you-sure-you-want-to-activate-this-user": "Are you sure you want to activate this user?",
    "Are-you-sure-you-want-to-disable-this-user": "Are you sure you want to disable this user?",
    "Are-you-sure-you-want-to-delete-this-visit": "Are you sure you want to delete this visit?",
    "As-a-grower": "As a Grower",
    "As-a-wholesale": "As a Wholesale",
    "Attribute": "Attribute",
    "available": "available",
    "bad": "Bad",
    "Billing-Constancy": "Billing Constancy",
    "Billing-data": "Billing data",
    "Birthday-date": "Birthday date",
    "bloom": "Bloom",
    "Boxes/Pallet": "Boxes/Pallet",
    "boxes-left-with-this-productor": "boxes left with this productor",
    "Boxes-per-pallet": "Boxes per pallet",
    "Brand": "Brand",
    "Brands": "Brands",
    "Brand-created-successfully": "Brand created successfully",
    "Brand-updated-successfully": "Brand updated successfully",
    "Brand's name": "Brand's name",
    "BROTHER": "Brother",
    "budding": "Budding",
    "Business-name": "Business name",
    "buttoning": "Buttoning",
    "Buyer": "Buyer",
    "Caliber": "Caliber",
    "Cancel": "Cancel",
    "Cancel-changes": "Cancel changes",
    "cer-here": ".cer here",
    "Certificate-and-digital-seal": "Certificate and digital seal",
    "Certifications": "Certifications",
    "Client": "Client",
    "Client-order": "Client order",
    "Climate-damage": "Climate damage",
    "Comments": "Comments",
    "company": "company",
    "Company-name": "Company name",
    "Company-name-here": "Company name here",
    "company.address": "address",
    "company.boughtProductOffers": "bought product offers",
    "company.businessName": "business name",
    "company.classification": "classification",
    "company.companyCode": "company code",
    "company.companyProducts": "company products",
    "company.contactCellphoneNumber": "contact cellphone number",
    "company.contactEmail": "contact email",
    "company.contactFirstLastName": "first last name",
    "company.contactLandlinePhoneNumber": "contact landline phone number",
    "company.contactName": "contact name",
    "company.contactSecondLastName": "second last name",
    "company.conversations": "conversations",
    "company.createdBy": "created by",
    "company.createdDate": "created date",
    "company.fileArchives": "file archives",
    "company.interestedOffers": "interested offers",
    "company.leadCode": "lead code",
    "company.orchards": "orchards",
    "company.producingProducts": "producing products",
    "company.registryStatus": "registry status",
    "company.rfc": "rfc",
    "company.senasicaNumber": "senasica number",
    "company.soldProductOffers": "sold product offers",
    "company.tradeName": "trade name",
    "company.type": "type",
    "company.updatedBy": "updated by",
    "company.updatedDate": "updated date",
    "companyProduct": "company product",
    "companyProduct.company": "company",
    "companyProduct.companyProductCode": "company product code",
    "companyProduct.country": "country",
    "companyProduct.createdBy": "created by",
    "companyProduct.createdDate": "created date",
    "companyProduct.product": "product",
    "companyProduct.productType": "product type",
    "companyProduct.productVariation": "product variation",
    "companyProduct.updatedBy": "updated by",
    "companyProduct.updatedDate": "updated date",
    "Confirm": "Confirm",
    "Confirm-delete-visit": "Confirm delete visit",
    "Confirm-grower": "Confirm productor",
    "Confirm-password": "Confirm password",
    "confirm-your-email": "Confirm your email",
    "confirmation-mail-sent": "Confirmation mail sent to",
    "confirmation-mail-sent-to-email": "Your confirmation mail has been re-sent",
    "confirmation-mail-text": "Please check your inbox. Click link in the email to confirm your email address.",
    "Consistency": "Consistency",
    "contact-email-format-error": "Contact email format error",
    "Continue": "Continue",
    "Continue-later": "Continue later",
    "Contitutional-act": "Contitutional act",
    "conversation": "conversation",
    "conversation.companies": "companies",
    "conversation.conversationMessages": "conversation messages",
    "conversation.createdBy": "created by",
    "conversation.createdDate": "created date",
    "conversation.updatedBy": "updated by",
    "conversation.updatedDate": "updated date",
    "conversationMessage": "conversation",
    "conversationMessage.conversation": "conversation",
    "conversationMessage.createdBy": "created by",
    "conversationMessage.createdDate": "created date",
    "conversationMessage.fileArchive": "file archive",
    "conversationMessage.message": "message",
    "conversationMessage.updatedBy": "updated by",
    "conversationMessage.updatedDate": "updated date",
    "Coordinates": "Coordinates",
    "Country": "Country",
    "country.code": "code",
    "country.createdBy": "created by",
    "country.createdDate": "created date",
    "country.id": "id",
    "country.name": "name",
    "country.states": "states",
    "country.updatedBy": "updated by",
    "country.updatedDate": "updated date",
    "Create-account": "Create account",
    "Create-an-account": "Create an account",
    "Create-orchard-and-continue": "Create orchard and continue",
    "Create-orders": "Create orders",
    "Create-orders-description": "User will be able to add new product orders anytime, with any review",
    "Create-product-order": "Create product order",
    "Create-productors": "Create productors",
    "Create-productors-description": "User will be able to add new productors users with any previous review.",
    "Create-sectors": "Create sectors",
    "Created-On": "Created On",
    "Created-by": "Created by",
    "credits": "credits",
    "Date": "Date",
    "Date-uploaded": "Date uploaded",
    "DAUGHTER": "Daughter",
    "Delete": "Delete",
    "Delete-product": "Delete product",
    "Delivery-address": "Delivery address",
    "Delivery-date": "Delivery date",
    "Dependant": "Dependant",
    "Description": "Description",
    "Description-here": "Description here",
    "Desired-bozed-to-buy": "Desired bozed to buy",
    "did-not-receive-email": "Did not receive the email?",
    "Digital-signature": "Digital signature",
    "Digital-signature-here": "Digital signature here",
    "Disable": "Disable",
    "Disable-user": "Disable user",
    "Disenroll-grower": "Disenroll grower",
    "Divorced": "Divorced",
    "Document": "Document",
    "Document-name": "Document name",
    "document-specs": ".pdf, .png, .jpg format accepted. Documents should be less than 5MB",
    "Documents": "Documents",
    "Documents-and-social-economic-information": "Documents and social-economic",
    "Documents-should-follow": "Documents should follow",
    "Documents-to-upload": "Documents to upload",
    "dont-have-account": "Don't have an account?",
    "Dose": "Dose",
    "Drag-and-drop-files": "Drag and drop files or click upload",
    "Edit-active-ingredient": "Edit active ingredient",
    "Edit-brand": "Edit brand",
    "Edit-follow-up-visit": "Edit follow-up visit",
    "Edit-orchard": "Edit orchard",
    "Edit-Productor": "Edit Productor",
    "Edit-user": "Edit user",
    "Edit-variant-attribute": "Edit variant attribute",
    "Edit-variant-value": "Edit variant value",
    "Edit-variety-attribute": "Edit variety attribute",
    "Edit-variation-value": "Edit variation value",
    "Edit-visit": "Edit visit",
    "Edit-warehouse": "Edit warehouse",
    "Email": "Email",
    "email-format-error": "Error in the email format",
    "English": "English",
    "Engineer-in-charge": "Engineer in charge",
    "Enter-company-email-here": "Enter your company email here",
    "Enter-email-here": "Enter your email here",
    "Enter-first-name-here": "Enter your first name here",
    "Enter-last-name-here": "Enter your last name here",
    "Entry-date": "Entry date",
    "Estimated-end-date": "Estimated end date",
    "Estimated-quantity": "Estimated quantity",
    "Estimated-start-date": "Estimated start date",
    "Explain-reason-of-application": "Explain reason of application",
    "expired-certification-message": "Expired certificate, please update document",
    "Export-program": "Export program",
    "Exportation": "Exportation",
    "exportProgram": "Export program",
    "Ext-number": "Ext. number",
    "expired" : "Expired",
    "Facade-picture": "Facade picture",
    "FATHER": "Father",
    "Field-visits": "Field visits",
    "fileArchive": "file archive",
    "fileArchive.appFiles": "files",
    "fileArchive.company": "company",
    "fileArchive.type": "type",
    "Files-should-be": "Files should be",
    "FileTypeError": "File type error",
    "Fill-the-products-applied-in-the-sector": "Fill the products applied in the sector",
    "filter-by": "Filter by",
    "Financing-profile": "Financing profile",
    "financingProfile": "Financing profile",
    "First-name": "First name",
    "Fiscal-Information": "Fiscal Information",
    "flower-bud-maturity": "Flower bud maturity",
    "foliarApplicationCreated": "Foliar application created",
    "foliarApplicationSaved": "Foliar application saved",
    "Foliar-application-damage": "Foliar application damage",
    "Foliar-application-date": "Foliar application date",
    "Foliar-applications": "Foliar applications",
    "Follow-up": "Follow up",
    "Followup-track-orders": "Follow up/track orders",
    "Followup-track-orders-description": "User will be able to track order status anytime and edit process. ",
    "Follow-ups": "Follow ups",
    "Follow-up-visits": "Follow up visits",
    "Forget-password": "Forget password",
    "General-and-fiscal-information": "General and fiscal information",
    "General-data": "General data",
    "General-Information": "General Information",
    "General-information-owner": "General information (owner)",
    "Get-Help": "Get Help",
    "good": "Good",
    "Great-see-again": "Great to see you again!",
    "Greenhouses": "Greenhouses",
    "Ground-analysis": "Ground analysis",
    "Grower": "Grower",
    "Grower-confirm-message": "If confirmed, all features for productors will be actived and the status will change to “Productor”",
    "Grower-created-successfully": "Grower created successfully",
    "Grower-disenroll-message": "Are you sure you want to unsubscribe this producer?",
    "grower-role-description": "Increase your sells, find new market internationally and make your product grow.",
    "Harvest-documentation": "Harvest documentation",
    "harvest-fruiting": "Harvest fruiting",
    "Has-data-plan": "Has data plan?",
    "Has-computer": "Has computer?",
    "Has-email": "Has email?",
    "Has-internet": "Has internet?",
    "Has-smartphone": "Has smartphone?",
    "Height": "Height",
    "Home": "Home",
    "HUSBAND": "Husband",
    "I-agree-with-terms-privacy": "You must accept <0>Terms</0> and <1>Privacy</1>",
    "ID here": "ID here",
    "ID Orchard": "ID Orchard",
    "Images": "Images",
    "Important": "Important",
    "Infraestructure": "Infraestructure",
    "Ingredients": "Ingredients",
    "Int-number": "Int. number",
    "Irrigation-system": "Irrigation System",
    "isAdministrator": "Administrator?",
    "key-here": ".key here",
    "Language": "Language",
    "Last transaction": "Last transaction",
    "Last-name": "Last name",
    "Last-Name": "Last Name",
    "Last-Name-here": "Enter your last name here",
    "LastName": "Last name",
    "Location": "Location",
    "Log in": "Log in",
    "Log-in-google": "Log in with Google account",
    "login-here": "Log in here",
    "Machinery": "Machinery",
    "Macro-tunnel": "Macro tunnel",
    "Madurity": "Maturity",
    "Manage-permissions": "Manage permissions",
    "Maturity": "Maturity",
    "Marital-status": "Marital status",
    "Marketplace": "Marketplace",
    "Mark-all-as-read": "Mark all as read",
    "Married": "Married",
    "Max-files-size": "Max files size",
    "Max-size": "Max size",
    "MaxFileSizeExceed": "File size should not exceed ",
    "missing-birthday-field": "Missing birthday field",
    "missing-email-field": "Missing email field",
    "missing-lastname-field": "Missing last name field",
    "missing-marital-status-field": "Missing marital status field",
    "missing-name-field": "Missing name field",
    "missing-other-activities-field": "Missing other activities field",
    "missing-password-field": "Missing password field",
    "More-actions": "More actions",
    "More-details": "More details",
    "MOTHER": "Mother",
    "Municipality": "Municipality",
    "municipality": "municipality",
    "municipality.createdBy": "created by",
    "municipality.createdDate": "created date",
    "municipality.name": "name",
    "municipality.neighbourhoods": "neighbourhoods",
    "municipality.state": "state",
    "municipality.updatedBy": "updated by",
    "municipality.updatedDate": "updated date",
    "must-accept-terms-privacy": "You must accept <0>Terms</0> and <1>Privacy</1>",
    "Must-select-all-varieties": "Must select all varieties",
    "Must-select-at-least-one-productor": "Must select at least one productor",
    "Must-select-the-base-product": "Must select the base product",
    "must-select-buyer-company": "You must select a buyer company",
    "Must-select-boxes-per-pallet": "Must select boxes per pallet",
    "Must-select-caliber": "Must select caliber",
    "Must-select-consistency": "Must select consistency",
    "Must-select-brand": "Must select brand",
    "Must-select-delivery-date": "Must select delivery date",
    "Must-select-handling": "Must select handling",
    "Must-select-no-boxes": "You must select the quantity of boxes",
    "Must-select-phenological-stage": "Must select phenological stage",
    "Must-select-price-per-box": "You must select the price per box",
    "Must-select-product": "You must select a product",
    "Must-select-productor": "You must select a productor",
    "must-select-seller-company": "Must select seller company",
    "Must-select-the-category-of-the-product": "Must select the category of the product",
    "Must-write-name": "Must write name",
    "Must-write-caliber-comments": "Must write caliber comments",
    "Must-write-consistency-comments": "Must write consistency comments",
    "My-account": "My account",
    "My-brands": "My brands",
    "My-Orders": "My Orders",
    "My-Requests": "My Requests",
    "My-users": "My users",
    "My-warehouses": "My warehouses",
    "Name": "Name",
    "Name-here": "Name here",
    "Neighborhood": "Neighborhood",
    "neighborhood": "neighbourhood",
    "neighborhood.createdBy": "created by",
    "neighborhood.createdDate": "created date",
    "neighborhood.municiaplity": "municiaplity",
    "neighborhood.name": "name",
    "neighborhood.updatedBy": "updated by",
    "neighborhood.updatedDate": "updated date",
    "neighborhood.zipCode": "zip code",
    "New-follow-up-visit": "New follow up visit",
    "New-Order": "New Order",
    "New-Productor": "New Productor",
    "No. boxes": "No. boxes",
    "not-enough-boxes-left": "Not enough boxes left",
    "notification.config": "config",
    "notification.createdBy": "created by",
    "notification.createdDate": "created date",
    "notification.groupName": "group name",
    "notification.html": "html",
    "notification.id": "notification",
    "notification.link": "link",
    "notification.metadata": "metadata",
    "notification.notificationTopic": "notification topic",
    "notification.quantity": "quantity",
    "notification.readDate": "read date",
    "notification.relatedUser": "related user",
    "notification.updatedBy": "updated by",
    "notification.updatedDate": "updated date",
    "notification.user": "user",
    "notificationTopic": "notification topic",
    "notificationTopic.description": "description",
    "notificationTopic.name": "name",
    "notificationTopic.permissions": "permissions",
    "Once-ODC-is-uploaded-status-will-change-to": "Once ODC is uploaded, status will change to ",
    "Orchard": "Orchard",
    "orchard": "orchard",
    "Orchard date": "Orchard date",
    "Orchard-applications": "Orchard applications",
    "Orchard-created-successfully": "Orchard created successfully",
    "Orchard-date": "Orchard date",
    "Orchard-name": "Orchard name",
    "orchard.address": "address",
    "orchard.company": "company",
    "orchard.createdBy": "created by",
    "orchard.createdDate": "created date",
    "orchard.hectares": "hectares",
    "orchard.name": "name",
    "orchard.orchardVisits": "orchard visits",
    "orchard.photo": "photo",
    "orchard.productOffers": "product offers",
    "orchard.updatedBy": "updated by",
    "orchard.updatedDate": "updated date",
    "Orchard-updated-successfully": "Orchard updated successfully",
    "Orchard-sectors": "Orchard sectors",
    "Orchards": "Orchards",
    "Orchards-sectors-and-products": "Orchards, sectors and products",
    "orchardVisit": "id",
    "orchardVisit.companyProduct": "company product",
    "orchardVisit.createdBy": "created by",
    "orchardVisit.createdDate": "created date",
    "orchardVisit.orchard": "orchard",
    "orchardVisit.phenologicalStage": "phenological stage",
    "orchardVisit.shceduledBy": "shceduled by",
    "orchardVisit.status": "status",
    "orchardVisit.updatedBy": "updated by",
    "orchardVisit.updatedDate": "updated date",
    "orchardVisit.userInCharge": "user in charge",
    "Order-created": "Order created",
    "Order-percentage-completed": "Order {{percentage}}% completed",
    "Order-will-be-compleated-once-all-products-fulfill-their-quantity": "Order will be compleated once all products fulfill their quantity",
    "order.cantrack": "You can track the status anytime!",
    "order.created": "¡Order created successfully!",
    "other": "other",
    "Other-activities": "Other activities",
    "Other-comments": "Other comments",
    "Own-family": "Own family",
    "Own-vehicle": "Own vehicle",
    "Packing-and-product-type": "Packing and product type",
    "Packing-comments": "Packing comments",
    "Packing-list": "Packing list",
    "Password": "Password",
    "passwords-are-different": "Passwords are different",
    "Past-performance": "Past performance",
    "Payment": "Payment",
    "PDF-Invoice": "PDF Invoice",
    "Pendant": "Pendant",
    "Pending": "Pending",
    "Pending-orchard-id": "New orchard",
    "Performance": "Performance",
    "permission": "permission",
    "Permissions": "Permissions",
    "permission.createdBy": "created by",
    "permission.createdDate": "created date",
    "permission.name": "name",
    "permission.roles": "roles",
    "permission.updatedBy": "updated by",
    "permission.updatedDate": "updated date",
    "permissionGroup": "permission group",
    "permissionGroup.code": "code",
    "permissionGroup.description": "description",
    "permissionGroup.name": "name",
    "permissionGroup.permissions": "permissions",
    "Person-type": "Person type",
    "Phenological-Stage": "Phenological stage",
    "Phone": "Phone",
    "Plague-demage": "Plague damage",
    "plant-development": "Plant development",
    "Please-enter-a-dose": "Please enter a dose",
    "Please-enter-a-name-for-the-variant": "Please enter a name for the variant",
    "Please-enter-a-reason": "Please enter a reason",
    "Please-select-a-date": "Please select a date",
    "Please-select-a-productor": "Please select a productor",
    "Please-select-a-unit": "Please select a unit",
    "Please-select-an-active-ingredient": "Please select an active ingredient",
    "Please-select-an-orchard": "Please select an orchard",
    "Please-select-the-orchard": "Please select the orchard",
    "Please-update-the-document": "Please update the document",
    "Phenological-stage": "Phenological stage",
    "Postal-code": "Postal code",
    "Price": "Price",
    "Price-per-box": "Price per box",
    "Product": "Product",
    "product": "product",
    "baseProduct": "Base product",
    "Product-created-successfully": "Product created successfully",
    "Product-code": "Product code",
    "Product-details": "Product details",
    "Product-follow-up": "Product follow up",
    "product.createdBy": "created by",
    "product.createdDate": "created date",
    "product.name": "name",
    "product.productCategory": "product category",
    "product.productCode": "product code",
    "Product-saved": "Product saved",
    "Product-type": "Product type",
    "product.updatedBy": "updated by",
    "product.updatedDate": "updated date",
    "Product-saved-successfully": "Product saved successfully",
    "productCategory": "product category",
    "productCategory.createdBy": "created by",
    "productCategory.createdDate": "created date",
    "productCategory.name": "name",
    "productCategory.products": "products",
    "productCategory.updatedBy": "updated by",
    "productCategory.updatedDate": "updated date",
    "productOffer": "product offer",
    "productOffer.buyerCompany": "buyer company",
    "productOffer.buyerSignature": "buyer signature",
    "productOffer.companyProduct": "company product",
    "productOffer.contractFile": "contract file",
    "productOffer.createdBy": "created by",
    "productOffer.createdDate": "created date",
    "productOffer.description": "description",
    "productOffer.filledByProductOrders": "filled by product orders",
    "productOffer.fillingOrder": "filling order",
    "productOffer.interestedCompanies": "interested companies",
    "productOffer.offerCode": "offer code",
    "productOffer.orchard": "orchard",
    "productOffer.productOfferUpdates": "product offer updates",
    "productOffer.sellerCompany": "seller company",
    "productOffer.sellerSignature": "seller signature",
    "productOffer.status": "status",
    "productOffer.updatedBy": "updated by",
    "productOffer.updatedDate": "updated date",
    "productOfferUpdate": "product offer update",
    "productOfferUpdate.comments": "comments",
    "productOfferUpdate.createdBy": "created by",
    "productOfferUpdate.createdDate": "created date",
    "productOfferUpdate.fileArchive": "file archive",
    "productOfferUpdate.productOffer": "product offer",
    "productOfferUpdate.type": "type",
    "productOfferUpdate.updatedBy": "updated by",
    "productOfferUpdate.updatedDate": "updated date",
    "Productor": "Productor",
    "Productor-confirmed-successfully": "Productor confirmed successfully",
    "Productor-deleted-successfully": "Productor deleted successfully",
    "Productor-disenrolled-successfully": "Productor disenrolled successfully",
    "Productor-name": "Productor name",
    "Productors": "Productors",
    "Products": "Products",
    "Products-to-be-ordered": "Products to be ordered",
    "productType.createdBy": "created by",
    "productType.createdDate": "created date",
    "productType.id": "product type",
    "productType.name": "name",
    "productType.updatedBy": "updated by",
    "productType.updatedDate": "updated date",
    "productVariation": "product variation",
    "productVariation.createdBy": "created by",
    "productVariation.createdDate": "created date",
    "productVariation.name": "name",
    "productVariation.updatedBy": "updated by",
    "productVariation.updatedDate": "updated date",
    "Profile-image": "Profile image",
    "Properties": "Properties",
    "Quality": "Quality",
    "Quantity": "Quantity",
    "Quick-explanation-sector": "Quick explanation of what a sector is",
    "Reason": "Reason",
    "re-send-confirmation-email": "Re-send confirmation email",
    "References": "References",
    "register-success": "Register success",
    "REGISTRY_STATUS_DISENROLL": "Disenroll",
    "REGISTRY_STATUS_PENDING": "Pending",
    "REGISTRY_STATUS_PRODUCER": "Producer",
    "REGISTRY_STATUS_PROSPECT": "Prospect",
    "regular": "Regular",
    "Replace-file": "Replace file",
    "Resume": "Resume",
    "RFC": "RFC",
    "role": "role",
    "role.assignable": "assignable",
    "role.createdBy": "created by",
    "role.createdDate": "created date",
    "role.name": "name",
    "role.permissions": "permissions",
    "role.title": "title",
    "role.updatedBy": "updated by",
    "role.updatedDate": "updated date",
    "role.users": "users",
    "RPPyC-legal-representant": "RPPyC legal representant",
    "RPPyC-registration": "RPPyC registration",
    "Save": "Save",
    "Save-and-continue": "Save and continue",
    "Save-and-continue-later": "Save and continue later",
    "Save-changes": "Save changes",
    "Saved-changes": "Saved changes successfully",
    "Search-by-name": "Search by name",
    "Search-by-name, email, id": "Search by name, email or id",
    "Search-by-name-or-id": "Search by name or id",
    "Search-by-name, state": "Search by name or state",
    "Search-by-name-product": "Search by name or product",
    "Sector-created-successfully": "Sector created successfully",
    "Sector-details": "Sector details",
    "Sector-name": "Sector name here",
    "See": "See",
    "See-file": "See file",
    "Search-by-orchard-name": "Search by orchard name",
    "Select the sectors to which you want to add the foliar application": "Select the sectors to which you want to add the foliar application",
    "Select-any-option": "Select any option",
    "Select-client": "Select client",
    "select-language": "Select your language",
    "Select-municipality": "Select municipality",
    "Select-neighborhood": "Select neighborhood",
    "Select-No-boxes": "Select No. boxes",
    "Select-permissions-to-grant": "Select permissions to grant",
    "Select-a-orchard": "Select a orchard",
    "Select-a-sector": "Select a sector",
    "Select-a-user": "Select a user",
    "Select-the-date-of-the-visit": "Select the date of the visit",
    "Productor-devlivery-date": "Productor delivery date",
    "Select-state": "Select state",
    "Select-status": "Select status",
    "Select-type": "Select type",
    "Select...": "Select...",
    "Separated": "Separated",
    "Serial": "Serial",
    "Settings": "Settings",
    "Shipment-details": "Shipment details",
    "Sign-up": "Sign up",
    "sign-up-free": "Sign up free",
    "Single": "Single",
    "SISTER": "Sister",
    "Socio-economic-information": "Socio-economic information",
    "SON": "Son",
    "Spanish": "Spanish",
    "State": "State",
    "state": "state",
    "state.code": "code",
    "state.country": "country",
    "state.createdBy": "created by",
    "state.createdDate": "created date",
    "state.municipalities": "municipalities",
    "state.name": "name",
    "state.updatedBy": "updated by",
    "state.updatedDate": "updated date",
    "Status": "Status",
    "STATUS_ACCEPTED": "Accepted",
    "STATUS_PENDING": "Pending",
    "Street": "Street",
    "Surface": "Surface",
    "Tax-system": "Tax system",
    "Telephone": "Telephone",
    "The-attribute-name-is-required-for-all-languages": "The attribute is required for all languages",
    "The-attribute-was-created-successfully": "The attribute was created successfully",
    "The-value-was-created-successfully": "The value was created successfully",
    "This-data-will-be-lost": "This data will be lost",
    "Types": "Types",
    "Units": "Units",
    "Units-per-box": "Units per box",
    "Updated-successfully": "Updated successfully",
    "Upload": "Upload",
    "Upload-buying-order": "Upload buying order",
    "Upload-image": "Upload image",
    "Uploaded-by": "Uploaded by",
    "Use-of-technology": "Use of technology",
    "Use-whatsapp": "Use whatsapp?",
    "User": "User",
    "user": "user",
    "user.company": "company",
    "user.createdBy": "created by",
    "Users": "Users",
    "users.created.success": "User created successfully",
    "user.createdDate": "created date",
    "user.disabledNotificationTopics": "disabled notification topics",
    "user.email": "email",
    "user.emailValidationDate": "email validation date",
    "user.emailValidationToken": "email validation token",
    "user.isActive": "is active",
    "user.lastName": "last name",
    "user.locale": "locale",
    "user.name": "name",
    "user.notifications": "notifications",
    "user.orchardVisits": "orchard visits",
    "user.password": "password",
    "user.permissions": "permissions",
    "user.role": "role",
    "user.scheduledOrchardVisits": "scheduled orchard visits",
    "user.update.success": "User updated successfully",
    "user.updatedBy": "updated by",
    "user.updatedDate": "updated date",
    "user.username": "username",
    "User-activated": "User activated",
    "User-created-successfully": "User created successfully",
    "User-disabled": "User disabled",
    "User-permissions": "User permissions",
    "User-permissions-description": "Provide members to get basic permission and functionalities by default. Costumize settings for all Members or make local adjustments for individual user in their profiles.",
    "User-permissions-updated-successfully": "User permissions updated successfully",
    "Value": "Value",
    "Values": "Values",
    "Variants": "Variants",
    "Variations": "Variations",
    "Variety": "Variety",
    "Varieties": "Varieties",
    "very-bad": "Very bad",
    "very-good": "Very good",
    "Visit-date": "Visit date",
    "Visit-details": "Visit details",
    "visit.cantrack": "You can track the status anytime!",
    "visit.created": "Visit created successfully!",
    "Visited": "Visited",
    "Visits": "Visits",
    "Visit-deleted-successfully": "Visit deleted successfully",
    "Warehouse": "Warehouse",
    "Warehouses": "Warehouses",
    "Warehouse-created-successfully": "Warehouse created successfully",
    "Warehouse-updated-successfully": "Warehouse updated successfully",
    "Water-analysis": "Water analysis",
    "We-believe-we-can-login-text": "We believe that we can improve dramatically the way trading happens and give a real chance for the producer to thrive.",
    "What-your-role": "What will be your role?",
    "Wholesale": "Wholesale",
    "wholesale-role-description": "Find better prices with local growers, improve your communication channels and enhance sells.",
    "Widowed": "Widowed",
    "WIFE": "Wife",
    "yes": "yes",
    "Yes": "Yes",
    "you-logged-in": "You are logged in :)",
    "You-must-add-the-email": "You must add the email",
    "You-must-add-the-name-of-the-company": "You must add the name of the company",
    "You-need-to-Add-a-new-Sector-to-add-products": "You need to Add a new Sector to add products",
    "XML-Invoice": "XML Invoice",
}

export default translations;
