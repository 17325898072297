import fieldTypes from "../../components/FormModal/fields/fieldTypes";
import {
    copyPropertyOrNull,
    copyPropertyOrThrow,
    copyPropertyValueOrNull,
    copyPropertyValueOrThrow,
} from "../formUtils";

export const companyProductTableSGroups = [
    'company_product_read',
    'company_product_read_id',
    'company_product_read_company',
    'company_read',
    'company_product_read_product',
    'product_read',
    'company_product_read_country',
    'country_read',
    'company_product_read_product_type',
    'product_type_read',
    'company_product_read_product_variation',
    'product_variation_read',
    'company_product_read_product_code',
    'read_created_by',
    'user_read',
    'read_updated_by',
    'read_created_date',
    'read_updated_date',
];

export const companyProductDetailSGroups = [
    'company_product_read',
    'company_product_read_estimated_start_date',
    'company_product_read_estimated_end_date',
    'company_product_read_product',
        'product_read',
    'company_product_read_product_type',
        'product_type_read',
    'company_product_read_sector',
        'sector_read_id',
        'sector_read_name',
        'sector_read_orchard',
        'read_file',
            'orchard_read_name',
            'orchard_read_address',
                'address_read',
                'address_read_neighbourhood',
                    'neighbourhood_read',
                    'neighbourhood_read_municipality',
                        'municipality_read', 
                        'state_read',
                        'state_read_country',
                        'country_read',
        'product_category_read_id',
        'product_category_read_name',
        'company_product_read_variation_values',
        'company_product_read_product_variation',
            'product_variation_read',
            'variation_attribute_read_variation_attribute',
            'variation_attribute_read_id',
            'variation_attribute_read_attribute',
            'variation_value_read_variation_attribute',
            'variation_value_read_value',
            'variation_value_read_id',
];

export const companyProductTableColumns = [
    {
        Header: 'id',
        accessor: 'id'
    },
     {
        Header: 'company',
        accessor: 'company?.id'
    },
     {
        Header: 'product',
        accessor: 'product?.id'
    },
     {
        Header: 'country',
        accessor: 'country?.id'
    },
     {
        Header: 'productType',
        accessor: 'productType?.id'
    },
     {
        Header: 'productVariation',
        accessor: 'productVariation?.id'
    },
     {
        Header: 'companyProductCode',
        accessor: 'companyProductCode'
    },
     {
        Header: 'createdBy',
        accessor: 'createdBy?.id'
    },
     {
        Header: 'updatedBy',
        accessor: 'updatedBy?.id'
    },
     {
        Header: 'createdDate',
        accessor: 'createdDate'
    },
     {
        Header: 'updatedDate',
        accessor: 'updatedDate'
    },
 ];

export const companyProductDataDetailsArray = (companyProduct) => [
    {data: companyProduct?.id, dataLabel: 'id'},
    {data: companyProduct?.company?.id, dataLabel: 'company'},
    {data: companyProduct?.product?.id, dataLabel: 'product'},
    {data: companyProduct?.country?.id, dataLabel: 'country'},
    {data: companyProduct?.productType?.id, dataLabel: 'productType'},
    {data: companyProduct?.productVariation?.id, dataLabel: 'productVariation'},
    {data: companyProduct?.companyProductCode, dataLabel: 'companyProductCode'},
    {data: companyProduct?.createdBy?.id, dataLabel: 'createdBy'},
    {data: companyProduct?.updatedBy?.id, dataLabel: 'updatedBy'},
    {data: companyProduct?.createdDate, dataLabel: 'createdDate'},
    {data: companyProduct?.updatedDate, dataLabel: 'updatedDate'},
];


export const newCompanyProductFormDefinition = [
    {
        name: 'company',
        label: 'company',
        required: true,
        type: fieldTypes.entity,
        entity: 'companies'
    },

    {
        name: 'product',
        label: 'product',
        required: true,
        type: fieldTypes.entity,
        entity: 'products'
    },

    {
        name: 'country',
        label: 'country',
        required: true,
        type: fieldTypes.entity,
        entity: 'countries'
    },

    {
        name: 'productType',
        label: 'productType',
        required: true,
        type: fieldTypes.entity,
        entity: 'productTypes'
    },

    {
        name: 'productVariation',
        label: 'productVariation',
        required: true,
        type: fieldTypes.entity,
        entity: 'productVariations'
    },

    {
        name: 'companyProductCode',
        label: 'companyProductCode',
        required: false,
        type: fieldTypes.text
    },

];

export const editCompanyProductFormDefinition = [
    ...newCompanyProductFormDefinition
];

export const prepareNewCompanyProductForServer = (form, variations, t) => {
    const companyProduct= {};

    copyPropertyValueOrThrow(form, companyProduct, 'sector', t('Must-select-a-sector'));
    copyPropertyValueOrThrow(form, companyProduct, 'product', t('Must-select-a-product'));
    copyPropertyOrNull(form, companyProduct, 'quantity');
    copyPropertyOrThrow(form, companyProduct, 'estimatedStartDate', t('Must-select-start-of-date'));
    copyPropertyOrThrow(form, companyProduct, 'estimatedEndDate', t('Must-select-end-of-date'));
    copyPropertyOrNull(form, companyProduct, 'description');
    copyPropertyValueOrThrow(form, companyProduct, 'handling', t('Must-select-a-handling'));

    companyProduct.quantity = parseFloat(companyProduct.quantity)||null;

    return companyProduct;
};

export const prepareEditedCompanyProductForServer = (form) => {
    return prepareNewCompanyProductForServer(form);
};

export const companyProductToFormData = (companyProduct) => {
    let estimatedStartDate = null;
    let estimatedEndDate = null;

    if(companyProduct.estimatedStartDate) {
        estimatedStartDate = new Date(companyProduct?.estimatedStartDate);
        estimatedStartDate.setMinutes(estimatedStartDate.getMinutes() + estimatedStartDate.getTimezoneOffset())
    }

    if(companyProduct.estimatedEndDate) {
        estimatedEndDate = new Date(companyProduct?.estimatedEndDate);
        estimatedEndDate.setMinutes(estimatedEndDate.getMinutes() + estimatedEndDate.getTimezoneOffset())
    }

    return {
        ...companyProduct,
        product: {label: companyProduct.product.baseProduct?.name||companyProduct.product.productCode, value: companyProduct?.product?.id},
        productType: {label: companyProduct?.productType?.name, value: companyProduct?.productType?.id},
        sector: {label: companyProduct?.sector?.name, value: companyProduct?.sector?.id},
        handling: handlingTypesOptions.find(option => option.value === companyProduct?.handling),
        productVariation: {label: companyProduct?.productVariation?.name, value: companyProduct?.productVariation?.id},
        orchard: companyProduct?.sector?.orchard,
        estimatedStartDate,
        estimatedEndDate,
    }
}

export const getEmptyCompanyProduct = () => {
    return {
        id: null,
        company: null,
        product: null,
        country: null,
        productType: null,
        productVariation: null,
        companyProductCode: null,
        createdBy: null,
        updatedBy: null,
        createdDate: null,
        updatedDate: null,
        variationValues: [],
        estimatedStartDate: null,
        estimatedEndDate: null,
    }
}

export const prepareNewCompanyProductForServerStep1 = (form) => {
    const product= {};

    copyPropertyValueOrThrow(form, product, 'product', 'Debes selecionar el producto');
    copyPropertyValueOrThrow(form, product, 'productType', 'Debes selecionar el tipo de producto');
    copyPropertyOrThrow(form, product, 'serial', 'Debes agregar el serial del producto');
    copyPropertyOrThrow(form, product, 'orchardDate', 'Debes seleccionar la fecha de la huerta');
    copyPropertyValueOrThrow(form, product, 'sector', 'Debes seleccionar el sector para el producto');
    copyPropertyOrThrow(form, product, 'name', 'Debes agregar el nombre de producto');
    copyPropertyOrThrow(form, product, 'brand', 'Debes agregar la marca del de producto');
    copyPropertyOrThrow(form, product, 'quantity', 'Debes agregar la cantidad de cajas del producto');
    
    product.quantity = parseFloat(product.quantity);
    
    return product;
}

export const companyProductsWithProductGroups = [
    'company_product_read_id',
    'company_product_read_name',
    'company_product_read_product',
        'product_read_variation_attributes',
            'variation_attribute_read_variation_values',
            'variation_attribute_read_id',
            'variation_attribute_read_values_value',
            'variation_attribute_read_variation_attribute',
            'variation_attribute_read_attribute',
            'variation_value_read_value',
            'variation_value_read_id',
    'company_product_read_sector',
    'company_product_read_quantity',
        'sector_read_orchard',
        'sector_read_name',
        'orchard_read_company',
            'company_read_id',
            'company_read_company_name',
        
];
export const prepareNewCompanyProductForServerStep2 = (form) => {
    const product= {};

    copyPropertyOrThrow(form, product, 'description', 'Debes agregar la descripción del producto');
    copyPropertyValueOrNull(form, product, 'productCategory', 'Debes seleccionar la categoría del producto');
    
    return product;
}

export const prepareNewCompanyProductForServerStep3 = (form) => {
    const product= {};
    
    copyPropertyOrThrow(form, product, 'unitsPerBox', 'Debes agregar las unidades por caja');
    copyPropertyOrThrow(form, product, 'boxesPerPallet', 'Debes agregar el número de cajas por pallet');
    copyPropertyOrThrow(form, product, 'pricePerBox', 'Debes agregar el precio por caja');
    copyPropertyValueOrThrow(form, product, 'handling', 'Debes selecionar el manejo');
    copyPropertyValueOrThrow(form, product, 'productVariation', 'Debes seleccionar la variedad');

    product.unitsPerBox = parseInt(product.unitsPerBox);
    product.boxesPerPallet = parseInt(product.boxesPerPallet);
    product.pricePerBox = parseFloat(product.pricePerBox);
    
    return product;
}

export const handlingTypes = {
    HANDLING_ORGANIC: 'organic',
	HANDLING_AGROECOLOGICAL: 'agroecological',
	HANDLING_CONVENTIONAL: 'conventional',
	HANDLING_OTHER: 'other'
};

export const handlingTypesOptions = [
    {value: handlingTypes.HANDLING_ORGANIC, label: 'Organic'},
    {value: handlingTypes.HANDLING_AGROECOLOGICAL, label: 'Agroecological'},
    {value: handlingTypes.HANDLING_CONVENTIONAL, label: 'Conventional'},
    {value: handlingTypes.HANDLING_OTHER, label: 'Other'},
];

export const getProductPictures = (form) => {
    const pictures = [];
    if (form.pictures) {
        form.pictures.forEach(picture => {
            pictures.push( picture ); // getFileFromForm(picture)
        });
    }

    return pictures;
}
