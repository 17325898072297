import React from 'react'
import { useTranslation } from 'react-i18next';
import SelectWithLabel from '../../../forms/SelectWithLabel/SelectWithLabel';
import TextInput from '../../../forms/TextInput/TextInput';
import {ReactComponent as TrashIcon} from "../../../../assets/images/icons/trash-2.svg";
import './FoliarApplicationItem.scss';
import useCallbackCreator from 'use-callback-creator';

const FoliarApplicationItem = ({ index, applicationUnitOptions, activeIngredientsOptions, foliarApplicationDetail, onDelete, onChange }) => {
    const {t} = useTranslation();

    const handleChange = useCallbackCreator((name, value) => {
        onChange({ ...foliarApplicationDetail, [name]: value });
    }, [onChange, foliarApplicationDetail]);

    const handleInputChange = useCallbackCreator((name, e) => {
        onChange({ ...foliarApplicationDetail, [name]: e.target.value });
    }, [onChange, foliarApplicationDetail]);

    return (
        <div className='FoliarApplicationItem'>
            <div className="row">
                <div className="col-md-4 margin-top-mobile">
                    <SelectWithLabel
                        label={t('Active-ingredient')}
                        inputProps={{
                            options: activeIngredientsOptions,
                            value: foliarApplicationDetail?.activeIngredient,
                            onChange: handleChange('activeIngredient')
                        }}
                    />
                </div>
                <div className="col-md-3 margin-top-mobile">
                    <TextInput
                        label={t('Dose')}
                        type='number'
                        max={99999}
                        min={0}
                        value={foliarApplicationDetail?.dose}
                        onChange={handleInputChange('dose')}
                    />
                </div>
                <div className="col-md-4 margin-top-mobile">
                    <SelectWithLabel
                        label={t('Units')}
                        inputProps={{
                            options: applicationUnitOptions,
                            value: foliarApplicationDetail?.unit,
                            onChange: handleChange('unit')
                        }}
                    />
                </div>
                <div className="col-md-1">
                    {index>0 &&
                        <div role="button" className='trash-button' onClick={onDelete}>
                            <TrashIcon />
                        </div>}
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-md-12">
                    <TextInput
                        label={t('Reason')}         
                        placeholder={t('Explain-reason-of-application')}
                        value={foliarApplicationDetail?.reason}
                        onChange={handleInputChange('reason')}
                    />
                </div>
            </div>
        </div>
    );
}

export default FoliarApplicationItem;