import React, { useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next';
import { getHtmlNotificationFormatted, getNotificationIconAndTitle } from '../../../../../../../services/models/notificationUtils';
import { SectionTitle } from '../../../../../SectionTitle/SectionTitle';
import {ReactComponent as CloseIcon} from "../../../../../../../assets/images/icons/close-type-2.svg";
import { ApiContext } from '../../../../../../../services/api/api-config';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import * as DOMPurify from 'dompurify';
import './NotificationsListRightBar.scss';
import { getDistanceDateFromNow } from '../../../../../../../services/dateFormatUtils';

const NotificationsListRightBar = ({ notifications, onHideBar, reloadNitifications, className }) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const api = useContext(ApiContext);

    const markAllAsRead = useCallback(async()=>{
        await api.notifications.markAllAsRead( {method: 'PUT', customProp:'ignore_all_notifications'} );
        
        reloadNitifications();
        onHideBar();
    }, [api, reloadNitifications, onHideBar]);

    const notificationClick = useCallback(async(notification)=>{
        if(!notification.readDate)
            await api.notifications.markAsRead( {id: notification.id.toString(), method: 'PUT', customProp:'notifications'} )

        if(notification.link) {
            navigate(notification.link);
            onHideBar();
        }
    },[api, navigate, onHideBar]);

    return (
        <div className={classNames('NotificationsListRightBar', 'fade-in-slide',  className)}>
            <div className='NotificationsListRightBar__close' onClick={onHideBar}>
                <CloseIcon />
            </div>

            <div className='d-flex justify-content-between align-items-center'>
                <SectionTitle>{t('Notifications')}</SectionTitle>
                <div className='NotificationsListRightBar__mark-as-read' onClick={markAllAsRead}>
                    {t('Mark-all-as-read')}
                </div>
            </div>

            <div className='NotificationsListRightBar__list'>
                {notifications.map((notification, index) => {
                    const {icon, title} = getNotificationIconAndTitle(notification, t);
                    const htmlFormatted = DOMPurify.sanitize(getHtmlNotificationFormatted(notification.html, t));
                    const isReadedClass = notification.readDate ? 'NotificationsListRightBar__list__item--readed' : '';

                    return <div key={index} className={classNames('NotificationsListRightBar__list__item', isReadedClass)}>
                            <div className='NotificationsListRightBar__list__item__image'>
                                { icon?.default && <img src={icon.default} alt="Notification" />}
                            </div>
                            <div className='NotificationsListRightBar__list__item__content'>
                                <div 
                                    className='NotificationsListRightBar__list__item__content__title' 
                                    role="button"
                                    onClick={() => notificationClick(notification)}
                                >
                                    {title}
                                </div>
                                <div className='NotificationsListRightBar__list__item__content__html'
                                    dangerouslySetInnerHTML={{__html: htmlFormatted}}
                                    role="button"
                                    onClick={() => notificationClick(notification)}
                                />
                                <div className='NotificationsListRightBar__list__item__content__subtitle'>
                                    {getDistanceDateFromNow(notification.createdDate, 'es')}
                                </div>
                            </div>
                        </div>})}
            </div>
        </div>
    );
}

export default NotificationsListRightBar;