import React, {useMemo, useState, useCallback, useContext } from 'react'
import { SectionTitle } from '../../../components/Dashboard/SectionTitle/SectionTitle';
import { SideMenu } from '../../../components/Dashboard/SideMenu/SideMenu';
import TopBar from '../../../components/Dashboard/TopBar/TopBar';
import {useTranslation} from "react-i18next";
import { SearchTextbox } from '../../../components/forms/SearchTextbox/SearchTextbox';
import Button from '../../../components/molecules/Button/Button';
import useTideTable from "../../../components/utility/TideReactTable/useTideTable";
import TideReactTable from "../../../components/utility/TideReactTable/TideReactTable";
import { ApiContext } from '../../../services/api/api-config';
import debounce from 'lodash.debounce';
import {paths} from "../../../services/routes/appRoutes";
import { useNavigate } from 'react-router-dom';
import {ReactComponent as EyeIcon} from "../../../assets/images/icons/eye-green.svg";
import { productTableSGroups } from '../../../services/models/productUtils';
import './ProductsList.scss';
import { generalDatesFormat } from '../../../services/generalConfig';
import moment from 'moment';

const ProductsList = () => {
    const api = useContext(ApiContext);
    const [textFilter, setTextFilter] = useState('');
    const {t} = useTranslation();
    const navigate = useNavigate();

    const produtcTableColumns = useMemo(() => [
        {
            Header: 'ID',
            accessor: 'id'
        },
        {
            Header: t('baseProduct'),
            accessor: 'baseProduct.name'
        },
        {
            Header: 'Image',
            accessor: product => product.image?.id  ? <img src={api.appFiles.getUrl(product.image)} alt={product.name} width="100" /> : '',
        },
        {
            Header: t('Created'),
            accessor: product => product.createdDate ? moment(product.createdDate).format(generalDatesFormat) : ''
        },
        {
            Header: t('Updated'),
            accessor: product => product.updatedDate ? moment(product.updatedDate).format(generalDatesFormat) : ''
        },
        {
            Header: t('Product-code'),
            accessor: 'productCode'
        },
        {
            Header: t("Actions"),
            accessor: product => 
                        <a className='ActionSee d-flex justify-content-start align-items-center' 
                            href={paths.mm.productsEdit.replace(":id", product.id)}
                        >
                            {t('See')} <EyeIcon />
                        </a>
        }
    ], [api, t]);

    const searchFilter = useMemo(() => {
        return {
            search: textFilter
        }
    }, 
    [textFilter]);

    const requestFilters = useMemo(() => ({
        sGroups: productTableSGroups,
        ...searchFilter
    }), [searchFilter]);

    const handleSearchTextChange = useCallback((e) => {
        setTextFilter(e.target.value);
    }, []);

    const debouncedChangeHandler = debounce(handleSearchTextChange, 400); 

    const table = useTideTable({
        entity: 'products',
        columns: produtcTableColumns,
        requestFilters
    });

    const handleNewProduct = useCallback(()=>navigate(paths.mm.productsForm), [navigate]);

    return (
        <div className="ProductsList dashboard-container">
            <SideMenu />
            <div className='right-content'>
                <TopBar />
                <div className='main-content'>
                    <SectionTitle>{t('Products')}</SectionTitle>

                    <div className='row'>
                        <div className='col d-flex justify-content-between align-items-center gap-3'>
                            <SearchTextbox
                                placeholder={t('Search-by-name')}
                                onChange={debouncedChangeHandler}
                            />

                            <Button 
                                className={'submit btnNewProduct'} 
                                type='button' 
                                onClick={handleNewProduct}
                                id="add-new-product"
                            >
                                + {t('Add-new-product')}
                            </Button>
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="">
                            <TideReactTable 
                                {...table.tableProps} 
                                className="products-table"
                            />
                        </div>
                    </div>   
                </div>
            </div>
        </div>
    );
}

export default ProductsList;