import React, {useMemo, useState, useCallback, useContext } from 'react'
import { SectionTitle } from '../../../components/Dashboard/SectionTitle/SectionTitle';
import { SideMenu } from '../../../components/Dashboard/SideMenu/SideMenu';
import TopBar from '../../../components/Dashboard/TopBar/TopBar';
import {useTranslation} from "react-i18next";
import { SearchTextbox } from '../../../components/forms/SearchTextbox/SearchTextbox';
import Button from '../../../components/molecules/Button/Button';
import useTideTable from "../../../components/utility/TideReactTable/useTideTable";
import TideReactTable from "../../../components/utility/TideReactTable/TideReactTable";
import debounce from 'lodash.debounce';
import {paths} from "../../../services/routes/appRoutes";
import { useNavigate } from 'react-router-dom';
import './FollowUpsList.scss';
import { orchardVisitTableSGroups } from '../../../services/models/orchardVisitUtils';
import { generalDatesFormat } from '../../../services/generalConfig';
import moment from 'moment';
import './FollowUpsList.scss';
import { ApiContext } from '../../../services/api/api-config';
import { getNotifier } from '../../../services/notifier';
import Modal from '../../../components/Modal/Modal';

const customProp = "FollowUpsList";

const FollowUpsList = () => {
    const api = useContext(ApiContext);
    const [textFilter, setTextFilter] = useState('');
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [showingDeleteModalConfirm, setShowingDeleteModalConfirm] = useState();
    const [selectedRow, setSelectedRow] = useState();

    const searchFilter = useMemo(() => {
        return {
            search: textFilter
        }
    }, 
    [textFilter]);

    const requestFilters = useMemo(() => ({
        sGroups: orchardVisitTableSGroups,
        ...searchFilter
    }), [searchFilter]);

    const handleSearchTextChange = useCallback((e) => {
        setTextFilter(e.target.value);
    }, []);

    const debouncedChangeHandler = debounce(handleSearchTextChange, 400); 

    const requestOptions = useMemo(() => ({ customProp }), []);


    const columns = useMemo(() => ([
        {
            Header: 'id',
            accessor: 'id'
        },
        {
            Header: t('Productor'),
            accessor: visit => `${visit.orchard?.company?.businessName} - ${visit.orchard?.company?.contactEmail}`
        },
        {
            Header: t('Orchard'),
            accessor: visit => visit.orchard?.name
        },
        {
            Header: t('Created-by'),
            accessor: 'createdBy.email'
        },
        {
            Header: t('Date'),
            accessor: visit => moment(visit.date).format(generalDatesFormat)
        },
        {
            Header: t("Actions"),
            accessor: application => 
                    <div className="actions">
                        <Button 
                            onClick={() => navigate(paths.mm.followupsEdit.replace(":id", application.id))} 
                        >
                            {t('Edit')}
                        </Button>

                        <Button
                            onClick={() => {
                                setSelectedRow(application)
                                setShowingDeleteModalConfirm(true);
                            }} 
                            design="danger"
                        >
                            {t('Delete')}
                        </Button>
                    </div>
        }
    ]), [t, navigate]);

    const table = useTideTable({
        entity: 'orchardVisits',
        columns: columns,
        requestFilters,
        requestOptions
    });

    const handleNewWarehouse = useCallback(()=>navigate(paths.mm.followupsForm), [navigate]);

    const handleDeleteVisit = useCallback(() => {
        api.orchardVisits.delete({id: selectedRow.id}).then(() => {
            table.reload();
            setShowingDeleteModalConfirm(false);
            setSelectedRow(null);
            getNotifier().success(t('Visit-deleted-successfully'));
        }).catch(() => {
            getNotifier().error(t('Visit-deleted-error'));
            setSelectedRow(null);
        });
    }, [table, selectedRow, api, t]);

    return (
        <div className="FollowUpsList dashboard-container">
            <SideMenu />
            <div className='right-content'>
                <TopBar />
                <div className='main-content'>
                    <SectionTitle>{t('Follow-up-visits')}</SectionTitle>

                    <div className='row'>
                        <div className='col d-flex justify-content-between align-items-center gap-3'>
                            <SearchTextbox
                                placeholder={t('Search-by-orchard-name')}
                                onChange={debouncedChangeHandler}
                            />

                            <Button 
                                className={'submit btnNewWarehouse'} 
                                type='button' 
                                onClick={handleNewWarehouse}
                                id="add-new-visit"
                            >
                                + {t("Add-new-visit")}
                            </Button>
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="">
                            <TideReactTable 
                                {...table.tableProps}
                            />
                        </div>
                    </div>   
                </div>
            </div>

            {showingDeleteModalConfirm &&
                <Modal
                    title={t('Confirm-delete-visit')}
                    mainButtonText={t('Confirm')}
                    mainButtonColor='danger'
                    mainButtonAction={handleDeleteVisit}
                    secondaryButtonText={'Cancel'}
                    secondaryButtonAction={() => {
                        setShowingDeleteModalConfirm(false)
                        setSelectedRow(null);
                    }}
                >
                    <div className='GrowerChangeStatusButtons__modal-content'>
                        <div className='GrowerChangeStatusButtons__modal-content-title'>
                            {t('Are-you-sure-you-want-to-delete-this-visit')}
                        </div>
                    </div>
                </Modal>}
        </div>
    );
}

export default FollowUpsList