import React, {useCallback, useState} from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import MiniTitle from '../../../../../components/Dashboard/MiniTitle/MiniTitle';
import Switch from '../../../../../components/forms/Switch/Switch';
import TextInput from '../../../../../components/forms/TextInput/TextInput';
import Modal from '../../../../../components/Modal/Modal';
import Button from '../../../../../components/molecules/Button/Button';
import useBoolean from '../../../../../hooks/useBoolean';
import { ApiContext } from '../../../../../services/api/api-config';
import { getEmptyVariationValue } from '../../../../../services/models/variationValueUtils';
import { getNotifier } from '../../../../../services/notifier';
import { SecurityContext } from '../../../../../services/SecurityManager';
import { enabledLanguages } from '../../../../../i18n/config';
import './VariationsForm.scss';

const defaultLocale = 'es';

const VariationsForm = ({form, setForm, bindInput, reloadVariants, variationAttributes=[]}) => {
    const {t} = useTranslation();
    const api = useContext(ApiContext);
    const {me} = useContext(SecurityContext);
    const [isAddVariationAttributeModalOpen, openAddVariationAttributeModal, closeAddVariationAttributeModal] = useBoolean(false);
    const [isAddVariationValueModalOpen, openAddVariationValueModal, closeAddVariationValueModal] = useBoolean(false);
    const [isEditVariationAttributeModalOpen, openEditVariationAttributeModal, closeAEditVariationAttributeModal] = useBoolean(false);
    const [isEditVariationValueModalOpen, openEditVariationValueModal, closeEditVariationValueModal] = useBoolean(false);
    const [attributeToEdit, setAttributeToEdit] = useState();
    const [variationToEdit, setVariationToEdit] = useState();

    const cleanState = () => {
        setAttributeToEdit();
        setVariationToEdit();
    }
    const handleAddVariationAttribute = useCallback(async ()=>{
        // validate attribute for all languages
        const hasAllAttributes = enabledLanguages.map(l => {
            const attribute = form[`${l.value}_newAttributeName`];
            return (!attribute || attribute.length === 0);
        }).filter(h => h).length === 0;

        if(!hasAllAttributes) {
            return getNotifier().error(t('The-attribute-name-is-required-for-all-languages'));
        }

        let mainVariationAttributeId = null;

        // create main attribute with current language
        const attribute = form[`${defaultLocale}_newAttributeName`];
        const params = { lang: defaultLocale, attribute };

        try {
            mainVariationAttributeId = (await api.variationAttributes.create({params})).id;
        } catch (e) {
            return getNotifier().error(t('An-error-occurred-while-creating-the-attribute'));
        }

        enabledLanguages.forEach(async l => {
            const attribute = form[`${l.value}_newAttributeName`];
            const params = { lang: l.value, attribute };

            try {
                if(l.value !== defaultLocale) 
                    await api.variationAttributes.update({id: mainVariationAttributeId, params});
            } catch (e) {
                console.error(e);
            }
        });

        getNotifier().success(t('The-attribute-was-created-successfully'));
        closeAddVariationAttributeModal();
        cleanState();
        reloadVariants();
    },[ api.variationAttributes, closeAddVariationAttributeModal, form, reloadVariants, t ]);

    const handleAddVariationValue = useCallback(async()=>{
        let mainVariationValueId = null;

        // create main value with current language
        let newVariationValue = getEmptyVariationValue();
        newVariationValue.value = form[`${defaultLocale}_newAttributeValue`];
        newVariationValue.variationAttribute = attributeToEdit.id;
        const params = { lang: defaultLocale, ...newVariationValue };

        try {
            mainVariationValueId = (await api.variationValues.create({params})).id;
        } catch (e) {
            return getNotifier().error(t('An-error-occurred-while-creating-the-value'));
        }

        enabledLanguages.forEach(async l => {
            let newVariationValue = getEmptyVariationValue();
            newVariationValue.value = form[`${l.value}_newAttributeValue`];
            newVariationValue.variationAttribute = attributeToEdit.id;
            const vvParams = { lang: l.value, ...newVariationValue }

            try {
                if(l.value !== defaultLocale) 
                    await api.variationValues.update({id: mainVariationValueId, params: vvParams});
            } catch (e) {
                console.error(e);
            }
        });

        getNotifier().success(t('The-value-was-created-successfully'));
        closeAddVariationValueModal();
        cleanState();
        reloadVariants();
    },[api.variationValues, closeAddVariationValueModal, reloadVariants, attributeToEdit, form, t]);
      
    const handleOpenNewVariationValueModal = useCallback((attribute)=>{
        setAttributeToEdit(attribute);
        openAddVariationValueModal();
    }, [openAddVariationValueModal]);

    const showModalToEditVariationAttribute = useCallback(async (attribute) => {
        const translations = {};
        
        // set translation for all languages
        enabledLanguages.forEach(l => {
            translations[`${l.value}_newAttributeName`] = attribute.variationAttributeTranslations[l.value]?.attribute;
        });

        setForm({...form, ...translations});
        setAttributeToEdit(attribute);
        openEditVariationAttributeModal();
    } ,[openEditVariationAttributeModal, form, setForm]);
    
    const handleEditVariationAttribute = useCallback(async()=>{
        if(form.newAttributeName?.length<=0)
            return getNotifier().error(t('Please-enter-a-name-for-the-new-variation-attribute'));
        
        const variationAttributes = [...form.variationAttributes];

        // if variation attribute is not in form, add it
        if(!variationAttributes.find(attribute => attribute.id === attributeToEdit.id)){
            attributeToEdit.attribute = form.newAttributeName;
            variationAttributes.push(attributeToEdit);
        } else {
            // else update it
            const attributeIndex = variationAttributes.findIndex(attribute => attribute.id === attributeToEdit.id);
            variationAttributes[attributeIndex].attribute = form.newAttributeName;
        }
        
        // create main attribute with current language
        const params = { lang: me.locale, attribute: form[`${me.locale}_newAttributeName`] };

        try {
            await api.variationAttributes.update({id: attributeToEdit.id, params});
        } catch (e) {
            return getNotifier().error(t('An-error-occurred-while-creating-the-attribute'));
        }

        enabledLanguages.forEach(async l => {
            const attribute = form[`${l.value}_newAttributeName`];
            const params = { lang: l.value, attribute };

            try {
                await api.variationAttributes.update({id: attributeToEdit.id, params});
            } catch (e) {
                console.error(e);
            }
        });

        closeAddVariationAttributeModal();
        reloadVariants();
        cleanState();
        getNotifier().success(t('Updated-successfully'));
    }, [me, api.variationAttributes, closeAddVariationAttributeModal, reloadVariants, attributeToEdit, form, t]);

    const showModalToEditVariationValue = useCallback((attribute, variationValueIndex, variationValue)=>{
        const translations = {};
        
        // set translation for all languages
        enabledLanguages.forEach(l => {
            translations[`${l.value}_newAttributeValue`] = variationValue.variationValueTranslations[l.value]?.value;
        });


        setAttributeToEdit(attribute);
        setVariationToEdit(variationValue);
        setForm({...form, ...translations});
        openEditVariationValueModal();
    } ,[openEditVariationValueModal, setForm, form]);

    const handleEditVariationValue = useCallback(()=>{
        // actualizar el valor de la variante
        enabledLanguages.forEach(async l => {
            let newVariationValue = getEmptyVariationValue();
            newVariationValue.value = form[`${l.value}_newAttributeValue`];
            newVariationValue.variationAttribute = attributeToEdit.id;
            const vvParams = { lang: l.value, ...newVariationValue }

            try {
                await api.variationValues.update({id: variationToEdit.id, params: vvParams});
            } catch (e) {
                console.error(e);
            }
        });

        getNotifier().success(t('The-value-was-created-successfully'));
        closeAddVariationValueModal();
        cleanState();
        reloadVariants();
    }, [api.variationValues, closeAddVariationValueModal, reloadVariants, attributeToEdit, form, variationToEdit, t]);

    const handleToggleVariationAttribute = useCallback((atributeId)=>{
        const variationAttribute = variationAttributes.find(attribute => attribute.id === atributeId);

        // if exists in form, remove it
        if(form.variationAttributes.find(va => va?.id === variationAttribute?.id)){
            const variationAttributes = [...form.variationAttributes];
            variationAttributes.splice(variationAttributes.findIndex(va => va?.id === variationAttribute?.id), 1);
            setForm({
                ...form,
                variationAttributes: variationAttributes
            });
        }  else {
            setForm(prevstate => {
                const variationAttributes = [...prevstate.variationAttributes];
                variationAttributes.push(variationAttribute);
                
                return {
                    ...prevstate,
                    variationAttributes: variationAttributes
                }
            });
        }
    }, [setForm, form, variationAttributes]);
    
    const handleToggleVariationValue = useCallback((variationAttributeId, variationValueId)=>{
        const _variationAttribute = variationAttributes.find(va => va.id === variationAttributeId);
        const _variationValue = _variationAttribute?.variationValues.find(vv => vv.id === variationValueId);

        // toggle variation value
        _variationValue.active = !_variationValue.active;

        // if variation attribute is not in form, add it
        if(!form.variationAttributes.find(va => va.id === _variationAttribute.id)){
            setForm(prevstate => {
                const variationAttributes = [...prevstate.variationAttributes, _variationAttribute];
                
                return {
                    ...prevstate,
                    variationAttributes: variationAttributes
                }
            });
        } else {
            setForm(prevstate => {
                const variationAttributes = [...prevstate.variationAttributes, _variationAttribute];
                
                return {
                    ...prevstate,
                    variationAttributes: variationAttributes
                }
            });
        }
    }, [setForm, form, variationAttributes]);

    return (
        <div className='VariationsForm row mt-3'>
            <div className='col margin-top-mobile'>
                <MiniTitle>{t('Variants')}</MiniTitle>
                
                <table className='simple-table'>
                    <thead>
                        <tr>
                            <th>{t('Attribute')}</th>
                            <th>{t('Values')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {variationAttributes?.map((variationAttribute, attributeIndex) => (
                            <tr key={attributeIndex} className="variation-row">
                                <td>
                                    <div className='d-flex justify-content-between pl-2 '>
                                        <div className="variation-value-center">
                                            <Switch
                                                value={form.variationAttributes.find(va => va.id === variationAttribute.id)||''}
                                                onChange={()=>handleToggleVariationAttribute(variationAttribute.id)}
                                            />
                                            
                                            <div className="attribute-label" onClick={()=>showModalToEditVariationAttribute(variationAttribute)}>
                                                {variationAttribute.attribute}
                                            </div>
                                        </div>

                                        <Button design='link' onClick={() => handleOpenNewVariationValueModal(variationAttribute)}>
                                            {t('Add-value')}
                                        </Button>
                                    </div>
                                </td>
                                <td>
                                    <div className='variation-values'>
                                        {variationAttribute.variationValues?.map((variationValue, variationValueIndex) => (
                                            <div key={variationValueIndex} className="variation-value-container">
                                                <Switch
                                                    value={ form.variationAttributes?.find(va => va?.id === variationAttribute?.id)?.variationValues?.find(vv => vv?.id === variationValue?.id)?.active || '' }
                                                    onChange={()=>handleToggleVariationValue(variationAttribute.id, variationValue.id)}
                                                />

                                                <div className="attribute-label" onClick={()=>showModalToEditVariationValue(variationAttribute, variationValueIndex, variationValue)}>
                                                    {variationValue?.value||''}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </td>
                            </tr>))}
                    </tbody>
                </table>

                <div className='row mt-3'>
                    <div className='col-sm-6 margin-top-mobile'>
                        <Button onClick={openAddVariationAttributeModal}>
                            {t('Add-variant')}
                        </Button>
                    </div>
                </div>
            </div>

            {isEditVariationAttributeModalOpen &&
                <Modal
                    title={t('Edit-variant-attribute')}
                    isOpen={isEditVariationAttributeModalOpen}
                    onClose={closeAEditVariationAttributeModal}
                    mainButtonAction={handleEditVariationAttribute}
                    confirmText={t('Update')}
                    cancelText={t('Cancel')}
                >

                    {enabledLanguages.map((language, index) => 
                        <div key={index}>
                            <MiniTitle>{t(language.label)}</MiniTitle>
                            <div className='row'>
                                <div className='col'>
                                    <TextInput
                                        label={t('Attribute')}
                                        placeholder={t('Attribute')}
                                        {...bindInput(`${language.value}_newAttributeName`)}
                                    />
                                </div>
                            </div>
                        </div>)}
                </Modal>}
                
            {isAddVariationAttributeModalOpen &&
                <Modal
                    title={t('Add-variant-attribute')}
                    isOpen={isAddVariationAttributeModalOpen}
                    onClose={closeAddVariationAttributeModal}
                    mainButtonAction={handleAddVariationAttribute}
                    confirmText={t('Add')}
                    cancelText={t('Cancel')}
                >
                    {enabledLanguages.map((language, index) => 
                        <div key={index}>
                            <MiniTitle>{t(language.label)}</MiniTitle>
                            <div className='row'>
                                <div className='col'>
                                    <TextInput
                                        label={t('Attribute')}
                                        placeholder={t('Attribute')}
                                        {...bindInput(`${language.value}_newAttributeName`)}
                                    />
                                </div>
                            </div>
                        </div>)}
                </Modal>}

            {isEditVariationValueModalOpen &&
                <Modal
                    title={t('Edit-variant-value')}
                    isOpen={isEditVariationValueModalOpen}
                    onClose={closeEditVariationValueModal}
                    mainButtonAction={handleEditVariationValue}
                    confirmText={t('Edit')}
                    cancelText={t('Cancel')}
                >
                    <div className='row'>
                        <div className='col'>
                            {enabledLanguages.map((language, index) => 
                                <div key={index}>
                                    <MiniTitle>{t(language.label)}</MiniTitle>
                                    <TextInput
                                        label={t('Value')}
                                        placeholder={t('Value')}
                                        {...bindInput(`${language.value}_newAttributeValue`)}
                                    />
                                </div>)}
                        </div>
                    </div>
                </Modal>}
                
            {isAddVariationValueModalOpen &&
                <Modal
                    title={t('Add-variety-value')}
                    isOpen={isAddVariationValueModalOpen}
                    onClose={closeAddVariationValueModal}
                    mainButtonAction={handleAddVariationValue}
                    confirmText={t('Add')}
                    cancelText={t('Cancel')}
                >
                    <div className='row'>
                        <div className='col'>
                        {enabledLanguages.map((language, index) => 
                            <div key={index}>
                                <MiniTitle>{t(language.label)}</MiniTitle>
                                <TextInput
                                    label={t('Value')}
                                    placeholder={t('Value')}
                                    {...bindInput(`${language.value}_newAttributeValue`)}
                                />
                            </div>)}
                        </div>
                    </div>
                </Modal>}
        </div>
    );
}

export default VariationsForm;