import React from 'react';
import moment from 'moment';
import {generalDatesFormat} from '../../../../../services/generalConfig';
//import {ReactComponent as ArrowUpIcon} from "../../../../../assets/images/icons/chevron-up-orange.svg";
//import {ReactComponent as ArrowDownIcon} from "../../../../../assets/images/icons/chevron-down-orange.svg";
//import useBoolean from '../../../../../hooks/useBoolean';
import './VisitDetails.scss';

const VisitDetails = ({visit}) => {
    return (
        <div className='VisitDetails'>
            <div className='VisitDetails__name'>
                { visit.companyProduct?.name }

                {/*showForm ? <ArrowUpIcon onClick={close} /> : <ArrowDownIcon onClick={open} />*/}
            </div>
            <div className='VisitDetails__date'>
                { moment(visit.date).format(generalDatesFormat) }
            </div>
            <div className='VisitDetails__productor'>
                <span>Productor: { visit.companyProduct?.sector?.orchard?.company?.companyName }</span>
            </div>
        </div>
    );
}

export default VisitDetails;