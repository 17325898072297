import React, {useMemo, useCallback, useState } from 'react'
import { SectionTitle } from '../../../components/Dashboard/SectionTitle/SectionTitle';
import { SideMenu } from '../../../components/Dashboard/SideMenu/SideMenu';
import TopBar from '../../../components/Dashboard/TopBar/TopBar';
import {useTranslation} from "react-i18next";
import Button from '../../../components/molecules/Button/Button';
import useTideTable from "../../../components/utility/TideReactTable/useTideTable";
import TideReactTable from "../../../components/utility/TideReactTable/TideReactTable";
import { ADMIN_ROLE_ID, usersWithPermissionsGSGroups } from '../../../services/models/userUtils';
import moment from 'moment';
import { generalDatesFormat } from '../../../services/generalConfig';
import {ReactComponent as SettingsGreenIcon} from "../../../assets/images/icons/settings-green.svg";
import UserAvatar from './components/UserAvatar/UserAvatar';
import Card from '../../../components/Dashboard/Card/Card';
import MiniTitle from '../../../components/Dashboard/MiniTitle/MiniTitle';
import {ReactComponent as RoleAdminIcon} from "../../../assets/images/icons/role-admin.svg";
import { SearchTextbox } from '../../../components/forms/SearchTextbox/SearchTextbox';
import UsersForm from '../UsersForm/UsersForm';
import './UsersList.scss';
import UserStatus from './components/UserStatus/UserStatus';
import ManageUserModal from './components/ManageUserModal/ManageUserModal';
import { useSelector } from 'react-redux';
import useFormState from '../../../hooks/useFormState';

const customProp = "UsersList";

const UsersList = () => {
    const {t} = useTranslation();
    const me = useSelector(({api})=>api.me);
    const [showingModalUser, setShowingModalUser] = useState();
    const [showingModalManageUser, setShowingModalManageUser] = useState();
    const [selectedUser, setSelectedUser] = useState(null);
    const {form, setForm} = useFormState({});

    const handleOpenUserDetails = useCallback((user) => {
        setSelectedUser(user);
        setShowingModalUser(true);
    }, [setSelectedUser, setShowingModalUser]);

    const handleOpenManageUserDetails = useCallback((user) => {
        setSelectedUser(user);
        setShowingModalManageUser(true);
    }, [setSelectedUser, setShowingModalManageUser]);
    
    const usersTableColumns = useMemo(() => [
        {
            Header: ' ',
            id: 'commands',            
            accessor: user => 
                <div className='actions-column'>
                    {/*<input type="checkbox" />*/}
                    <UserStatus user={user} />
                </div>
        },
        {
            Header: 'id',
            accessor: 'id',
            disableSortBy: false,
        },
        {
            Header: t('Name'),
            id: 'name',
            disableSortBy: false,
            accessor: user => <UserAvatar user={user} onClick={() => handleOpenUserDetails(user)} />
        },
        {
            Header: t('Email'),
            accessor: 'email',
            disableSortBy: false,
        },
        {
            Header: t('Phone'),
            accessor: 'phone',
            disableSortBy: false,
        },
        {
            Header: t('Created-On'),
            accessor: user => moment(user.createdDate).format( generalDatesFormat ),
        },
        {
            Header: t("Permissions"),
            accessor: user => (user.role.id!==ADMIN_ROLE_ID && me.id!==user.id ) &&
                                <div className='manage-user-button' onClick={() => handleOpenManageUserDetails(user)}>
                                    <div className='settings-button'>
                                        <SettingsGreenIcon />
                                        {t('Manage')}
                                    </div>
                                </div>
        }
    ], [t, handleOpenUserDetails, handleOpenManageUserDetails, me.id]);

    const requestFilters = useMemo(() => ({
        sGroups: usersWithPermissionsGSGroups,
        search: form.email
    }), [form.email]);
    
    const requestOptions = useMemo(() => ({ customProp }), []);

    const table = useTideTable({
        entity: 'users',
        columns: usersTableColumns,
        requestFilters,
        requestOptions,
    });
    
    const handleNewUser = useCallback(()=>setShowingModalUser(true), []);
    const onClose = useCallback(() => {
        setShowingModalUser(false);
        setSelectedUser(null);
        table.reload();
    }, [setShowingModalUser, setSelectedUser, table]);

    const onCloseManageUser = useCallback(() => {
        setShowingModalManageUser(false);
        table.reload();
    }, [setShowingModalManageUser, table]);

    return (
        <div className="UsersList dashboard-container">
            <SideMenu />
            <div className='right-content'>
                <TopBar />
                <div className='main-content'>
                    <div className='col d-flex justify-content-between align-items-center gap-3 mt-4'>
                        <SectionTitle className="m-0 p-0">{t('My-users')}</SectionTitle>

                        <Button 
                            className={'submit btnNewProducer'} 
                            type='button' 
                            onClick={handleNewUser}
                            id="add-new-user"
                        >
                            + {t("Add-new-user")}
                        </Button>
                    </div>

                    <div className="row mt-3">
                        <Card>
                            <div>
                                <MiniTitle className='permissions-title'>{t('User-permissions')}</MiniTitle>

                                <div className='user-permissions-description'>
                                    {t('User-permissions-description')}
                                </div>

                                <div className='role-with-icon'>
                                    <span className='bold d-flex justify-content-center align-items-center gap-3'>
                                        {t('Admin-user')} <RoleAdminIcon />
                                    </span>
                                </div>

                                <div className='d-flex justify-content-between align-items-center gap-3 mt-3 mb-3'>
                                    <SearchTextbox
                                        placeholder={t('Search-by-name, email, id')}
                                        value={form.email}
                                        onChange={e => setForm({email: e.target.value})}
                                    />

                                    {/*<Button
                                        className={'submit btnNewProducer'} 
                                        type='button' 
                                        onClick={handleNewUser}
                                        id="add-new-user"
                                    >
                                        + {t("More-actions")}
                                    </Button>*/}
                                </div>
                            </div>

                            <TideReactTable 
                                {...table.tableProps}
                                className="users-table"
                            />
                        </Card>
                    </div>   
                </div>
            </div>

            {showingModalUser && <UsersForm onClose={onClose} selectedUserId={selectedUser?.id} />}
            {showingModalManageUser && <ManageUserModal onClose={onCloseManageUser} user={selectedUser} />}
        </div>
    );
}

export default UsersList